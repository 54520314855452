<mat-form-field floatLabel="always">
    <mat-label>{{'CORE.SEARCH.SORT_BY' | translate}}</mat-label>
    <mat-select [(value)]="selected" (selectionChange)="onOptionChanged($event)">
        <mat-option *ngFor="let option of options" [value]="option.key">
            {{ option.label | translate }}
        </mat-option>
    </mat-select>
</mat-form-field>

<button *ngIf="selected" mat-icon-button (click)="toggleSortDirection()" aria-label="'CORE.SEARCH.TOGGLE_ASC_DESC_ORDER' | translate">
    <mat-icon *ngIf="ascending">arrow_upward</mat-icon>
    <mat-icon *ngIf="!ascending">arrow_downward</mat-icon>
</button>
