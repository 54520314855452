<aca-page-layout *ngIf="loaded">
  <div class="aca-page-layout-header">
    <button mat-icon-button [routerLink]="landingPage">
      <mat-icon class="app-profile-icon">arrow_back</mat-icon>
    </button>
    <h1>{{ 'APP.BROWSE.ABOUT.TITLE' | translate }}</h1>
  </div>

  <div class="aca-page-layout-content scrollable">
    <adf-about>
      <adf-about-panel *ngIf="dev" [label]="'ABOUT.SERVER_SETTINGS.TITLE' | translate">
        <ng-template>
            <adf-about-server-settings></adf-about-server-settings>
        </ng-template>
      </adf-about-panel>

      <adf-about-panel [label]="'ABOUT.REPOSITORY' | translate" *ngIf="repository">
        <ng-template>
            <adf-about-repository-info [data]="repository"></adf-about-repository-info>
        </ng-template>
      </adf-about-panel>

      <adf-about-panel *ngIf="dev" [label]="'ABOUT.PACKAGES.TITLE' | translate">
        <ng-template>
            <adf-about-package-list [dependencies]="packageJson?.dependencies"></adf-about-package-list>
        </ng-template>
      </adf-about-panel>

      <adf-about-panel *ngIf="extensions$ | async as extensions" [label]="'ABOUT.PLUGINS.TITLE' | translate">
        <ng-template>
            <adf-about-extension-list [data]="extensions"></adf-about-extension-list>
        </ng-template>
      </adf-about-panel>

      <adf-about-panel [label]="'APP.SETTINGS.GROUPS' | translate">
        <ng-template>
          <mat-form-field>
            <input
              matInput
              (keyup)="applyGroupsFilter()"
              [(ngModel)]="filterGroups"
              [placeholder]="filterPlaceholder" />
          </mat-form-field>
          <div class="datatable-container">
            <adf-datatable
              [rows]="groupsRows" 
              [columns]="groupsSchema"
              [actions]="true"
              (showRowActionsMenu)="onShowRowActionsGroupMenu($event)"
              (executeRowAction)="onExecuteRowGroupAction($event)"> 
            </adf-datatable>
          </div>
          <div class="settings-buttons">
            <button mat-button (click)="onNewGroup()">
              {{ 'APP.SETTINGS.NEW_GROUP' | translate }}
            </button>
          </div>
        </ng-template>
      </adf-about-panel>
  
      <adf-about-panel [label]="'APP.SETTINGS.USERS' | translate">
        <ng-template>
          <mat-form-field>
            <input
              matInput
              (keyup)="applyUsersFilter()"
              [(ngModel)]="filterUsers"
              [placeholder]="filterPlaceholder" />
          </mat-form-field>
          <div class="datatable-container">
            <adf-datatable
              [rows]="usersRows"
              [columns]="usersSchema"
              [actions]="true"
              (showRowActionsMenu)="onShowRowActionsUsersMenu($event)"
              (executeRowAction)="onExecuteUsersRowAction($event)">
            </adf-datatable>
          </div>
          <div class="settings-buttons">
            <button mat-button (click)="onNewUser()">
              {{ 'APP.SETTINGS.NEW_USER' | translate }}
            </button>
          </div>
        </ng-template>
      </adf-about-panel>
  
      <adf-about-panel [label]="'APP.SETTINGS.SITES' | translate">
        <ng-template>
          <mat-form-field>
            <input
              matInput
              (keyup)="applySitesFilter()"
              [(ngModel)]="filterSites"
              [placeholder]="filterPlaceholder" />
          </mat-form-field>
          <div class="datatable-container">
            <adf-datatable
              [rows]="sitesRows" 
              [columns]="sitesSchema"
              [actions]="true"
              (showRowActionsMenu)="onShowRowActionsSitesMenu($event)"
              (executeRowAction)="onExecuteSitesRowAction($event)"> 
            </adf-datatable>
          </div>
          <div class="settings-buttons">
            <button mat-button (click)="onNewSite()">
              {{ 'APP.SETTINGS.NEW_SITE' | translate }}
            </button>
          </div>
        </ng-template>
      </adf-about-panel>
    </adf-about>
  </div>
</aca-page-layout>
