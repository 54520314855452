/*!
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Alfresco Example Content Application
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail. Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * from Hyland Software. If not, see <http://www.gnu.org/licenses/>.
 */

import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { DEV_MODE_TOKEN } from './dev-mode.tokens';
import { AboutModule, AppConfigService, AuthenticationService, DataCellEvent, DataColumn, DataRowActionEvent, DataTableModule, RepositoryInfo, TranslationService } from '@alfresco/adf-core';
import { ConfirmDialogComponent, DiscoveryApiService, } from '@alfresco/adf-content-services';
import { PACKAGE_JSON } from './package-json.token';
import { TranslateModule } from '@ngx-translate/core';
import { AppExtensionService, Constants, debounce, PageLayoutComponent, SiteService, UserService, GroupService } from '@alfresco/aca-shared';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ProfileState } from '@alfresco/adf-extensions';
import { Observable } from 'rxjs';
import { AppStore, getGroupList, getUserList, getUserProfile, SetGroupListAction, SetUserListAction, SnackbarErrorAction, SnackbarInfoAction } from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Person } from '@alfresco/js-api';
import { MatDialog } from '@angular/material/dialog';
import { CreateGroupDialogComponent } from './components/create-group-dialog/create-group-dialog.component';
import { CreateSiteDialogComponent } from './components/create-site-dialog/create-site-dialog.component';
import { CreateUserDialogComponent } from './components/create-user-dialog/create-user-dialog.component';
import { GroupMembersDialogComponent } from './components/group-members-dialog/group-members-dialog.component';
import { SiteMembersDialogComponent } from './components/site-members-dialog/site-members-dialog.component';
import { UserGroupsDialogComponent } from './components/user-groups-dialog/user-groups-dialog.component';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, AboutModule, RouterModule, FormsModule, MatFormFieldModule, MatInputModule, MatIconModule, MatButtonModule, DataTableModule, PageLayoutComponent],
  selector: 'app-about-page',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AboutComponent implements OnInit {
  private authService = inject(AuthenticationService);
  private appExtensions = inject(AppExtensionService);
  private discovery = inject(DiscoveryApiService);
  private appConfigService = inject(AppConfigService);
  private translation = inject(TranslationService);
  private store = inject(Store<AppStore>);
  private groupService = inject(GroupService);
  private userService = inject(UserService);
  private siteService = inject(SiteService);
  private dialog = inject(MatDialog);
  public packageJson? = inject(PACKAGE_JSON, { optional: true });
  public dev = inject(DEV_MODE_TOKEN);

  extensions$ = this.appExtensions.references$;
  repository: RepositoryInfo = null;
  landingPage = this.appConfigService.get('landingPage', '/personal-files');

  filterGroups: string;
  groups: object[];
  groupsRows: object[];
  groupsSchema: DataColumn[];

  filterUsers: string;
  users: object[];
  usersRows: object[];
  usersSchema: DataColumn[];

  filterSites: string;
  sites: object[];
  sitesRows: object[];
  sitesSchema: DataColumn[];

  filterPlaceholder: string;
  usersFilterDebounce: Function;
  groupsFilterDebounce: Function;
  sitesFilterDebounce: Function;

  profile$: Observable<ProfileState>;
  loaded: boolean = false;

  ngOnInit(): void {
    if (this.authService.isEcmLoggedIn()) {
      this.setECMInfo();
    }

    this.profile$ = this.store.select(getUserProfile);
    this.profile$.subscribe(user => {
      if (!user.isAdmin)
        window.location.href = '';
      else
        this.loaded = true;
    });

    this.getGroups();
    this.getUsers();
    this.getSites();

    this.groupsSchema = [
      {
        type: 'text',
        key: 'id',
        title: this.translation.instant('APP.SETTINGS.ID'),
        sortable: true
      },
      {
        type: 'text',
        key: 'displayName',
        title: this.translation.instant('APP.SETTINGS.DISPLAY_NAME'),
        cssClass: 'full-width',
        sortable: true
      }
    ];
    this.usersSchema = [
      {
        type: 'text',
        key: 'id',
        title: this.translation.instant('APP.SETTINGS.ID'),
        sortable: true
      },
      {
        type: 'text',
        key: 'displayName',
        title: this.translation.instant('APP.SETTINGS.DISPLAY_NAME'),
        cssClass: 'full-width',
        sortable: true
      },
      {
        type: 'text',
        key: 'email',
        title: this.translation.instant('APP.SETTINGS.EMAIL'),
        cssClass: 'full-width',
        sortable: true
      }
    ];
    this.sitesSchema = [
      {
        type: 'text',
        key: 'id',
        title: this.translation.instant('APP.SETTINGS.ID'),
        sortable: true
      },
      {
        type: 'text',
        key: 'title',
        title: this.translation.instant('APP.SETTINGS.TITLE'),
        sortable: true
      },
      {
        type: 'text',
        key: 'description',
        title: this.translation.instant('APP.SETTINGS.DESCRIPTION'),
        cssClass: 'full-width',
        sortable: true
      },
      {
        type: 'text',
        key: 'visibility',
        title: this.translation.instant('APP.SETTINGS.VISIBILITY'),
        cssClass: 'full-width',
        sortable: true
      }
    ];

    this.filterPlaceholder = this.translation.instant('APP.SETTINGS.FILTER');
  }

  setECMInfo() {
    this.discovery.getEcmProductInfo().subscribe((repository) => {
      this.repository = repository as RepositoryInfo;
    });
  }

  getGroups() {
    this.store.select(getGroupList).subscribe((groupList) => {
      this.groups = groupList;
      this.groupsRows = this.groups;
      this.applyGroupsFilter(true);
    });
  }

  loadGroups() {
    this.groupService
      .listGroups()
      .then((response: any) => {
        this.store.dispatch(new SetGroupListAction({ groups: response }));
        this.getGroups();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onShowRowActionsGroupMenu(event: DataCellEvent) {
    const editAction = {
      id: 0,
      title: this.translation.instant('APP.ACTIONS.EDIT')
    };
    const deleteAction = {
      id: 1,
      title: this.translation.instant('APP.ACTIONS.DELETE')
    };
    const usersAction = {
      id: 2,
      title: this.translation.instant('APP.ACTIONS.VIEW_USERS')
    };
    event.value.actions = [editAction, deleteAction, usersAction];
  }

  onExecuteRowGroupAction = (event: DataRowActionEvent) => {
    const args = event.value;
    if (args.action.id === 0) {
      this.openEditGroupDialog(args.row);
    } else if (args.action.id === 1) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'APP.SETTINGS.DELETE_GROUP_TITLE',
          message: this.translation.instant('APP.SETTINGS.DELETE_GROUP_MESSAGE').replace('{0}', args.row.obj.displayName),
          yesLabel: 'APP.DIALOGS.CONFIRM_DELETE.YES_LABEL',
          noLabel: 'APP.DIALOGS.CONFIRM_DELETE.NO_LABEL'
        },
        minWidth: '250px'
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.deleteGroup(args.row.obj.id, args.row.obj.displayName);
        }
      });
    } else if (args.action.id === 2) {
      this.openGroupViewUsersDialog(args.row.obj.id, args.row.obj.displayName);
    }
  };

  applyGroupsFilter(skipTimeout = false) {
    if (!this.groupsFilterDebounce) this.groupsFilterDebounce = debounce(Constants.CONTINUED_TYPING_THRESHOLD_VALUE);

    const proc = () => {
      if (!this.filterGroups) this.groupsRows = this.groups;
      else
        this.groupsRows = this.groups.filter(
          (group) =>
            group['id'].toLowerCase().indexOf(this.filterGroups.toLowerCase()) !== -1 ||
            group['displayName'].toLowerCase().indexOf(this.filterGroups.toLowerCase()) !== -1
        );
    };

    if (!skipTimeout) {
      this.groupsFilterDebounce(proc);
    } else {
      proc();
    }
  }

  onNewGroup() {
    const dialogRef = this.dialog.open(CreateGroupDialogComponent, {
      width: '450px'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.loadGroups();
    });
  }

  openEditGroupDialog(element): void {
    const dialogRef = this.dialog.open(CreateGroupDialogComponent, {
      width: '450px',
      data: element.obj
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.loadGroups();
    });
  }

  deleteGroup(id, displayName) {
    this.groupService.deleteGroup(id).then(
      (_) => {
        this.store.dispatch(new SnackbarInfoAction(this.translation.instant('APP.SETTINGS.GROUP_DELETED').replace('{0}', displayName)));
        this.loadGroups();
      },
      (_) => {
        this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      }
    );
  }

  openGroupViewUsersDialog(groupId: string, groupName: string) {
    this.dialog.open(GroupMembersDialogComponent, {
      width: '600px',
      data: { groupId, groupName }
    });
  }

  getUsers() {
    this.store.select(getUserList).subscribe((userList) => {
      this.users = userList;
      this.usersRows = this.users;
      this.applyUsersFilter(true);
    });
  }

  loadUsers() {
    this.userService
      .listUsers()
      .then((response: any) => {
        this.store.dispatch(new SetUserListAction({ users: response as Person[] }));
        this.getUsers();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onShowRowActionsUsersMenu(event: DataCellEvent) {
    const editAction = {
      id: 0,
      title: this.translation.instant('APP.ACTIONS.EDIT')
    };
    const deleteAction = {
      id: 1,
      title: this.translation.instant('APP.ACTIONS.DELETE')
    };
    const groupsAction = {
      id: 2,
      title: this.translation.instant('APP.ACTIONS.VIEW_GROUPS')
    };
    event.value.actions = [editAction, deleteAction, groupsAction];
  }

  onExecuteUsersRowAction(event: DataRowActionEvent) {
    let args = event.value;
    if (args.action.id === 0) {
      this.openEditUserDialog(args.row);
    } else if (args.action.id === 1) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'APP.SETTINGS.DELETE_USER_TITLE',
          message: this.translation.instant('APP.SETTINGS.DELETE_USER_MESSAGE').replace('{0}', args.row.obj.displayName),
          yesLabel: 'APP.DIALOGS.CONFIRM_DELETE.YES_LABEL',
          noLabel: 'APP.DIALOGS.CONFIRM_DELETE.NO_LABEL'
        },
        minWidth: '250px'
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.deleteUser(args.row.obj.id, args.row.obj.displayName);
        }
      });
    } else if (args.action.id === 2) {
      this.openUserViewGroupsDialog(args.row.obj.id, args.row.obj.displayName);
    }
  }

  applyUsersFilter(skipTimeout = false) {
    if (!this.usersFilterDebounce) this.usersFilterDebounce = debounce(Constants.CONTINUED_TYPING_THRESHOLD_VALUE);

    const proc = () => {
      if (!this.filterUsers) this.usersRows = this.users;
      else
        this.usersRows = this.users.filter(
          (user) =>
            user['id'].toLowerCase().indexOf(this.filterUsers.toLowerCase()) !== -1 ||
            user['displayName'].toLowerCase().indexOf(this.filterUsers.toLowerCase()) !== -1 ||
            (user['email'] && user['email'].toLowerCase().indexOf(this.filterUsers.toLowerCase()) !== -1)
        );
    };

    if (!skipTimeout) {
      this.usersFilterDebounce(proc);
    } else {
      proc();
    }
  }

  onNewUser() {
    const dialogRef = this.dialog.open(CreateUserDialogComponent, {
      width: '450px'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.loadUsers();
    });
  }

  openEditUserDialog(element): void {
    const dialogRef = this.dialog.open(CreateUserDialogComponent, {
      width: '450px',
      data: element.obj
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.loadUsers();
    });
  }

  deleteUser(userId, userDisplayName) {
    this.userService.deleteUser(userId).then(
      (_) => {
        this.store.dispatch(new SnackbarInfoAction(this.translation.instant('APP.SETTINGS.USER_DELETED').replace('{0}', userDisplayName)));
        this.loadUsers();
      },
      (_) => {
        this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      }
    );
  }

  openUserViewGroupsDialog(username: string, displayName: string) {
    this.dialog.open(UserGroupsDialogComponent, {
      width: '600px',
      data: { username, displayName }
    });
  }

  getSites() {
    this.siteService
      .listSites()
      .then((data: any) => {
        this.sites = data;
        this.sitesRows = this.sites;
        this.applySitesFilter(true);
      })
      .catch((_) => {
        this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      });
  }

  onShowRowActionsSitesMenu(event: DataCellEvent) {
    const editAction = {
      id: 0,
      title: this.translation.instant('APP.ACTIONS.EDIT')
    };
    const deleteAction = {
      id: 1,
      title: this.translation.instant('APP.ACTIONS.DELETE')
    };
    const membersAction = {
      id: 2,
      title: this.translation.instant('APP.ACTIONS.VIEW_MEMBERS')
    };
    event.value.actions = [editAction, deleteAction, membersAction];
  }

  onExecuteSitesRowAction(event: DataRowActionEvent) {
    let args = event.value;
    if (args.action.id === 0) {
      this.openEditSiteDialog(args.row);
    } else if (args.action.id === 1) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'APP.SETTINGS.DELETE_SITE_TITLE',
          message: this.translation.instant('APP.SETTINGS.DELETE_SITE_MESSAGE').replace('{0}', args.row.obj.title),
          yesLabel: 'APP.DIALOGS.CONFIRM_DELETE.YES_LABEL',
          noLabel: 'APP.DIALOGS.CONFIRM_DELETE.NO_LABEL'
        },
        minWidth: '250px'
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.deleteSite(args.row.obj.id, args.row.obj.title);
        }
      });
    } else if (args.action.id === 2) {
      this.openSiteViewMembersDialog(args.row.obj.id, args.row.obj.title);
    }
  }

  applySitesFilter(skipTimeout = false) {
    if (!this.sitesFilterDebounce) this.sitesFilterDebounce = debounce(Constants.CONTINUED_TYPING_THRESHOLD_VALUE);

    const proc = () => {
      if (!this.filterSites) this.sitesRows = this.sites;
      else
        this.sitesRows = this.sites.filter(
          (site) =>
            site['id'].toLowerCase().indexOf(this.filterSites.toLowerCase()) !== -1 ||
            site['title'].toLowerCase().indexOf(this.filterSites.toLowerCase()) !== -1
        );
    };

    if (!skipTimeout) {
      this.sitesFilterDebounce(proc);
    } else {
      proc();
    }
  }

  onNewSite() {
    const dialogRef = this.dialog.open(CreateSiteDialogComponent, {
      width: '450px'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.getSites();
    });
  }

  openEditSiteDialog(element): void {
    const dialogRef = this.dialog.open(CreateSiteDialogComponent, {
      width: '450px',
      data: element.obj
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.getSites();
    });
  }

  deleteSite(id, displayName) {
    this.siteService.deleteSite(id).then(
      (_) => {
        this.store.dispatch(new SnackbarInfoAction(this.translation.instant('APP.SETTINGS.SITE_DELETED').replace('{0}', displayName)));
        this.getSites();
      },
      (_) => {
        this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      }
    );
  }

  openSiteViewMembersDialog(siteId, siteTitle) {
    this.dialog.open(SiteMembersDialogComponent, {
      width: '600px',
      data: { siteId, siteTitle }
    });
  }
}
