import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageLayoutComponent, PageLayoutContentComponent, PageLayoutHeaderComponent, WorkflowService, } from '@alfresco/aca-shared';
import { DataColumn, DataTableModule, TranslationService } from '@alfresco/adf-core';
import { Utils } from '../utils';
import { Router } from '@angular/router';
import { BreadcrumbModule, ConfirmDialogComponent } from '@alfresco/adf-content-services';
import { Node, } from '@alfresco/js-api';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppStore, SnackbarErrorAction, SnackbarInfoAction } from '@alfresco/aca-shared/store';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NeocomDocumentSelectorComponent } from '../../neocom-document-selector/neocom-document-selector.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PageLayoutComponent,
    PageLayoutHeaderComponent,
    PageLayoutContentComponent,
    BreadcrumbModule,
    NeocomDocumentSelectorComponent,
    MatProgressSpinnerModule,
    MatButtonModule,
    DataTableModule,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-workflow-details',
  templateUrl: './workflow-details.component.html',
  styleUrls: ['./workflow-details.component.scss']
})
export class WorkflowDetailsComponent implements OnInit {

  activitiId: string = '';
  additionalDescription: string;
  isLoading: boolean = true;
  workflowError: any = null;
  workflow: any;
  workflowStartTask: any;
  workflowResolvedData: any[];

  initiallySelectedDocuments: Node[] = [];
  actionStarted: boolean = false;
  
  currentTasksSchema: DataColumn[];
  currentTasksRows: object[];
  tasksHistorySchema: DataColumn[];
  tasksHistoryRows: object[];

  _taskStatusOptions: object[];

  constructor(
    private store: Store<AppStore>,
    private translation: TranslationService,
    private workflowService: WorkflowService,
    private dialogRef: MatDialog,
    route: ActivatedRoute,
    private router: Router,
  ) {
    this._taskStatusOptions = Utils.getTaskStatusOptions(translation);

    this.activitiId = `activiti$${route.snapshot.params.workflowId}`;

    this.currentTasksSchema = [
      {
        type: 'text',
        key: 'title',
        title: this.translation.instant('APP.WORKFLOW.GENERAL.TYPE'),
        sortable: false
      },
      {
        type: 'text',
        key: 'ownerParsed',
        title: this.translation.instant('APP.WORKFLOW.GENERAL.ASSIGNED_TO'),
        sortable: false
      },
      {
        type: 'text',
        key: 'dueDateParsed',
        title: this.translation.instant('APP.WORKFLOW.GENERAL.DUE_DATE'),
        sortable: false
      },
      {
        type: 'text',
        key: 'statusParsed',
        title: this.translation.instant('APP.WORKFLOW.GENERAL.STATUS'),
        sortable: false
      }
    ];

    this.tasksHistorySchema = [
      {
        type: 'text',
        key: 'title',
        title: this.translation.instant('APP.WORKFLOW.GENERAL.TYPE'),
        sortable: false
      },
      {
        type: 'text',
        key: 'ownerParsed',
        title: this.translation.instant('APP.WORKFLOW.GENERAL.COMPLETED_BY'),
        sortable: false
      },
      {
        type: 'text',
        key: 'completionDateParsed',
        title: this.translation.instant('APP.WORKFLOW.GENERAL.DATE_COMPLETED'),
        sortable: false
      },
      {
        type: 'text',
        key: 'outcome',
        title: this.translation.instant('APP.WORKFLOW.GENERAL.OUTCOME'),
        sortable: false
      },
      {
        type: 'text',
        key: 'commentParsed',
        title: this.translation.instant('APP.WORKFLOW.GENERAL.COMMENT'),
        sortable: false
      }
    ];
  }

  ngOnInit() {
    this.getWorkflowDetails();
  }

  onFormSubmit(event) {
    event.preventDefault();
  }

  getWorkflowDetails() {
    this.workflowService
      .getWorkflowDetails(this.activitiId)
      .then(async (data: any) => {
        this.workflow = data;
        this.workflowStartTask = data['tasks'].find(el => el['id'] === data['startTaskInstanceId']);
        this.currentTasksRows = data['tasks'].filter(el => el['state'] === 'IN_PROGRESS').map(el => this._populateInProgressTaskAdditionalProperties(el));
        this.tasksHistoryRows = data['tasks'].filter(el => el['state'] === 'COMPLETED').map(el => this._populateCompletedTaskAdditionalProperties(el));
        const workflowPackage = data['package'];
        const resolveWorkflowDataPayload = this._getResolveWorkflowDataPayload();
        try {
          const promises: any[] = [];
          let promise: any = this.workflowService.getPackageContents(workflowPackage);
          promises.push(promise);
          if (resolveWorkflowDataPayload && resolveWorkflowDataPayload.length > 0) {
            promise = this.workflowService.resolveWorkflowData(resolveWorkflowDataPayload);
            promises.push(promise);
          }
          const result = await Promise.all(promises);
          this.initiallySelectedDocuments = result[0];
          if (resolveWorkflowDataPayload && resolveWorkflowDataPayload.length > 0) {
            this.workflowResolvedData = result[1];
          }
        } catch (_) {
          this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
        }
        console.log({ workflowData: data, resolvedWorkflowData: this.workflowResolvedData });
        this.isLoading = false;
      })
      .catch((err) => {
        this.workflowError = err;
        this.isLoading = false;
      });
  }

  getWorkflowName() {
    if (!this.workflow)
      return '';
    return Utils.getWorkflowTypeName(this.workflow['name'], this.translation);
  }
  
  getWorkflowMessage() {
    if (!this.workflow)
      return '';
    return this.workflow['message'];
  }

  getWorkflowInitiator() {
    if (!this.workflow)
      return '';
    const initiator = this.workflow['initiator'];
    if (!initiator) {
      return this.translation.instant('APP.WORKFLOW.GENERAL.EMPTY_FIELD');
    }
    return `${initiator.firstName}${initiator.lastName ? (' ' + initiator.lastName) : ''}`;
  }

  getWorkflowPriority() {
    if (!this.workflow)
      return '';
    const priority = this.workflow['priority'];
    return Utils.getPriorityTranslation(this.translation, priority);
  }

  getWorkflowDue() {
    if (!this.workflow)
      return '';
    const dueDateIsoString = this.workflow['dueDate'];
    if (dueDateIsoString) {
      const dueDate = Utils.dateFromISO8601(dueDateIsoString);
      return Utils.formatDate(dueDate);
    } else {
      return this.translation.instant('APP.WORKFLOW.GENERAL.EMPTY_FIELD');
    }
  }

  getWorkflowAdditionalDescription() {
    if (!this.workflowStartTask)
      return '';
    this.additionalDescription = this.workflowStartTask['properties']['ncwf_moreDescription'];
    if (!this.additionalDescription) {
      return this.translation.instant('APP.WORKFLOW.GENERAL.EMPTY_FIELD');
    }
    return this.additionalDescription;
  }

  getWorkflowStartDate() {
    if (!this.workflow)
      return '';
    const startDateIsoString = this.workflow['startDate'];
    if (startDateIsoString) {
      const startDate = Utils.dateFromISO8601(startDateIsoString);
      return Utils.formatDate(startDate);
    } else {
      return this.translation.instant('APP.WORKFLOW.GENERAL.EMPTY_FIELD');
    }    
  }

  getWorkflowStatus() {
    if (!this.workflow)
      return '';
    return this.translation.instant(this.workflow['isActive'] ? 'APP.WORKFLOW.GENERAL.IN_PROGRESS' : 'APP.WORKFLOW.GENERAL.COMPLETED');
  }

  getEmailNotification() {
    if (!this.workflowStartTask)
      return '';
    const value = this.workflowStartTask['properties']['bpm_sendEMailNotifications'];
    return this.translation.instant(value ? 'APP.GENERAL.YES' : 'APP.GENERAL.NO');
  }

  getGARDReviewGroup() {
    return this._getResolvedGroup(() => this._getGARDGroupAssigneeNodeRef());
  }

  getGARDRequiredApprovalPercentage() {
    if (!this.workflowStartTask)
      return '';
    const value = this.workflowStartTask['properties']['wf_requiredApprovePercent'];
    return value ? value : '';
  }

  getGRDReviewGroups() {
    return this._getMultipleResolvedGroups(() => this._getGRDGroupAssigneesNodeRefs());
  }

  haveMLARLevel2() {
    const value = this._getMLARAssigneesNodeRefsLevel2();
    return value && value.length > 0;
  }

  haveMLARLevel3() {
    const value = this._getMLARAssigneesNodeRefsLevel3();
    return value && value.length > 0;
  }

  haveMLARLevel4() {
    const value = this._getMLARAssigneesNodeRefsLevel4();
    return value && value.length > 0;
  }

  haveMLARLevel5() {
    const value = this._getMLARAssigneesNodeRefsLevel5();
    return value && value.length > 0;
  }

  haveMLARGroup() {
    const value = this._getMLARGroupNodeRef();
    return Boolean(value);
  }

  getMLARAssigneesLevel1() {
    return this._getMultipleResolvedAssignees(() => this._getMLARAssigneesNodeRefsLevel1());
  }

  getMLARAssigneesLevel2() {
    return this._getMultipleResolvedAssignees(() => this._getMLARAssigneesNodeRefsLevel2());
  }

  getMLARAssigneesLevel3() {
    return this._getMultipleResolvedAssignees(() => this._getMLARAssigneesNodeRefsLevel3());
  }

  getMLARAssigneesLevel4() {
    return this._getMultipleResolvedAssignees(() => this._getMLARAssigneesNodeRefsLevel4());
  }

  getMLARAssigneesLevel5() {
    return this._getMultipleResolvedAssignees(() => this._getMLARAssigneesNodeRefsLevel5());
  }

  getMLARRequiredApprovalPercentageLevel1() {
    if (!this.workflowStartTask)
      return '';
    const value = this.workflowStartTask['properties']['ncwf_requiredApprovePercent1'];
    return value;
  }

  getMLARRequiredApprovalPercentageLevel2() {
    if (!this.workflowStartTask)
      return '';
    const value = this.workflowStartTask['properties']['ncwf_requiredApprovePercent2'];
    return value ? value : '';
  }

  getMLARRequiredApprovalPercentageLevel3() {
    if (!this.workflowStartTask)
      return '';
    const value = this.workflowStartTask['properties']['ncwf_requiredApprovePercent3'];
    return value ? value : '';
  }

  getMLARRequiredApprovalPercentageLevel4() {
    if (!this.workflowStartTask)
      return '';
    const value = this.workflowStartTask['properties']['ncwf_requiredApprovePercent4'];
    return value ? value : '';
  }

  getMLARRequiredApprovalPercentageLevel5() {
    if (!this.workflowStartTask)
      return '';
    const value = this.workflowStartTask['properties']['ncwf_requiredApprovePercent5'];
    return value ? value : '';
  }

  getMLARGroup() {
    return this._getResolvedGroup(() => this._getMLARGroupNodeRef());
  }

  getMRADAssignees() {
    return this._getMultipleResolvedAssignees(() => this._getMRADAssigneesNodeRefs());
  }

  onRemove() {
    this.dialogRef
      .open(ConfirmDialogComponent, {
        data: {
          title: 'APP.WORKFLOW.GENERAL.REMOVE_WORKFLOW',
          message: this.translation.instant('APP.WORKFLOW.GENERAL.REMOVE_WORKFLOW_PROMPT'),
          yesLabel: 'APP.DIALOGS.CONFIRM_DELETE.YES_LABEL',
          noLabel: 'APP.DIALOGS.CONFIRM_DELETE.NO_LABEL'
        },
        minWidth: '250px'
      })
      .afterClosed()
      .subscribe(async (result) => {
        if (result === true) {
          this.workflowService.cancelWorkflow(this.activitiId, !this.workflow['isActive'])
            .then(() => {
              this.store.dispatch(new SnackbarInfoAction(this.translation.instant('APP.WORKFLOW.GENERAL.SUCCESSFULLY_REMOVED_WORKFLOW')));
              const url = `/workflow/my-workflows`;
              this.router.navigateByUrl(url);
            })
            .catch(_ => {
              this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
            });
        }
      });
  }

  onDocumentSelectorNavigate(index: number) {
    const node = this.initiallySelectedDocuments[index];
    const url = `#/libraries/${node.parentId}/(viewer:view/${node.id})?location=%2Flibraries%2F${node.parentId}`;
    window.open(url, '_blank');
  }

  onRowClick(event){
    const taskId = event.detail.value.obj.id;
    const taskIdShort = taskId.substring('activiti$'.length);
    const url = `workflow/task-details/${taskIdShort}`;
    this.router.navigateByUrl(url);
  }

  _populateInProgressTaskAdditionalProperties(task: object) {
    let statusElement = this._taskStatusOptions.find(el => el['id'] === task['properties']['bpm_status']);
    const status = statusElement ? statusElement['value'] : this.translation.instant('APP.WORKFLOW.GENERAL.EMPTY_FIELD');  // the else clause should not occur in practice
    return {
      ...task,
      ownerParsed: this._buildTaskOwnerString(task),
      dueDateParsed: this._getTaskDateValue(task['properties']['bpm_dueDate']),
      statusParsed: status,
    }
  }

  _populateCompletedTaskAdditionalProperties(task: object) {
    const comment = task['properties']['bpm_comment'];
    return {
      ...task,
      ownerParsed: this._buildTaskOwnerString(task),
      completionDateParsed: this._getTaskDateValue(task['properties']['bpm_completionDate']),
      commentParsed: comment ? comment : '/',
    }
  }

  _buildTaskOwnerString(task: object) {
    return task['owner'] ? (task['owner']['firstName'] + (task['owner']['lastName'] ? (' ' + task['owner']['lastName']) : '')) : this.translation.instant('APP.WORKFLOW.GENERAL.EMPTY_FIELD');
  }

  _getTaskDateValue(dateIsoString: string) {
    if (dateIsoString) {
      const date = Utils.dateFromISO8601(dateIsoString);
      return Utils.formatDate(date);
    } else {
      return this.translation.instant('APP.WORKFLOW.GENERAL.EMPTY_FIELD');
    }
  }

  _getResolveWorkflowDataPayload() {
    const payload: string[] = [];

    switch (this.workflow['name']) {
      case 'activiti$groupApproveRejectDoc':
      {
        const groupNodeRef = this._getGARDGroupAssigneeNodeRef();
        payload.push(groupNodeRef);
        break;
      }
      case 'activiti$groupReceiveDoc':
      {
        const groupsNodeRefs = this._getGRDGroupAssigneesNodeRefs();
        payload.push(...groupsNodeRefs);
        break;
      }
      case 'activiti$multipleLevelApproveDoc':
      {
        const assigneesNodeRefs = this._getMLARAssigneesNodeRefsAll();
        payload.push(...assigneesNodeRefs);
        break;
      }
      case 'activiti$multipleReviewArchDoc':
      {
        const assigneesNodeRefs =  this._getMRADAssigneesNodeRefs();
        payload.push(...assigneesNodeRefs);
        break;
      }
      default:
        return null;
    }

    return payload;
  }

  _getGARDGroupAssigneeNodeRef() {
    if (!this.workflowStartTask)
      return null;
    return this.workflowStartTask['properties']['bpm_groupAssignee'];
  }

  _getGRDGroupAssigneesNodeRefs() {
    if (!this.workflowStartTask)
      return null;
    return this.workflowStartTask['properties']['bpm_groupAssignees'];
  }

  _getMLARAssigneesNodeRefsLevel1() {
    if (!this.workflowStartTask)
      return null;
    return this.workflowStartTask['properties']['bpm_assignees'];
  }

  _getMLARAssigneesNodeRefsLevel2() {
    if (!this.workflowStartTask)
      return null;
    return this.workflowStartTask['properties']['ncwf_secondLevelAssignees'];
  }

  _getMLARAssigneesNodeRefsLevel3() {
    if (!this.workflowStartTask)
      return null;
    return this.workflowStartTask['properties']['ncwf_thirdLevelAssignees'];
  }

  _getMLARAssigneesNodeRefsLevel4() {
    if (!this.workflowStartTask)
      return null;
    return this.workflowStartTask['properties']['ncwf_fourthLevelAssignees'];
  }

  _getMLARAssigneesNodeRefsLevel5() {
    if (!this.workflowStartTask)
      return null;
    return this.workflowStartTask['properties']['ncwf_fifthLevelAssignees'];
  }

  _getMLARGroupNodeRef() {
    if (!this.workflowStartTask)
      return null;
    return this.workflowStartTask['properties']['ncwf_group'];
  }

  _getMLARAssigneesNodeRefsAll() {
    const result: string[] = [];
    let level = this._getMLARAssigneesNodeRefsLevel1();
    result.push(...level);
    level = this._getMLARAssigneesNodeRefsLevel2();
    if (level)
      result.push(...level);
    level = this._getMLARAssigneesNodeRefsLevel3();
    if (level)
      result.push(...level);
    level = this._getMLARAssigneesNodeRefsLevel4();
    if (level)
      result.push(...level);
    level = this._getMLARAssigneesNodeRefsLevel5();
    if (level)
      result.push(...level);
    const group = this._getMLARGroupNodeRef();
    if (group)
      result.push(group);
    return result;
  }

  _getMRADAssigneesNodeRefs() {
    if (!this.workflowStartTask)
      return null;
    return this.workflowStartTask['properties']['bpm_assignees'];
  }

  _getMultipleResolvedAssignees(proc) {
    if (!this.workflowResolvedData)
      return '';
    
    const assigneesNodeRefs = proc();
    const resolvedItems = assigneesNodeRefs ? assigneesNodeRefs.map(el => this.workflowResolvedData.find(el2 => el2['nodeRef'] === el)) : [];
    if (resolvedItems.length === 0)
      return '';

    const resolvedItemsString = resolvedItems.map(el => el ? this._trimUserIdentity(el['name']) : this.translation.instant('APP.WORKFLOW.GENERAL.N_A')).join(', ');
    return resolvedItemsString;
  }

  _getResolvedGroup(proc) {
    if (!this.workflowResolvedData)
      return '';
    
    const groupNodeRef = proc();
    const resolvedItem = this.workflowResolvedData.find(el => el['nodeRef'] === groupNodeRef);
    if (!resolvedItem)
      return '';

    return resolvedItem['name'];
  }

  _getMultipleResolvedGroups(proc) {
    if (!this.workflowResolvedData)
      return '';
    
    const groupsNodeRefs = proc();
    const resolvedItems = groupsNodeRefs ? groupsNodeRefs.map(el => this.workflowResolvedData.find(el2 => el2['nodeRef'] === el)) : [];
    if (resolvedItems.length === 0)
      return '';

    const resolvedItemsString = resolvedItems.map(el => el ? el['name'] : this.translation.instant('APP.WORKFLOW.GENERAL.N_A')).join(', ');
    return resolvedItemsString;
  }

  _trimUserIdentity(name) {
    const lastIndexOfSpace = name.lastIndexOf(' ');
    if (lastIndexOfSpace != -1) {
      return name.substring(0, lastIndexOfSpace);
    }
    return name;
  }
}
