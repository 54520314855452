import { ArchiveAdminService, ArchiveService, PageLayoutComponent, PageLayoutContentComponent, PageLayoutHeaderComponent, } from '@alfresco/aca-shared';
import { AppStore, SnackbarInfoAction, SnackbarErrorAction, getUserList } from '@alfresco/aca-shared/store';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DataCellEvent, DataRowActionEvent, DataTableModule } from '@alfresco/adf-core';
import { Person } from '@alfresco/js-api';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from '@alfresco/adf-content-services';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    PageLayoutComponent,
    PageLayoutHeaderComponent,
    PageLayoutContentComponent,
    BreadcrumbModule,
    DataTableModule,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-create-archive',
  templateUrl: './create-archive.component.html',
  styleUrls: ['./create-archive.component.scss']
})
export class CreateArchiveComponent implements OnInit {

  editMode: boolean = false;

  archivesSchema: Array<object>;
  archives: any[] = [];
  users: Person[] = [];
  selectedUsers: Person[] = [];
  filteredUsers: Person[] = [];
  stampData: string = '';

  @ViewChild('stampPreviewComp')
  stampPreviewComp;

  @ViewChild('stampInput')
  stampInput;

  @ViewChild('formDirective')
  formDirective;

  archivesForm = new FormGroup({
    id: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    archivers: new FormControl([]),
  });

  constructor(
    private archiveAdminService: ArchiveAdminService,
    private archiveService: ArchiveService,
    private store: Store<AppStore>,
    private translation: TranslateService,
  )
  {
    this.archivesSchema = [
      {
        type: 'text',
        key: 'id',
        title: this.translation.instant('APP.ARCHIVE_ADMIN.ID'),
        sortable: true
      },    
      {
        type: 'text',
        key: 'name',
        title: this.translation.instant('APP.ARCHIVE_ADMIN.NAME'),
        sortable: true
      }
    ];
  }

  async ngOnInit(): Promise<void> {    
    await this.getArchives();
    this.getUsers();
  }

  async getArchives(){
    this.archives = await this.archiveService.getArchives();
  }

  async createArchive(): Promise<void> {
    if (this.archivesForm.invalid)
      return;
    
    if (!this.selectedUsers || !this.selectedUsers.length) {
      this.store.dispatch(
        new SnackbarErrorAction(this.translation.instant('APP.ARCHIVE_ADMIN.MISSING_ARCHIVERS')));
      return;
    }
    
    if (!this.stampData) {
      this.store.dispatch(
        new SnackbarErrorAction(this.translation.instant('APP.ARCHIVE_ADMIN.MISSING_STAMP')));
      return;
    }

    const requestBody = { ...this.archivesForm.value };

    const selectedUsersIds = this.selectedUsers.map(a => a.id);

    try {
      await this.archiveAdminService.createArchive(
        requestBody.id,
        requestBody.name,
        selectedUsersIds,
        this.stampData
      );

      this.resetEverything();
      this.getArchives();

      this.store.dispatch(
        new SnackbarInfoAction(this.translation.instant('APP.ARCHIVE_ADMIN.CREATE_ARCHIVE_SUCCESS')));
    } catch (error) {
      this.store.dispatch(
        new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }

  async updateArchive() {
    if (this.archivesForm.get('name').invalid)
      return;
    
    if (!this.selectedUsers || !this.selectedUsers.length) {
      this.store.dispatch(
        new SnackbarErrorAction(this.translation.instant('APP.ARCHIVE_ADMIN.MISSING_ARCHIVERS')));
      return;
    }
    
    if (!this.stampData) {
      this.store.dispatch(
        new SnackbarErrorAction(this.translation.instant('APP.ARCHIVE_ADMIN.MISSING_STAMP')));
      return;
    }

    const selectedUsersIds = this.selectedUsers.map(a => a.id);

    try {
      await this.archiveAdminService.updateArchive(
        this.archivesForm.get('id').value,
        selectedUsersIds,
        this.stampData
      );
      this.resetEverything();
      this.getArchives();

      this.store.dispatch(
        new SnackbarInfoAction(this.translation.instant('APP.ARCHIVE_ADMIN.UPDATE_ARCHIVE_SUCCESS')));
    } catch (error) {
      this.store.dispatch(
        new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }

  cancelEdit() {
    this.resetEverything();
  }

  resetEverything() {
    this.editMode = false;
    this.archivesForm.reset();
    if (this.formDirective && this.formDirective.resetForm) {
      this.formDirective.resetForm();
    }
    this.selectedUsers = [];
    this.filteredUsers = this.filterUsers();
    this.stampData = '';
  }

  getUsers() {
    this.store.select(getUserList).subscribe((userList) => {
      this.users = userList;
      this.filteredUsers = this.filterUsers();
    });
  }

  onShowRowActionsMenu = (event: DataCellEvent) => {
    const editAction = {
      id: 0,
      title: this.translation.instant('APP.ARCHIVE_ADMIN.EDIT_ARCHIVE')
    };
    event.value.actions = [editAction];    
  }

  onExecuteRowAction(event: DataRowActionEvent) {
    let args = event.value;
    if (args.action.id === 0) {
      this.editArchive(args.row.obj.id);
    }
  }

  editArchive(shortName) {
    this.archiveAdminService.getArchiveInfo(shortName)
      .then((response: any) => {
        this.editMode = true;
        this.archivesForm.get('id').setValue(response['shortName']);
        this.archivesForm.get('name').setValue(response['title']);
        const archivers = response['users'];
        const selectedUsers = [];
        for (let i = 0, len = archivers.length; i < len; ++i) {
          const userId = this.users.find(item => item.id === archivers[i]);
          if (userId)
            selectedUsers.push(userId);
        }
        this.selectedUsers = selectedUsers;
        this.stampData = response['stampBase64'];
        this.updateStampPreview();
      })
      .catch((_) => {
        this.store.dispatch(
          new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      });
  }

  onArchiversSelectionChange(event) {
    if (event.value) {
      this.selectedUsers.push(event.value);
      this.filteredUsers = this.filterUsers();
      this.archivesForm.get('archivers').setValue(null);
    }
  }

  filterUsers() {
    return this.users.filter(el => !Boolean(
      this.selectedUsers.find(el2 => el2.id === el.id)));
  }

  onStampFileSelected(event) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.stampData = reader.result.toString().substring(22);
        this.updateStampPreview();
        if (this.stampInput && this.stampInput.nativeElement) {
          this.stampInput.nativeElement.value = null;
        }
      }
    }
  }

  updateStampPreview() {
    const resultingDataURL = 'data:image/png;base64,' + this.stampData;
    if (this.stampPreviewComp && this.stampPreviewComp.nativeElement) {
      this.stampPreviewComp.nativeElement.src = resultingDataURL;
    }
  }

  onRemoveSelectedUser(index) {
    this.selectedUsers.splice(index, 1);
    this.filteredUsers = this.filterUsers();
  }
}
