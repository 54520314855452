<h2 mat-dialog-title>
  {{ 'APP.ARCHIVE.CHANGE_SENDER.TITLE' | translate }}
</h2>

<mat-dialog-content class="adf-change-sender-dialog-content">
  <form [formGroup]="changeForm" novalidate>
    <mat-form-field appearance="standard" [style.display]="!hasKomitenti? 'block': 'none'">
      <mat-label>{{ 'APP.ARCHIVE.CHANGE_SENDER.NEW_SENDER_NAME' | translate }}</mat-label>
      <input matInput formControlName="senderName" name="senderName" type="text" [required]="!hasKomitenti" />
      <mat-error *ngIf="senderName.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="standard" [style.display]="hasKomitenti? 'block': 'none'">
      <mat-label>{{ 'APP.ARCHIVE.CHANGE_SENDER.NEW_SENDER_NAME' | translate }}</mat-label>
      <input matInput formControlName="senderValue" [matAutocomplete]="autocompleteSenderValue" [required]="hasKomitenti" />
      <mat-autocomplete #autocompleteSenderValue="matAutocomplete">
        <mat-option *ngFor="let komitent of komitentiFiltered | async" [value]="komitent.id">{{ komitent.name }}</mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="senderValue.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
    <span class="adf-fill-remaining-space"></span>

    <button
      mat-button
      class="adf-dialog-action-button"
      color="primary"
      (click)="changeSender()">
        {{ 'APP.ARCHIVE.CHANGE_SENDER.CHANGE' | translate }}
    </button>
    <button
      mat-button
      mat-dialog-close>
      {{ 'APP.GENERAL.CANCEL' | translate }}
    </button>
</mat-dialog-actions>
