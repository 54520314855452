<ng-container *ngIf="process">
    <article>
    <div>{{ 'ABOUT.VERSIONS.PROCESS_SERVICE' | translate }}</div>
    <p>
        {{ 'ABOUT.VERSIONS.LABELS.VERSION' | translate }}: {{ process.majorVersion }}.{{process.minorVersion }}.{{ process.revisionVersion }}
    </p>
    </article>
</ng-container>

<ng-container *ngIf="repository">
    <article>
        <div>{{ 'ABOUT.VERSIONS.CONTENT_SERVICE' | translate }}</div>
        <p>{{ 'ABOUT.VERSIONS.LABELS.EDITION' | translate }}: {{ repository.edition }}</p>
        <p>{{ 'ABOUT.VERSIONS.LABELS.VERSION' | translate }}: {{ repository.version.display }}</p>
    </article>
</ng-container>

<ng-container *ngIf="modeling$ | async as modeling">
    <article>
        <div>{{ 'ABOUT.VERSIONS.MODELING_SERVICE' | translate }}</div>
        <p>{{ 'ABOUT.VERSIONS.LABELS.VERSION' | translate }}: {{ modeling.version }}</p>
    </article>
</ng-container>

<ng-container *ngIf="deployment$| async as deployment">
    <article>
        <div>{{ 'ABOUT.VERSIONS.DEPLOYMENT_SERVICE' | translate }}</div>
        <p>{{ 'ABOUT.VERSIONS.LABELS.VERSION' | translate }}: {{ deployment.version }}</p>
    </article>
</ng-container>

<ng-container *ngIf="rb$ | async as rb">
    <article>
        <div>{{ 'ABOUT.VERSIONS.RB' | translate }}</div>
        <p>{{ 'ABOUT.VERSIONS.LABELS.EDITION' | translate }}: {{ rb.artifact }}</p>
        <p>{{ 'ABOUT.VERSIONS.LABELS.VERSION' | translate }}: {{ rb.version }}</p>
    </article>
</ng-container>

<ng-container *ngIf="query$ | async as query">
    <article>
        <div>{{ 'ABOUT.VERSIONS.QUERY_SERVICE' | translate }}</div>
        <p>{{ 'ABOUT.VERSIONS.LABELS.VERSION' | translate }}: {{ query.version }}</p>
    </article>
</ng-container>
