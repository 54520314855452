<div class="adf-textfield adf-number-widget {{field.className}}"
     [class.adf-invalid]="!field.isValid && isTouched()" [class.adf-readonly]="field.readOnly" [class.adf-left-label-input-container]="field.leftLabels">
    <div *ngIf="field.leftLabels">
        <label class="adf-label adf-left-label" [attr.for]="field.id">{{field.name | translate }}<span class="adf-asterisk" *ngIf="isRequired()">*</span></label>
    </div>
    <div>
        <mat-form-field [hideRequiredMarker]="true">
            <label class="adf-label" *ngIf="!field.leftLabels" [attr.for]="field.id">{{field.name | translate }}<span class="adf-asterisk" *ngIf="isRequired()">*</span></label>
            <input matInput
                class="adf-input"
                type="text"
                pattern="-?[0-9]*(\.[0-9]+)?"
                [id]="field.id"
                [required]="isRequired()"
                [value]="displayValue"
                [(ngModel)]="field.value"
                (ngModelChange)="onFieldChanged(field)"
                [disabled]="field.readOnly"
                [placeholder]="field.placeholder"
                [matTooltip]="field.tooltip"
                (blur)="markAsTouched()"
                matTooltipPosition="above"
                matTooltipShowDelay="1000">
        </mat-form-field>
        <error-widget [error]="field.validationSummary" ></error-widget>
        <error-widget *ngIf="isInvalidFieldRequired() && isTouched()" required="{{ 'FORM.FIELD.REQUIRED' | translate }}"></error-widget>
    </div>
</div>
