<mat-list id="adf-rating-container" class="adf-rating-container">
    <mat-list-item class="adf-rating-star" *ngFor="let currentRate of stars; let idx = index;">
            <span id="adf-rate-{{idx}}">
                <mat-icon id="adf-colored-star-{{idx}}" *ngIf="currentRate.fill" class="adf-colored-star"
                          [ngClass]="{'adf-average-star': !ratingValue}"
                          (click)="updateVote(idx + 1)">star_rate
                </mat-icon>
                <mat-icon id="adf-grey-star-{{idx}}" *ngIf="!currentRate.fill" class="adf-grey-star"
                          (click)="updateVote(idx + 1)">star_border
                </mat-icon>
            </span>
    </mat-list-item>
    <div class="adf-rating-counter-container">
    <div id="adf-rating-counter" class="adf-rating-counter">{{ratingsCounter}}</div>
    <div class="adf-rating-left" *ngIf="ratingsCounter<=1">Rating</div>
    <div class="adf-rating-left" *ngIf="ratingsCounter>1">Ratings</div>
    </div>
</mat-list>
