<mat-table [dataSource]="data">
  <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
    <mat-header-cell *matHeaderCellDef>
      {{ column.header | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row" [innerHTML]="column.cell(row)"></mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
