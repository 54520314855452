import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { Site } from '@alfresco/js-api';
import { TranslationService } from '@alfresco/adf-core';
import { SiteService, SiteVisibility } from '@alfresco/aca-shared';
import { AppStore, SnackbarErrorAction, SnackbarInfoAction } from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
  ],
  selector: 'aca-create-site-dialog',
  templateUrl: './create-site-dialog.component.html',
  styleUrls: ['./create-site-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateSiteDialogComponent implements OnInit {

  form: FormGroup;
  
  editTitle = 'APP.SETTINGS.EDIT_SITE';
  createTitle = 'APP.SETTINGS.NEW_SITE';

  hasProcessedEntity: Boolean = false;

  fcId: FormControl;
  fcTitle: FormControl;
  fcDescription: FormControl;
  fcVisibility: FormControl;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialogRef<CreateSiteDialogComponent>,
    private store: Store<AppStore>,
    private translation: TranslationService,
    private siteService: SiteService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
  }

  get editing(): boolean {
    return Boolean(this.data);
  }

  ngOnInit() {
    let id = this.data ? this.data.id : '';
    let title = this.data ? this.data.title : '';
    let description = this.data ? this.data.description : '';
    let visibility = this.data ? this.data.visibility : 'PRIVATE';

    const validators = {
      id: [
        Validators.required,
      ],
      title: [
        Validators.required,
      ],
    };

    this.form = this.formBuilder.group({
      id: [ id, validators.id ],
      title: [ title, validators.title ],
      description: [ description ],
      visibility: [ visibility ],
    });

    this.fcId = this.form.controls['id'] as FormControl;
    this.fcTitle = this.form.controls['title'] as FormControl;
    this.fcDescription = this.form.controls['description'] as FormControl;
    this.fcVisibility = this.form.controls['visibility'] as FormControl;
  }

  get id(): string {
    const { id } = this.form.value;

    return (id || '').trim();
  }

  get title(): string {
    const { title } = this.form.value;

    return (title || '').trim();
  }

  get description(): string {
    const { description } = this.form.value;

    return (description || '').trim();
  }

  get visibility(): string {
    const { visibility } = this.form.value;

    return (visibility || '').trim();
  }

  private create(): Promise<Site> {
    return this.siteService.createSite(this.id, this.title, this.description, this.visibility as SiteVisibility);
  }

  private edit(): Promise<Site> {
    return this.siteService.updateSite(this.id, this.title, this.description, this.visibility as SiteVisibility);
  }

  closeDialog() {
    this.dialog.close(this.hasProcessedEntity);
  }

  submit() {
    const { form, editing } = this;

    if (!form.valid) { return; }

    (editing ? this.edit() : this.create())
      .then(_ => {
        this.store.dispatch(new SnackbarInfoAction(
          this.translation.instant(this.editing ? 'APP.SETTINGS.SITE_UPDATED' : 'APP.SETTINGS.SITE_CREATED')
            .replace('{0}', this.title)
        ));
        this.hasProcessedEntity = true;
        this.closeDialog();
      })
      .catch(error => this.handleError(error));
  }

  handleError(error: any): any {
    this.store.dispatch(new SnackbarErrorAction(
      this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));

    return error;
  }
}