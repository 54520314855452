<div id="aspect-list-container" class="adf-aspect-list-container" *ngIf="aspects$ | async as aspects; else loading">
    <mat-accordion class="adf-accordion-aspect-list">
        <mat-expansion-panel *ngFor="let aspect of aspects; let colIndex = index" [id]="'aspect-list-'+getId(aspect)">
            <mat-expansion-panel-header [id]="'aspect-list-'+(getId(aspect))+'header'">
                <mat-panel-title>
                    <mat-checkbox class="adf-aspect-list-check-button" [id]="'aspect-list-'+colIndex+'-check'"
                                  [checked]="nodeAspects?.includes(aspect?.entry?.id)"
                                  (click)="onCheckBoxClick($event)"
                                  (change)="onChange($event, aspect?.entry?.id)">
                                  <p class="adf-aspect-list-element-title">{{getTitle(aspect)}}</p>
                    </mat-checkbox>                    
                </mat-panel-title>
                <mat-panel-description [id]="'aspect-list-'+colIndex+'-title'"
                                        [matTooltip]="getTitle(aspect)">
                    {{getTitle(aspect)}}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <p class="adf-property-paragraph" [id]="'aspect-list-'+colIndex+'-description'"> {{aspect?.entry?.description}}</p>

            <table mat-table [dataSource]="aspect?.entry?.properties" *ngIf="aspect?.entry?.properties?.length > 0" class="adf-aspect-property-table" [id]="'aspect-list-'+colIndex+'-properties-table'">
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> {{'ADF-ASPECT-LIST.PROPERTY_NAME' | translate}} </th>
                  <td mat-cell *matCellDef="let property"> {{property.id}} </td>
                </ng-container>
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef> {{'ADF-ASPECT-LIST.DESCRIPTION' | translate}} </th>
                  <td mat-cell *matCellDef="let property"> {{property.title}} </td>
                </ng-container>
                <ng-container matColumnDef="dataType">
                  <th mat-header-cell *matHeaderCellDef> {{'ADF-ASPECT-LIST.DATA_TYPE' | translate}} </th>
                  <td mat-cell *matCellDef="let property"> {{property.dataType}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="propertyColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: propertyColumns;"></tr>
              </table>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<ng-template #loading>
    <div class="adf-aspect-list-spinner">
       <mat-spinner id="adf-aspect-spinner"></mat-spinner>
    </div>
</ng-template>
