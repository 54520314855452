import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppStore, getUserProfile } from '@alfresco/aca-shared/store';
import { map, switchMap } from 'rxjs/operators';
import { ProfileState } from '@alfresco/adf-extensions';
import { Group } from '@alfresco/js-api';
import { Constants } from '@alfresco/aca-shared';
import { GroupService } from '@alfresco/adf-content-services';

@Injectable({
  providedIn: 'root'
})
export class AppArchiveRuleGuard implements CanActivate, CanActivateChild {
  loggedInUserGroupMembership$: Observable<boolean>;

  constructor(store: Store<AppStore>, groupService: GroupService, router: Router) {
    this.loggedInUserGroupMembership$ = store.select(getUserProfile)
      .pipe(
        switchMap(async (profileState: ProfileState) => {
          if (!profileState.groups) {
            return await groupService.listAllGroupMembershipsForPerson('-me-', { maxItems: 250 })
              .then((groupsEntries) => groupsEntries.map(groupEntry => groupEntry.entry));
          } else {
            return profileState.groups;
          }
        }),
        map((groups: Group[]) => {
          if (!groups || groups.length === 0)
            return false;

          const groupEntry = groups.find((g: Group) => g.id === 'GROUP_' + Constants.GROUP_ARCHIVE_USERS_ID);
          const result = Boolean(groupEntry);
          if (!result)
            router.navigateByUrl('/invalid-route');  // workaround for a blank-screen Angular Router bug; https://github.com/angular/angular/issues/16211
          return result;
        }),
      );
  }

  canActivate(_: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.loggedInUserGroupMembership$;
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route);
  }
}
