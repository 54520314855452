import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges, ViewEncapsulation, } from '@angular/core';
import { AppStore, SnackbarErrorAction, } from '@alfresco/aca-shared/store';
import { ThumbnailService, TranslationService } from '@alfresco/adf-core';
import { Subject } from 'rxjs';
import { Node, } from '@alfresco/js-api';
import { MatDialog } from '@angular/material/dialog';
import { ContentNodeSelectorComponent, ContentNodeSelectorComponentData, } from '@alfresco/adf-content-services';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-neocom-document-selector',
  templateUrl: './neocom-document-selector.component.html',
  styleUrls: ['./neocom-document-selector.component.scss']
})
export class NeocomDocumentSelectorComponent implements OnInit, OnChanges {

  selection: Node[] = [];
  selectionThumbnails: string[] = [];

  @Input()
  initialSelection: Node[] = [];

  @Input()
  readOnly: boolean = false;

  @Output()
  selectionChanged = new EventEmitter<Node[]>();

  @Output()
  navigate = new EventEmitter<number>();

  constructor(
    private store: Store<AppStore>,
    private translation: TranslationService,
    private dialog: MatDialog,
    private thumbnailService: ThumbnailService,
  ) {}

  ngOnInit() {
    this.selection = [ ...this.initialSelection ];
    this.selectionThumbnails = this.initialSelection.map(el => this._getThumbnailPath(el));
    if (this.selection.length > 0) {
      this.selectionChanged.emit(this.selection);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initialSelection && this.initialSelection.length > 0) {
      this.selection = [ ...this.initialSelection, ...this.selection ];
      this.selectionThumbnails = [ ...this.initialSelection.map(el => this._getThumbnailPath(el)), ...this.selectionThumbnails ];
      this.selectionChanged.emit(this.selection);
    }
}

  onAdd(_) {
    const data: ContentNodeSelectorComponentData = {
      selectionMode: 'multiple',
      title: this.translation.instant('APP.NEOCOM_DOCUMENT_SELECTOR.DIALOG_TITLE'),
      currentFolderId: '-mysites-',
      select: new Subject<Node[]>()
    };

    this.dialog.open(ContentNodeSelectorComponent,
      {
        data,
        panelClass: 'adf-content-node-selector-dialog',
        width: '630px'
      }
    );

    data.select.subscribe((selections: Node[]) => {
      const newNodes = selections.filter(el => el.isFile && !Boolean(this.selection.find(el2 => el2.id === el.id)));
      const newNodesThumbnails = newNodes.map(el => this._getThumbnailPath(el));
      this.selection.push(...newNodes);
      this.selectionThumbnails.push(...newNodesThumbnails);
      this.selectionChanged.emit(this.selection);
    }, (error) => {
      console.log({ error });
      this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }, () => {
      this.dialog.closeAll();
    });
  }

  onRemoveAll(_) {
    this.selection = [];
    this.selectionThumbnails = [];
    this.selectionChanged.emit(this.selection);
  }

  onRemove(_, index) {
    this.selection.splice(index, 1);
    this.selectionThumbnails.splice(index, 1);
    this.selectionChanged.emit(this.selection);
  }

  onPreview(_, index) {
    this.navigate.emit(index);
  }

  formatDate(date: Date): string {
    if (!date)
      return '/';
    
    let result = date.toString();
    const index = result.lastIndexOf(' (');
    if (index !== -1)
      result = result.substring(0, index);
    
    return result;
  }

  private _getThumbnailPath(node: Node): string {
    return node.content ? this.thumbnailService.getMimeTypeIcon(node.content.mimeType) : this.thumbnailService.getDefaultMimeTypeIcon();
  }
}
