<div>
    <adf-login #adfLogin
               class="adf-panel-login-dialog-component"
               [showRememberMe]="false"
               [showLoginActions]="false"
               [backgroundImageUrl]="''"
               (success)="onLoginSuccess($event)">
        <adf-login-header><ng-template></ng-template></adf-login-header>
        <adf-login-footer><ng-template></ng-template></adf-login-footer>
    </adf-login>
</div>
