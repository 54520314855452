/*!
 * @license
 * Alfresco Example Content Application
 *
 * Copyright (C) 2005 - 2020 Alfresco Software Limited
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail.  Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with Alfresco. If not, see <http://www.gnu.org/licenses/>.
 */

import { ArchiveService } from '@alfresco/aca-shared';
import { AppStore, SnackbarErrorAction, SnackbarInfoAction } from '@alfresco/aca-shared/store';
import { TranslationService } from '@alfresco/adf-core';
import { Node } from '@alfresco/js-api';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import moment from 'moment-es6';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    MatButtonModule,
  ],
  templateUrl: './divorce-document.dialog.html',
  styleUrls: ['./divorce-document.dialog.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'aca-divorce-document-dialog' }
})
export class DivorceDocumentDialogComponent implements OnInit {

  node: Node;

  divorceForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private store: Store<AppStore>,
    private archiveService: ArchiveService,
    private translation: TranslationService,
    private dialogRef: MatDialogRef<DivorceDocumentDialogComponent>
  ) {
    this.node = data.node.entry;

    const todayDateFormatted = moment().format('DD.MM.YYYY');
    this.divorceForm = new FormGroup({
      date: new FormControl(todayDateFormatted),
      description: new FormControl('', Validators.required)
    });

    archiveService.getDocumentProperties(`workspace://SpacesStore/${this.node.id}`)
      .then(docProperties => {
        const descriptionInitialValue = this.translation.instant('APP.ARCHIVE.DIVORCE_DOCUMENT.SIGN_PATTERN').replace('{0}', docProperties['archiveSign']).replace('{1}', docProperties['monthsKeeping']);
        this.divorceForm.get('description').setValue(descriptionInitialValue);
      })
      .catch(_ => {});
  }

  async ngOnInit() {
    
  }

  async divorceDocument() {
    if (this.divorceForm.invalid)
      return;

    try {
      await this.archiveService.divorceDocument(
        `workspace://SpacesStore/${this.node.id}`,
        this.divorceForm.get('description').value,
      );

      this.store.dispatch(
        new SnackbarInfoAction(this.translation.instant('APP.ARCHIVE.DIVORCE_DOCUMENT.SUCCESS')));
      this.dialogRef.close(true);
    } catch (e) {
      this.store.dispatch(
        new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }
}
