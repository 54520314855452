<div *ngIf="showViewer"
     class="adf-viewer-container"
     [class.adf-viewer-overlay-container]="overlayMode"
     [class.adf-viewer-inline-container]="!overlayMode">

    <div class="adf-viewer-content"
         [cdkTrapFocus]="overlayMode"
         cdkTrapFocusAutoCapture>
        <ng-content select="adf-viewer-toolbar"></ng-content>
        <ng-container *ngIf="showToolbar && !toolbar">
            <adf-toolbar id="adf-viewer-toolbar" class="adf-viewer-toolbar">
                <adf-toolbar-title>

                    <ng-container *ngIf="allowLeftSidebar">
                        <button mat-icon-button
                                [attr.aria-expanded]="showLeftSidebar"
                                [attr.aria-label]="'ADF_VIEWER.ACTIONS.INFO' | translate"
                                title="{{ 'ADF_VIEWER.ACTIONS.INFO' | translate }}"
                                data-automation-id="adf-toolbar-left-sidebar"
                                [color]="showLeftSidebar ? 'accent' : null"
                                (click)="toggleLeftSidebar()">
                            <mat-icon>info_outline</mat-icon>
                        </button>
                    </ng-container>

                    <button *ngIf="allowGoBack"
                            class="adf-viewer-close-button"
                            data-automation-id="adf-toolbar-back"
                            [attr.aria-label]="'ADF_VIEWER.ACTIONS.CLOSE' | translate"
                            mat-icon-button
                            title="{{ 'ADF_VIEWER.ACTIONS.CLOSE' | translate }}"
                            (click)="onClose()">
                        <mat-icon>close</mat-icon>
                    </button>
                </adf-toolbar-title>

                <div class="adf-viewer__file-title">
                    <button *ngIf="allowNavigate && canNavigateBefore"
                            data-automation-id="adf-toolbar-pref-file"
                            mat-icon-button
                            [attr.aria-label]="'ADF_VIEWER.ACTIONS.PREV_FILE' | translate"
                            title="{{ 'ADF_VIEWER.ACTIONS.PREV_FILE' | translate }}"
                            (click)="onNavigateBeforeClick($event)">
                        <mat-icon>navigate_before</mat-icon>
                    </button>
                    <img class="adf-viewer__mimeicon"
                         [alt]="mimeType"
                         [src]="mimeType | adfMimeTypeIcon"
                         data-automation-id="adf-file-thumbnail">
                    <span class="adf-viewer__display-name"
                          id="adf-viewer-display-name">{{ fileName }}</span>
                    <button *ngIf="allowNavigate && canNavigateNext"
                            data-automation-id="adf-toolbar-next-file"
                            mat-icon-button
                            [attr.aria-label]="'ADF_VIEWER.ACTIONS.NEXT_FILE' | translate"
                            title="{{ 'ADF_VIEWER.ACTIONS.NEXT_FILE' | translate }}"
                            (click)="onNavigateNextClick($event)">
                        <mat-icon>navigate_next</mat-icon>
                    </button>
                </div>

                <ng-content select="adf-viewer-toolbar-actions"></ng-content>

                <ng-container *ngIf="mnuOpenWith"
                              data-automation-id='adf-toolbar-custom-btn'>
                    <button id="adf-viewer-openwith"
                            mat-button
                            [matMenuTriggerFor]="mnuOpenWith"
                            data-automation-id="adf-toolbar-open-with">
                        <span>{{ 'ADF_VIEWER.ACTIONS.OPEN_WITH' | translate }}</span>
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <mat-menu #mnuOpenWith="matMenu"
                              [overlapTrigger]="false">
                        <ng-content select="adf-viewer-open-with"></ng-content>
                    </mat-menu>
                </ng-container>

                <adf-toolbar-divider></adf-toolbar-divider>

                <ng-content select="adf-viewer-toolbar-custom-actions"></ng-content>

                <button id="adf-viewer-fullscreen"
                        *ngIf="allowFullScreen"
                        mat-icon-button
                        [attr.aria-label]="'ADF_VIEWER.ACTIONS.FULLSCREEN' | translate"
                        title="{{ 'ADF_VIEWER.ACTIONS.FULLSCREEN' | translate }}"
                        data-automation-id="adf-toolbar-fullscreen"
                        (click)="enterFullScreen()">
                    <mat-icon>fullscreen</mat-icon>
                </button>

                <ng-container *ngIf="allowRightSidebar">
                    <adf-toolbar-divider></adf-toolbar-divider>

                    <button mat-icon-button
                            [attr.aria-expanded]="showRightSidebar"
                            [attr.aria-label]="'ADF_VIEWER.ACTIONS.INFO' | translate"
                            title="{{ 'ADF_VIEWER.ACTIONS.INFO' | translate }}"
                            data-automation-id="adf-toolbar-sidebar"
                            [color]="showRightSidebar ? 'accent' : null"
                            (click)="toggleRightSidebar()">
                        <mat-icon>info_outline</mat-icon>
                    </button>

                </ng-container>

                <ng-container *ngIf="mnuMoreActions">
                    <button id="adf-viewer-moreactions"
                            mat-icon-button
                            [matMenuTriggerFor]="mnuMoreActions"
                            [attr.aria-label]="'ADF_VIEWER.ACTIONS.MORE_ACTIONS' | translate"
                            title="{{ 'ADF_VIEWER.ACTIONS.MORE_ACTIONS' | translate }}"
                            data-automation-id="adf-toolbar-more-actions">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #mnuMoreActions="matMenu"
                              [overlapTrigger]="false">
                        <ng-content select="adf-viewer-more-actions"></ng-content>
                    </mat-menu>
                </ng-container>

            </adf-toolbar>
        </ng-container>

        <div class="adf-viewer-sidebars">

            <ng-container *ngIf="allowRightSidebar && showRightSidebar">
                <div class="adf-viewer__sidebar adf-viewer__sidebar__right"
                     id="adf-right-sidebar">
                    <ng-container *ngIf="sidebarRightTemplate">
                        <ng-container *ngTemplateOutlet="sidebarRightTemplate;context:sidebarRightTemplateContext">
                        </ng-container>
                    </ng-container>
                    <ng-content *ngIf="!sidebarRightTemplate"
                                select="adf-viewer-sidebar"></ng-content>
                </div>
            </ng-container>

            <ng-container *ngIf="allowLeftSidebar && showLeftSidebar">
                <div class="adf-viewer__sidebar adf-viewer__sidebar__left"
                     id="adf-left-sidebar">
                    <ng-container *ngIf="sidebarLeftTemplate">
                        <ng-container *ngTemplateOutlet="sidebarLeftTemplate;context:sidebarLeftTemplateContext">
                        </ng-container>
                    </ng-container>
                    <ng-content *ngIf="!sidebarLeftTemplate"
                                select="adf-viewer-sidebar"></ng-content>
                </div>
            </ng-container>

            <adf-viewer-render
                (close)="onClose()"
                [mimeType]="mimeType"
                [fileName]="fileName"
                [blobFile]="blobFile"
                [readOnly]="readOnly"
                (submitFile)="onSubmitFile($event)"
                [urlFile]="urlFile"
                (isSaving)="allowNavigate = !$event"
                [tracks]="tracks">
            </adf-viewer-render>

        </div>
    </div>
</div>
