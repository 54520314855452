/*!
 * @license
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const formModelTabs: any = {
    formRepresentation: {
        id: 16,
        name: 'start event',
        description: '',
        version: 2,
        lastUpdatedBy: 4,
        lastUpdatedByFullName: 'User Test',
        lastUpdated: '2017-10-04T13:00:03.030+0000',
        stencilSetId: null,
        referenceId: null,
        formDefinition: {
          tabs: [],
          fields: [
            {
              fieldType: 'ContainerRepresentation',
              id: '1507037668653',
              name: 'Label',
              type: 'container',
              value: null,
              required: false,
              readOnly: false,
              overrideId: false,
              colspan: 1,
              placeholder: null,
              minLength: 0,
              maxLength: 0,
              minValue: null,
              maxValue: null,
              regexPattern: null,
              optionType: null,
              hasEmptyValue: null,
              options: null,
              restUrl: null,
              restResponsePath: null,
              restIdProperty: null,
              restLabelProperty: null,
              tab: null,
              className: null,
              dateDisplayFormat: null,
              layout: null,
              sizeX: 2,
              sizeY: 1,
              row: -1,
              col: -1,
              visibilityCondition: null,
              numberOfColumns: 2,
              fields: {
                1: [
                  {
                    fieldType: 'AmountFieldRepresentation',
                    id: 'label',
                    name: 'Label',
                    type: 'amount',
                    value: null,
                    required: false,
                    readOnly: false,
                    overrideId: false,
                    colspan: 1,
                    placeholder: null,
                    minLength: 0,
                    maxLength: 0,
                    minValue: null,
                    maxValue: null,
                    regexPattern: null,
                    optionType: null,
                    hasEmptyValue: null,
                    options: null,
                    restUrl: null,
                    restResponsePath: null,
                    restIdProperty: null,
                    restLabelProperty: null,
                    tab: null,
                    className: null,
                    params: {
                      existingColspan: 1,
                      maxColspan: 2
                    },
                    dateDisplayFormat: null,
                    layout: {
                      row: -1,
                      column: -1,
                      colspan: 1
                    },
                    sizeX: 1,
                    sizeY: 1,
                    row: -1,
                    col: -1,
                    visibilityCondition: null,
                    enableFractions: false,
                    currency: null
                  }
                ],
                2: [
                  {
                    fieldType: 'FormFieldRepresentation',
                    id: 'label1',
                    name: 'Label1',
                    type: 'date',
                    value: null,
                    required: false,
                    readOnly: false,
                    overrideId: false,
                    colspan: 1,
                    placeholder: null,
                    minLength: 0,
                    maxLength: 0,
                    minValue: null,
                    maxValue: null,
                    regexPattern: null,
                    optionType: null,
                    hasEmptyValue: null,
                    options: null,
                    restUrl: null,
                    restResponsePath: null,
                    restIdProperty: null,
                    restLabelProperty: null,
                    tab: null,
                    className: null,
                    params: {
                      existingColspan: 1,
                      maxColspan: 1
                    },
                    dateDisplayFormat: null,
                    layout: {
                      row: -1,
                      column: -1,
                      colspan: 1
                    },
                    sizeX: 1,
                    sizeY: 1,
                    row: -1,
                    col: -1,
                    visibilityCondition: null
                  }
                ]
              }
            },
            {
              fieldType: 'ContainerRepresentation',
              id: '1507037670167',
              name: 'Label',
              type: 'container',
              value: null,
              required: false,
              readOnly: false,
              overrideId: false,
              colspan: 1,
              placeholder: null,
              minLength: 0,
              maxLength: 0,
              minValue: null,
              maxValue: null,
              regexPattern: null,
              optionType: null,
              hasEmptyValue: null,
              options: null,
              restUrl: null,
              restResponsePath: null,
              restIdProperty: null,
              restLabelProperty: null,
              tab: null,
              className: null,
              dateDisplayFormat: null,
              layout: null,
              sizeX: 2,
              sizeY: 1,
              row: -1,
              col: -1,
              visibilityCondition: null,
              numberOfColumns: 2,
              fields: {
                1: [
                  {
                    fieldType: 'FormFieldRepresentation',
                    id: 'label2',
                    name: 'Label2',
                    type: 'boolean',
                    value: null,
                    required: false,
                    readOnly: false,
                    overrideId: false,
                    colspan: 1,
                    placeholder: null,
                    minLength: 0,
                    maxLength: 0,
                    minValue: null,
                    maxValue: null,
                    regexPattern: null,
                    optionType: null,
                    hasEmptyValue: null,
                    options: null,
                    restUrl: null,
                    restResponsePath: null,
                    restIdProperty: null,
                    restLabelProperty: null,
                    tab: null,
                    className: null,
                    params: {
                      existingColspan: 1,
                      maxColspan: 2
                    },
                    dateDisplayFormat: null,
                    layout: {
                      row: -1,
                      column: -1,
                      colspan: 1
                    },
                    sizeX: 1,
                    sizeY: 1,
                    row: -1,
                    col: -1,
                    visibilityCondition: null
                  }
                ],
                2: []
              }
            }
          ],
          outcomes: [],
          javascriptEvents: [],
          className: '',
          style: '',
          customFieldTemplates: {},
          metadata: {},
          variables: [],
          customFieldsValueInfo: {},
          gridsterForm: false
        }
    }
  };
