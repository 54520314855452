<h2 mat-dialog-title>
  {{ 'APP.WORKFLOW.SELECT_USER.TITLE' | translate }}
</h2>

<mat-dialog-content class="adf-select-user-dialog-content">
  <form [formGroup]="userForm" novalidate>
    <mat-form-field appearance="standard" id="user-form-field">
      <mat-label>{{ 'APP.WORKFLOW.SELECT_USER.USER' | translate }}</mat-label>
      <input matInput formControlName="selectedUser" [matAutocomplete]="autocompleteUser">
      <mat-autocomplete #autocompleteUser="matAutocomplete">
        <mat-option *ngFor="let user of usersFiltered | async" [value]="user.id">
          {{ user.firstName + (user.lastName ? (' ' + user.lastName) : '') }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="selectedUser.invalid">{{ 'APP.WORKFLOW.SELECT_USER.ERROR.REQUIRED' | translate }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
  <span class="adf-fill-remaining-space"></span>

  <button
    mat-button
    class="adf-dialog-action-button"
    color="primary"
    (click)="onSelect()">
    {{ 'APP.WORKFLOW.SELECT_USER.SELECT' | translate }}
  </button>
  <button
    mat-button
    mat-dialog-close>
    {{ 'APP.GENERAL.CANCEL' | translate }}
  </button>
</mat-dialog-actions>
