<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="APP.BROWSE.ARCHIVE.MENU.ARCHIVE_ACT.TITLE"> </adf-breadcrumb>
  </aca-page-layout-header>

  <aca-page-layout-content>
    <div class="archive-act-main-content">
      <form [formGroup]="archiveActForm" (ngSubmit)="archiveAct()" novalidate>
        <div class="form-column">
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.REGISTRY_BOOK' | translate }}</mat-label>
            <mat-select formControlName="selectedRegistry" required>
              <mat-option *ngFor="let registry of registries" [value]="registry.nodeRef">{{ registry.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="selectedRegistry.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard" required>
            <mat-label>{{ 'APP.ARCHIVE.INVENTORY_OF_ACTS' | translate }}</mat-label>
            <mat-select formControlName="selectedInventoryOfActs" required>
              <mat-option *ngFor="let inventory of inventoriesOfActs" [value]="inventory.nodeRef">{{ inventory.title }}</mat-option>
            </mat-select>
            <mat-error *ngIf="selectedInventoryOfActs.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.DATE_ARCHIVING' | translate }}</mat-label>
            <input matInput [matDatepicker]="dateArchivingPicker" [max]="today" formControlName="dateArchiving" name="dateArchiving" readonly />
            <mat-datepicker-toggle matSuffix [for]="dateArchivingPicker"></mat-datepicker-toggle>
            <mat-datepicker #dateArchivingPicker disabled="false"></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="standard" [style.display]="!hasKomitenti? 'block': 'none'">
            <mat-label>{{ 'APP.ARCHIVE.SENDER_NAME' | translate }}</mat-label>
            <input matInput formControlName="senderName" name="senderName" type="text" [required]="!hasKomitenti" />
            <mat-error *ngIf="senderName.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard" [style.display]="hasKomitenti? 'block': 'none'">
            <mat-label>{{ 'APP.ARCHIVE.SENDER_NAME' | translate }}</mat-label>
            <input matInput formControlName="senderValue" [matAutocomplete]="autocompleteSenderValue" [required]="hasKomitenti" />
            <mat-autocomplete #autocompleteSenderValue="matAutocomplete">
              <mat-option *ngFor="let komitent of komitentiFiltered | async" [value]="komitent.id">{{ komitent.name }}</mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="senderValue.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ (isInvoice ? 'APP.ARCHIVE.INVOICE_NUMBER' : 'APP.ARCHIVE.SENDER_NUMBER') | translate }}</mat-label>
            <input matInput formControlName="senderNumber" name="senderNumber" type="text" [required]="isInvoice" />
            <mat-error *ngIf="senderNumber.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="form-column">
          <mat-form-field appearance="standard">
            <mat-label>{{ (isInvoice ? 'APP.ARCHIVE.INVOICE_DATE' : 'APP.ARCHIVE.DATE_SENT') | translate }}</mat-label>
            <input matInput [matDatepicker]="dateSentPicker" [max]="today" [required]="isInvoice" formControlName="dateSent" name="dateSent" readonly />
            <mat-datepicker-toggle matSuffix [for]="dateSentPicker"></mat-datepicker-toggle>
            <mat-datepicker #dateSentPicker disabled="false"></mat-datepicker>
            <mat-error *ngIf="dateSent.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.DIVORCE_DATE' | translate }}</mat-label>
            <input matInput [matDatepicker]="divorceDatePicker" formControlName="divorceDate" name="divorceDate" readonly />
            <mat-datepicker-toggle matSuffix [for]="divorceDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #divorceDatePicker disabled="false"></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.DIVORCE_SIGN' | translate }}</mat-label>
            <input matInput formControlName="divorceSign" name="divorceSign" type="text" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.NOTES' | translate }}</mat-label>
            <textarea matInput formControlName="notes" name="notes"></textarea>
          </mat-form-field>
        </div>
        <div class="form-column">
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.ADDITIONAL_PROPERTIES' | translate }}</mat-label>
            <mat-select formControlName="selectedAdditionalProperties">
              <mat-option *ngFor="let property of additionalProperties" [value]="property.value">{{ property.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="invoice-amount" *ngIf="isInvoice">
            <mat-form-field appearance="standard">
              <mat-label>{{ 'APP.ARCHIVE.INVOICE_AMOUNT' | translate }}</mat-label>
              <input matInput formControlName="invoiceAmount" name="invoiceAmmount" type="number" inputmode="numeric" min="0" />
              <mat-select formControlName="selectedCurrency">
                <mat-option *ngFor="let currency of currencies" [value]="currency">{{ currency }}</mat-option>
              </mat-select>
              <mat-error *ngIf="invoiceAmount.invalid">{{ 'APP.ARCHIVE.ERROR.NEGATIVE_VALUE' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="form-footer">
          <button mat-raised-button type="submit">{{ 'APP.ARCHIVE.ARCHIVE' | translate }}</button>
        </div>
      </form>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
