<div class="adf-about-server-settings">
    <mat-card class="mat-elevation-z0 adf-about-server-settings__card">
        <p data-automation-id="adf-process-service-host">
            {{ 'ABOUT.SERVER_SETTINGS.PROCESS_SERVICE_HOST' | translate: {value: bpmHost} }}
        </p>
        <p data-automation-id="adf-content-service-host">
            {{ 'ABOUT.SERVER_SETTINGS.CONTENT_SERVICE_HOST' | translate: {value: ecmHost} }}
        </p>
    </mat-card>
</div>
