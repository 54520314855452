<div class="adf-file-uploading-row">
    <mat-icon *ngIf="mimeType === 'default'" mat-list-icon class="adf-file-uploading-row__type">
        insert_drive_file
    </mat-icon>

    <adf-icon *ngIf="mimeType !== 'default'" value="adf:{{ mimeType }}"></adf-icon>

    <span
        tabindex="0"
        class="adf-file-uploading-row__name"
        title="{{ file.name }}">
        {{ file.name }}
    </span>

    <span *ngIf="isUploadVersion()" class="adf-file-uploading-row__version" tabindex="0" >
        <mat-chip color="primary"
            [attr.aria-label]="'ADF_FILE_UPLOAD.ARIA-LABEL.VERSION' | translate: { version:  versionNumber }"
            [title]="'version' + versionNumber" disabled
        >{{ versionNumber }}</mat-chip>
    </span>

    <div
        tabindex="0"
        role="button"
        #toggleIcon="toggleIcon"
        adf-toggle-icon
        (keyup.enter)="onCancel(file)"
        (click)="onCancel(file)"
        data-automation-id="cancel-upload-progress"
        *ngIf="isUploading()"
        [attr.aria-label]="'ADF_FILE_UPLOAD.ARIA-LABEL.STOP_FILE_UPLOAD' | translate: { file: file.name }"
        class="adf-file-uploading-row__group adf-file-uploading-row__group--toggle"
        title="{{ 'ADF_FILE_UPLOAD.BUTTON.STOP_FILE' | translate }}">

        <span class="adf-file-uploading-row__status" *ngIf="!toggleIcon.isToggled">
            {{ file.progress.loaded | adfFileSize }} / {{ file.progress.total | adfFileSize }}
        </span>

        <mat-icon *ngIf="toggleIcon.isToggled"
            class="adf-file-uploading-row__action adf-file-uploading-row__action--cancel">
            clear
        </mat-icon>
    </div>

    <button mat-icon-button
        adf-toggle-icon
        #toggleIcon="toggleIcon"
        *ngIf="isUploadComplete()"
        class="adf-file-uploading-row__group"
        [attr.aria-label]="'ADF_FILE_UPLOAD.ARIA-LABEL.UPLOAD_SUCCESSFUL' | translate: { file: file.name }"
        title="{{ 'ADF_FILE_UPLOAD.BUTTON.UPLOAD_SUCCESSFUL' | translate }}">

        <mat-icon
            class="adf-file-uploading-row__status adf-file-uploading-row__status--done">
            check_circle
        </mat-icon>
    </button>

    <div
        *ngIf="isUploadVersionComplete()"
        class="adf-file-uploading-row__file-version"
        [attr.aria-label]="'ADF_FILE_UPLOAD.STATUS.FILE_DONE_STATUS' | translate"
        role="status"
        >
        <mat-icon
            mat-list-icon
            class="adf-file-uploading-row__status--done">
            check_circle
        </mat-icon>
    </div>

    <button
        adf-toggle-icon
        #toggleIconCancel="toggleIcon"
        mat-icon-button
        *ngIf="canCancelUpload()"
        (click)="onCancel(file)"
        data-automation-id="cancel-upload-queue"
        class="adf-file-uploading-row__group"
        title="{{ 'ADF_FILE_UPLOAD.BUTTON.STOP_FILE' | translate }}"
        [attr.aria-label]="'ADF_FILE_UPLOAD.ARIA-LABEL.STOP_FILE' | translate: { file: file.name }">
        <mat-icon
            *ngIf="!toggleIconCancel.isToggled"
            class="adf-file-uploading-row__status adf-file-uploading-row__status--pending">
            schedule
        </mat-icon>

        <mat-icon
            *ngIf="toggleIconCancel.isToggled"
            class="adf-file-uploading-row__action adf-file-uploading-row__action--remove">
            remove_circle
        </mat-icon>
    </button>

    <div
        tabindex="0"
        role="status"
        *ngIf="isUploadError()"
        class="adf-file-uploading-row__block adf-file-uploading-row__status--error">
        <mat-icon mat-list-icon
            [attr.aria-label]="'ADF_FILE_UPLOAD.ARIA-LABEL.UPLOAD_FILE_ERROR' | translate: { error: file.errorCode | adfFileUploadError }"
            [title]="file.errorCode | adfFileUploadError">
            report_problem
        </mat-icon>
    </div>

    <div
        tabindex="0"
        [attr.aria-label]="'ADF_FILE_UPLOAD.STATUS.FILE_CANCELED_STATUS' | translate"
        role="status"
        *ngIf="showCancelledStatus()"
        class="adf-file-uploading-row__block adf-file-uploading-row__status--cancelled">
        {{ 'ADF_FILE_UPLOAD.STATUS.FILE_CANCELED_STATUS' | translate }}
    </div>
</div>
