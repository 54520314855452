import { ContentApiService } from '@alfresco/aca-shared';
import { DataCellEvent, DataRowActionEvent, DataTableModule, TranslationService } from '@alfresco/adf-core';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { NodeActionsService } from '../../../../services/node-actions.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DataTableModule,
    MatDialogModule,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-subject-info',
  templateUrl: './subject-info.component.html',
  styleUrls: ['./subject-info.component.scss']
})
export class SubjectInfoComponent implements OnInit {
  subjectSchema = [
    {
      type: 'text',
      key: 'seqIndex',
      title: this.translation.instant('APP.ARCHIVE.SUBJECT_INFO.SEQUENTIAL_INDEX'),
      sortable: true
    },
    {
      type: 'text',
      key: 'dateArchived',
      title: this.translation.instant('APP.ARCHIVE.SUBJECT_INFO.DATE_ARCHIVED'),
      sortable: true
    },
    {
      type: 'text',
      key: 'subject',
      title: this.translation.instant('APP.ARCHIVE.SUBJECT_INFO.SUBJECT'),
      sortable: true
    },
    {
      type: 'text',
      key: 'sender',
      title: this.translation.instant('APP.ARCHIVE.SUBJECT_INFO.SENDER'),
      sortable: true
    },
    {
      type: 'text',
      key: 'senderNumber',
      title: this.translation.instant('APP.ARCHIVE.SUBJECT_INFO.SENDER_NUMBER'),
      sortable: true
    },
    {
      type: 'text',
      key: 'dateSent',
      title: this.translation.instant('APP.ARCHIVE.SUBJECT_INFO.DATE_SENT'),
      sortable: true
    },
    {
      type: 'text',
      key: 'archiveSign',
      title: this.translation.instant('APP.ARCHIVE.SUBJECT_INFO.ARCHIVE_SIGN'),
      sortable: true
    }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translation: TranslationService,
    private contentApiService: ContentApiService,
    private nodeActionsService: NodeActionsService
  ) {}

  ngOnInit(): void {}

  onShowRowActionsUsersMenu(event: DataCellEvent) {
    const viewDocumentAction = {
      id: 0,
      title: this.translation.instant('APP.ARCHIVE.SUBJECT_INFO.VIEW_DOCUMENT')
    };

    event.value.actions = [viewDocumentAction];
  }

  async onExecuteUsersRowAction(event: DataRowActionEvent) {
    if (event.value.action.id === 0) {
      const nodeRef: string = event.value.row.obj.nodeRef;

      const nodeGuid = nodeRef.substring(nodeRef.lastIndexOf('/') + 1);
      const node = await this.contentApiService.getNodeInfo(nodeGuid).toPromise();
      const nodeParentId = this.nodeActionsService.getEntryParentId(node);
      const url = `/#/libraries/${nodeParentId}/(viewer:view/${nodeGuid})`;

      window.open(url, '_blank');
    }
  }
}
