<h2 mat-dialog-title>
  {{ 'APP.ARCHIVE.INSERT_INTO_SUBJECT.TITLE' | translate }}
</h2>

<mat-dialog-content class="adf-insert-into-subject-dialog-content">
  <form [formGroup]="archiveForm" novalidate>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'APP.ARCHIVE.REGISTRY_BOOK' | translate }}</mat-label>
      <mat-select formControlName="selectedRegistry" required>
        <mat-option *ngFor="let registry of registries" [value]="registry.nodeRef">{{ registry.name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="selectedRegistry.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'APP.ARCHIVE.INDEX' | translate }}</mat-label>
      <input matInput formControlName="subjectIndex" name="index" type="number" inputmode="numeric" min="1" />
      <span matSuffix>{{ selectedRegistryCounter }}</span>
      <mat-error *ngIf="subjectIndex.errors?.min">{{ 'APP.ARCHIVE.ERROR.NEGATIVE_VALUE' | translate }}</mat-error>
      <mat-error *ngIf="subjectIndex.errors?.invalidIndex">{{ 'APP.ARCHIVE.ERROR.INDEX_INVALID' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'APP.ARCHIVE.SEQUENTIAL_INDEX' | translate }}</mat-label>
      <input matInput formControlName="sequentialIndex" name="index" type="number" inputmode="numeric" min="1" />
      <mat-error *ngIf="sequentialIndex.errors?.min">{{ 'APP.ARCHIVE.ERROR.NEGATIVE_VALUE' | translate }}</mat-error>
      <mat-error *ngIf="sequentialIndex.errors?.invalidIndex">{{ 'APP.ARCHIVE.ERROR.INDEX_INVALID' | translate }}</mat-error>
    </mat-form-field>
    <div *ngIf="selectedSubject" class="results-preview">
      {{ printSelectedSubject() }}
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
  <span class="adf-fill-remaining-space"></span>

  <button
    mat-button
    class="adf-dialog-action-button"
    color="primary"
    (click)="insertIntoSubject()">
    {{ 'APP.ARCHIVE.INSERT_INTO_SUBJECT.INSERT' | translate }}
  </button>
  <button
    mat-button
    mat-dialog-close>
    {{ 'APP.GENERAL.CANCEL' | translate }}
  </button>
</mat-dialog-actions>
