<mat-chip-list>
    <ng-container *ngIf="facetFiltersService.selectedBuckets.length">
        <mat-chip *ngIf="clearAll && facetFiltersService.selectedBuckets.length > 1"
            data-automation-id="reset-filter"
            color="primary"
            selected
            matTooltip="{{ 'SEARCH.FILTER.BUTTONS.CLEAR-ALL.TOOLTIP' | translate }}"
            matTooltipPosition="right"
            (click)="facetFiltersService.resetAllSelectedBuckets()">
            {{ 'SEARCH.FILTER.BUTTONS.CLEAR-ALL.LABEL' | translate }}
        </mat-chip>

        <mat-chip
            data-automation-id="chip-list-entry"
            *ngFor="let selection of facetFiltersService.selectedBuckets"
            [removable]="true"
            (removed)="facetFiltersService.unselectFacetBucket(selection.field, selection.bucket)">
            {{ (selection.bucket.display || selection.bucket.label) | translate }}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
    </ng-container>
</mat-chip-list>
