/*!
 * @license
 * Alfresco Example Content Application
 *
 * Copyright (C) 2005 - 2020 Alfresco Software Limited
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail.  Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with Alfresco. If not, see <http://www.gnu.org/licenses/>.
 */

import { WorkflowService } from '@alfresco/aca-shared';
import { AppStore, SnackbarErrorAction, } from '@alfresco/aca-shared/store';
import { DataColumn, DataRowEvent, DataTableModule, TranslationService } from '@alfresco/adf-core';
import { Node } from '@alfresco/js-api';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    DataTableModule,
  ],
  templateUrl: './show-all-workflows.dialog.html',
  styleUrls: ['./show-all-workflows.dialog.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'aca-show-all-workflows-dialog' }
})
export class ShowAllWorkflowsDialogComponent implements OnInit {
  node: Node;

  workflows: object[];
  workflowRows: object[];
  workflowSchema: DataColumn[];

  loading: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private store: Store<AppStore>,
    private workflowService: WorkflowService,
    private translate: TranslationService,
  ) {
    this.node = data.node.entry;

    this.workflowSchema = [
      {
        type: 'text',
        key: 'status',
        title: this.translate.instant('APP.WORKFLOW.GENERAL.STATUS'),
        sortable: true
      },
      {
        type: 'text',
        key: 'startDate',
        title: this.translate.instant('APP.WORKFLOW.GENERAL.DATE_STARTED'),
        sortable: false
      },
      {
        type: 'text',
        key: 'description',
        title: this.translate.instant('APP.WORKFLOW.GENERAL.DESCRIPTION'),
        cssClass: 'full-width',
        sortable: false
      },
      {
        type: 'text',
        key: 'endDate',
        title: this.translate.instant('APP.WORKFLOW.GENERAL.DATE_COMPLETED'),
        sortable: false
      }
    ];
  }

  async ngOnInit() {
    try {
      this.workflows = await this.workflowService.getDocumentWorkflows(`workspace://SpacesStore/${this.node.id}`);
      this.workflowRows = this.workflows.map(el => ({
        ...el,
        status: this.translate.instant(el['isActive'] === 'true' ? 'APP.WORKFLOW.GENERAL.ACTIVE' : 'APP.WORKFLOW.GENERAL.COMPLETED'),
      })).sort((a, b) => {
        if (a['isActive'] === 'true' && b['isActive'] === 'true')  {
          return 0;
        } else if (a['isActive'] === 'true' && b['isActive'] === 'false') {
          return -1;
        } else if (a['isActive'] === 'false' && b['isActive'] === 'true') {
          return 1;
        } else {
          return 0;
        }
      });
      this.loading = false;
    } catch (err) {
      this.store.dispatch(new SnackbarErrorAction(this.translate.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }

  onRowClicked(event: DataRowEvent) {
    const activitiId = event.value.obj.id;
    const workflowId = activitiId.substring('activiti$'.length);
    window.open(`#/workflow/workflow-details/${workflowId}`, '_blank');
  }
}
