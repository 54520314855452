/*!
 * @license
 * Alfresco Example Content Application
 *
 * Copyright (C) 2005 - 2020 Alfresco Software Limited
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail.  Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with Alfresco. If not, see <http://www.gnu.org/licenses/>.
 */

import { AppStore, getUserProfile, SnackbarErrorAction, } from '@alfresco/aca-shared/store';
import { TranslationService } from '@alfresco/adf-core';
import { DigitalSignatureService } from '../../services/digital-signature.service';
import { Node } from '@alfresco/js-api';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './digital-signature.dialog.html',
  styleUrls: ['./digital-signature.dialog.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'aca-digital-signature-dialog' }
})
export class DigitalSignatureDialogComponent implements OnInit {
  static SUCCESS_MESSAGE_LIFESPAN = 3500;

  node: Node;
  positionValues: string[] = [];
  positions: { id: string, name: string }[] = [];
  base64Content: string;
  paramsPades: string;
  paramsCades: string;
  signatureAlg: string;
  signPosition: number;
  userName: string;
  step = 0;
  result: boolean = false;
  resultMessage: string = '';

  digitallySignForm = new FormGroup({
    selectedPosition: new FormControl(undefined, Validators.required),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private store: Store<AppStore>,
    private digitalSignatureService: DigitalSignatureService,
    private translate: TranslationService,
    private dialogRef: MatDialogRef<DigitalSignatureDialogComponent>
  ) {
    this.node = data.node.entry;
  }

  get selectedPosition() {
    return this.digitallySignForm.get('selectedPosition');
  }

  async ngOnInit() {
    this.store.select(getUserProfile).subscribe((user) => (this.userName = user.id));

    this.digitalSignatureService.getNodeBase64Content(`workspace://SpacesStore/${this.node.id}`)
      .then((base64) => {
        this.base64Content = base64;
      })
      .catch((_) => {
        this.store.dispatch(new SnackbarErrorAction(this.translate.instant('APP.ARCHIVE.DIGITALLY_SIGN.ERROR.GENERIC')));
      }) 

    try {
      const signatureParams = await this.digitalSignatureService.getSignatureParams();

      // build signature positions
      const aspectNames = this.node.aspectNames;
      if (aspectNames.indexOf('sign:firstSignature') === -1) {
        this.positions.push({
          id: 'sig1',
          name: this.translate.instant('APP.ARCHIVE.DIGITALLY_SIGN.POSITIONS.FIRST'),
        });
      }
      if (aspectNames.indexOf('sign:secondSignature') === -1) {
        this.positions.push({
          id: 'sig2',
          name: this.translate.instant('APP.ARCHIVE.DIGITALLY_SIGN.POSITIONS.SECOND'),
        });
      }
      if (aspectNames.indexOf('sign:thirdSignature') === -1) {
        this.positions.push({
          id: 'sig3',
          name: this.translate.instant('APP.ARCHIVE.DIGITALLY_SIGN.POSITIONS.THIRD'),
        });
      }
      if (aspectNames.indexOf('sign:fourthSignature') === -1) {
        this.positions.push({
          id: 'sig4',
          name: this.translate.instant('APP.ARCHIVE.DIGITALLY_SIGN.POSITIONS.FOURTH'),
        });
      }
      this.positions.push({
        id: 'sig5',
        name: this.translate.instant('APP.ARCHIVE.DIGITALLY_SIGN.POSITIONS.FIFTH'),
      });

      this.paramsPades = signatureParams['paramsPades'];
      this.paramsCades = signatureParams['paramsCades'];
      this.signatureAlg = signatureParams['signatureAlg'];

      this.positionValues = [
        signatureParams['firstSignaturePosition'],
        signatureParams['secondSignaturePosition'],
        signatureParams['thirdSignaturePosition'],
        signatureParams['fourthSignaturePosition'],
        signatureParams['fifthSignaturePosition'],
      ];
      
      if (signatureParams['useServerTime']) {
        const getNow = () => {
          const formatNumber = (number) => {
            return number < 10 ? '0' + number : number;
          }

          const now = new Date();
          const day = now.getDate();
          const month = now.getMonth() + 1;
          const year = now.getFullYear();
          const hour = now.getHours();
          const minutes = now.getMinutes();
          
          const isDaylightSavingsTime = now.getTimezoneOffset() < Math.max(new Date(now.getFullYear(), 0, 1).getTimezoneOffset(), new Date(now.getFullYear(), 6, 1).getTimezoneOffset());
          let timezoneAbbr = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
          if((timezoneAbbr == 'GMT+1' && !isDaylightSavingsTime) || (timezoneAbbr == 'GMT+2' && isDaylightSavingsTime))
            timezoneAbbr = 'CET';
          
          return formatNumber(day) + '.' + formatNumber(month) + '.' + year + '{space}' + formatNumber(hour) + ':' + formatNumber(minutes) + '{space}' + timezoneAbbr;
        }
        const dateTime = getNow();
        const signatureFormat = '\tlayer2Text=$$SUBJECTCN$${newline}' + dateTime /*+ '\tlayer2FontSize=8'*/;
        for (let i = 0, len = this.positionValues.length; i < len; ++i) {
          this.positionValues[i] += signatureFormat;
        }
      }

      this.selectedPosition.setValue(
        this.positions[0].id);
    } catch (_) {
      this.store.dispatch(new SnackbarErrorAction(this.translate.instant('APP.ARCHIVE.DIGITALLY_SIGN.ERROR.GENERIC')));
    }
  }

  async initiateSignature() {
    if (this.digitallySignForm.invalid) return;

    const page = 'last_page';
    const selectedPositionIndex = this.positions.findIndex(el => el.id === this.selectedPosition.value);
    const selectedPositionValue = this.positionValues[selectedPositionIndex]
      .replace(/ /g, '\t')
      .replace(/\{space\}/g, ' ')
      .replace(/\{page\}/g, page)
      .replace(/\{newline\}/g, '\\n')
      .replace(/\t/g, '\n');
    this.signPosition = selectedPositionIndex + 1;

    let mimeType = this.node.content.mimeType;
    const slashIndexOf = mimeType.indexOf('/');
    if (slashIndexOf !== -1) {
      mimeType = mimeType.substring(slashIndexOf + 1);
    }
    
    this.step = 1;
    
    try {
      const { signatureBase64, certificateB64, } = await this.digitalSignatureService.performSignature(mimeType, this.paramsCades, selectedPositionValue, this.signatureAlg, this.base64Content);
      const nodeRef = `workspace://SpacesStore/${this.node.id}`;
      try {
        await this.digitalSignatureService.saveSignature(nodeRef, mimeType, '', this.base64Content, signatureBase64, certificateB64, this.signPosition.toString(), this.userName);
        this.result = true;
      } catch (_) {
        this.result = false;
      }
    } catch (_) {
      this.result = false;
    }

    this.resultMessage = this.translate.instant(this.result ?
      'APP.ARCHIVE.DIGITALLY_SIGN.SUCCESS_SIGNED' :
      'APP.ARCHIVE.DIGITALLY_SIGN.ERROR.NOT_SIGNED');
    this.step = 2;
  }

  closeDialog() {
    this.dialogRef.close(this.result);
  }
}
