/*!
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Alfresco Example Content Application
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail. Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * from Hyland Software. If not, see <http://www.gnu.org/licenses/>.
 */

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppConfigService } from '@alfresco/adf-core';
import { NodeEntry } from '@alfresco/js-api';
import { FileAutoDownloadComponent } from '@alfresco/adf-content-services';

const BYTES_TO_MB_CONVERSION_VALUE = 1048576;

@Injectable({
  providedIn: 'root'
})
export class AcaFileAutoDownloadService {
  constructor(private dialog: MatDialog, private appConfig: AppConfigService) {}

  public shouldFileAutoDownload(fileSizeInBytes: number): boolean {
    const sizeInMB = fileSizeInBytes / BYTES_TO_MB_CONVERSION_VALUE;

    const fileAutoDownloadFlag: boolean = this.appConfig.get('viewer.enableFileAutoDownload', true);
    const sizeThreshold: number = this.appConfig.get('viewer.fileAutoDownloadSizeThresholdInMB', 15);

    return fileAutoDownloadFlag && sizeInMB && sizeInMB > sizeThreshold;
  }

  public autoDownloadFile(node: NodeEntry) {
    this.dialog.open(FileAutoDownloadComponent, { disableClose: true, data: node });
  }
}
