<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="APP.BROWSE.ARCHIVE.MENU.ARCHIVE_FILE.TITLE"> </adf-breadcrumb>
  </aca-page-layout-header>

  <aca-page-layout-content>
    <div class="archive-file-main-content">
      <form [formGroup]="archiveFileForm" (ngSubmit)="archiveFile()" novalidate>
        <div class="form-column">
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.REGISTRY_BOOK' | translate }}</mat-label>
            <mat-select formControlName="selectedRegistry" required>
              <mat-option *ngFor="let registry of registries" [value]="registry.nodeRef">{{ registry.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="selectedRegistry.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard" required>
            <mat-label>{{ 'APP.ARCHIVE.ORGANIZATIONAL_UNIT' | translate }}</mat-label>
            <mat-select formControlName="selectedOrganizationalUnit" required>
              <mat-option *ngFor="let unit of organizationalUnits" [value]="unit.nodeRef">{{ unit.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="selectedOrganizationalUnit.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.ARCHIVE_SIGN' | translate }}</mat-label>
            <mat-select formControlName="selectedArchiveSign" required>
              <mat-option *ngFor="let sign of archiveSigns" [value]="sign.nodeRef">{{ sign.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="selectedArchiveSign.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.INDEX' | translate }}</mat-label>
            <input matInput formControlName="subjectIndex" name="index" type="number" inputmode="numeric" min="1" />
            <span *ngIf="subjectsAtIndex.has(subjectIndex.value)" matPrefix (click)="openSubjectsAtIndex()"><mat-icon>visibility</mat-icon></span>
            <span matSuffix>{{ selectedRegistryCounter }}</span>
            <mat-error *ngIf="subjectIndex.errors?.min">{{ 'APP.ARCHIVE.ERROR.NEGATIVE_VALUE' | translate }}</mat-error>
            <mat-error *ngIf="subjectIndex.errors?.invalidIndex">{{ 'APP.ARCHIVE.ERROR.INDEX_EXISTS' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.SEQUENTIAL_INDEX' | translate }}</mat-label>
            <input matInput formControlName="sequentialIndex" name="index" type="number" inputmode="numeric" min="1" />
            <mat-error *ngIf="sequentialIndex.invalid">{{ 'APP.ARCHIVE.ERROR.NEGATIVE_VALUE' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="form-column">
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.DATE_ARCHIVING' | translate }}</mat-label>
            <input matInput [matDatepicker]="dateArchivingPicker" [max]="today" formControlName="dateArchiving" name="dateArchiving" readonly />
            <mat-datepicker-toggle matSuffix [for]="dateArchivingPicker"></mat-datepicker-toggle>
            <mat-datepicker #dateArchivingPicker disabled="false"></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.SUBJECT' | translate }}</mat-label>
            <input matInput formControlName="subjectName" name="subjectName" type="text" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.SENDER_NAME' | translate }}</mat-label>
            <input matInput formControlName="senderName" name="senderName" type="text" />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.DATE_SENT' | translate }}</mat-label>
            <input matInput [matDatepicker]="dateSentPicker" [max]="today" formControlName="dateSent" name="dateSent" readonly />
            <mat-datepicker-toggle matSuffix [for]="dateSentPicker"></mat-datepicker-toggle>
            <mat-datepicker #dateSentPicker disabled="false"></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.SENDER_NUMBER' | translate }}</mat-label>
            <input matInput formControlName="senderNumber" name="senderNumber" type="text" />
          </mat-form-field>
        </div>
        <div class="form-column">
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.NOTES' | translate }}</mat-label>
            <textarea matInput formControlName="notes" name="notes"></textarea>
          </mat-form-field>
        </div>
        <div class="form-footer">
          <button mat-raised-button type="submit">{{ 'APP.ARCHIVE.ARCHIVE' | translate }}</button>
        </div>
      </form>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
