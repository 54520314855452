import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-image-annotator',
  templateUrl: './image-annotator.component.html',
  styleUrls: ['./image-annotator.component.scss']
})
export class ImageAnnotatorComponent implements AfterViewInit, OnChanges {
  
  @Input('size') size = null;
  @Input('image') image = null;
  @Input('mode') mode = null;
  @Input('contentHtml') contentHtml = null;
  @Input('textScaleCoef') textScaleCoef = 6.5;

  @Output('coordinatesChanged') coordinatesChangedEvent = new EventEmitter<{ x: number, y: number }>();
  @Output('coordinatesReset') coordinatesResetEvent = new EventEmitter<void>();

  @ViewChild('outerContainer') divOuterContainer: ElementRef;
  @ViewChild('thumbnailPreview') imgThumbnailPreview: ElementRef;
  @ViewChild('canvas') divCanvas: ElementRef;
  @ViewChild('annotation') divAnnotation: ElementRef;

  constructor() {}

  updateSize(size: any): void {
    if (!this.divOuterContainer)
      return;
    
    const htmlElement = this.divOuterContainer.nativeElement;
    htmlElement.style.width = `${size.width}px`;
    htmlElement.style.height = `${size.height}px`;
  }

  updateImage(image: any): void {
    if (!this.imgThumbnailPreview)
      return;
    if (!image) {
      this.imgThumbnailPreview.nativeElement.style.display = 'none';
    } else {
      this.imgThumbnailPreview.nativeElement.style.display = 'block';
    }
  }

  updateAnnotationRect(contentHtml: any): void {
    if (!this.divAnnotation)
      return;
    const htmlElement = this.divAnnotation.nativeElement;
    if (this.mode === ImageAnnotatorComponent.Mode.Text && Boolean(contentHtml)) {
      htmlElement.innerHTML = contentHtml;
      htmlElement.style.padding = '2px 4px';
      const { width, height } = this.getAnnotationRectSize();
      const prevWidth = parseInt(htmlElement.style.width.substring(0, htmlElement.style.width.length - 2)),
            prevHeight = parseInt(htmlElement.style.height.substring(0, htmlElement.style.height.length - 2)),
            prevLeft = parseInt(htmlElement.style.left.substring(0, htmlElement.style.left.length - 2)),
            prevTop = parseInt(htmlElement.style.top.substring(0, htmlElement.style.top.length - 2));
      htmlElement.style.width = `${width}px`;
      htmlElement.style.height = `${height}px`;
      if (!isNaN(prevWidth) && !isNaN(prevHeight) && !isNaN(prevLeft) && !isNaN(prevTop)) {
        const left = prevLeft - Math.round((width - prevWidth) / 2),
              top = prevTop - Math.round((height - prevHeight) / 2);
        htmlElement.style.top = `${top}px`;
        htmlElement.style.left = `${left}px`;
      }
    } else {
      htmlElement.innerHTML = '';
      htmlElement.style.padding = '0';
    }
  }

  ngAfterViewInit(): void {
    this.updateSize(this.size);
    this.updateImage(this.image);
    this.updateAnnotationRect(this.contentHtml);
    this.divAnnotation.nativeElement.style.display = 'none';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['size']) {
      this.updateSize(changes['size'].currentValue);
    }
    if (changes['image']) {
      this.updateImage(changes['image'].currentValue);
    }
    if (changes['contentHtml']) {
      this.updateAnnotationRect(changes['contentHtml'].currentValue);
    }
    if (changes['mode']) {
      this.updateAnnotationRect(this.contentHtml);
      if (this.divAnnotation && this.divAnnotation.nativeElement.style.display === 'block') {
        this.divAnnotation.nativeElement.style.display = 'none';
        this.coordinatesResetEvent.emit();
      }
    }
  }

  getAnnotationRectSize(): any {
    if (this.mode === ImageAnnotatorComponent.Mode.Rect) {
      return { width: 90, height: 51 };
    } else {
      // not extendable at all
      const contentHtmlParts = this.contentHtml.split('<br />');
      let longestPart = '';
      contentHtmlParts.forEach((el) => {
        if (el.length > longestPart.length) {
          longestPart = el;
        }
      });
      const finalWidth = Math.round(longestPart.length * this.textScaleCoef),
            finalHeight = 10 * contentHtmlParts.length + 4;
      return { width: finalWidth, height: finalHeight };
    }
  }

  updateAnnotationPosition(x: number, y: number): void {
    const htmlElement = this.divAnnotation.nativeElement;
    const { width, height } = this.getAnnotationRectSize();
		const left = x - Math.round(width / 2),
          top = y - Math.round(height / 2);
    htmlElement.style.width = `${width}px`;
    htmlElement.style.height = `${height}px`;
    htmlElement.style.top = `${top}px`;
    htmlElement.style.left = `${left}px`;
    htmlElement.style.display = 'block';
  }

  onCanvasClicked(event: MouseEvent): void {
    const { offsetX, offsetY } = event;
    this.updateAnnotationPosition(offsetX, offsetY);
    this.coordinatesChangedEvent.emit({ x: offsetX, y: offsetY });
  }
}

export namespace ImageAnnotatorComponent
{
  export enum Mode
  {
    Rect = 'rect',
    Text = 'text',
  }
}