<div id="adf-like-container" class="adf-like-container">
    <div class="adf-like">
        <span id="adf-like-{{nodeId}}" [ngClass]="{'adf-like-select': isLike ,'adf-like-grey': !isLike  }"
              (click)="likeClick()">
            <mat-icon>thumb_up</mat-icon>
        </span>
    </div>
    <div class="adf-like-counter-container">
        <div id="adf-like-counter" class="adf-like-counter">{{likesCounter}}</div>
        <div class="adf-left" *ngIf="likesCounter===1">Like</div>
        <div class="adf-left" *ngIf="likesCounter!==1">Likes</div>
    </div>
</div>
