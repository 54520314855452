import { AlfrescoApiService } from '@alfresco/adf-core';
import { ContentClient } from '@alfresco/js-api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomApiCallService {
  constructor(
    private alfrescoApiService: AlfrescoApiService,
  ) { }

  callApi(url: string, method: HTTPMethod, payload: any = null, payloadType: PayloadType = PayloadType.JSON, payloadTypeCustom: string = null, responseType: ResponseType = ResponseType.JSON): Promise<any> {
    return this._callApi('/api', url, method, payload, payloadType, payloadTypeCustom, responseType);
  }

  callService(url: string, method: HTTPMethod, payload: any = null, payloadType: PayloadType = PayloadType.JSON, payloadTypeCustom: string = null, responseType: ResponseType = ResponseType.JSON): Promise<any> {
    return this._callApi('/s', url, method, payload, payloadType, payloadTypeCustom, responseType);
  }

  callServiceApi(url: string, method: HTTPMethod, payload: any = null, payloadType: PayloadType = PayloadType.JSON, payloadTypeCustom: string = null, responseType: ResponseType = ResponseType.JSON): Promise<any> {
    return this._callApi('/s/api', url, method, payload, payloadType, payloadTypeCustom, responseType);
  }

  private _callApi(baseUrl: string, url: string, method: HTTPMethod, payload: any = null, payloadType: PayloadType = PayloadType.JSON, payloadTypeCustom: string = null, responseType: ResponseType = ResponseType.JSON): Promise<any> {
    const client = new ContentClient(this.alfrescoApiService.getInstance().config, baseUrl);
    client.setAuthentications({
      ...this.alfrescoApiService.getInstance().authClient.authentications,
    });
    let body = null;
    const contentTypes = [];
    const acceptTypes = [];
    if (payload && payloadType === PayloadType.JSON) {
      body = JSON.stringify(payload);
      contentTypes.push('application/json');
    } else if (payload && payloadType === PayloadType.FORM_DATA) {
      body = payload; // expected type is FormData
      contentTypes.push('multipart/form-data');
    } else if (payload && payloadType === PayloadType.CUSTOM) {
      body = payload;
      contentTypes.push(payloadTypeCustom);
    }
    const acceptMimeType = this.responseTypeToMimeType(responseType);
    if (acceptMimeType)
      acceptTypes.push(acceptMimeType);
    return client.callApi(url, method, null, null, null, null, body, contentTypes, acceptTypes, null, null, responseType);
  }

  private responseTypeToMimeType(responseType: ResponseType): string {
    switch (responseType) {
      case ResponseType.JSON:
        return 'application/json';
      case ResponseType.TEXT:
        return 'plain/text';
      case ResponseType.ARRAYBUFFER:
        return 'application/octet-stream';
      case ResponseType.BLOB:
        return 'application/octet-stream';
      case ResponseType.DOCUMENT:
        return '';  // handle using additional parameter, if needed
      default:
        return '';
    }
  }
}

export enum PayloadType { JSON, FORM_DATA, CUSTOM };

export enum HTTPMethod { GET = 'GET', POST = 'POST', PUT = 'PUT', DELETE = 'DELETE' };

export enum ResponseType { JSON = 'json', TEXT = 'text', ARRAYBUFFER = 'arraybuffer', BLOB = 'blob', DOCUMENT = 'document' };