<div mat-dialog-title data-automation-id="aspect-list-dialog-title" class="adf-aspect-list-dialog">
    <h1 class="adf-aspect-list-dialog-title">{{title | translate}}</h1>
    <div class="adf-aspect-list-dialog-description">{{description | translate}}</div>
</div>

<div class="adf-aspect-list-dialog-information">
    <p id="aspect-list-dialog-over-table-message">{{overTableMessage | translate}}</p>
    <p id="aspect-list-dialog-counter">{{currentAspectSelection ? currentAspectSelection.length : 0}}
        {{'ADF-ASPECT-LIST.DIALOG.SELECTED' | translate}}</p>
</div>
<mat-dialog-content class="adf-aspect-dialog-content">
    <adf-aspect-list #aspectList (valueChanged)="onValueChanged($event)" [nodeId]="currentNodeId">
    </adf-aspect-list>
</mat-dialog-content>

<mat-dialog-actions>
    <div>
        <button mat-button (click)="aspectList.reset()" id="aspect-list-dialog-actions-reset"
            data-automation-id="aspect-list-dialog-actions-reset">{{
            'ADF-ASPECT-LIST.DIALOG.RESET' | translate }}
        </button>

        <button mat-button (click)="aspectList.clear()" id="aspect-list-dialog-actions-clear"
            data-automation-id="aspect-list-dialog-actions-clear">{{
            'ADF-ASPECT-LIST.DIALOG.CLEAR' | translate }}
        </button>
    </div>
    <div>
        <button mat-button adf-auto-focus (click)="onCancel()" id="aspect-list-dialog-actions-cancel"
            data-automation-id="aspect-list-dialog-actions-cancel">{{
            'ADF-ASPECT-LIST.DIALOG.CANCEL' | translate }}
        </button>

        <button mat-button (click)="onApply()" id="aspect-list-dialog-actions-apply"
            [disabled]="aspectList.hasEqualAspect"
            data-automation-id="aspect-list-dialog-actions-apply">{{
            'ADF-ASPECT-LIST.DIALOG.APPLY' | translate }}
        </button>
    </div>
</mat-dialog-actions>
