import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { GroupService, Constants, debounce } from '@alfresco/aca-shared';
import { MatDialog, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { AppStore, SnackbarErrorAction, SnackbarInfoAction, getUserList } from '@alfresco/aca-shared/store';
import { DataCellEvent, DataColumn, DataRowActionEvent, DataTableModule, TranslationService } from '@alfresco/adf-core';
import { Store } from '@ngrx/store';
import { Person } from '@alfresco/js-api';
import { ConfirmDialogComponent } from '@alfresco/adf-content-services';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    DataTableModule,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-group-members-dialog',
  templateUrl: './group-members-dialog.component.html',
  styleUrls: ['./group-members-dialog.component.scss']
})
export class GroupMembersDialogComponent implements OnInit {
  mode = 0;

  groupName: string;
  groupId: string;

  groupUsersSchema: DataColumn[];
  groupUsersRows: object[];

  groupAddUsersSchema: DataColumn[];
  groupAddUsersRows: object[];
  groupAddUsersRowsAll: object[];

  allUsers: Person[];
  allGroupUsers: object[];

  loading: Boolean = false;
  hasMadeUserChange: Boolean = false;

  filterGroupAddUsers: string = '';
  filterPlaceholder: string;
  filterDebounce: Function;

  constructor(
    private dialog: MatDialog,
    private store: Store<AppStore>,
    private translation: TranslationService,
    private groupService: GroupService,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    this.groupName = data.groupName;
    this.groupId = data.groupId;

    this.groupUsersSchema = [
      {
        type: 'text',
        key: 'id',
        title: this.translation.instant('APP.SETTINGS.USERNAME'),
        sortable: true
      },
      {
        type: 'text',
        key: 'displayName',
        title: this.translation.instant('APP.SETTINGS.FULL_NAME'),
        cssClass: 'full-width',
        sortable: true
      }
    ];

    this.groupAddUsersSchema = [
      {
        type: 'text',
        key: 'id',
        title: this.translation.instant('APP.SETTINGS.USERNAME'),
        sortable: true
      },
      {
        type: 'text',
        key: 'displayName',
        title: this.translation.instant('APP.SETTINGS.FULL_NAME'),
        cssClass: 'full-width',
        sortable: true
      }
    ];

    this.filterPlaceholder = this.translation.instant('APP.SETTINGS.FILTER');
  }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers(utilizeTimeout = false) {
    const proc = () => {
      this.store.select(getUserList).subscribe((userList) => {
        this.allUsers = userList;
        this.groupService
            .listUsersInGroup(this.groupId)
            .then((groupUsers) => {
              this.allGroupUsers = groupUsers;
              const users = groupUsers.map((entity) => {
                const result = { ...entity };
                if (result.id.indexOf('GROUP_') !== 0) {
                  const userEntity = userList.find((user) => user.id === entity.id);
                  if (userEntity) result.displayName = userEntity.displayName;
                }
                return result;
              });
              this.groupUsersRows = users;
              this.applyGroupAddUsersFilter(true);
              this.loading = false;
            })
            .catch((_) => {
              this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
              this.loading = false;
            });
      });
    };

    this.loading = true;

    if (!utilizeTimeout) {
      proc();
    } else {
      setTimeout(proc, Constants.GROUP_MEMBERSHIP_UPDATE_TIMEOUT_VALUE);
    }
  }

  onGroupShowRowActionsMenu(event: DataCellEvent) {
    const removeAction = {
      id: 0,
      title: this.translation.instant('APP.ACTIONS.REMOVE')
    };
    event.value.actions = [removeAction];
  }

  onGroupExecuteRowAction = (event: DataRowActionEvent) => {
    const args = event.value;
    if (args.action.id === 0) {
      this.onRemoveUser(args.row.obj);
    }
  };

  onRemoveUser(obj) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'APP.SETTINGS.REMOVE_USER_GROUP_TITLE',
        message: this.translation.instant('APP.SETTINGS.REMOVE_USER_GROUP_MESSAGE').replace('{0}', obj.displayName).replace('{1}', this.groupName),
        yesLabel: 'APP.DIALOGS.CONFIRM_REMOVE.YES_LABEL',
        noLabel: 'APP.DIALOGS.CONFIRM_REMOVE.NO_LABEL'
      },
      minWidth: '250px'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.groupService
          .removeUserFromGroup(this.groupId, obj.id)
          .then(() => {
            this.store.dispatch(
              new SnackbarInfoAction(this.translation.instant('APP.SETTINGS.USER_REMOVED_MESSAGE_GROUP').replace('{0}', obj.displayName))
            );
            this.getUsers(true);
          })
          .catch((_) => {
            this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
          });
      }
    });
  }

  onGroupAddShowRowActionsMenu(event: DataCellEvent) {
    const addUserAction = {
      id: 0,
      title: this.translation.instant('APP.SETTINGS.ADD_USER')
    };
    event.value.actions = [addUserAction];
  }

  onGroupAddExecuteRowAction = (event: DataRowActionEvent) => {
    const args = event.value;
    if (args.action.id === 0) {
      this.executeAddUser(args.row.obj.id, args.row.obj.displayName);
    }
  };

  onRefreshList(): void {
    this.getUsers();
  }

  onAddUser(): void {
    const userIdsInGroup = this.allGroupUsers.map((u) => u['id']);
    this.groupAddUsersRowsAll = this.allUsers.filter((u) => userIdsInGroup.indexOf(u.id) === -1);
    this.groupAddUsersRows = [...this.groupAddUsersRowsAll];
    this.filterGroupAddUsers = '';
    this.mode = 1;
  }

  onViewMembers(): void {
    if (this.hasMadeUserChange) {
      this.getUsers(true);
      this.hasMadeUserChange = false;
    }
    this.mode = 0;
  }

  executeAddUser(username: string, displayName: string) {
    this.hasMadeUserChange = true;
    this.groupService
      .addUserToGroup(this.groupId, username)
      .then(() => {
        this.groupAddUsersRows = this.groupAddUsersRows.filter((u) => u['id'] !== username);
        this.store.dispatch(
          new SnackbarInfoAction(
            this.translation.instant('APP.SETTINGS.USER_ADDED_MESSAGE_GROUP').replace('{0}', displayName).replace('{1}', this.groupName)
          )
        );
      })
      .catch((_) => {
        this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      });
  }

  applyGroupAddUsersFilter(skipTimeout = false) {
    if (!this.filterDebounce) this.filterDebounce = debounce(Constants.CONTINUED_TYPING_THRESHOLD_VALUE);

    const proc = () => {
      if (!this.filterGroupAddUsers) this.groupAddUsersRows = this.groupAddUsersRowsAll;
      else
        this.groupAddUsersRows = this.groupAddUsersRowsAll.filter(
          (u) =>
            u['id'].toLowerCase().indexOf(this.filterGroupAddUsers.toLowerCase()) !== -1 ||
            u['displayName'].toLowerCase().indexOf(this.filterGroupAddUsers.toLowerCase()) !== -1
        );
    };

    if (!skipTimeout) {
      this.filterDebounce(proc);
    } else {
      proc();
    }
  }

  matDialogContentStyle(): string {
    let style = 'max-height: unset; overflow-y: auto !important; ';
    if (this.loading) style += 'height: 75px;';
    else if ((!this.groupUsersRows || this.groupUsersRows.length === 0) && this.mode === 0) style += 'height: 75px';
    else style += 'height: 500px;';
    return style;
  }
}
