<h2 mat-dialog-title>
  {{ 'APP.SETTINGS.GROUP_USERS' | translate }} {{ groupName }}
</h2>

<mat-dialog-content [style]="matDialogContentStyle()">
  <div *ngIf="mode === 0" class="mode0-container">
    <mat-spinner *ngIf="loading" [diameter]="60" style="margin: 0 auto;"></mat-spinner>
    <span class="no-users" *ngIf="!loading && (!groupUsersRows || groupUsersRows.length === 0)">{{ 'APP.SETTINGS.NO_USERS' | translate }}</span>
    <div *ngIf="!loading && groupUsersRows && groupUsersRows.length > 0" class="datatable-container">
      <adf-datatable
        [rows]="groupUsersRows" 
        [columns]="groupUsersSchema"
        [actions]="true"
        [stickyHeader]="false"
        (showRowActionsMenu)="onGroupShowRowActionsMenu($event)"
        (executeRowAction)="onGroupExecuteRowAction($event)"> 
      </adf-datatable>
    </div>
    <div *ngIf="!loading" class="intra-dialog-buttons-left">
      <button mat-button (click)="onRefreshList()">
        <mat-icon class="mat-icon-custom-color">refresh</mat-icon>
      </button>
    </div>
    <div *ngIf="!loading" class="intra-dialog-buttons">
      <button mat-button (click)="onAddUser()">
        {{ 'APP.SETTINGS.ADD_USER' | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="mode === 1" class="mode1-container">
    <span *ngIf="!groupAddUsersRowsAll || groupAddUsersRowsAll.length === 0">{{ 'APP.SETTINGS.NO_USERS_LEFT_TO_ADD' | translate }}</span>
    <mat-form-field>
      <input
        matInput
        (keyup)="applyGroupAddUsersFilter()"
        [(ngModel)]="filterGroupAddUsers"
        [placeholder]="filterPlaceholder" />
    </mat-form-field>
    <div *ngIf="groupAddUsersRows && groupAddUsersRows.length > 0" class="datatable-container">
      <adf-datatable
        [rows]="groupAddUsersRows" 
        [columns]="groupAddUsersSchema"
        [actions]="true"
        [stickyHeader]="false"
        (showRowActionsMenu)="onGroupAddShowRowActionsMenu($event)"
        (executeRowAction)="onGroupAddExecuteRowAction($event)"> 
      </adf-datatable>
    </div>
    <div class="intra-dialog-buttons">
      <button mat-button (click)="onViewMembers()">
        {{ 'APP.ACTIONS.VIEW_MEMBERS' | translate }}
      </button>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
  <span class="adf-fill-remaining-space"></span>
  <button
      mat-button
      id="adf-folder-cancel-button"
      mat-dialog-close>
      {{ 'APP.ACTIONS.CLOSE' | translate }}
  </button>
</mat-dialog-actions>
