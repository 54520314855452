/*!
 * @license
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { FileModel, FileUploadStatus } from '../models/file.model';

export class FileUploadEvent {

    constructor(
        public readonly file: FileModel,
        public readonly status: FileUploadStatus = FileUploadStatus.Pending,
        public readonly error: any = null) {
    }

}

export class FileUploadCompleteEvent extends FileUploadEvent {

    constructor(file: FileModel, public totalComplete: number = 0, public data?: any, public totalAborted: number = 0) {
        super(file, FileUploadStatus.Complete);
    }

}

export class FileUploadDeleteEvent extends FileUploadEvent {

    constructor(file: FileModel, public totalComplete: number = 0) {
        super(file, FileUploadStatus.Deleted);
    }

}

export class FileUploadErrorEvent extends FileUploadEvent {

    constructor(file: FileModel, public error: any, public totalError: number = 0) {
        super(file, FileUploadStatus.Error);
    }

}
