<div class="adf-categories-management">
    <p *ngIf="!categories.length && !categoryNameControlVisible"
       class="adf-no-categories-message">
        {{ noCategoriesMsg | translate }}
    </p>
    <div class="adf-categories-list"
         [class.adf-categories-list-fixed]="!categoryNameControlVisible">
        <span
            *ngFor="let category of categories"
            [class.adf-categories-padded]="!isCRUDMode"
            class="adf-assigned-categories">
            {{ category.name }}
            <button
                data-automation-id="categories-remove-category-button"
                mat-icon-button
                [class.adf-btn-padded]="!isCRUDMode"
                (click)="removeCategory(category)"
                [attr.title]="removeCategoryTitle | translate"
                [disabled]="disableRemoval">
                <mat-icon>remove</mat-icon>
            </button>
        </span>
    </div>
    <div *ngIf="((!categoryNameControlVisible && categories.length)) || categoryNameControlVisible"
        [hidden]="!categoryNameControlVisible"
        class="adf-category-name-field">
        <mat-form-field>
            <mat-icon matPrefix>search</mat-icon>
            <mat-label id="adf-category-name-input-label">
                {{ 'CATEGORIES_MANAGEMENT.NAME' | translate }}
            </mat-label>
            <input
                #categoryNameInput
                matInput
                autocomplete="off"
                [formControl]="categoryNameControl"
                (keyup.enter)="addCategory()"
                aria-labelledby="adf-category-name-input-label"
                adf-auto-focus
            />
            <mat-error [hidden]="!categoryNameControl.invalid">{{ categoryNameErrorMessageKey | translate }}</mat-error>
        </mat-form-field>
        <button
            mat-icon-button
            [class.adf-btn-padded]="!isCRUDMode"
            (click)="hideNameInput()"
            [attr.title]="'CATEGORIES_MANAGEMENT.HIDE_INPUT' | translate">
            <mat-icon>remove</mat-icon>
        </button>
    </div>
</div>
<div class="adf-existing-categories-panel" *ngIf="existingCategoriesPanelVisible">
    <ng-container *ngIf="isCRUDMode && (!existingCategoriesLoading || existingCategories)">
        <span class="adf-create-category-label"
            (click)="addCategory()"
            [hidden]="categoryNameControl.invalid || typing">
            {{ 'CATEGORIES_MANAGEMENT.GENERIC_CREATE' | translate : { name: categoryNameControl.value } }}
        </span>
    </ng-container>
    <div *ngIf="categoryNameControlVisible" class="adf-categories-list">
        <ng-container *ngIf="!existingCategoriesLoading && existingCategories">
            <p class="adf-existing-categories-label">
                {{ existingCategoriesMsg | translate }}
            </p>
            <mat-selection-list
                [disabled]="isCRUDMode"
                (selectionChange)="addCategoryToAssign($event)">
                <mat-list-option
                    *ngFor="let category of existingCategories"
                    class="adf-category"
                    [value]="category">
                    {{ category.name }}
                </mat-list-option>
                <p *ngIf="!existingCategories?.length && !existingCategoriesLoading">
                    {{ 'CATEGORIES_MANAGEMENT.NO_EXISTING_CATEGORIES' | translate }}
                </p>
            </mat-selection-list>
        </ng-container>
        <mat-spinner
            *ngIf="existingCategoriesLoading"
            [diameter]="50"
            [attr.aria-label]="'CATEGORIES_MANAGEMENT.LOADING' | translate">
        </mat-spinner>
    </div>
</div>
