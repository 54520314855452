import { Component, OnInit, ViewEncapsulation, } from '@angular/core';
import { TranslationService } from '@alfresco/adf-core';
import { debounce, Constants as ConstantsShared, WorkflowService, ListWorkflowSort, SortDir, WorkflowListFilter, WorkflowListFilterDescription, WorkflowListFilterPriority, WorkflowListFilterDateStarted, WorkflowPriority, PageLayoutComponent, PageLayoutHeaderComponent, PageLayoutContentComponent } from '@alfresco/aca-shared';
import { Constants } from '../constants'
import { Utils } from '../utils'
import { AppStore, getUserProfile, SnackbarErrorAction, SnackbarInfoAction } from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import moment from 'moment';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule, ConfirmDialogComponent } from '@alfresco/adf-content-services';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    PageLayoutComponent,
    PageLayoutHeaderComponent,
    PageLayoutContentComponent,
    BreadcrumbModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-my-workflows',
  templateUrl: './my-workflows.component.html',
  styleUrls: ['./my-workflows.component.scss']
})
export class MyWorkflowsComponent implements OnInit {

  workflowActive: string = 'active';
  currentSort: string = 'date';
  sortDateAsc: boolean = false;
  sortPriorityAsc: boolean = true;
  showFilters: boolean = false;
  priorityOptions: any[] = [];
  today = moment();

  filterForm = new FormGroup({
    description: new FormControl(''),
    priority: new FormControl('all'),
    dateStartedFrom: new FormControl(undefined),
    dateStartedTo: new FormControl(undefined),
  });
  dataRequestDebounce: Function;
  userName: string;
  haveFilterChange: boolean = false;
  isLoading: boolean = false;

  currentPage: number = 1;
  selectedPage: number = 1;
  numberOfPages: number = 1;

  workflows: any[] = [];

  constructor(
    private store: Store<AppStore>,
    private translation: TranslationService,
    private workflowService: WorkflowService,
    private dialogRef: MatDialog,
    private router: Router,
  ) {
    this.priorityOptions = Utils.getPriorityOptions(this.translation);
  }

  ngOnInit() {
    this.isLoading = true;

    this.store.select(getUserProfile).subscribe((user) => {
      this.userName = user.id;
      this.onFormChange(null);
    });
  }

  onFilterFormChange(event) {
    this.haveFilterChange = true;
    this.onFormChange(event);
  }

  loadData = () => {
    if (!this.userName)
      return;

    this.isLoading = this.workflows.length === 0;

    const filters: WorkflowListFilter[] = [];
    if (this.showFilters) {
      const description = this.filterForm.get('description').value;
      if (description && description.length >= 3) {
        const filter = new WorkflowListFilterDescription(description);
        filters.push(filter);
      }
      
      const priority = this.filterForm.get('priority').value;
      if (priority && priority !== 'all') {
        const enumVal = priority as WorkflowPriority;
        const filter = new WorkflowListFilterPriority(enumVal);
        filters.push(filter);
      }

      const startedFrom = this.filterForm.get('dateStartedFrom').value,
            startedTo = this.filterForm.get('dateStartedTo').value;
      if (startedFrom || startedTo) {
        const startedFromValue = startedFrom ? startedFrom : null;
        const startedToValue = startedTo ? startedTo : null;
        const filter = new WorkflowListFilterDateStarted(startedFromValue, startedToValue);
        filters.push(filter);
      }
    }

    let sort: ListWorkflowSort,
        sortDir: SortDir;
    if (this.currentSort === 'date') {
      sort = ListWorkflowSort.DATE;
      sortDir = this.sortDateAsc ? SortDir.ASC : SortDir.DESC;
    } else if (this.currentSort === 'priority') {
      sort = ListWorkflowSort.PRIORITY;
      sortDir = this.sortPriorityAsc ? SortDir.ASC : SortDir.DESC;
    }

    this.workflowService.listWorkflows(this.userName, this.workflowActive === 'active', filters, sort, sortDir, this.selectedPage - 1, Constants.LIST_PAGE_ITEM_COUNT)
      .then((data) => {
        const workflows = data.data;
        const paging = data.paging;
        if (!workflows || !paging || (paging.totalItems === undefined || paging.totalItems === null)) {
          this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
          return;
        }

        this.workflows = workflows;
        this.numberOfPages = Math.floor(paging.totalItems / Constants.LIST_PAGE_ITEM_COUNT) + 1;
        if (paging.totalItems % Constants.LIST_PAGE_ITEM_COUNT === 0)
          this.numberOfPages--;

        this.isLoading = false;
      })
      .catch((err) => {
        console.log(err);
        this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      });
    this.currentPage = this.selectedPage;
  }

  onFormChange(_) {
    if (!this.dataRequestDebounce) this.dataRequestDebounce = debounce(ConstantsShared.CONTINUED_TYPING_THRESHOLD_VALUE);

    this.dataRequestDebounce(this.loadData);
  }

  onFormEnter(event) {
    event.preventDefault();
    this.onFormChange(event);
  }

  onSortByDate(event) {
    if (this.currentSort !== 'date') {
      this.currentSort = 'date';
    } else {
      this.sortDateAsc = !this.sortDateAsc;
    }
    this.onFormChange(event);
  }

  onSortByPriority(event) {
    if (this.currentSort !== 'priority') {
      this.currentSort = 'priority';
    } else {
      this.sortPriorityAsc = !this.sortPriorityAsc;
    }
    this.onFormChange(event);
  }

  onToggleShowFilters(event) {
    this.showFilters = !this.showFilters;
    if (this.haveFilterChange) {
      this.onFormChange(event);
    }
  }

  onResetFilters(_) {
    this.filterForm.reset();
    this.filterForm.get('priority').setValue('all');
    this.haveFilterChange = false;
    this.onFormChange(null);
  }

  getWorkflowIconURL(/*workflow*/_: object) {
    return 'assets/images/neocom/workflow.png';
  }

  getWorkflowSentValue(workflow: object) {
    const isoString = workflow['startDate'];
    const dueDate = Utils.dateFromISO8601(isoString);
    return Utils.formatDate(dueDate);
  }

  getWorkflowFromValue(workflow: object) {
    const initiator = workflow['initiator'];
    return `${initiator.firstName}${initiator.lastName ? (' ' + initiator.lastName) : ''}`;
  }

  getWorkflowPriorityValue(workflow: object) {
    return Utils.getPriorityTranslation(this.translation, workflow['priority']);
  }

  getWorkflowTypeValue(workflow: object) {
    return Utils.getWorkflowTypeName(workflow['name'], this.translation);
  }

  getWorkflowDueValue(workflow: object) {
    const dueDateIsoString = workflow['dueDate'];
    if (dueDateIsoString) {
      const dueDate = Utils.dateFromISO8601(dueDateIsoString);
      return Utils.formatDate(dueDate);
    } else {
      return '/';
    }
  }

  getWorkflowEndedValue(workflow: object) {
    const dueDateIsoString = workflow['endDate'];
    if (dueDateIsoString) {
      const dueDate = Utils.dateFromISO8601(dueDateIsoString);
      return Utils.formatDate(dueDate);
    } else {
      return '/';
    }
  }

  onClickWorkflow(workflowId: string) {
    // temporarily disabled, until the corresponding page is finished
    const workflowIdShort = workflowId.substring('activiti$'.length);
    const url = `/workflow/workflow-details/${workflowIdShort}`;
    this.router.navigateByUrl(url);
  }

  onRemoveWorkflow(workflowId: string) {
    this.dialogRef
      .open(ConfirmDialogComponent, {
        data: {
          title: 'APP.WORKFLOW.GENERAL.REMOVE_WORKFLOW',
          message: this.translation.instant('APP.WORKFLOW.GENERAL.REMOVE_WORKFLOW_PROMPT'),
          yesLabel: 'APP.DIALOGS.CONFIRM_DELETE.YES_LABEL',
          noLabel: 'APP.DIALOGS.CONFIRM_DELETE.NO_LABEL'
        },
        minWidth: '250px'
      })
      .afterClosed()
      .subscribe(async (result) => {
        if (result === true) {
          this.workflowService.cancelWorkflow(workflowId, this.workflowActive === 'complete')
            .then(() => {
              this.store.dispatch(new SnackbarInfoAction(this.translation.instant('APP.WORKFLOW.GENERAL.SUCCESSFULLY_REMOVED_WORKFLOW')));
              this.onFormChange(null);
            })
            .catch(_ => {
              this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
            });
        }
      });
  }

  onJumpToPage() {
    if (this.selectedPage >= 1 && this.selectedPage <= this.numberOfPages) {
      this.onFormChange(null);
    } else {
      this.selectedPage = this.currentPage;
    }
  }

  onNextPage() {
    if (this.currentPage < this.numberOfPages) {
      this.currentPage++;
      this.selectedPage = this.currentPage;
      this.onFormChange(null);
    }
  }

  onPreviousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.selectedPage = this.currentPage;
      this.onFormChange(null);
    }
  }

}
