import { Injectable } from '@angular/core';
import { SitesApi, Site, SiteBodyCreate, SiteBodyUpdate, SiteEntry, SiteMember, SiteMemberEntry, SiteMemberPaging, SitePaging } from '@alfresco/js-api';
import { CustomApiCallService, HTTPMethod, PayloadType } from './custom-api-call.service';
import { AlfrescoApiService } from '@alfresco/adf-core';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  private sitesApi: SitesApi;

  constructor(
    alfrescoApiService: AlfrescoApiService,
    private customApiCallService: CustomApiCallService,
  ) {
    this.sitesApi = new SitesApi(alfrescoApiService.getInstance());
  }

  listSites(): Promise<Site[]> {
    return new Promise((resolve, reject) => {
      this.sitesApi.listSites()
        .then((data: SitePaging) => {
          if (data && data.list && data.list.entries) {
            const results = data.list.entries.map(el => el.entry);
            resolve(results);
          } else {
            resolve([]);
          }
        }).catch(err => reject(err));
    });
  }

  getSiteByGuid(siteGuid: string): Promise<Site> {
    return new Promise((resolve, reject) => {
      this.listSites()
        .then((data: Site[]) => {
          const result = data.find(s => s.guid === siteGuid);
          resolve(result);
        }).catch(err => reject(err));
    });
  }

  createSite(siteId: string, title: string, description: string, visibility: SiteVisibility): Promise<Site> {
    const createObj: SiteBodyCreate = {
      id: siteId,
      title,
      description,
      visibility, 
    };
    return new Promise((resolve, reject) => {
      this.sitesApi.createSite(createObj)
        .then((data: SiteEntry) => {
          if (data && data.entry) {
            resolve(data.entry);
          } else {
            resolve(null);
          }
        }).catch(err => reject(err));
    });
  }

  updateSite(siteId: string, title: string = null, description: string = null, visibility: SiteVisibility = null): Promise<Site> {
    const updateObj: SiteBodyUpdate = {};
    if (title !== null && title !== undefined) {
      updateObj['title'] = title;
    }
    if (description !== null && description !== undefined) {
      updateObj['description'] = description;
    }
    if (visibility !== null && visibility !== undefined) {
      updateObj['visibility'] = visibility;
    }
    if (Object.keys(updateObj).length === 0) {
      throw new Error('no data provided to update');
    }
    return new Promise((resolve, reject) => {
      this.sitesApi.updateSite(siteId, updateObj)
        .then((data: SiteEntry) => {
          if (data && data.entry) {
            resolve(data.entry);
          } else {
            resolve(null);
          }
        }).catch(err => reject(err));
    });
  };

  deleteSite(siteId: string): Promise<any> {
    return this.sitesApi.deleteSite(siteId);
  }

  getSiteMembers(siteId: string): Promise<SiteMember[]> {
    return new Promise((resolve, reject) => {
      this.sitesApi.listSiteMemberships(siteId)
        .then((data: SiteMemberPaging) =>  {
          if (data && data.list && data.list.entries) {
            const results = data.list.entries.map(el => el.entry);
            resolve(results);
          } else {
            resolve([]);
          }
        }).catch(err => reject(err));
    });
  }

  addSiteMember(siteId: string, userId: string, role: SiteRoles): Promise<SiteMember> {
    return new Promise((resolve, reject) => {
      this.sitesApi.createSiteMembership(siteId, {
        id: userId,
        role,
      }).then((data: SiteMemberEntry) => {
        if (data && data.entry) {
          resolve(data.entry);
        } else {
          resolve(null);
        }
      }).catch(err => reject(err));
    });
  }

  updateSiteMember(siteId: string, userId: string, role: SiteRoles): Promise<SiteMember> {
    return new Promise((resolve, reject) => {
      this.sitesApi.updateSiteMembership(siteId, userId, {
        role,
      }).then((data: SiteMemberEntry) => {
        if (data && data.entry) {
          resolve(data.entry);
        } else {
          resolve(null);
        }
      }).catch(err => reject(err));
    });
  }

  removeSiteMember(siteId: string, userId: string): Promise<any> {
    return this.sitesApi.deleteSiteMembership(siteId, userId);
  }

  // TODO: these could be reworked to utilize the corresponding methods of the sitesApi
  getSiteGroupMembers(siteId: string): Promise<object[]> {
    return new Promise((resolve, reject) => {
      this.customApiCallService.callApi(`-default-/public/alfresco/versions/1/sites/${siteId}/group-members`, HTTPMethod.GET)
        .then(data => {
          if (data && data.list && data.list.entries) {
            const results = data.list.entries.map(el => ({
              ...el.entry,
              group: {
                ...el.entry.group,
                id: el.entry.group.id.substring(6), // removes GROUP_ prefix
              },
              id: el.entry.id.substring(6),
            }));
            resolve(results);
          } else {
            resolve([]);
          }
        }).catch(err => reject(err));
    });
  }

  addSiteGroupMember(siteId: string, groupId: string, role: SiteRoles): Promise<SiteMember> {
    return new Promise((resolve, reject) => {
      const payload = {
        id: `GROUP_${groupId}`,
        role,
      }
      this.customApiCallService.callApi(`-default-/public/alfresco/versions/1/sites/${siteId}/group-members`, HTTPMethod.POST, payload, PayloadType.JSON)
        .then(data => {
          if (data && data.entry) {
            resolve(data.entry);
          } else {
            resolve(null);
          }
        }).catch(err => reject(err));
    });
  }

  updateSiteGroupMember(siteId: string, groupId: string, role: SiteRoles): Promise<SiteMember> {
    return new Promise((resolve, reject) => {
      const payload = {
        role,
      }
      this.customApiCallService.callApi(`-default-/public/alfresco/versions/1/sites/${siteId}/group-members/GROUP_${groupId}`, HTTPMethod.PUT, payload, PayloadType.JSON)
        .then(data => {
          if (data && data.entry) {
            resolve(data.entry);
          } else {
            resolve(null);
          }
        }).catch(err => reject(err));
    });
  }

  removeSiteGroupMember(siteId: string, groupId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.customApiCallService.callApi(`-default-/public/alfresco/versions/1/sites/${siteId}/group-members/GROUP_${groupId}`, HTTPMethod.DELETE)
        .then(response => {
          resolve(response);
        }).catch(err => reject(err));
    });
  }

  static mapRoleToI18NString(role: SiteRoles): string {
    switch (role) {
      case SiteRoles.MANAGER:
        return 'APP.SETTINGS.SITE_ROLE_MANAGER';
      case SiteRoles.COLLABORATOR:
        return 'APP.SETTINGS.SITE_ROLE_COLLABORATOR';
      case SiteRoles.CONTRIBUTOR:
        return 'APP.SETTINGS.SITE_ROLE_CONTRIBUTOR';
      case SiteRoles.CONSUMER:
        return 'APP.SETTINGS.SITE_ROLE_CONSUMER';
      default:
        return null;
    }
  }
}

export enum SiteVisibility {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  MODERATED = 'MODERATED',
};

export enum SiteRoles {
  MANAGER = 'SiteManager',
  COLLABORATOR = 'SiteCollaborator',
  CONTRIBUTOR = 'SiteContributor',
  CONSUMER = 'SiteConsumer',
};