import { ArchiveAdminService, ArchiveService, PageLayoutComponent, PageLayoutContentComponent, PageLayoutHeaderComponent} from '@alfresco/aca-shared';
import { AppStore, SnackbarInfoAction, SnackbarErrorAction } from '@alfresco/aca-shared/store';
import { Component, OnInit, ViewChild, ViewEncapsulation, } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'; //, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DataCellEvent, DataColumn, DataRowActionEvent, DataTableModule } from '@alfresco/adf-core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from '@alfresco/adf-content-services';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    PageLayoutComponent,
    PageLayoutHeaderComponent,
    PageLayoutContentComponent,
    BreadcrumbModule,
    DataTableModule,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-create-registry-book',
  templateUrl: './create-registry-book.component.html',
  styleUrls: ['./create-registry-book.component.scss']
})
export class CreateRegistryBookComponent implements OnInit {

  registriesSchema: DataColumn[];
  cache: Map<string, any[]> = new Map();
  registries: any[] = [];
  archives: any[] = [];

  archivesForm = new FormGroup({
    selectedArchive: new FormControl(undefined, Validators.required),
    year: new FormControl(undefined, Validators.required),
    title: new FormControl('', Validators.required)
  });

  @ViewChild('formDirective')
  formDirective;

  constructor(
    private archiveAdminService: ArchiveAdminService,
    private archiveService: ArchiveService,
    private store: Store<AppStore>,
    private translation: TranslateService)
  {
    this.registriesSchema = [
      {
        type: 'text',
        key: 'name',
        title: this.translation.instant('APP.ARCHIVE_ADMIN.NAME'),
        sortable: true,
      },    
      {
        type: 'text',
        key: 'year',
        title: this.translation.instant('APP.ARCHIVE_ADMIN.YEAR'),
        sortable: true,
      },
      {
        type: 'text',
        key: 'isActive',
        title: this.translation.instant('APP.ARCHIVE_ADMIN.IS_ACTIVE'),
        sortable: true,
      },
    ];
  }

  async ngOnInit(): Promise<void> {
    await this.getArchives(); 
  }

  async getArchives() {
    this.archives = await this.archiveService.getArchives();
  }

  async createRegistryBook(): Promise<void> {
    if (this.archivesForm.invalid)
      return;

    const requestBody = { ...this.archivesForm.value };

    try {
      await this.archiveAdminService.createRegistryBook(
        requestBody.selectedArchive,
        requestBody.title,
        requestBody.year,
        true
      );

      if (this.formDirective && this.formDirective.resetForm) {
        this.formDirective.resetForm();
      }
      this.archivesForm.get('selectedArchive').setValue(requestBody.selectedArchive);
      this.getRegistryBooks(requestBody.selectedArchive);

      this.store.dispatch(
        new SnackbarInfoAction(this.translation.instant('APP.ARCHIVE_ADMIN.CREATE_REGISTRY_BOOK_SUCCESS')));
    } catch (error) {
      this.store.dispatch(
        new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }

  onChangeArchive(event) {
    this.getRegistryBooks(event.value);
  }

  async getRegistryBooks(id: string) {
    const registries = await this.archiveService.getRegistryBooks(id);
    // the following line fixes (works around) a problem with the datatable container
    this.registries = registries.map((el, index) => ({
      ...el,
      id: index,
    }));
  }

  onShowRowActionsMenu = (event: DataCellEvent) => {
    const activateAction = {
      id: 0,
      title: this.translation.instant('APP.ARCHIVE_ADMIN.ACTIVATE_REGISTRY_BOOK'),
    };
    const deactivateAction = {
      id: 1,
      title: this.translation.instant('APP.ARCHIVE_ADMIN.DEACTIVATE_REGISTRY_BOOK'),
    };
    if (event.value.row.obj.isActive === 'true') {
      event.value.actions = [ deactivateAction ];
    } else {
      event.value.actions = [ activateAction ];
    }
  }

  async onExecuteRowAction(event: DataRowActionEvent) {
    let args = event.value;
    try {
      await this.archiveAdminService.changeRegistryStatus(args.row.obj.nodeRef);
      this.store.dispatch(
            new SnackbarInfoAction(
                  this.translation.instant('APP.ARCHIVE_ADMIN.UPDATE_REGISTRY_STATUS_SUCCESS')));
      const archive = this.archivesForm.get('selectedArchive').value;
      this.registries = []; // workaround for a problem with the datatable container
      this.getRegistryBooks(archive);
    } catch (error) {
      this.store.dispatch(
            new SnackbarErrorAction(
                  this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }
}
