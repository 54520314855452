<div id="site-dropdown-container" class="adf-site-dropdown-container">
    <mat-form-field>
        <mat-label>{{ 'NODE_SELECTOR.LOCATION' | translate }}</mat-label>
        <mat-select
            adf-infinite-select-scroll
            (scrollEnd)="loadAllOnScroll()"
            #siteSelect
            data-automation-id="site-my-files-option"
            class="adf-site-dropdown-list-element"
            id="site-dropdown"
            placeholder="{{placeholder | translate}}"
            floatPlaceholder="never"
            [(value)]="selected"
            (selectionChange)="selectedSite($event)">
            <mat-option *ngFor="let site of siteList?.list.entries;" [value]="site">
                {{ site.entry.title | translate}}
            </mat-option>
            <mat-option *ngIf="showLoading()" disabled="true" data-automation-id="site-loading">
                {{ 'ADF_DROPDOWN.LOADING' | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
