import { ArchiveService, PageLayoutComponent, PageLayoutContentComponent, PageLayoutHeaderComponent } from '@alfresco/aca-shared';
import { AppStore, SnackbarInfoAction } from '@alfresco/aca-shared/store';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BreadcrumbModule } from '@alfresco/adf-content-services';
import { DataTableModule } from '@alfresco/adf-core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatButtonModule,
    PageLayoutComponent,
    PageLayoutHeaderComponent,
    PageLayoutContentComponent,
    BreadcrumbModule,
    DataTableModule,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-inventory-of-act',
  templateUrl: './inventory-of-act.component.html',
  styleUrls: ['./inventory-of-act.component.scss']
})
export class InventoryOfActComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject<boolean>();

  cache: Map<string, any[]> = new Map();

  registries: any[] = [];
  organizationalUnits: any[] = [];
  archiveSigns: any[] = [];
  inventoriesOfActs: any[] = [];

  inventoryOfActsForm = new FormGroup({
    selectedRegistry: new FormControl(undefined, Validators.required),
    selectedOrganizationalUnit: new FormControl(undefined, Validators.required),
    selectedArchiveSign: new FormControl(undefined, Validators.required),
    subjectIndex: new FormControl(undefined, [Validators.min(1), this.validateIndex()]),
    title: new FormControl('')
  });

  actsSchema = [
    {
      type: 'text',
      key: 'index',
      title: this.translation.instant('APP.ARCHIVE.INDEX'),
      sortable: true
    },
    {
      type: 'text',
      key: 'title',
      title: this.translation.instant('APP.ARCHIVE.TITLE'),
      sortable: true
    },
    {
      type: 'text',
      key: 'months',
      title: this.translation.instant('APP.ARCHIVE.MONTHS_TO_KEEP'),
      sortable: true
    },
    {
      type: 'text',
      key: 'orgUnit',
      title: this.translation.instant('APP.ARCHIVE.ORGANIZATIONAL_UNIT'),
      sortable: true
    },
    {
      type: 'text',
      key: 'sign',
      title: this.translation.instant('APP.ARCHIVE.ARCHIVE_SIGN'),
      sortable: true
    }
  ];

  constructor(private archiveService: ArchiveService, private store: Store<AppStore>, private translation: TranslateService) {}

  get selectedRegistry() {
    return this.inventoryOfActsForm.get('selectedRegistry');
  }

  get selectedOrganizationalUnit() {
    return this.inventoryOfActsForm.get('selectedOrganizationalUnit');
  }

  get selectedArchiveSign() {
    return this.inventoryOfActsForm.get('selectedArchiveSign');
  }

  get subjectIndex() {
    return this.inventoryOfActsForm.get('subjectIndex');
  }

  get selectedRegistryCounter(): number {
    const selectedRegistry = this.registries.find((registry) => registry['nodeRef'] === this.selectedRegistry.value);

    return (parseInt(selectedRegistry?.['counter']) || 0) + 1;
  }

  async ngOnInit(): Promise<void> {
    await this.getRegistryBooks();

    this.selectedRegistry.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => value && this.updateOrganizationalUnits());

    this.selectedOrganizationalUnit.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => value && this.updateArchiveSigns());
  }

  async createInventoryOfActs(): Promise<void> {
    if (this.inventoryOfActsForm.invalid) return;

    const requestBody = { ...this.inventoryOfActsForm.value };

    try {
      await this.archiveService.createInventoryOfActs(
        requestBody.selectedRegistry,
        requestBody.selectedOrganizationalUnit,
        requestBody.selectedArchiveSign,
        requestBody.subjectIndex,
        requestBody.title
      );

      this.inventoryOfActsForm.reset();
      this.inventoriesOfActs = [];
      this.getRegistryBooks();

      this.store.dispatch(new SnackbarInfoAction(this.translation.instant('APP.ARCHIVE.SUCCESS.NEW_INVENTORY_OF_ACTS')));
    } catch (error) {
      this.store.dispatch(new SnackbarInfoAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }

  private async updateOrganizationalUnits(): Promise<void> {
    this.subjectIndex.setValue(undefined, { emitEvent: false });
    this.selectedArchiveSign.setValue(undefined, { emitEvent: false });
    this.selectedOrganizationalUnit.setValue(undefined, { emitEvent: false });

    const nodeRef = this.selectedRegistry.value;
    this.getInventoryOfActs(nodeRef);

    if (this.cache.has(nodeRef)) {
      this.organizationalUnits = this.cache.get(nodeRef);
    } else {
      this.organizationalUnits = await this.archiveService.getOrganizationalUnits(nodeRef);
      this.cache.set(nodeRef, this.organizationalUnits);
    }
  }

  private async getRegistryBooks() {
    this.registries = await this.archiveService.getRegistryBooks();
  }

  private async getInventoryOfActs(nodeRef: string) {
    this.inventoriesOfActs = await this.archiveService.getInventoryOfActs(nodeRef);
  }

  private async updateArchiveSigns(): Promise<void> {
    this.selectedArchiveSign.setValue(undefined, { emitEvent: false });

    const nodeRef = this.selectedOrganizationalUnit.value;

    if (this.cache.has(nodeRef)) {
      this.archiveSigns = this.cache.get(nodeRef);
    } else {
      this.archiveSigns = await this.archiveService.getArchiveSigns(nodeRef);
      this.cache.set(nodeRef, this.archiveSigns);
    }
  }

  private validateIndex(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      const selectedRegistry = this.registries.find((registry) => registry['nodeRef'] === this.selectedRegistry.value);

      if (!selectedRegistry) return null;

      if (control.value < this.selectedRegistryCounter) return { lowerValue: { value: control.value } };

      return selectedRegistry['invActs'].split(',').includes(control.value?.toString()) ? { invalidIndex: { value: control.value } } : null;
    };
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
