<div id="adf-image-container" (keydown)="onKeyDown($event)" class="adf-image-container" tabindex="0" role="img" [attr.aria-label]="fileName" data-automation-id="adf-image-container">
    <img #image id="viewer-image" [src]="urlFile" [alt]="fileName" (error)="onImageError()" />
</div>

<div class="adf-image-viewer__toolbar" *ngIf="showToolbar">
    <adf-toolbar class="adf-main-toolbar">
        <button
            id="viewer-zoom-out-button"
            title="{{ 'ADF_VIEWER.ARIA.ZOOM_OUT' | translate }}"
            attr.aria-label="{{ 'ADF_VIEWER.ARIA.ZOOM_OUT' | translate }}"
            mat-icon-button
            (click)="zoomOut()">
            <mat-icon>zoom_out</mat-icon>
        </button>

        <div class="adf-image-viewer__toolbar-page-scale" data-automation-id="adf-page-scale">
            {{ currentScaleText }}
        </div>

        <button
            id="viewer-zoom-in-button"
            mat-icon-button
            title="{{ 'ADF_VIEWER.ARIA.ZOOM_IN' | translate }}"
            attr.aria-label="{{ 'ADF_VIEWER.ARIA.ZOOM_IN' | translate }}"
            (click)="zoomIn()">
            <mat-icon>zoom_in</mat-icon>
        </button>

        <button
            *ngIf="!readOnly" id="viewer-rotate-button"
            title="{{ 'ADF_VIEWER.ARIA.ROTATE' | translate }}"
            attr.aria-label="{{ 'ADF_VIEWER.ARIA.ROTATE' | translate }}"
            mat-icon-button
            (click)="rotateImage()">
            <mat-icon>rotate_left</mat-icon>
        </button>
        <button
            *ngIf="!readOnly" id="viewer-crop-button"
            title="{{ 'ADF_VIEWER.ARIA.CROP' | translate }}"
            attr.aria-label="{{ 'ADF_VIEWER.ARIA.CROP' | translate }}"
            mat-icon-button
            (click)="cropImage()">
            <mat-icon>crop</mat-icon>
        </button>

        <button
            id="viewer-reset-button"
            title="{{ 'ADF_VIEWER.ARIA.RESET' | translate }}"
            attr.aria-label="{{ 'ADF_VIEWER.ARIA.RESET' | translate }}"
            mat-icon-button
            (click)="reset()">
            <mat-icon>zoom_out_map</mat-icon>
        </button>

    </adf-toolbar>

    <adf-toolbar class="adf-secondary-toolbar" *ngIf="!readOnly && isEditing">
        <button
            id="viewer-cancel-button"
            title="{{ 'ADF_VIEWER.ARIA.CANCEL' | translate }}"
            attr.aria-label="{{ 'ADF_VIEWER.ARIA.CANCEL' | translate }}"
            mat-icon-button
            (click)="reset()">
            <mat-icon>close</mat-icon>
        </button>

        <button
            id="viewer-save-button"
            title="{{ 'ADF_VIEWER.ARIA.SAVE' | translate }}"
            attr.aria-label="{{ 'ADF_VIEWER.ARIA.SAVE' | translate }}"
            mat-icon-button
            (click)="save()">
            <mat-icon>check</mat-icon>
        </button>

    </adf-toolbar>
</div>
