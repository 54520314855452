<div *ngIf="isLoading"
     class="adf-viewer-render-main">
    <div class="adf-viewer-render-layout-content adf-viewer__fullscreen-container">
        <div class="adf-viewer-render-content-container">
            <ng-container *ngIf="isLoading">
                <div class="adf-viewer-render__loading-screen">
                    <h2>{{ 'ADF_VIEWER.LOADING' | translate }}</h2>
                    <div>
                        <mat-spinner></mat-spinner>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
</div>

<div *ngIf="!isLoading"
     class="adf-viewer-render-main">
    <div class="adf-viewer-render-layout-content adf-viewer__fullscreen-container">
        <div class="adf-viewer-render-content-container" [ngSwitch]="viewerType">
            <ng-container *ngSwitchCase="'external'">
                <adf-preview-extension
                    *ngIf="!!externalViewer"
                    [id]="externalViewer.component"
                    [url]="urlFile"
                    [extension]="externalViewer.fileExtension"
                    [attr.data-automation-id]="externalViewer.component">
                </adf-preview-extension>
            </ng-container>

            <ng-container *ngSwitchCase="'pdf'">
                <adf-pdf-viewer [thumbnailsTemplate]="thumbnailsTemplate"
                                [allowThumbnails]="allowThumbnails"
                                [blobFile]="blobFile"
                                [urlFile]="urlFile"
                                [fileName]="internalFileName"
                                [cacheType]="cacheTypeForContent"
                                (close)="onClose()"
                                (error)="onUnsupportedFile()">
                </adf-pdf-viewer>
            </ng-container>

            <ng-container *ngSwitchCase="'image'">
                <adf-img-viewer [urlFile]="urlFile"
                                [readOnly]="readOnly"
                                [fileName]="internalFileName"
                                [blobFile]="blobFile"
                                (error)="onUnsupportedFile()"
                                (submit)="onSubmitFile($event)"
                                (isSaving)="isSaving.emit($event)"
                ></adf-img-viewer>
            </ng-container>

            <ng-container *ngSwitchCase="'media'">
                <adf-media-player id="adf-mdedia-player"
                                  [urlFile]="urlFile"
                                  [tracks]="tracks"
                                  [mimeType]="mimeType"
                                  [blobFile]="blobFile"
                                  [fileName]="internalFileName"
                                  (error)="onUnsupportedFile()">
                </adf-media-player>
            </ng-container>

            <ng-container *ngSwitchCase="'text'">
                <adf-txt-viewer [urlFile]="urlFile"
                                [blobFile]="blobFile">
                </adf-txt-viewer>
            </ng-container>

            <ng-container *ngSwitchCase="'custom'">
                <ng-container *ngFor="let ext of viewerExtensions">
                    <adf-preview-extension *ngIf="checkExtensions(ext.fileExtension)"
                                           [id]="ext.component"
                                           [url]="urlFile"
                                           [extension]="extension"
                                           [attr.data-automation-id]="ext.component">
                    </adf-preview-extension>
                </ng-container>

                <span class="adf-viewer-render-custom-content"
                      *ngFor="let extensionTemplate of extensionTemplates">
                                <ng-template *ngIf="extensionTemplate.isVisible"
                                             [ngTemplateOutlet]="extensionTemplate.template"
                                             [ngTemplateOutletContext]="{ urlFile: urlFile, extension:extension }">
                                </ng-template>
                            </span>
            </ng-container>

            <ng-container *ngSwitchDefault>
                <adf-viewer-unknown-format></adf-viewer-unknown-format>
            </ng-container>
        </div>
    </div>
</div>
