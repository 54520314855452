<div
  *ngIf="inheritedRuleSetGroupingItems.length > 0"
  class="aca-rule-list__item"
  data-automation-id="rule-list-item"
  [ngClass]="{ expanded: inheritedRuleSetsExpanded }">

  <div
    tabindex="0"
    class="aca-rule-list__item__header"
    matRipple matRippleColor="hsla(0,0%,0%,0.05)"
    (click)="inheritedRuleSetsExpanded = !inheritedRuleSetsExpanded"
    (keyup.enter)="inheritedRuleSetsExpanded = !inheritedRuleSetsExpanded">

    <span class="aca-rule-list__item__header__title">
      {{ 'ACA_FOLDER_RULES.RULE_LIST.INHERITED_RULES' | translate }}
      <mat-icon [matTooltip]="'ACA_FOLDER_RULES.RULE_LIST.INHERITED_RULES_WILL_BE_RUN_FIRST' | translate">
        info
      </mat-icon>
    </span>
    <mat-icon class="aca-rule-list__item__header__icon">
      {{ inheritedRuleSetsExpanded ? 'expand_more' : 'chevron_right' }}
    </mat-icon>
  </div>

  <aca-rule-list-grouping
    *ngIf="inheritedRuleSetsExpanded"
    [items]="inheritedRuleSetGroupingItems"
    [selectedRule]="selectedRule"
    (selectRule)="onSelectRule($event)"
    (ruleEnabledChanged)="onRuleEnabledChanged($event)"
    (loadMoreRules)="onLoadMoreRules($event)"
    (loadMoreRuleSets)="onLoadMoreRuleSets()">
  </aca-rule-list-grouping>

</div>

<div
  *ngIf="mainRuleSetGroupingItems.length > 0 || isMainRuleSetLinked"
  class="aca-rule-list__item"
  data-automation-id="rule-list-item"
  [ngClass]="{ expanded: mainRuleSetExpanded }">

  <div
    tabindex="0"
    class="aca-rule-list__item__header"
    matRipple matRippleColor="hsla(0,0%,0%,0.05)"
    (click)="mainRuleSetExpanded = !mainRuleSetExpanded"
    (keyup.enter)="mainRuleSetExpanded = !mainRuleSetExpanded">

    <span class="aca-rule-list__item__header__title" data-automation-id="main-rule-set-title">
      <ng-container *ngIf="isMainRuleSetOwned; else linkedRuleSet">
        {{ 'ACA_FOLDER_RULES.RULE_LIST.OWNED_RULES' | translate }}
      </ng-container>
      <ng-template #linkedRuleSet>
          {{ 'ACA_FOLDER_RULES.RULE_LIST.LINKED_RULES' | translate }}
      </ng-template>
    </span>

    <ng-container *ngIf="!isMainRuleSetOwned">
      <mat-icon class="aca-rule-list__item__header__icon" (click)="onRuleSetEditLinkClicked($event)">edit</mat-icon>
      <mat-icon class="aca-rule-list__item__header__icon" (click)="onRuleSetUnlinkClicked($event)">link_off</mat-icon>
    </ng-container>

    <mat-icon class="aca-rule-list__item__header__icon">
      {{ mainRuleSetExpanded ? 'expand_more' : 'chevron_right' }}
    </mat-icon>
  </div>

  <ng-container *ngIf="mainRuleSetExpanded">
    <aca-rule-list-grouping
      *ngIf="mainRuleSetGroupingItems.length > 0; else emptyLinkedRuleSet"
      [items]="mainRuleSetGroupingItems"
      [selectedRule]="selectedRule"
      [showEnabledToggles]="isMainRuleSetOwned"
      (selectRule)="onSelectRule($event)"
      (ruleEnabledChanged)="onRuleEnabledChanged($event)"
      (loadMoreRules)="onLoadMoreRules($event)">
    </aca-rule-list-grouping>

    <ng-template #emptyLinkedRuleSet>
      <div class="aca-rule-list__item__all-linked-rules-are-disabled">
        {{ 'ACA_FOLDER_RULES.RULE_LIST.ALL_LINKED_RULES_ARE_DISABLED' | translate }}
        <button mat-stroked-button [routerLink]="['/nodes', mainRuleSet.owningFolder.id, 'rules']">
          {{ 'ACA_FOLDER_RULES.MANAGE_RULES.TOOLBAR.ACTIONS.SEE_IN_FOLDER' | translate }}
        </button>
      </div>
    </ng-template>
  </ng-container>

</div>
