<h1 mat-dialog-title>{{ title | translate }}</h1>
<section mat-dialog-content *ngIf="!data.showVersionsOnly">
  <adf-version-comparison id="adf-version-comparison" [newFileVersion]="data.file" [node]="data.node"></adf-version-comparison>
  <adf-version-upload
    id="adf-version-upload-button"
    [node]="data.node"
    [newFileVersion]="data.file"
    [currentVersion]="data.currentVersion"
    (success)="handleUpload($event)"
    (cancel)="handleCancel()"
    (error)="onUploadError($event)"
  >
  </adf-version-upload>
</section>
<ng-container *ngIf="data.showVersionsOnly">
  <section mat-dialog-content>
    <div class="adf-version-list-container">
      <div class="adf-version-list-table">
        <adf-version-list
          [node]="data.node"
          [showComments]="'adf-version-manager.allowComments' | adfAppConfig: true"
          [allowDownload]="'adf-version-manager.allowDownload' | adfAppConfig: true"
          (deleted)="refresh($event)"
          (restored)="refresh($event)"
          (viewVersion)="onViewingVersion($event)"
        ></adf-version-list>
      </div>
    </div>
  </section>
  <div mat-dialog-actions>
    <button mat-button color="primary" [mat-dialog-close]="true">{{ 'ADF-NEW-VERSION-UPLOADER.DIALOG_LIST.CLOSE' | translate }}</button>
  </div>
</ng-container>
