import { Component, OnInit, ViewChild, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Validators, FormGroup, FormBuilder, FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { TranslationService } from '@alfresco/adf-core';
import { Person } from '@alfresco/js-api';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from '@alfresco/adf-content-services';
import { Constants, GenericErrorComponent, PageLayoutComponent, PageLayoutContentComponent, PageLayoutErrorComponent, PageLayoutHeaderComponent, UserService } from '@alfresco/aca-shared';
import { AppStore, SnackbarErrorAction, SnackbarInfoAction } from '@alfresco/aca-shared/store';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    PageLayoutComponent,
    PageLayoutHeaderComponent,
    PageLayoutContentComponent,
    PageLayoutErrorComponent,
    GenericErrorComponent,
    BreadcrumbModule,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'app-profile-settings',
  templateUrl: 'profile-settings.component.html',
  styleUrls: ['profile-settings.component.scss'],
})
export class ProfileSettingsComponent implements OnInit, AfterViewInit {

  userData;
  personalForm: FormGroup;
  passwordForm: FormGroup;
  existingAvatarUrl: string;
  selectedAvatarFile: File;
  avatarDataReady: Boolean = false;

  fcUsername: FormControl;
  fcFirstName: FormControl;
  fcLastName: FormControl;
  fcEmail: FormControl;
  fcOldPassword: FormControl;
  fcPassword: FormControl;
  fcPasswordRepeated: FormControl;

  @ViewChild('avatarPreviewComp') avatarPreviewComp;
  @ViewChild('avatarInput') avatarInput;

  constructor(
    private store: Store<AppStore>,
    private titleService: Title,
    private translation: TranslationService,
    private userService: UserService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.titleService.setTitle(
      `${this.translation.instant('APP.PROFILE_SETTINGS.PAGE_TITLE')} - Alfresco Content Application`);

    this.userService.getUserDetails('-me-')
      .then(data => {
        this.userData = data;
        this.updatePersonalForm();
      })
      .catch(_ => {
        this.store.dispatch(new SnackbarErrorAction(
          this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      });
    
    this.reloadUserAvatar();
    
    const personalValidators = {
      first_name: [
        Validators.required,
      ],
      email: [
        Validators.required,
        Validators.email,
      ],
    };

    this.personalForm = this.formBuilder.group({
      username: [ '' ],
      first_name: [ '', personalValidators.first_name ],
      last_name: [ '' ],
      email: [ '', personalValidators.email ],
    });

    const passwordValidators = {
      old_password: [
        Validators.required,
      ],
      password: [
        Validators.required,
      ],
      password_repeated: [
        Validators.required,
      ],
    };

    this.passwordForm = this.formBuilder.group({
      old_password: [ '', passwordValidators.old_password ],
      password: [ '', passwordValidators.password ],
      password_repeated: [ '', passwordValidators.password_repeated ],
    });

    this.fcUsername = this.personalForm.controls['username'] as FormControl;
    this.fcFirstName = this.personalForm.controls['first_name'] as FormControl;
    this.fcLastName = this.personalForm.controls['last_name'] as FormControl;
    this.fcEmail = this.personalForm.controls['email'] as FormControl;
    this.fcOldPassword = this.passwordForm.controls['old_password'] as FormControl;
    this.fcPassword = this.passwordForm.controls['password'] as FormControl;
    this.fcPasswordRepeated = this.passwordForm.controls['password_repeated'] as FormControl;
  }

  ngAfterViewInit() {
    if (this.existingAvatarUrl) {
      this.avatarDataReady = true;
      this.avatarPreviewComp.nativeElement.src = this.existingAvatarUrl;
    }
  }

  reloadUserAvatar = (useTimeout=false) => {
    const proc = () => {
      this.userService.getUserAvatar('-me-')
        .then(data => {
          this.existingAvatarUrl = URL.createObjectURL(data);
          this.avatarDataReady = Boolean(data);
          if (this.avatarDataReady && this.avatarPreviewComp.nativeElement) {
            this.avatarPreviewComp.nativeElement.src = this.existingAvatarUrl;
          }
        })
        .catch(_ => {
          this.existingAvatarUrl = null;
          this.avatarDataReady = Boolean(this.selectedAvatarFile);
        });
    };
    if (!useTimeout) {
      proc();
    } else {
      setTimeout(proc, Constants.AVATAR_UPDATE_TIMEOUT_VALUE);
    }
  }

  updatePersonalForm() {
    const username = this.userData ? this.userData.id : '';
    const first_name = this.userData ? this.userData.firstName : '';
    const last_name = this.userData ? this.userData.lastName : '';
    const email = this.userData ? this.userData.email : '';

    this.personalForm.patchValue({
      username,
      first_name,
      last_name,
      email,
    });
  }

  resetPasswordForm() {
    this.passwordForm.patchValue({
      old_password: '',
      password: '',
      password_repeated: '',
    });
  }

  onPersonalFormSubmit() {
    this.userService.updateUser(this.username, this.first_name, this.last_name, this.email)
      .then((data: Person) => {
        this.userData = data; // has no effect on the forms, but those should already be up to date
        this.updatePersonalForm();
        this.store.dispatch(new SnackbarInfoAction(
          this.translation.instant('APP.PROFILE_SETTINGS.PROFILE_UPDATE_SUCCESS')));
      })
      .catch(_ => {
        this.store.dispatch(new SnackbarErrorAction(
          this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      });
  }

  onPasswordFormSubmit() {
    this.userService.updateUser(this.username, null, null, null, this.old_password, this.password)
      .then((data: Person) => {
        this.userData = data; // has no effect on the forms, but those should already be up to date
        this.resetPasswordForm();
        this.store.dispatch(new SnackbarInfoAction(
          this.translation.instant('APP.PROFILE_SETTINGS.PASSWORD_UPDATE_SUCCESS')));
      })
      .catch(_ => {
        this.store.dispatch(new SnackbarErrorAction(
          this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      });
  }

  onClearAvatarFile() {
    this.selectedAvatarFile = null;
    this.avatarInput.nativeElement.value = '';
    this.avatarDataReady = Boolean(this.existingAvatarUrl);
    if (this.existingAvatarUrl) {
      this.avatarPreviewComp.nativeElement.src = this.existingAvatarUrl;
    }
  }

  onAvatarFileSelected(event) {
    if (event.target.files && event.target.files[0]) {
      this.selectedAvatarFile = event.target.files[0];
      
      const fileReader = new FileReader();
      fileReader.readAsDataURL(this.selectedAvatarFile);
      fileReader.onload = frEvent => {
        this.avatarDataReady = true;
        this.avatarPreviewComp.nativeElement.src = frEvent.target.result;
      };      
    }
  }

  onUseDefaultAvatarFile() {
    this.userService.deleteUserAvatar('-me-')
      .then(_ => {
        this.store.dispatch(new SnackbarInfoAction(
          this.translation.instant('APP.PROFILE_SETTINGS.AVATAR_UPDATE_SUCCESS')));
        this.selectedAvatarFile = null;
        this.reloadUserAvatar(true);
      })
      .catch(_ => {
        this.store.dispatch(new SnackbarErrorAction(
          this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
          this.selectedAvatarFile = null;
      })
  }

  onUpdateAvatar() {
    this.userService.updateUserAvatar(this.username, this.selectedAvatarFile)
      .then(() => {
        this.store.dispatch(new SnackbarInfoAction(
          this.translation.instant('APP.PROFILE_SETTINGS.AVATAR_UPDATE_SUCCESS')));
        this.selectedAvatarFile = null;
        this.reloadUserAvatar(true);
      })
      .catch(_ => {
        this.store.dispatch(new SnackbarErrorAction(
          this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
          this.selectedAvatarFile = null;
      });
  }

  get username(): string {
    const { username } = this.personalForm.value;

    return (username || '').trim();
  }

  get first_name(): string {
    const { first_name } = this.personalForm.value;

    return (first_name || '').trim();
  }

  get last_name(): string {
    const { last_name } = this.personalForm.value;

    return (last_name || '').trim();
  }

  get email(): string {
    const { email } = this.personalForm.value;

    return (email || '').trim();
  }

  get old_password(): string {
    const { old_password } = this.passwordForm.value;

    return (old_password || '').trim();
  }

  get password(): string {
    const { password } = this.passwordForm.value;

    return (password || '').trim();
  }

  get password_repeated(): string {
    const { password_repeated } = this.passwordForm.value;

    return (password_repeated || '').trim();
  }
}
