/*!
 * @license
 * Alfresco Example Content Application
 *
 * Copyright (C) 2005 - 2020 Alfresco Software Limited
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail.  Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with Alfresco. If not, see <http://www.gnu.org/licenses/>.
 */

import { Person } from '@alfresco/js-api';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    MatButtonModule,
    MatAutocompleteModule,
  ],
  templateUrl: './select-user.dialog.html',
  styleUrls: ['./select-user.dialog.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'aca-select-user-dialog' }
})
export class SelectUserDialogComponent implements OnInit {
  
  userList: Person[] = [];
  usersFiltered: Observable<Person[]>;

  userForm = new FormGroup({
    selectedUser: new FormControl(undefined, Validators.required),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<SelectUserDialogComponent>
  ) {
    this.userList = data.userList;
  }

  ngOnInit() {
    this.usersFiltered = this.userForm.get('selectedUser').valueChanges.pipe(
      startWith(''),
      map(value => this._filterUsers(value || '')),
    );
  }

  get selectedUser() {
    return this.userForm.get('selectedUser');
  }

  onSelect() {
    const userName = this.userForm.get('selectedUser').value ? this.userForm.get('selectedUser').value : null;
    if (userName) {
      this.dialogRef.close(userName);
    } else {
      this.dialogRef.close(null);
    }
  }

  private _filterUsers(value: string): Person[] {
    if (!this.userList)
      return [];
    const filterValue = value.toLowerCase();
    return this.userList.filter(person => person.firstName.toLowerCase().includes(filterValue) || (person.lastName && person.lastName.toLowerCase().includes(filterValue)));
  }
}
