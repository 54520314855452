<form [formGroup]="form" novalidate (ngSubmit)="apply(form.value, form.valid)">
    <mat-form-field>
        <mat-label>{{ 'SEARCH.FILTER.RANGE.SELECT-FROM-DATE' | translate }}</mat-label>
        <input matInput
                (input)="forcePlaceholder($event)"
                [formControl]="from"
                [errorStateMatcher]="matcher"
                placeholder="{{ 'SEARCH.FILTER.RANGE.FROM-DATE' | translate }}"
                [attr.aria-label]="'SEARCH.FILTER.RANGE.FROM-DATE' | translate"
                [matDatepicker]="fromDatepicker"
                [max]="fromMaxDate"
                (dateChange)="onChangedHandler($event, from)"
                data-automation-id="date-range-from-input">
        <mat-datepicker-toggle matSuffix [for]="fromDatepicker" data-automation-id="date-range-from-date-toggle"></mat-datepicker-toggle>
        <mat-datepicker #fromDatepicker color="accent"></mat-datepicker>
        <mat-error *ngIf="from.invalid" data-automation-id="date-range-from-error">
            {{ getFromValidationMessage() | translate: { requiredFormat: datePickerFormat } }}
        </mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'SEARCH.FILTER.RANGE.SELECT-TO-DATE' | translate }}</mat-label>
        <input matInput
                (input)="forcePlaceholder($event)"
                [formControl]="to"
                [errorStateMatcher]="matcher"
                placeholder="{{ 'SEARCH.FILTER.RANGE.TO-DATE' | translate }}"
                [attr.aria-label]="'SEARCH.FILTER.RANGE.TO-DATE' | translate"
                [matDatepicker]="toDatepicker"
                [min]="from.value"
                [max]="maxDate"
                (dateChange)="onChangedHandler($event, to)"
                data-automation-id="date-range-to-input">
        <mat-datepicker-toggle matSuffix [for]="toDatepicker" data-automation-id="date-range-to-date-toggle"></mat-datepicker-toggle>
        <mat-datepicker #toDatepicker color="accent"></mat-datepicker>
        <mat-error *ngIf="to.invalid" data-automation-id="date-range-to-error">
            {{ getToValidationMessage() | translate: { requiredFormat: datePickerFormat } }}
        </mat-error>
    </mat-form-field>

    <div class="adf-facet-buttons adf-facet-buttons--topSpace" *ngIf="!settings?.hideDefaultAction">
        <button mat-button color="primary" type="button" (click)="clear()" data-automation-id="date-range-clear-btn">
            {{ 'SEARCH.FILTER.ACTIONS.CLEAR' | translate }}
        </button>
        <button mat-button color="primary" type="submit" [disabled]="!form.valid" data-automation-id="date-range-apply-btn">
            {{ 'SEARCH.FILTER.ACTIONS.APPLY' | translate }}
        </button>
    </div>
</form>
