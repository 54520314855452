/*!
 * @license
 * Alfresco Example Content Application
 *
 * Copyright (C) 2005 - 2020 Alfresco Software Limited
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail.  Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with Alfresco. If not, see <http://www.gnu.org/licenses/>.
 */

import { ArchiveService } from '@alfresco/aca-shared';
import { AppStore, SnackbarErrorAction, SnackbarInfoAction } from '@alfresco/aca-shared/store';
import { TranslationService } from '@alfresco/adf-core';
import { Node } from '@alfresco/js-api';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    MatButtonModule,
  ],
  templateUrl: './insert-into-act.dialog.html',
  styleUrls: ['./insert-into-act.dialog.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'aca-insert-into-act-dialog' }
})
export class InsertIntoActDialogComponent implements OnInit {
  node: Node;

  registries: any[] = [];
  inventoriesOfActs: any[] = [];
  selectedAct: any | undefined = undefined;
  attemptedToFindSelectedAct: boolean = false;

  archiveForm = new FormGroup({
    selectedRegistry: new FormControl(undefined, Validators.required),
    selectedInventoryOfActs: new FormControl(undefined, Validators.required),
    sequentialIndex: new FormControl(undefined, [Validators.min(1), this.validateSeqIndex()]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private store: Store<AppStore>,
    private archiveService: ArchiveService,
    private translate: TranslationService,
    private dialogRef: MatDialogRef<InsertIntoActDialogComponent>
  ) {
    this.node = data.node.entry;

    this.selectedRegistry.valueChanges.subscribe((_) => {
      this.onSelectedRegistryChanged();
    });

    this.selectedInventoryOfActs.valueChanges.subscribe((_) => {
      this.onSelectedInventoryOfActsChanged();
    });

    this.sequentialIndex.valueChanges.subscribe((_) => {
      this.onSequentialIndexChanged();
    });
  }

  get selectedRegistry() {
    return this.archiveForm.get('selectedRegistry');
  }

  get selectedInventoryOfActs() {
    return this.archiveForm.get('selectedInventoryOfActs');
  }

  get sequentialIndex() {
    return this.archiveForm.get('sequentialIndex');
  }

  get selectedRegistryCounter(): number {
    const selectedRegistry = this.registries.find((registry) => registry['nodeRef'] === this.selectedRegistry.value);

    return selectedRegistry?.['counter'] ?? 0;
  }

  async ngOnInit() {
    this.registries = await this.archiveService.getRegistryBooks();
  }

  printSelectedAct(): string {
    if (!this.selectedAct)
      return '';
    return this.selectedAct['title'];
  }

  async insertIntoAct() {
    if (this.archiveForm.invalid) return;

    try {
      await this.archiveService.insertDocumentIntoAct(
        `workspace://SpacesStore/${this.node.id}`,
        this.selectedRegistry.value,
        this.selectedInventoryOfActs.value,
        this.sequentialIndex.value.toString(),
      );

      this.store.dispatch(new SnackbarInfoAction(this.translate.instant('APP.ARCHIVE.INSERT_INTO_ACT.SUCCESS.INSERT_INTO_ACT')));
      this.dialogRef.close();
    } catch (e) {
      this.store.dispatch(new SnackbarErrorAction(this.translate.instant('APP.ARCHIVE.INSERT_INTO_ACT.ERROR.INSERT_INTO_ACT')));
    }
  }

  private async onSelectedRegistryChanged() {
    try {
      this.inventoriesOfActs = await this.archiveService.getInventoryOfActs(this.selectedRegistry.value);
    } catch (_) {
      this.store.dispatch(new SnackbarErrorAction(this.translate.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
    this.selectedInventoryOfActs.setValue(undefined);
    this.sequentialIndex.setValue(undefined);
    this.selectedAct = undefined;
    this.attemptedToFindSelectedAct = false;
  }

  private onSelectedInventoryOfActsChanged() {
    this.sequentialIndex.setValue(undefined);
    this.selectedAct = undefined;
    this.attemptedToFindSelectedAct = false;
  }

  private onSequentialIndexChanged() {
    this.selectedAct = undefined;
    this.attemptedToFindSelectedAct = false;
    if (this.selectedInventoryOfActs.value && this.sequentialIndex.value) {
      this.archiveService.getActs(this.selectedInventoryOfActs.value)
        .then((value: object[]) => {
          this.selectedAct = value.find(el => el['seqIndex'] === this.sequentialIndex.value);
          this.attemptedToFindSelectedAct = true;
          this.sequentialIndex.updateValueAndValidity({
            emitEvent: false,
          });
        })
        .catch(_ => {
          this.store.dispatch(new SnackbarErrorAction(this.translate.instant('CORE.MESSAGES.ERRORS.GENERIC')));
        });
    }
  }

  private validateSeqIndex(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      if (this.attemptedToFindSelectedAct && !this.selectedAct)
        return { invalidIndex: { value: control.value } };
      return null;
    };
  }
}
