<mat-list>
    <mat-list-item *ngFor="let currentEntry of tagsEntries; let idx = index">
        <div class="adf-tag-actions-container" id="tag_delete_{{currentEntry.entry.tag}}" (click)="removeTag(currentEntry.entry.id)">
            <div class="adf-tag-actions-delete-text" id="tag_name_{{currentEntry.entry.tag}}">{{currentEntry.entry.tag}}</div>
            <mat-icon class="adf-tag-actions-delete-icon">delete</mat-icon>
        </div>
    </mat-list-item>
</mat-list>
<table class="adf-full-width" cellspacing="0">
    <tr>
        <td>
            <mat-form-field class="adf-full-width">
                <input
                    id="new-tag-text"
                    matInput placeholder="{{'TAG.LABEL.NEWTAG' | translate }}"
                    type="text"
                    (keypress)="cleanErrorMsg()"
                    [(ngModel)]="newTagName"
                />
                <mat-hint data-automation-id="errorMessage" *ngIf="error" [ngStyle]="{'color': 'red'}" align="start">{{errorMsg}}</mat-hint>
            </mat-form-field>
        </td>
        <td>
            <button
                id="add-tag"
                class="adf-full-width"
                color="primary"
                (click)="addTag()"
                [disabled]="disableAddTag"
                mat-raised-button
            >{{'TAG.BUTTON.ADD' | translate }}</button>
        </td>
    </tr>
</table>
