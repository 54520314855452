<div class="adf-property-list">
    <div *ngFor="let property of properties">
        <div [attr.data-automation-id]="'header-'+property.key" class="adf-property">
            <adf-card-view-item-dispatcher
                [property]="property"
                [editable]="editable"
                [displayEmpty]="displayEmpty"
                [displayNoneOption]="property['displayNoneOption'] !== undefined ? property['displayNoneOption'] : displayNoneOption"
                [displayClearAction]="displayClearAction"
                [copyToClipboardAction]="copyToClipboardAction"
                [useChipsForMultiValueProperty]="useChipsForMultiValueProperty"
                [multiValueSeparator]="multiValueSeparator"
                [displayLabelForChips]="displayLabelForChips">
            </adf-card-view-item-dispatcher>
        </div>
    </div>
</div>
