<div class="adf-sidenav-layout-full-space">
    <ng-container *ngIf="!isHeaderInside">
        <ng-container class="adf-sidenav-layout-outer-header"
                      *ngTemplateOutlet="headerTemplate; context:templateContext"></ng-container>
    </ng-container>

    <adf-layout-container #container
                          [direction]="dir"
                          [position]="position"
                          [sidenavMin]="sidenavMin"
                          [sidenavMax]="sidenavMax"
                          [mediaQueryList]="mediaQueryList"
                          [hideSidenav]="hideSidenav"
                          [expandedSidenav]="expandedSidenav"
                          data-automation-id="adf-layout-container"
                          class="adf-layout__content">

        <ng-container app-layout-navigation
                      *ngTemplateOutlet="navigationTemplate; context:templateContext"></ng-container>

        <ng-container app-layout-content>
            <ng-container *ngIf="isHeaderInside">
                <ng-container *ngTemplateOutlet="headerTemplate; context:templateContext"></ng-container>
            </ng-container>
            <ng-container *ngTemplateOutlet="contentTemplate; context:templateContext"></ng-container>
        </ng-container>
    </adf-layout-container>

    <ng-template #emptyTemplate></ng-template>
</div>
