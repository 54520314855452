<div class="adf-github-link-container">
    <header data-automation-id="adf-github-source-code-title">{{ 'ABOUT.SOURCE_CODE.TITLE' | translate }}</header>
    <mat-card class="mat-elevation-z0">
        <h3 data-automation-id="adf-github-app-title">{{application}}</h3>
        <p *ngIf="version" data-automation-id="adf-github-version">{{ 'ABOUT.VERSION' | translate }}: {{ version }}</p>

        <div *ngIf="url">
            <small>{{ 'ABOUT.SOURCE_CODE.DESCRIPTION' | translate }}</small>
            <div data-automation-id="adf-github-url">
                <a [href]="url" target="_blank">{{url}}</a>
            </div>
        </div>
    </mat-card>
</div>
