<adf-login
  [copyrightText]="'application.copyright' | adfAppConfig | translate"
  providers="ECM"
  successRoute="/personal-files"
  logoImageUrl="./assets/images/alfresco-logo.svg"
  backgroundImageUrl="./assets/images/Wallpaper-BG-generic.svg"
  [showRememberMe]="false"
  [showLoginActions]="false"
>
</adf-login>
