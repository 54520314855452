<h2 mat-dialog-title>
  {{ 'APP.ARCHIVE.CLASSIFY_DOCUMENT.TITLE' | translate }}
</h2>

<mat-dialog-content class="adf-classify-document-dialog-content">
  <form [formGroup]="classifyForm" novalidate>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'APP.ARCHIVE.CLASSIFY_DOCUMENT.CLASSIFICATION_TYPE' | translate }}</mat-label>
      <mat-select formControlName="classifyType">
        <mat-option value="public">{{ 'APP.ARCHIVE.CLASSIFY_DOCUMENT.CLASSIFICATION_PUBLIC' | translate }}</mat-option>
        <mat-option value="internal">{{ 'APP.ARCHIVE.CLASSIFY_DOCUMENT.CLASSIFICATION_INTERNAL' | translate }}</mat-option>
        <mat-option value="confidential">{{ 'APP.ARCHIVE.CLASSIFY_DOCUMENT.CLASSIFICATION_CONFIDENTIAL' | translate }}</mat-option>
        <mat-option value="higlyconfidential">{{ 'APP.ARCHIVE.CLASSIFY_DOCUMENT.CLASSIFICATION_HIGHLY_CONFIDENTIAL' | translate }}</mat-option>
        <mat-option value="statesecret">{{ 'APP.ARCHIVE.CLASSIFY_DOCUMENT.CLASSIFICATION_STATE_SECRET' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <aca-image-annotator
    [size]="previewSize"
    [image]="thumbnail"
    [mode]="annotatorMode()"
    [contentHtml]="annotationContentHtml()"
    [textScaleCoef]="4.5"
    (coordinatesChanged)="onAnnotatorCoordinatesChanged($event)"
    (coordinatesReset)="onAnnotatorCoordinatesReset()">
  </aca-image-annotator>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
    <span class="adf-fill-remaining-space"></span>

    <button
      mat-button
      class="adf-dialog-action-button"
      color="primary"
      (click)="classifyDocument()">
        {{ 'APP.ARCHIVE.CLASSIFY_DOCUMENT.CLASSIFY' | translate }}
    </button>
    <button
      mat-button
      mat-dialog-close>
      {{ 'APP.GENERAL.CANCEL' | translate }}
    </button>
</mat-dialog-actions>
