<mat-form-field class="adf-chip-list" appearance="outline">
    <mat-chip-list #chipList [attr.aria-label]="'SEARCH.FILTER.ARIA-LABEL.OPTIONS-SELECTION' | translate">
        <mat-chip
            class="adf-option-chips"
            *ngFor="let option of selectedOptions"
            (removed)="remove(option)">
            <span [matTooltip]="'SEARCH.RESULTS.WILL_CONTAIN' | translate:{searchTerm: option.fullPath}"
                  [matTooltipDisabled]="!option.fullPath" [matTooltipShowDelay]="tooltipShowDelay">
                {{ option.value }}
            </span>
            <button matChipRemove class="adf-option-chips-delete-button" [matTooltipDisabled]="!option.fullPath"
                    [matTooltip]="('SEARCH.FILTER.BUTTONS.REMOVE' | translate) + ' \'' + option.fullPath + '\''"
                    [matTooltipShowDelay]="tooltipShowDelay"
                    [attr.aria-label]="('SEARCH.FILTER.BUTTONS.REMOVE' | translate) + ' ' + option.value">
                <mat-icon class="adf-option-chips-delete-icon">close</mat-icon>
            </button>
        </mat-chip>
        <input
            placeholder="{{ placeholder | translate }}"
            aria-controls="adf-search-chip-autocomplete"
            #optionInput
            [formControl]="formCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [attr.aria-label]="placeholder | translate"
            (matChipInputTokenEnd)="add($event)"
            (blur)="activeAnyOption = false"
            data-automation-id="adf-search-chip-autocomplete-input">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" id="adf-search-chip-autocomplete"
                      (optionActivated)="activeAnyOption = true" (closed)="activeAnyOption = false">
        <ng-container *ngIf="optionInput.value.length > 0">
            <mat-option
                [disabled]="option | adfIsIncluded: selectedOptions : compareOption"
                *ngFor="let option of filteredOptions" [value]="option" [matTooltipShowDelay]="tooltipShowDelay"
                [matTooltipDisabled]="!option.fullPath" matTooltipPosition="right"
                [attr.data-automation-id]="'option-' + (option.value)"
                [matTooltip]="'SEARCH.RESULTS.WILL_CONTAIN' | translate:{searchTerm: option.fullPath || option.value}"
                [ngClass]="(option | adfIsIncluded: selectedOptions : compareOption) && 'adf-autocomplete-added-option'">
                {{ option.fullPath || option.value }}
            </mat-option>
        </ng-container>
    </mat-autocomplete>
</mat-form-field>
