<h2 mat-dialog-title>
  {{ 'APP.SETTINGS.SITE_USERS' | translate }} {{ siteTitle }}
</h2>

<mat-dialog-content [style]="matDialogContentStyle()">
  <div *ngIf="mode === 0" class="mode0-container">
    <mat-spinner *ngIf="loading" [diameter]="60" style="margin: 0 auto;"></mat-spinner>
    <span *ngIf="!loading && (!siteUsersRows || siteUsersRows.length === 0)">{{ 'APP.SETTINGS.NO_USERS' | translate }}</span>
    <div *ngIf="!loading && siteUsersRows && siteUsersRows.length > 0" class="datatable-container">
      <adf-datatable
        [rows]="siteUsersRows" 
        [columns]="siteUsersSchema"
        [actions]="true"
        [stickyHeader]="false"
        (showRowActionsMenu)="onSiteShowRowActionsMenu($event)"
        (executeRowAction)="onSiteExecuteRowAction($event)"> 
      </adf-datatable>
    </div>
    <div *ngIf="!loading && siteUsersRows && siteUsersRows.length > 0" class="intra-dialog-buttons-left">
      <button mat-button (click)="onRefreshList()">
        <mat-icon class="mat-icon-custom-color">refresh</mat-icon>
      </button>
    </div>
    <div *ngIf="!loading && siteUsersRows && siteUsersRows.length > 0" class="intra-dialog-buttons">
      <button mat-button (click)="onAddUsers()">
        {{ 'APP.SETTINGS.ADD_USERS' | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="mode === 1" class="mode1-container">
    <span *ngIf="!siteAddUsersRowsAll || siteAddUsersRowsAll.length === 0">{{ 'APP.SETTINGS.NO_USERS_LEFT_TO_ADD' | translate }}</span>
    <mat-form-field>
      <input
        matInput
        (keyup)="applySiteAddUsersFilter()"
        [(ngModel)]="filterSiteAddUsers"
        [placeholder]="filterPlaceholder" />
    </mat-form-field>
    <div *ngIf="siteAddUsersRows && siteAddUsersRows.length > 0" class="datatable-container">
      <adf-datatable
        [rows]="siteAddUsersRows" 
        [columns]="siteAddUsersSchema"
        [actions]="true"
        [stickyHeader]="false"
        (showRowActionsMenu)="onSiteAddShowRowActionsMenu($event)"
        (executeRowAction)="onSiteAddExecuteRowAction($event)"> 
      </adf-datatable>
    </div>
    <div class="intra-dialog-buttons">
      <button mat-button (click)="onViewUsers()">
        {{ 'APP.ACTIONS.VIEW_USERS' | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="mode === 2" class="mode2-container">
    <span class="role-message">{{ getRoleMessage() }}</span>
    <mat-form-field>
      <mat-select
        [(ngModel)]="roleChoice"
        placeholder="{{ 'APP.SETTINGS.SITE_ROLE' | translate }}">
          <mat-option value="SiteManager">{{ 'APP.SETTINGS.SITE_ROLE_MANAGER' | translate }}</mat-option>
          <mat-option value="SiteCollaborator">{{ 'APP.SETTINGS.SITE_ROLE_COLLABORATOR' | translate }}</mat-option>
          <mat-option value="SiteContributor">{{ 'APP.SETTINGS.SITE_ROLE_CONTRIBUTOR' | translate }}</mat-option>
          <mat-option value="SiteConsumer">{{ 'APP.SETTINGS.SITE_ROLE_CONSUMER' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="intra-dialog-buttons">
      <button mat-button (click)="onRoleCancel()">
        {{ 'APP.ACTIONS.BACK' | translate }}
      </button>
      <button mat-button (click)="onRoleSubmit()">
        {{ 'APP.ACTIONS.SUBMIT' | translate }}
      </button>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
  <span class="adf-fill-remaining-space"></span>
  <button
      mat-button
      id="adf-folder-cancel-button"
      mat-dialog-close>
      {{ 'APP.ACTIONS.CLOSE' | translate }}
  </button>
</mat-dialog-actions>
