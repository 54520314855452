<ng-container *ngIf="!property.isEmpty() || isEditable()">
    <div class="adf-property-value">
        <mat-checkbox [attr.data-automation-id]="'card-boolean-' + property.key"
                      [attr.title]="'CORE.METADATA.ACTIONS.TOGGLE' | translate"
                      [checked]="property.displayValue"
                      [disabled]="!isEditable()"
                      color="primary"
                      (change)="changed($event)">
            <div [attr.data-automation-id]="'card-boolean-label-' + property.key"
                 class="adf-property-label">{{ property.label | translate }}</div>
        </mat-checkbox>
    </div>
</ng-container>
