import { AlfrescoApiService } from '@alfresco/adf-core';
import { CustomApiCallService, HTTPMethod } from './custom-api-call.service';
import { GroupsApi, Group, GroupEntry, GroupMember, GroupMemberPaging, GroupPaging } from '@alfresco/js-api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  // all group ids provided to methods of this class need to exclude the "GROUP_" prefix

  private groupsApi: GroupsApi;
  
  constructor(
    alfrescoApiService: AlfrescoApiService,
    private customApiCallService: CustomApiCallService,
  ) {
    this.groupsApi = new GroupsApi(alfrescoApiService.getInstance());
  }

  listGroups(): Promise<Group[]> {
    return new Promise((resolve, reject) => {
      this.groupsApi.listGroups({
        where: `(zones in ('APP.DEFAULT'))`,
      }).then((data: GroupPaging) => {
        if (data && data.list && data.list.entries) {
          const groupArray = data.list.entries.map(el => {
            el.entry.id = el.entry.id.substring(6); // removes "GROUP_" prefix
            return el.entry;
          });
          resolve(groupArray);
        } else {
          resolve([]);
        }
      }).catch(err => {
        reject(err);
      });
    });
  }

  createGroup(identifier: string, displayName: string): Promise<Group> {
    return new Promise((resolve, reject) => {
      this.groupsApi.createGroup({
        id: identifier,
        displayName,
      }).then((data: GroupEntry) => {
        if (data && data.entry) {
          resolve(data.entry);
        } else {
          resolve(null);
        }
      }).catch(err => {
        reject(err);
      });
    });
  }

  updateGroup(groupId: string, displayName: string): Promise<Group> {
    return new Promise((resolve, reject) => {
      this.groupsApi.updateGroup(`GROUP_${groupId}`, {
        displayName,
      }).then((data: GroupEntry) => {
        if (data && data.entry) {
          resolve(data.entry);
        } else {
          resolve(null);
        }
      }).catch(err => {
        reject(err);
      });
    });
  }

  deleteGroup(groupId: string): Promise<any> {
    return this.groupsApi.deleteGroup(`GROUP_${groupId}`);
  }

  listUsersInGroup(groupId: string): Promise<GroupMember[]> {
    return new Promise((resolve, reject) => {
      this.groupsApi.listGroupMemberships(`GROUP_${groupId}`)
        .then((data: GroupMemberPaging) => {
          if (data && data.list && data.list.entries) {
            const groupMemberEntries = data.list.entries.map(el => el.entry);
            resolve(groupMemberEntries);
          } else {
            resolve([]);
          }
        }).catch(err => {
          reject(err);
        });
    });
  }

  addUserToGroup(groupId: string, userId: string): Promise<any> {
    return this.customApiCallService.callServiceApi(`groups/${groupId}/children/${userId}`, HTTPMethod.POST);
  }

  removeUserFromGroup(groupId: string, userId: string): Promise<any> {
    return this.customApiCallService.callServiceApi(`groups/${groupId}/children/${userId}`, HTTPMethod.DELETE);
  }
}
