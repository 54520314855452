/*!
 * @license
 * Alfresco Example Content Application
 *
 * Copyright (C) 2005 - 2020 Alfresco Software Limited
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail.  Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with Alfresco. If not, see <http://www.gnu.org/licenses/>.
 */

import { ArchiveService } from '@alfresco/aca-shared';
import { AppStore, SnackbarErrorAction, SnackbarInfoAction } from '@alfresco/aca-shared/store';
import { TranslationService } from '@alfresco/adf-core';
import { Node } from '@alfresco/js-api';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    MatButtonModule,
  ],
  templateUrl: './rename-subject.dialog.html',
  styleUrls: ['./rename-subject.dialog.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'aca-rename-subject-dialog' }
})
export class RenameSubjectDialogComponent implements OnInit {

  node: Node;

  renameForm: FormGroup;
  registryBook: string;
  currentArchiveSign: string;
  organizationalUnits: any[] = [];
  archiveSigns: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private store: Store<AppStore>,
    private archiveService: ArchiveService,
    private translation: TranslationService,
    private dialogRef: MatDialogRef<RenameSubjectDialogComponent>
  ) {
    this.node = data.node.entry;

    this.renameForm = new FormGroup({
      subject: new FormControl('', Validators.required),
      selectedOrganizationalUnit: new FormControl(undefined),
      selectedArchiveSign: new FormControl(undefined),
    }); 
  }

  async ngOnInit() {
    this.archiveService.getDocumentProperties(`workspace://SpacesStore/${this.node.id}`)
      .then(docProperties => {
        this.renameForm.get('subject').setValue(docProperties['subject']);
        this.registryBook = docProperties['registryBook'];
        this.currentArchiveSign = docProperties['archiveSign'];
        this.loadOrganizationalUnits();
      })
      .catch(_ => {
        this.store.dispatch(
          new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      });
  }

  async loadOrganizationalUnits() {
    try {
      this.organizationalUnits = await this.archiveService.getOrganizationalUnits(this.registryBook);
      const currentIndex = this.currentArchiveSign.substring(0, 2);
      const currentOrgUnit = this.organizationalUnits.find(el => el['index'] === currentIndex);
      if (currentOrgUnit) {
        this.selectedOrganizationalUnit.setValue(currentOrgUnit['nodeRef']);
        this.loadArchiveSigns();
      }
    } catch (_) {
      this.store.dispatch(
        new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }

  async loadArchiveSigns() {
    try {
      this.archiveSigns = await this.archiveService.getArchiveSigns(this.selectedOrganizationalUnit.value);
      const currentArchiveSign = this.archiveSigns.find(el => el['index'] === this.currentArchiveSign);
      if (currentArchiveSign) {
        this.selectedArchiveSign.setValue(currentArchiveSign['nodeRef']);
      }
    } catch (_) {
      this.store.dispatch(
        new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }

  async renameSubject() {
    if (this.renameForm.invalid)
      return;

    try {
      await this.archiveService.renameSubject(
        `workspace://SpacesStore/${this.node.id}`,
        this.renameForm.get('subject').value,
        this.selectedArchiveSign.value,
      );

      this.store.dispatch(
        new SnackbarInfoAction(this.translation.instant('APP.ARCHIVE.RENAME_SUBJECT.SUCCESS')));
      this.dialogRef.close(true);
      window.location.reload();
    } catch (e) {
      this.store.dispatch(
        new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }

  get selectedOrganizationalUnit() {
    return this.renameForm.get('selectedOrganizationalUnit');
  }

  get selectedArchiveSign() {
    return this.renameForm.get('selectedArchiveSign');
  }
}
