import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ContentApiService, GenericErrorComponent, PageComponent, PageLayoutComponent, PageLayoutContentComponent, PageLayoutErrorComponent, PageLayoutHeaderComponent, SiteRoles, SiteService } from '@alfresco/aca-shared';
import { isAdmin, SnackbarErrorAction, SnackbarInfoAction } from '@alfresco/aca-shared/store';
import { ActivatedRoute } from '@angular/router';
import { Node, NodeEntry, Site, SiteMember } from '@alfresco/js-api';
import { takeUntil } from 'rxjs/operators';
import { DataCellEvent, DataColumn, DataRowActionEvent, DataTableModule, TranslationService } from '@alfresco/adf-core';
import { MatDialog } from '@angular/material/dialog';
import { BreadcrumbModule, ConfirmDialogComponent } from '@alfresco/adf-content-services';
import { EditRoleDialogComponent } from './edit-role-dialog/edit-role-dialog.component';
import { AddSiteUserDialogComponent } from './add-site-user-dialog/add-site-user-dialog.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PageLayoutComponent,
    PageLayoutHeaderComponent,
    PageLayoutContentComponent,
    PageLayoutErrorComponent,
    GenericErrorComponent,
    BreadcrumbModule,
    DataTableModule,
    MatButtonModule,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './site-user-membership.component.html',
  styleUrls: ['site-user-membership.component.scss'],
})
export class SiteUserMembershipComponent extends PageComponent implements OnInit, OnDestroy {

  title: string = null;
  hasError: boolean = false;
  documentLibraryNode: Node = null;
  isAdmin: Boolean = false;

  siteId: string;
  siteTitle: string;

  membersRows: object[];
  membersSchema: DataColumn[]; 

  constructor(
    private translation: TranslationService,
    private siteService: SiteService,
    private route: ActivatedRoute,
    private contentApi: ContentApiService,
    public dialog: MatDialog
  ) {    
    super();

    this.membersSchema = [
      {
        type: 'text',
        key: 'id',
        title: this.translation.instant('APP.SETTINGS.USERNAME'),
        sortable: true,
      },
      {
        type: 'text',
        key: 'person.displayName',
        title: this.translation.instant('APP.SETTINGS.FULL_NAME'),
        cssClass: 'full-width',
        sortable: true,
      },
      {
        type: 'text',
        key: 'roleName',
        title: this.translation.instant('APP.SETTINGS.SITE_ROLE'),
        cssClass: 'full-width',
        sortable: true,
      }
    ];
  }

  ngOnInit() {
    super.ngOnInit();

    const route = this.route.snapshot;
    const siteGuid = route.params.folderId;
    this.title = route.data.title;
    
    this.getMembers();

    // loads data for the breadcrumb component
    this.contentApi
      .getNode(siteGuid, { relativePath: '/documentLibrary' })
      .subscribe((node: NodeEntry) => {
        this.updateCurrentNode(node.entry);
      }, _ => {
        this.hasError = true;
      });

    this.store
      .select(isAdmin)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        this.isAdmin = value;
      });
  }

  onShowRowActionsMenu(event: DataCellEvent) {
    const editAction = {
      id: 0,
      title: this.translation.instant('APP.ACTIONS.EDIT_ROLE'),
    };
    const removeAction = {
      id: 1,
      title: this.translation.instant('APP.ACTIONS.REMOVE'),
    };
    event.value.actions = [
      editAction, removeAction,
    ];
  }

  onExecuteRowAction(event: DataRowActionEvent) {
    let args = event.value;
    if (args.action.id === 0) {
      this.openRoleEditDialog(args.row.obj);
    } else if (args.action.id === 1) {
      this.onRemoveUser(args.row.obj);
    }
  }

  onRemoveUser(obj) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'APP.SETTINGS.REMOVE_USER_SITE_TITLE',
        message: this.translation.instant('APP.SETTINGS.REMOVE_USER_SITE_MESSAGE').replace('{0}', obj.person.displayName).replace('{1}', this.siteTitle),
        yesLabel: 'APP.DIALOGS.CONFIRM_REMOVE.YES_LABEL',
        noLabel: 'APP.DIALOGS.CONFIRM_REMOVE.NO_LABEL'
      },
      minWidth: '250px'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.siteService.removeSiteMember(this.siteId, obj.person.id)
          .then(() => {
            this.store.dispatch(new SnackbarInfoAction(
              this.translation.instant('APP.SETTINGS.USER_REMOVED_MESSAGE_SITE')
                .replace('{0}', obj.person.displayName)));
            this.getMembers();
          })
          .catch(_ => {
            this.store.dispatch(new SnackbarErrorAction(
              this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
          });
      }
    });
  }

  openRoleEditDialog(obj) {
    const dialogRef = this.dialog.open(EditRoleDialogComponent, {
      width: '450px',
      data: { currentRole: obj.role, userDisplayName: obj.person.displayName },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.siteService.updateSiteMember(this.siteId, obj.person.id, result as SiteRoles)
          .then(() => {
            this.store.dispatch(new SnackbarInfoAction(
              this.translation.instant('APP.SETTINGS.USER_ROLE_EDITED_MESSAGE')
                .replace('{0}', obj.person.displayName)
                .replace('{1}', this.translation.instant(
                  SiteService.mapRoleToI18NString(result as SiteRoles)))
                ));
            this.getMembers();
          })
          .catch(_ => {
            this.store.dispatch(new SnackbarErrorAction(
              this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
          });
      }
    });
  }

  openAddUserDialog(): void {
    const dialogRef = this.dialog.open(AddSiteUserDialogComponent, {
      width: '600px',
      data: { siteId: this.siteId, siteTitle: this.siteTitle },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getMembers();
      }
    });
  }

  getMembers() {
    const getMembersProc = (siteId) => {
      this.siteService.getSiteMembers(siteId)
        .then((data: SiteMember[]) => {
          this.membersRows = data.map(el => ({
            ...el,
            roleName: this.translation.instant(
              SiteService.mapRoleToI18NString(el.role as SiteRoles)),
          }));
        }).catch(_ => {
          this.hasError = true;
        });
    };
    if (!this.siteId) {
      const route = this.route.snapshot;
      const siteGuid = route.params.folderId;
      this.siteService.getSiteByGuid(siteGuid)
        .then((data: Site) => {
          if (data) {
            this.siteId = data.id;
            this.siteTitle = data.title;
            getMembersProc(this.siteId);
          }
        }).catch(_ => {
          this.hasError = true;
        });
    } else {
      getMembersProc(this.siteId);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // the following three methods are copied from the files component
  // if it is ever updated, these methods need to be updated as well

  private async updateCurrentNode(node: Node) {
    if (node && node.path && node.path.elements) {
      const elements = node.path.elements;
      if (elements.length > 1) {
        if (elements[1].name === 'User Homes') {
          if (!this.isAdmin) {
            elements.splice(0, 2);
          }
        } else if (elements[1].name === 'Sites') {
          await this.normalizeSitePath(node);
        }
      }
    }

    this.documentLibraryNode = node;
  }

  private async normalizeSitePath(node: Node) {
    const elements = node.path.elements;

    // remove 'Sites'
    elements.splice(1, 1);

    if (this.isSiteContainer(node)) {
      // rename 'documentLibrary' entry to the target site display name
      // clicking on the breadcrumb entry loads the site content
      const parentNode = await this.contentApi.getNodeInfo(node.parentId).toPromise();
      node.name = parentNode.properties['cm:title'] || parentNode.name;

      // remove the site entry
      elements.splice(1, 1);
    } else {
      // remove 'documentLibrary' in the middle of the path
      const docLib = elements.findIndex((el) => el.name === 'documentLibrary');
      if (docLib > -1) {
        const siteFragment = elements[docLib - 1];
        const siteNode = await this.contentApi.getNodeInfo(siteFragment.id).toPromise();

        // apply Site Name to the parent fragment
        siteFragment.name = siteNode.properties['cm:title'] || siteNode.name;
        elements.splice(docLib, 1);
      }
    }
  }

  isSiteContainer(node: Node): boolean {
    if (node && node.aspectNames && node.aspectNames.length > 0) {
      return node.aspectNames.indexOf('st:siteContainer') >= 0;
    }
    return false;
  }
}
