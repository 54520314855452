import { Group, GroupPaging, GroupsApi, PeopleApi, Person, PersonBodyUpdate, PersonEntry, PersonPaging } from '@alfresco/js-api';
import { Injectable } from '@angular/core';
import { CustomApiCallService, HTTPMethod, PayloadType } from './custom-api-call.service';
import { AlfrescoApiService } from '@alfresco/adf-core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private peopleApi: PeopleApi;
  private groupsApi: GroupsApi;

  constructor(
    alfrescoApiService: AlfrescoApiService,
    private customApiCallService: CustomApiCallService,
  ) {
    const alfrescoApi = alfrescoApiService.getInstance();
    this.peopleApi = new PeopleApi(alfrescoApi);
    this.groupsApi = new GroupsApi(alfrescoApi);
  }
  
  listUsers(): Promise<Person[]> {
    return new Promise((resolve, reject) => {
      this.peopleApi.listPeople()
        .then((data: PersonPaging) => {
          if (data && data.list && data.list.entries) {
            const userEntries = data.list.entries.map(el => el.entry);
            resolve(userEntries);
          } else {
            resolve([]);
          }
        }).catch(err => {
          reject(err);
        });
    });
  }

  getUserDetails(username: string): Promise<Person> {
    return new Promise((resolve, reject) => {
      this.peopleApi.getPerson(username)
        .then((data: PersonEntry) => {
          if (data && data.entry) {
            resolve(data.entry);
          } else {
            resolve(null);
          }
        }).catch(err => {
          reject(err);
        });
    });
  }

  getUserGroups(username: string): Promise<Group[]> {
    return new Promise((resolve, reject) => {
      this.groupsApi.listGroupMembershipsForPerson(username)
        .then((data: GroupPaging) => {
          if (data && data.list && data.list.entries) {
            const groupEntries = data.list.entries.map(el => {
              el.entry.id = el.entry.id.substring(6); // removes "GROUP_" prefix
              return el.entry;
            });
            resolve(groupEntries);
          } else {
            resolve([]);
          }
        }).catch(err => {
          reject(err);
        });
    });
  }

  createUser(username: string, password: string, firstName: string, lastName: string, email: string): Promise<Person> {
    return new Promise((resolve, reject) => {
      this.peopleApi.createPerson({
        id: username,
        firstName,
        lastName,
        email,
        password,
      }).then((data: PersonEntry) => {
        if (data && data.entry) {
          resolve(data.entry);
        } else {
          resolve(null);
        }
      }).catch(err => {
        reject(err);
      });
    });
  }

  updateUser(username: string, firstName: string = null, lastName: string = null, email: string = null, oldPassword: string = null, password: string = null): Promise<Person> {
    return new Promise((resolve, reject) => {
      const updateObj: PersonBodyUpdate = {};
      if (firstName !== null && firstName !== undefined)
        updateObj['firstName'] = firstName;
      if (lastName !== null && lastName !== undefined)
        updateObj['lastName'] = lastName;
      if (email !== null && email !== undefined)
        updateObj['email'] = email;
      if (password !== null && password !== undefined && password !== '') {
        updateObj['password'] = password;
        if (oldPassword !== null && oldPassword !== undefined && oldPassword !== '') {
          updateObj['oldPassword'] = oldPassword;
        }
      }
      if (Object.keys(updateObj).length === 0) {
        throw new Error('no data provided to update');
      }
      this.peopleApi.updatePerson(username, updateObj)
        .then((data: PersonEntry) => {
          if (data && data.entry) {
            resolve(data.entry);
          } else {
            resolve(null);
          }
        }).catch(err => {
          reject(err);
        });
    });
  }

  getUserAvatar(username: string): Promise<Blob> {
    return new Promise((resolve, reject) => {
      this.peopleApi.getAvatarImage(username).then((data: Blob) => {
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  updateUserAvatar(username: string, avatar: File): Promise<any> {
    return new Promise((resolve, reject) => {
      avatar.arrayBuffer().then(arbuf => {
        const extension = avatar.name.substring(avatar.name.lastIndexOf(".") + 1).toLowerCase();
        let imageType = extension;
        if (imageType === 'jpg')
          imageType = 'jpeg';
        const contentType = `image/${imageType};charset=UTF-8`;
        this.customApiCallService.callApi(`-default-/public/alfresco/versions/1/people/${username}/avatar`, HTTPMethod.PUT, arbuf, PayloadType.CUSTOM, contentType)
          .then(value => resolve(value))
          .catch(err => reject(err));
      });
    });
  }

  deleteUserAvatar(username: string): Promise<any> {
    return this.peopleApi.deleteAvatarImage(username);
  }

  deleteUser(username: string): Promise<any> {
    return this.customApiCallService.callServiceApi(`people/${username}`, HTTPMethod.DELETE);
  }
}
