<h2 mat-dialog-title>
  {{ 'APP.ARCHIVE.RENAME_SUBJECT.TITLE' | translate }}
</h2>

<mat-dialog-content class="adf-rename-subject-dialog-content">
  <form [formGroup]="renameForm" novalidate>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'APP.ARCHIVE.RENAME_SUBJECT.NEW_SUBJECT' | translate }}</mat-label>
      <input matInput formControlName="subject" name="subject" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'APP.ARCHIVE.ORGANIZATIONAL_UNIT' | translate }}</mat-label>
      <mat-select formControlName="selectedOrganizationalUnit">
        <mat-option *ngFor="let unit of organizationalUnits" [value]="unit.nodeRef">{{ unit.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'APP.ARCHIVE.ARCHIVE_SIGN' | translate }}</mat-label>
      <mat-select formControlName="selectedArchiveSign">
        <mat-option *ngFor="let sign of archiveSigns" [value]="sign.nodeRef">{{ sign.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
    <span class="adf-fill-remaining-space"></span>

    <button
      mat-button
      class="adf-dialog-action-button"
      color="primary"
      (click)="renameSubject()">
        {{ 'APP.ARCHIVE.RENAME_SUBJECT.RENAME' | translate }}
    </button>
    <button
      mat-button
      mat-dialog-close>
      {{ 'APP.GENERAL.CANCEL' | translate }}
    </button>
</mat-dialog-actions>
