
<h2 mat-dialog-title>
  {{ 'APP.ARCHIVE.PENDING_DOCUMENTS.REMOVE_DOCUMENT.TITLE' | translate }}
</h2>

<mat-dialog-content>
  <p id="remove-document-prompt">{{ 'APP.ARCHIVE.PENDING_DOCUMENTS.REMOVE_DOCUMENT.PROMPT' | translate }}</p>
  <mat-form-field>
    <textarea
      matNativeControl
      name="reason"
      [placeholder]="'APP.ARCHIVE.PENDING_DOCUMENTS.REMOVE_DOCUMENT.REASON' | translate"
      required="remove"
      [(ngModel)]="reason"
      rows="3"
    ></textarea>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
  <span class="adf-fill-remaining-space"></span>
  <button mat-button color="primary" [disabled]="!reason" (click)="onConfirm()">{{ 'APP.DIALOGS.CONFIRM_REMOVE.YES_LABEL' | translate }}</button>
  <button mat-button (click)="onReject()">{{ 'APP.DIALOGS.CONFIRM_REMOVE.NO_LABEL' | translate }}</button>
</mat-dialog-actions>
