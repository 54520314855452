<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="APP.BROWSE.ARCHIVE.MENU.REPORTS.TITLE"> </adf-breadcrumb>
  </aca-page-layout-header>

  <aca-page-layout-content>
    <div class="reports-main-content">
      <form [formGroup]="reportForm" (ngSubmit)="generateReport()" novalidate>
        <div class="form-row">
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.REPORTS.REPORT_TYPE' | translate }}</mat-label>
            <mat-select formControlName="selectedReportType" (selectionChange)="onReportTypeChanged()" required>
              <mat-option *ngFor="let reportType of reportTypes" [value]="reportType.value">{{ reportType.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="selectedReportType.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.REGISTRY_BOOK' | translate }}</mat-label>
            <mat-select formControlName="selectedRegistry" required>
              <mat-option *ngFor="let registry of registries" [value]="registry.nodeRef">{{ registry.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="selectedRegistry.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="selectedReportType.value === reportTypes[1].value" appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.INVENTORY_OF_ACTS' | translate }}</mat-label>
            <mat-select formControlName="selectedInventoryOfActs">
              <mat-option *ngFor="let inventory of inventoriesOfActs" [value]="inventory.nodeRef">{{ inventory.title }}</mat-option>
            </mat-select>
            <mat-error *ngIf="selectedInventoryOfActs.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
          </mat-form-field>
          <div>
            <button mat-raised-button type="submit">{{ 'APP.ARCHIVE.REPORTS.GENERATE' | translate }}</button>
          </div>
        </div>
        <div class="form-row">
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.REPORTS.FILTER_BY' | translate }}</mat-label>
            <mat-select formControlName="selectedFilter">
              <mat-option *ngFor="let filter of filters" [value]="filter.value">{{ filter.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="selectedFilter.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="selectedFilter.value === filters[1].value" appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.REPORTS.FROM' | translate }}</mat-label>
            <input matInput formControlName="indexFrom" name="indexFrom" type="text" />
            <mat-error *ngIf="indexFrom.errors?.required">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
            <mat-error *ngIf="indexFrom.errors?.pattern">{{ 'APP.ARCHIVE.ERROR.NUMBER_PATTERN' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="selectedFilter.value === filters[1].value" appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.REPORTS.TO' | translate }}</mat-label>
            <input matInput formControlName="indexTo" name="indexTo" type="text" />
            <mat-error *ngIf="indexTo.errors?.required">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
            <mat-error *ngIf="indexTo.errors?.pattern">{{ 'APP.ARCHIVE.ERROR.NUMBER_PATTERN' | translate }}</mat-error>
            <mat-error *ngIf="indexTo.errors?.lowerValue">{{ 'APP.ARCHIVE.ERROR.LOWER_VALUE' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="this.selectedReportType.value === this.reportTypes[0].value && selectedFilter.value === filters[2].value" appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.ARCHIVE_SIGN' | translate }}</mat-label>
            <input matInput formControlName="archiveSign" name="archiveSign" type="text" />
            <mat-error *ngIf="archiveSign.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="(this.selectedReportType.value === this.reportTypes[0].value && selectedFilter.value === filters[3].value) || (this.selectedReportType.value === this.reportTypes[1].value && (selectedFilter.value === filters[2].value || selectedFilter.value === filters[3].value))" appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.REPORTS.FROM_TO' | translate }}</mat-label>
            <mat-date-range-input [rangePicker]="rangePicker">
              <input matStartDate formControlName="startDate" placeholder="Start date" readonly />
              <input matEndDate formControlName="endDate" placeholder="End date" readonly />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="rangePicker" disabled="false"></mat-datepicker-toggle>
            <mat-date-range-picker #rangePicker disabled="false"> </mat-date-range-picker>
            <mat-error *ngIf="startDate.invalid || endDate.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </form>
      <div *ngIf="reportData" class="report-table">
        <aca-reports-table [reportType]="reportType" [reportData]="reportData"></aca-reports-table>
      </div>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
