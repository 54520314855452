<form class="adf-upload-button-file-container">
    <!--Files Upload-->
    <div *ngIf="!uploadFolders">

        <!--Single Files Upload-->
        <button *ngIf="!multipleFiles"
            [disabled]="isButtonDisabled()"
            mat-raised-button
            (click)="uploadSingleFile.click()">
            <mat-icon>file_upload</mat-icon>
            <span id="upload-single-file-label"
                *ngIf="!staticTitle">{{ 'FILE_UPLOAD.BUTTON.UPLOAD_FILE' | translate }}</span>
            <span id="upload-single-file-label-static"
                *ngIf="staticTitle">{{ staticTitle }}</span>
            <input #uploadSingleFile
                id="upload-single-file"
                data-automation-id="upload-single-file"
                [type]="file ? 'button' : 'file'"
                name="uploadFiles"
                accept="{{acceptedFilesType}}"
                [attr.disabled]="isButtonDisabled()"
                [title]="tooltip"
                (change)="onFilesAdded($event)"
                (click)="onClickUploadButton()">
        </button>

        <!--Multiple Files Upload-->
        <button *ngIf="multipleFiles"
            [disabled]="isButtonDisabled()"
            mat-raised-button
            (click)="uploadMultipleFiles.click()">
            <mat-icon>file_upload</mat-icon>

            <span id="upload-multiple-file-label"
                *ngIf="!staticTitle">{{ 'FILE_UPLOAD.BUTTON.UPLOAD_FILE' | translate }}</span>
            <span
                id="upload-multiple-file-label-static"
                *ngIf="staticTitle">{{ staticTitle }}</span>
            <input #uploadMultipleFiles
                id="upload-multiple-files"
                data-automation-id="upload-multiple-files"
                type="file"
                name="uploadFiles"
                multiple="multiple"
                accept="{{acceptedFilesType}}"
                [attr.disabled]="isButtonDisabled()"
                [title]="tooltip"
                (change)="onFilesAdded($event)">
        </button>
    </div>

    <!--Folders Upload-->
    <div *ngIf="uploadFolders">
        <button
            [disabled]="isButtonDisabled()"
            mat-raised-button
            (click)="uploadFolders.click()">
            <mat-icon>file_upload</mat-icon>
            <span id="uploadFolder-label"
                *ngIf="!staticTitle">{{ 'FILE_UPLOAD.BUTTON.UPLOAD_FOLDER' | translate }}</span>
            <span id="uploadFolder-label-static"
                *ngIf="staticTitle">{{ staticTitle }}</span>
            <input #uploadFolders
                id="uploadFolder"
                data-automation-id="uploadFolder"
                type="file"
                name="uploadFiles"
                multiple="multiple"
                accept="{{acceptedFilesType}}"
                webkitdirectory directory
                [attr.disabled]="isButtonDisabled()"
                [title]="tooltip"
                (change)="onDirectoryAdded($event)">
        </button>
    </div>
</form>
