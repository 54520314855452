import { CustomApiCallService, HTTPMethod, PayloadType, ResponseType } from './custom-api-call.service';
import { Injectable } from '@angular/core';
import { NodeChildAssociationPaging, Node, NodesApi } from '@alfresco/js-api';
import { AlfrescoApiService } from '@alfresco/adf-core';

export enum Workflow {
  GROUP_APPROVE_REJECT_DOC = 'groupApproveRejectDoc',
  GROUP_RECEIVE_DOC = 'groupReceiveDoc',
  MULTIPLE_LEVEL_APPROVE_DOC = 'multipleLevelApproveDoc',
  MULTIPLE_REVIEW_ARCH_DOC = 'multipleReviewArchDoc',
}

export enum WorkflowPriority {
  HIGH = '1',
  MEDIUM = '2',
  LOW = '3',
}

export type TaskPriority = WorkflowPriority

function formatDueDate(date: Date) {
  if (!date)
    return '';
  let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;
  return [year, month, day].join('-');
}

function formatISODateTime(date: Date): string {
  return date.toISOString();
}

export interface StartWorkflowPayload {
  type(): Workflow;
  payload(customApiCallService: CustomApiCallService): Promise<object>;
}

export class StartGARDPayload implements StartWorkflowPayload {
  private _payload = {
    assoc_bpm_groupAssignee_added: '',  // cm:authorityContainer (group) node ref
    assoc_bpm_groupAssignee_removed: '',
    prop_wf_requiredApprovePercent: '', // percentage as string
    assoc_packageItems_added: '', // csv list of document node refs
    assoc_packageItems_removed: '',
    prop_bpm_workflowDescription: '',
    prop_bpm_workflowDueDate: '', // YYYY-MM-DD
    prop_bpm_workflowPriority: '',  // WorkflowPriority
    prop_bpm_sendEMailNotifications: '',  // boolean as string
  };

  constructor(
    group: string,  // group name
    approvePercent: number,
    documents: string[], // list of node refs
    description: string,
    due: Date,
    priority: WorkflowPriority,
    sendEmailNotifications: boolean,
  ) {
    this._payload.assoc_bpm_groupAssignee_added = `GROUP_${group}`;
    this._payload.prop_wf_requiredApprovePercent = approvePercent.toString();
    this._payload.assoc_packageItems_added = documents.join(',');
    this._payload.prop_bpm_workflowDescription = description;
    this._payload.prop_bpm_workflowDueDate = formatDueDate(due);
    this._payload.prop_bpm_workflowPriority = priority;
    this._payload.prop_bpm_sendEMailNotifications = sendEmailNotifications.toString();
  }

  public type(): Workflow { return Workflow.GROUP_APPROVE_REJECT_DOC; }

  private async fixPayload(customApiCallService: CustomApiCallService): Promise<void> {
    const payload = {
      group: this._payload.assoc_bpm_groupAssignee_added,
    }
    try {
      const dataObj = await customApiCallService.callServiceApi(`neoworkflow/resolve-start-workflow-data`, HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
      const { group } = dataObj;
      
      // fix group
      this._payload.assoc_bpm_groupAssignee_added = group;
    } catch (err) {
      console.log('error in fixPayload: ', err);
    }
  }

  public async payload(customApiCallService: CustomApiCallService): Promise<object> {
    await this.fixPayload(customApiCallService);
    return this._payload;
  }
}

export class StartGRDPayload implements StartWorkflowPayload {
  private _payload = {
    assoc_bpm_groupAssignees_added: '', // cm:authorityContainer (group) node ref
    assoc_bpm_groupAssignees_removed: '',
    assoc_packageItems_added: '', // csv list of document node refs
    assoc_packageItems_removed: '',
    prop_bpm_workflowDescription: '',
    prop_bpm_workflowDueDate: '', // YYYY-MM-DD
    prop_bpm_workflowPriority: '',  // WorkflowPriority
    prop_bpm_sendEMailNotifications: '',  // boolean as string
    prop_ncwf_moreDescription: '',
  };

  constructor(
    group: string,  // group name
    documents: string[], // list of node refs
    description: string,
    due: Date,
    priority: WorkflowPriority,
    sendEmailNotifications: boolean,
    moreDescription: string = '',
  ) {
    this._payload.assoc_bpm_groupAssignees_added = `GROUP_${group}`;
    this._payload.assoc_packageItems_added = documents.join(',');
    this._payload.prop_bpm_workflowDescription = description;
    this._payload.prop_bpm_workflowDueDate = formatDueDate(due);
    this._payload.prop_bpm_workflowPriority = priority;
    this._payload.prop_bpm_sendEMailNotifications = sendEmailNotifications.toString();
    this._payload.prop_ncwf_moreDescription = moreDescription;
  }

  public type(): Workflow { return Workflow.GROUP_RECEIVE_DOC; }

  private async fixPayload(customApiCallService: CustomApiCallService): Promise<void> {
    const payload = {
      group: this._payload.assoc_bpm_groupAssignees_added,
    }
    try {
      const dataObj = await customApiCallService.callServiceApi(`neoworkflow/resolve-start-workflow-data`, HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
      const { group } = dataObj;
      
      // fix group
      this._payload.assoc_bpm_groupAssignees_added = group;
    } catch (err) {
      console.log('error in fixPayload: ', err);
    }
  }

  public async payload(customApiCallService: CustomApiCallService): Promise<object> {
    await this.fixPayload(customApiCallService);
    return this._payload;
  }
}

export class StartMLADPayload implements StartWorkflowPayload {
  private _payload = {
    assoc_bpm_assignees_added: '',  // csv list of cm:person node refs (same holds for all levels)
    assoc_bpm_assignees_removed: '',
    assoc_ncwf_secondLevelAssignees_added: '',
    assoc_ncwf_secondLevelAssignees_removed: '',
    assoc_ncwf_thirdLevelAssignees_added: '',
    assoc_ncwf_thirdLevelAssignees_removed: '',
    assoc_ncwf_fourthLevelAssignees_added: '',
    assoc_ncwf_fourthLevelAssignees_removed: '',
    assoc_ncwf_fifthLevelAssignees_added: '',
    assoc_ncwf_fifthLevelAssignees_removed: '',
    prop_ncwf_requiredApprovePercent1: '',  // percentage as string
    prop_ncwf_requiredApprovePercent2: '',
    prop_ncwf_requiredApprovePercent3: '',
    prop_ncwf_requiredApprovePercent4: '',
    prop_ncwf_requiredApprovePercent5: '',
    assoc_ncwf_group_added: '', // csv list of cm:authorityContainer (group) node refs
    assoc_ncwf_group_removed: '',
    assoc_packageItems_added: '', // csv list of document node refs
    assoc_packageItems_removed: '',
    prop_bpm_workflowDescription: '',
    prop_bpm_workflowDueDate: '', // YYYY-MM-DD
    prop_bpm_workflowPriority: '',  // WorkflowPriority
    prop_bpm_sendEMailNotifications: '',  // boolean as string
    prop_ncwf_moreDescription: '',
  }

  constructor(
    firstLevelAssignees: string[],  // list of usernames
    firstLevelApprovePercent: number,
    secondLevelAssignees: string[],
    secondLevelApprovePercent: number,
    thirdLevelAssignees: string[],
    thirdLevelApprovePercent: number,
    fourthLevelAssignees: string[],
    fourthLevelApprovePercent: number,
    fifthLevelAssignees: string[],
    fifthLevelApprovePercent: number,
    group: string,  // group name
    documents: string[], // list of node refs
    description: string,
    due: Date,
    priority: WorkflowPriority,
    sendEmailNotifications: boolean,
    moreDescription: string = '',
  ) {
    this._payload.assoc_bpm_assignees_added = firstLevelAssignees.join(',');
    this._payload.prop_ncwf_requiredApprovePercent1 = firstLevelApprovePercent.toString();
    this._payload.assoc_ncwf_secondLevelAssignees_added = secondLevelAssignees.join(',');
    this._payload.prop_ncwf_requiredApprovePercent2 = secondLevelApprovePercent.toString();
    this._payload.assoc_ncwf_thirdLevelAssignees_added = thirdLevelAssignees.join(',');
    this._payload.prop_ncwf_requiredApprovePercent3 = thirdLevelApprovePercent.toString();
    this._payload.assoc_ncwf_fourthLevelAssignees_added = fourthLevelAssignees.join(',');
    this._payload.prop_ncwf_requiredApprovePercent4 = fourthLevelApprovePercent.toString();
    this._payload.assoc_ncwf_fifthLevelAssignees_added = fifthLevelAssignees.join(',');
    this._payload.prop_ncwf_requiredApprovePercent5 = fifthLevelApprovePercent.toString();
    this._payload.assoc_ncwf_group_added = group ? `GROUP_${group}` : '';
    this._payload.assoc_packageItems_added = documents.join(',');
    this._payload.prop_bpm_workflowDescription = description;
    this._payload.prop_bpm_workflowDueDate = formatDueDate(due);
    this._payload.prop_bpm_workflowPriority = priority;
    this._payload.prop_bpm_sendEMailNotifications = sendEmailNotifications.toString();
    this._payload.prop_ncwf_moreDescription = moreDescription;
  }

  private async fixPayload(customApiCallService: CustomApiCallService): Promise<void> {
    const assignees = [
      ...this._payload.assoc_bpm_assignees_added.split(','),
      ...this._payload.assoc_ncwf_secondLevelAssignees_added.split(','),
      ...this._payload.assoc_ncwf_thirdLevelAssignees_added.split(','),
      ...this._payload.assoc_ncwf_fourthLevelAssignees_added.split(','),
      ...this._payload.assoc_ncwf_fifthLevelAssignees_added.split(','),
    ]
    const payload = {
      users: assignees,
      group: this._payload.assoc_ncwf_group_added,
    }
    try {
      const dataObj = await customApiCallService.callServiceApi(`neoworkflow/resolve-start-workflow-data`, HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
      const { users, group } = dataObj;
      
      // fix assignees
      this._payload.assoc_bpm_assignees_added = this._payload.assoc_bpm_assignees_added.split(',').map(el => users[el]).join(',');
      this._payload.assoc_ncwf_secondLevelAssignees_added = this._payload.assoc_ncwf_secondLevelAssignees_added.split(',').map(el => users[el]).join(',');
      this._payload.assoc_ncwf_thirdLevelAssignees_added = this._payload.assoc_ncwf_thirdLevelAssignees_added.split(',').map(el => users[el]).join(',');
      this._payload.assoc_ncwf_fourthLevelAssignees_added = this._payload.assoc_ncwf_fourthLevelAssignees_added.split(',').map(el => users[el]).join(',');
      this._payload.assoc_ncwf_fifthLevelAssignees_added = this._payload.assoc_ncwf_fifthLevelAssignees_added.split(',').map(el => users[el]).join(',');

      // fix group
      this._payload.assoc_ncwf_group_added = group;
    } catch (err) {
      console.log('error in fixPayload: ', err);
    }
  }

  public async payload(customApiCallService: CustomApiCallService): Promise<object> {
    await this.fixPayload(customApiCallService);
    return this._payload;
  }

  public type(): Workflow { return Workflow.MULTIPLE_LEVEL_APPROVE_DOC; }
}

export class StartMRADPayload implements StartWorkflowPayload {
  private _payload = {
    assoc_bpm_assignees_added: '',  // csv list of cm:person node refs
    assoc_bpm_assignees_removed: '',
    assoc_packageItems_added: '', // csv list of document node refs
    assoc_packageItems_removed: '',
    prop_bpm_workflowDescription: '',
    prop_bpm_workflowDueDate: '', // YYYY-MM-DD
    prop_bpm_workflowPriority: '',  // WorkflowPriority
    prop_bpm_sendEMailNotifications: '',  // boolean as string
    prop_ncwf_moreDescription: '',
  };

  constructor(
    assignees: string[],  // list of usernames
    documents: string[], // list of node refs
    description: string,
    due: Date,
    priority: WorkflowPriority,
    sendEmailNotifications: boolean,
    moreDescription: string = '',
  ) {
    this._payload.assoc_bpm_assignees_added = assignees.join(',');
    this._payload.assoc_packageItems_added = documents.join(',');
    this._payload.prop_bpm_workflowDescription = description;
    this._payload.prop_bpm_workflowDueDate = formatDueDate(due);
    this._payload.prop_bpm_workflowPriority = priority;
    this._payload.prop_bpm_sendEMailNotifications = sendEmailNotifications.toString();
    this._payload.prop_ncwf_moreDescription = moreDescription;
  }

  public type(): Workflow { return Workflow.MULTIPLE_REVIEW_ARCH_DOC; }

  private async fixPayload(customApiCallService: CustomApiCallService): Promise<void> {
    const assignees = this._payload.assoc_bpm_assignees_added.split(',');
    const payload = {
      users: assignees,
    }

    try {
      const dataObj = await customApiCallService.callServiceApi(`neoworkflow/resolve-start-workflow-data`, HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
      const { users } = dataObj;
      
      // fix assignees
      this._payload.assoc_bpm_assignees_added = this._payload.assoc_bpm_assignees_added.split(',').map(el => users[el]).join(',');
    } catch (err) {
      console.log('error in fixPayload: ', err);
    }
  }

  public async payload(customApiCallService: CustomApiCallService): Promise<object> {
    await this.fixPayload(customApiCallService);
    return this._payload;
  }
}

export enum ListTaskSort {
  DATE = 'date',
  PRIORITY = 'priority',
}

export enum ListWorkflowSort {
  DATE = 'date',
  PRIORITY = 'priority',
}

export enum SortDir {
  ASC = 'asc',
  DESC = 'desc',
}

export interface EntityListFilter {
  getQueryString(): string;
}

export interface TaskListFilter extends EntityListFilter {}

export class TaskListFilterDescription implements TaskListFilter {

  private _value: string;

  constructor(value: string) {
    this._value = value;
  }

  public getQueryString(): string {
    return `description=${this._value}`;
  }
}

export class TaskListFilterPriority implements TaskListFilter {

  private _value: TaskPriority;

  constructor(value: TaskPriority) {
    this._value = value;
  }

  public getQueryString(): string {
    return `priority=${this._value}`;
  }
}

export class TaskListFilterDateStarted implements TaskListFilter {

  private _after: Date;
  private _before: Date;

  constructor(after: Date | null, before: Date | null) {
    this._after = after;
    this._before = before;
  }

  public getQueryString(): string {
    if (this._after && this._before) {
      return `startedAfter=${formatISODateTime(this._after)}&startedBefore=${formatISODateTime(this._before)}`;
    } else if (this._after) {
      return `startedAfter=${formatISODateTime(this._after)}`;
    } else if (this._before) {
      return `startedBefore=${formatISODateTime(this._before)}`;
    } else {
      return null;
    }
  }
}

export class TaskListFilterInitiator implements TaskListFilter {

  private _value: string;

  constructor(value: string) {
    this._value = value;
  }

  public getQueryString(): string {
    return `initiator=${this._value}`;
  }
}

export interface WorkflowListFilter extends EntityListFilter {}

export class WorkflowListFilterDescription implements WorkflowListFilter {

  private _value: string;

  constructor(value: string) {
    this._value = value;
  }

  public getQueryString(): string {
    return `description=${this._value}`;
  }
}

export class WorkflowListFilterPriority implements WorkflowListFilter {

  private _value: TaskPriority;

  constructor(value: TaskPriority) {
    this._value = value;
  }

  public getQueryString(): string {
    return `priority=${this._value}`;
  }
}

export class WorkflowListFilterDateStarted implements WorkflowListFilter {

  private _after: Date;
  private _before: Date;

  constructor(after: Date, before: Date) {
    this._after = after;
    this._before = before;
  }

  public getQueryString(): string {
    if (this._after && this._before) {
      return `startedAfter=${formatISODateTime(this._after)}&startedBefore=${formatISODateTime(this._before)}`;
    } else if (this._after) {
      return `startedAfter=${formatISODateTime(this._after)}`;
    } else if (this._before) {
      return `startedBefore=${formatISODateTime(this._before)}`;
    } else {
      return null;
    }
  }
}

export enum TaskStatus {
  NOT_YET_STARTED = 'Not Yet Started',
  IN_PROGRESS = 'In Progress',
  ON_HOLD = 'On Hold',
  CANCELLED = 'Cancelled',
  COMPLETED = 'Completed',
}

export enum TaskTransition {
  TASK_DONE = '<special_case>',
  APPROVE_DOCUMENT = 'Approve_Document',
  REJECT_DOCUMENT = 'Reject_Document',
  APPROVE = 'Approve',
  REJECT = 'Reject',
}

export const TaskTransitionPropMap = {
  [TaskTransition.TASK_DONE]: null,
  [TaskTransition.APPROVE_DOCUMENT]: 'prop_ncwf_reviewOutcome',
  [TaskTransition.REJECT_DOCUMENT]: 'prop_ncwf_reviewOutcome',
  [TaskTransition.APPROVE]: 'prop_wf_reviewOutcome',
  [TaskTransition.REJECT]: 'prop_wf_reviewOutcome',
}

const TaskTypeTransitionMap = {
  'wf:activitiReviewTask': [ TaskTransition.APPROVE, TaskTransition.REJECT, ],
  'wf:approvedParallelTask': [ TaskTransition.TASK_DONE, ],
  'wf:rejectedParallelTask': [ TaskTransition.TASK_DONE, ],
  'ncwf:reviewGroupAdhocTask': [ TaskTransition.TASK_DONE, ],
  'ncwf:endGroupAdhocTask': [ TaskTransition.TASK_DONE, ],
  'ncwf:multipleLevelReviewTask': [ TaskTransition.APPROVE_DOCUMENT, TaskTransition.REJECT_DOCUMENT, ],
  'ncwf:multipleLevelApprovedTask': [ TaskTransition.TASK_DONE, ],
  'ncwf:multipleLevelRejectedTask': [ TaskTransition.TASK_DONE, ],
  'ncwf:multipleLevelGroupAdhocTask': [ TaskTransition.TASK_DONE, ],
  'ncwf:multipleAdhocTask': [ TaskTransition.TASK_DONE, ],
  'ncwf:completedMultipleAdhocTask': [ TaskTransition.TASK_DONE, ],
};

export const getAvailableTransitionsForTaskType = (taskType) => TaskTypeTransitionMap[taskType];

export const canClaimTask = (taskType, username, currentOwner) => {
  // not owned group task can be claimed
  return (taskType === 'ncwf:reviewGroupAdhocTask' || taskType === 'ncwf:multipleLevelGroupAdhocTask') && username !== currentOwner;
}

export const canReleaseTask = (taskType, username, currentOwner) => {
  // owned group task can be reassigned
  return (taskType === 'ncwf:reviewGroupAdhocTask' || taskType === 'ncwf:multipleLevelGroupAdhocTask') && username === currentOwner;
}

export const canReassignTask = (taskType) => {
  // group tasks cannot be reassigned
  return taskType !== 'ncwf:reviewGroupAdhocTask' && taskType !== 'ncwf:multipleLevelGroupAdhocTask';
}

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  private nodesApi: NodesApi;

  constructor(
    alfrescoApiService: AlfrescoApiService,
    private customApiCallService: CustomApiCallService,
  ) {
    this.nodesApi = new NodesApi(alfrescoApiService.getInstance());
  }

  async startWorkflow(payload: StartWorkflowPayload): Promise<void> {
    const finalPayload = await payload.payload(this.customApiCallService);
    return new Promise((resolve, reject) => {
      this.customApiCallService
        .callServiceApi(`workflow/activiti%24${payload.type()}/formprocessor`, HTTPMethod.POST, finalPayload, PayloadType.JSON, null, ResponseType.JSON)
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  }

  async listTasks(
    username: string,
    active: boolean,
    filters: TaskListFilter[],
    sort: ListTaskSort,
    sortDir: SortDir,
    skipCount: number,
    maxItems: number
  ): Promise<any> {
    let baseUrl = `neocom-tsd/task-instances?properties=bpm_priority,bpm_status,bpm_dueDate,bpm_description,ncwf_viewApprovers,ncwf_viewRejectors,ncwf_siteOriginated,ncwf_reviewersComments&authority=${username}&sort=${sort}&dir=${sortDir}&skipCount=${skipCount}&maxItems=${maxItems}${active ? '' : '&state=COMPLETED&exclude=wcmwf:*&pooledTasks=false'}`;
    if (filters.length > 0) {
      const filterQueryPart = filters.map(filter => filter.getQueryString()).join('&');
      baseUrl += `&${filterQueryPart}`;
    }

    return new Promise((resolve, reject) => {
      this.customApiCallService
        .callServiceApi(baseUrl, HTTPMethod.GET, null, null, null, ResponseType.JSON)
        .then((value) => resolve(value))
        .catch((err) => reject(err));
    });
  }

  async listWorkflows(
    username: string,
    active: boolean,
    filters: WorkflowListFilter[],
    sort: ListWorkflowSort,
    sortDir: SortDir,
    skipCount: number,
    maxItems: number
  ): Promise<any> {
    let baseUrl = `neocom-tsd/workflow-instances?initiator=${username}&exclude=jbpm$wcmwf:*,jbpm$wf:articleapproval,activiti$publishWebContent,jbpm$publishWebContent,jbpm$inwf:invitation-nominated,jbpm$imwf:invitation-moderated,activiti$activitiInvitationModerated,activiti$activitiInvitationNominated${active ? '' : '&pooledTasks=false&state=COMPLETED'}&sort=${sort}&dir=${sortDir}&skipCount=${skipCount}&maxItems=${maxItems}`;
    if (filters.length > 0) {
      const filterQueryPart = filters.map(filter => filter.getQueryString()).join('&');
      baseUrl += `&${filterQueryPart}`;
    }

    return new Promise((resolve, reject) => {
      this.customApiCallService
        .callServiceApi(baseUrl, HTTPMethod.GET, null, null, null, ResponseType.JSON)
        .then((value) => resolve(value))
        .catch((err) => reject(err));
    });
  }

  async getTaskDetails(taskId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.customApiCallService
        .callServiceApi(`task-instances/${taskId}?detailed=true`, HTTPMethod.GET, null, null, null, ResponseType.JSON)
        .then((value) => resolve(value.data ? value.data : null))
        .catch((err) => reject(err));
    });
  }

  async getWorkflowDetails(workflowId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.customApiCallService
        .callServiceApi(`workflow-instances/${workflowId}?includeTasks=true`, HTTPMethod.GET, null, null, null, ResponseType.JSON)
        .then((value) => resolve(value.data ? value.data : null))
        .catch((err) => reject(err));
    });
  }

  async getPackageContents(nodeRef: string): Promise<Node[]> {
    const nodeId = nodeRef.substring('workspace://SpacesStore/'.length);
    return new Promise((resolve, reject) => {
      this.nodesApi.listNodeChildren(nodeId)
        .then((data: NodeChildAssociationPaging) => {
          if (data && data.list && data.list.entries) {
            const resolvedData = data.list.entries.map(el => el.entry as Node);
            resolve(resolvedData);
          } else {
            reject(new Error('internal error'));
          }
        })
        .catch((err) => reject(err));
    });
  }

  async resolveWorkflowData(data: string[]): Promise<object[]> {
    const payload = {
      itemValueType: 'nodeRef',
      items: data,
    };
    return new Promise((resolve, reject) => {
      this.customApiCallService.callServiceApi(`forms/picker/items`, HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON)
        .then((data) => {
          if (data && data.data && data.data.items) {
            resolve(data.data.items as object[]);
          } else {
            reject(new Error('internal error'));
          }
        })
        .catch((err) => reject(err));
    });
  }

  async editTask(
    taskId: string,
    documentsAdded: string[],
    documentsRemoved: string[],
    comment: string,
    status: TaskStatus,
    transition: TaskTransition = null,
  ): Promise<void> {
    const payload: object = {
      assoc_packageItems_added: documentsAdded.join(','),
      assoc_packageItems_removed: documentsRemoved.join(','),
      prop_bpm_comment: comment,
      prop_bpm_status: status,
    };
    if (transition) {
      if (transition !== TaskTransition.TASK_DONE) {
        const taskProp = TaskTransitionPropMap[transition];
        payload[taskProp] = transition;
      }
      payload['prop_transitions'] = 'Next';
    }
    return new Promise((resolve, reject) => {
      this.customApiCallService
        .callServiceApi(`task/${taskId}/formprocessor`, HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON)
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  }

  async claimTask(
    taskId: string,
    username: string, // of new owner
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const payload = {
        cm_owner: username,
      };
      this.customApiCallService
        .callServiceApi(`task-instances/${taskId}`, HTTPMethod.PUT, payload, PayloadType.JSON, null, ResponseType.JSON)
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  }

  async releaseTask(
    taskId: string,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const payload = {
        cm_owner: null,
      };
      this.customApiCallService
        .callServiceApi(`task-instances/${taskId}`, HTTPMethod.PUT, payload, PayloadType.JSON, null, ResponseType.JSON)
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  }

  async reassignTask(
    taskId: string,
    username: string,  // of new owner
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const payload = {
        cm_owner: username,
      };
      this.customApiCallService
        .callServiceApi(`task-instances/${taskId}`, HTTPMethod.PUT, payload, PayloadType.JSON, null, ResponseType.JSON)
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  }

  async cancelWorkflow(
    workflowId: string,
    forced: boolean
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.customApiCallService
        .callServiceApi(`workflow-instances/${workflowId}?forced=${forced ? 'true' : 'false'}`, HTTPMethod.DELETE, null, null, null, ResponseType.JSON)
        .then((_) => resolve())
        .catch((err) => reject(err));
    });
  }

  async getDocumentWorkflows(nodeRef: string): Promise<object[]> {
    return new Promise((resolve, reject) => {
      this.customApiCallService
        .callServiceApi(`mk/neocom/get-all-workflows?nodeRef=${nodeRef}`, HTTPMethod.GET, null, null, null, ResponseType.JSON)
        .then((data) => resolve(data && data.items ? data.items : []))
        .catch((err) => reject(err));
    });
  }
}
