<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="APP.BROWSE.ARCHIVE.MENU.NEW_INVENTORY_OF_ACTS.TITLE"> </adf-breadcrumb>
  </aca-page-layout-header>

  <aca-page-layout-content>
    <div class="ioa-main-content">
      <form [formGroup]="inventoryOfActsForm" (ngSubmit)="createInventoryOfActs()" novalidate>
        <div class="form-column">
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.REGISTRY_BOOK' | translate }}</mat-label>
            <mat-select formControlName="selectedRegistry" required>
              <mat-option *ngFor="let registry of registries" [value]="registry.nodeRef">{{ registry.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="selectedRegistry.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard" required>
            <mat-label>{{ 'APP.ARCHIVE.ORGANIZATIONAL_UNIT' | translate }}</mat-label>
            <mat-select formControlName="selectedOrganizationalUnit" required>
              <mat-option *ngFor="let unit of organizationalUnits" [value]="unit.nodeRef">{{ unit.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="selectedOrganizationalUnit.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.ARCHIVE_SIGN' | translate }}</mat-label>
            <mat-select formControlName="selectedArchiveSign" required>
              <mat-option *ngFor="let sign of archiveSigns" [value]="sign.nodeRef">{{ sign.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="selectedArchiveSign.invalid">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="form-column">
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.INDEX' | translate }}</mat-label>
            <input matInput formControlName="subjectIndex" name="index" type="number" inputmode="numeric" min="1" required />
            <span matSuffix>{{ selectedRegistryCounter }}</span>
            <mat-error *ngIf="subjectIndex.errors?.min">{{ 'APP.ARCHIVE.ERROR.NEGATIVE_VALUE' | translate }}</mat-error>
            <mat-error *ngIf="subjectIndex.errors?.invalidIndex">{{ 'APP.ARCHIVE.ERROR.INDEX_EXISTS' | translate }}</mat-error>
            <mat-error *ngIf="subjectIndex.errors?.lowerValue">{{ 'APP.ARCHIVE.ERROR.LOWER_INDEX' | translate }}</mat-error>
            <mat-error *ngIf="subjectIndex.errors?.required">{{ 'APP.ARCHIVE.ERROR.REQUIRED' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE.TITLE' | translate }}</mat-label>
            <input matInput formControlName="title" name="title" type="text" />
          </mat-form-field>
        </div>
        <div class="form-footer">
          <button mat-raised-button type="submit">{{ 'APP.ARCHIVE.CREATE' | translate }}</button>
        </div>
      </form>
      <div *ngIf="inventoriesOfActs.length" class="table">
        <adf-datatable [rows]="inventoriesOfActs" [columns]="actsSchema" [actions]="false"> </adf-datatable>
      </div>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
