import { ArchiveService, PageLayoutComponent, PageLayoutContentComponent, PageLayoutHeaderComponent } from '@alfresco/aca-shared';
import { TranslationService } from '@alfresco/adf-core';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from '@alfresco/adf-content-services';
import { ReportsTableComponent } from './reports-table/reports-table.component';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatButtonModule,
    PageLayoutComponent,
    PageLayoutHeaderComponent,
    PageLayoutContentComponent,
    BreadcrumbModule,
    ReportsTableComponent,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject<boolean>();

  cache: Map<string, any[]> = new Map();

  registries: any[] = [];
  inventoriesOfActs: any[] = [];
  reportData: any;
  reportType: string;

  reportTypes = [
    {
      name: this.translation.instant('APP.ARCHIVE.REPORTS.REGISTRY_BOOK_REPORT'),
      value: 'registry_book'
    },
    {
      name: this.translation.instant('APP.ARCHIVE.REPORTS.INVENTORY_OF_ACTS_REPORT'),
      value: 'ioa_type'
    }
  ];
  registryBookFilters = [
    {
      name: this.translation.instant('APP.ARCHIVE.REPORTS.SHOW_ALL'),
      value: 'all'
    },
    {
      name: this.translation.instant('APP.ARCHIVE.INDEX'),
      value: 'index'
    },
    {
      name: this.translation.instant('APP.ARCHIVE.ARCHIVE_SIGN'),
      value: 'sign'
    },
    {
      name: this.translation.instant('APP.ARCHIVE.SUBJECT_INFO.DATE_ARCHIVED'),
      value: 'date'
    },
    {
      name: this.translation.instant('APP.ARCHIVE.REPORTS.SHOW_EXPIRED'),
      value: 'expired'
    }
  ];
  inventoryOfActsFilters = [
    {
      name: this.translation.instant('APP.ARCHIVE.REPORTS.SHOW_ALL'),
      value: 'all'
    },
    {
      name: this.translation.instant('APP.ARCHIVE.SEQUENTIAL_INDEX'),
      value: 'seqIndex'
    },
    {
      name: this.translation.instant('APP.ARCHIVE.SUBJECT_INFO.DATE_ARCHIVED'),
      value: 'date'
    },
    {
      name: this.translation.instant('APP.ARCHIVE.DATE_SENT'),
      value: 'sentDate'
    }
  ];

  reportForm = new FormGroup({
    selectedReportType: new FormControl(this.reportTypes[0].value, Validators.required),
    selectedRegistry: new FormControl(undefined, Validators.required),
    selectedInventoryOfActs: new FormControl('', this.requiredIf('selectedInventoryOfActs')),
    selectedFilter: new FormControl(this.registryBookFilters[0].value, this.requiredIf('selectedFilter')),
    archiveSign: new FormControl('', this.requiredIf('archiveSign')),
    indexFrom: new FormControl('', [this.requiredIf('indexFrom'), Validators.pattern('[0-9]+')]),
    indexTo: new FormControl('', [this.requiredIf('indexTo'), Validators.pattern('[0-9]+'), this.lowerThanFrom()]),
    startDate: new FormControl('', this.requiredIf('startDate')),
    endDate: new FormControl('', this.requiredIf('endDate'))
  });

  constructor(private archiveService: ArchiveService, private translation: TranslationService) {}

  get selectedReportType() {
    return this.reportForm?.get('selectedReportType');
  }

  get selectedRegistry() {
    return this.reportForm?.get('selectedRegistry');
  }

  get selectedInventoryOfActs() {
    return this.reportForm?.get('selectedInventoryOfActs');
  }

  get selectedFilter() {
    return this.reportForm?.get('selectedFilter');
  }

  get archiveSign() {
    return this.reportForm?.get('archiveSign');
  }

  get indexFrom() {
    return this.reportForm?.get('indexFrom');
  }

  get indexTo() {
    return this.reportForm?.get('indexTo');
  }

  get startDate() {
    return this.reportForm?.get('startDate');
  }

  get endDate() {
    return this.reportForm?.get('endDate');
  }

  get filters() {
    if (!this.selectedReportType)
      return [];
    return this.selectedReportType.value === this.reportTypes[0].value ? this.registryBookFilters : this.inventoryOfActsFilters;
  }

  async ngOnInit(): Promise<void> {   
    this.registries = await this.archiveService.getRegistryBooks();
    this.selectedReportType.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.selectedFilter.setValue(this.filters[0].value));
    this.selectedRegistry.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => value && this.updateInventoryOfActs());  
  }

  private async updateInventoryOfActs(): Promise<void> {
    this.selectedInventoryOfActs.setValue(undefined, { emitEvent: false });

    const nodeRef = this.selectedRegistry.value;

    if (this.cache.has(nodeRef)) {
      this.inventoriesOfActs = this.cache.get(nodeRef);
    } else {
      this.inventoriesOfActs = await this.archiveService.getInventoryOfActs(nodeRef);
      this.cache.set(nodeRef, this.inventoriesOfActs);
    }
  }

  onReportTypeChanged() {
    // reset filter values
    this.reportForm.get('archiveSign').reset();
    this.reportForm.get('indexFrom').reset();
    this.reportForm.get('indexTo').reset();
    this.reportForm.get('startDate').reset();
    this.reportForm.get('endDate').reset();
  }

  async generateReport(): Promise<void> {
    Object.values(this.reportForm.controls).forEach((control) => control.updateValueAndValidity({ emitEvent: false }));

    if (this.reportForm.invalid) return;

    const request = { ...this.reportForm.value };

    const parseDate = (text: string): Date => {
      return new Date(text);
    };

    if (request['selectedReportType'] === this.reportTypes[0].value) {
      const filterParams = [];
      if (request['selectedFilter'] === this.registryBookFilters[1].value) {
        filterParams.push.apply(filterParams, [request['indexFrom'], request['indexTo']]);
      } else if (request['selectedFilter'] === this.registryBookFilters[2].value) {
        filterParams.push(request['archiveSign']);
      } else if (request['selectedFilter'] === this.registryBookFilters[3].value) {
        filterParams.push.apply(filterParams, [parseDate(request['startDate']), parseDate(request['endDate'])]);
      }
      this.reportData = await this.archiveService.getRegistryBookReport(request['selectedRegistry'], request['selectedFilter'], filterParams);
      this.reportType = this.selectedReportType.value;
    } else {
      const filterParams = [];
      if (request['selectedFilter'] === this.inventoryOfActsFilters[1].value) {
        filterParams.push.apply(filterParams, [request['indexFrom'], request['indexTo']]);
      } else if (request['selectedFilter'] === this.inventoryOfActsFilters[2].value || request['selectedFilter'] === this.inventoryOfActsFilters[3].value) {
        filterParams.push.apply(filterParams, [parseDate(request['startDate']), parseDate(request['endDate'])]);
      }
      this.reportData = await this.archiveService.getInventoryOfActsReport(request['selectedRegistry'], request['selectedInventoryOfActs'], request['selectedFilter'], filterParams);
      this.reportType = this.selectedReportType.value;
    }
  }

  private requiredIf(property: string): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      const isRequired = ((prop: string) =>
        ({
          selectedInventoryOfActs: this.selectedReportType?.value === this.reportTypes[1].value,
          selectedFilter: this.selectedReportType?.value === this.reportTypes[0].value,
          archiveSign: this.selectedReportType?.value === this.reportTypes[0].value && this.selectedFilter?.value === this.filters[2].value,
          indexFrom: this.selectedReportType?.value === this.reportTypes[0].value && this.selectedFilter?.value === this.filters[1].value,
          indexTo: this.selectedReportType?.value === this.reportTypes[0].value && this.selectedFilter?.value === this.filters[1].value,
          startDate: this.selectedReportType?.value === this.reportTypes[0].value && this.selectedFilter?.value === this.filters[3].value,
          endDate: this.selectedReportType?.value === this.reportTypes[0].value && this.selectedFilter?.value === this.filters[3].value
        }[prop]))(property);
      return isRequired && !control.value ? { required: { value: false } } : null;
    };
  }

  private lowerThanFrom(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => (control.value && control.value <= this.indexFrom.value ? { lowerValue: true } : null);
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
