import { TranslationService } from '@alfresco/adf-core';
import { Component, OnInit } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
  ],
  selector: 'aca-delete-pending-document-dialog',
  templateUrl: './delete-pending-document.dialog.html',
  styleUrls: ['./delete-pending-document.dialog.scss']
})
export class DeletePendingDocumentDialogComponent implements OnInit {

  promptText: string;
  placeholderText: string;
  rejectText: string;
  confirmText: string;

  reason: string;

  constructor(
    public dialogRef: MatDialogRef<DeletePendingDocumentDialogComponent>,
    private translation: TranslationService,
  ) {
    this.promptText = this.translation.instant('APP.ARCHIVE.PENDING_DOCUMENTS.REMOVE_DOCUMENT.PROMPT');
    this.placeholderText = this.translation.instant('APP.ARCHIVE.PENDING_DOCUMENTS.REMOVE_DOCUMENT.REASON');
    this.confirmText = this.translation.instant('');
    this.rejectText = this.translation.instant('APP.DIALOGS.CONFIRM_REMOVE.NO_LABEL');
  }

  ngOnInit(): void
  {}
  
  onReject(): void {
    this.dialogRef.close(null);
  }

  onConfirm(): void {
    this.dialogRef.close({ reason: this.reason });
  }

}
