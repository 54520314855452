<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="APP.BROWSE.ARCHIVE.MENU.PENDING_DOCUMENTS.TITLE"> </adf-breadcrumb>
  </aca-page-layout-header>
  <aca-page-layout-content>
    <div class="pending-documents-main-content">
      <div class="table" *ngIf="!loading && !error && dataRows && dataRows.length > 0">
        <adf-datatable 
          [rows]="dataRows" 
          [columns]="pendingDocumentsSchema"
          [actions]="true"
          (showRowActionsMenu)="onShowRowActionsMenu($event)"
          (executeRowAction)="onExecuteRowAction($event)">
        </adf-datatable>
      </div>
      <div *ngIf="!loading && !error && (!dataRows || dataRows.length === 0)">
        {{ 'APP.ARCHIVE.PENDING_DOCUMENTS.NO_DOCUMENTS' | translate }}
      </div>
      <div *ngIf="!loading && error">
        {{ 'CORE.MESSAGES.ERRORS.GENERIC' | translate }}
      </div>
      <div *ngIf="loading">
        {{ 'APP.ARCHIVE.PENDING_DOCUMENTS.LOADING' | translate }}
      </div>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
