/*!
 * @license
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { PaginationModel } from '../../models/pagination.model';

export interface IdentityGroupModel {
    id?: string;
    name?: string;
    path?: string;
    realmRoles?: string[];
    clientRoles?: any;
    access?: any;
    attributes?: any;
    readonly?: boolean;
}

export interface IdentityGroupSearchParam {
    name?: string;
}

export interface IdentityGroupQueryResponse {

    entries: IdentityGroupModel[];
    pagination: PaginationModel;
}

export interface IdentityGroupQueryCloudRequestModel {
    first: number;
    max: number;
}

export interface IdentityGroupCountModel {
    count: number;
}
