<div class="adf-error-content">
    <p class="adf-error-content-code">
        {{ errorCodeTranslated }}
    </p>
    <div class="adf-error-content-shadow"></div>
    <p class="adf-error-content-title">
        {{ 'ERROR_CONTENT.' + errorCodeTranslated + '.TITLE' | translate }}
    </p>
    <p class="adf-error-content-description">
        {{ 'ERROR_CONTENT.' + errorCodeTranslated + '.DESCRIPTION' | translate }}
    </p>
    <ng-content select="[adf-error-content-actions]"></ng-content>
</div>
