<div class="adf-sidebar-action-menu">
    <button *ngIf="isExpanded()" mat-raised-button class="adf-sidebar-action-menu-button" data-automation-id="create-button" [matMenuTriggerFor]="adfSidebarMenu">
        <span *ngIf="title" class="adf-sidebar-action-menu-text">{{ title }}</span>
        <ng-content select="[adf-sidebar-menu-title-icon], [sidebar-menu-title-icon]"></ng-content>
    </button>

    <div *ngIf="!isExpanded()" class="adf-sidebar-action-menu-icon" [matMenuTriggerFor]="adfSidebarMenu">
        <ng-content select="[adf-sidebar-menu-expand-icon], [sidebar-menu-expand-icon]"></ng-content>
    </div>

    <mat-menu #adfSidebarMenu="matMenu" class="adf-sidebar-action-menu-panel" [overlapTrigger]="false" yPosition="below">
        <div class="adf-sidebar-action-menu-options" [style.width.px]="width">
            <ng-content select="[adf-sidebar-menu-options], [sidebar-menu-options]"></ng-content>
        </div>
    </mat-menu>
</div>
