/*!
 * @license
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { FileModel } from '../../common/models/file.model';
import { UploadService } from '../../common/services/upload.service';
import { EventEmitter } from '@angular/core';

export class UploadFilesEvent {
    private isDefaultPrevented: boolean = false;

    get defaultPrevented() {
        return this.isDefaultPrevented;
    }

    preventDefault() {
        this.isDefaultPrevented = true;
    }

    constructor(
        public files: Array<FileModel>,
        private uploadService: UploadService,
        private successEmitter: EventEmitter<any>,
        private errorEmitter: EventEmitter<any>
    ) {}

    pauseUpload() {
        this.preventDefault();
    }

    resumeUpload() {
        if (this.files && this.files.length > 0) {
            this.uploadService.addToQueue(...this.files);
            this.uploadService.uploadFilesInTheQueue(this.successEmitter, this.errorEmitter);
        }
    }
}
