<h2 mat-dialog-title>
  {{ 'APP.ARCHIVE.DIGITALLY_SIGN.TITLE' | translate }}
</h2>

<mat-dialog-content class="adf-digitally-sign-dialog-content">
  <div [hidden]="step !== 0">
    <form [formGroup]="digitallySignForm" novalidate>
      <mat-form-field appearance="standard">
        <mat-label>{{ 'APP.ARCHIVE.DIGITALLY_SIGN.POSITION' | translate }}</mat-label>
        <mat-select formControlName="selectedPosition" required>
          <mat-option *ngFor="let position of positions; let i = index" [value]="position.id">{{ position.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="selectedPosition.invalid">{{ 'APP.ARCHIVE.DIGITALLY_SIGN.ERROR.REQUIRED' | translate }}</mat-error>
      </mat-form-field>
    </form>
  </div>
  <div [hidden]="step !== 1">
    <span class="message-container">{{ 'APP.ARCHIVE.DIGITALLY_SIGN.ACCEPT_EVERY_DIALOG' | translate }}</span>
    <mat-spinner [diameter]="24" style="margin: 10px 0 0 0;"></mat-spinner>
  </div>
  <div [hidden]="step !== 2">
    <span class="message-container">{{ resultMessage | translate }}</span>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
  <span class="adf-fill-remaining-space"></span>

  <button
    mat-button
    class="adf-dialog-action-button"
    color="primary"
    [disabled]="step !== 0"
    (click)="initiateSignature()">
    {{ 'APP.ARCHIVE.DIGITALLY_SIGN.SIGN' | translate }}
  </button>
  <button
    mat-button
    [disabled]="step === 1"
    (click)="closeDialog()">
    {{ 'APP.GENERAL.CANCEL' | translate }}
  </button>
</mat-dialog-actions>
