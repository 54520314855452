<h1 mat-dialog-title data-automation-id="adf-confirm-dialog-title">{{ title | translate }}</h1>
<mat-dialog-content>
    <p *ngIf="!htmlContent; else cutomContent" data-automation-id="adf-confirm-dialog-base-message">
        {{ message | translate }}
    </p>
    <ng-template #cutomContent>
        <span [innerHTML]="sanitizedHtmlContent()" data-automation-id="adf-confirm-dialog-custom-content">
        </span>
    </ng-template>
</mat-dialog-content>
<mat-dialog-actions>
    <span class="adf-dialog-spacer" data-automation-id="adf-confirm-dialog-spacer"></span>
    <button id="adf-confirm-accept" mat-button color="primary" data-automation-id="adf-confirm-dialog-confirmation"
        [mat-dialog-close]="true">{{ yesLabel | translate }}</button>
    <button id="adf-confirm-all" mat-button *ngIf="thirdOptionLabel" [mat-dialog-close]="thirdOptionLabel" data-automation-id="adf-confirm-dialog-confirm-all">{{ thirdOptionLabel | translate }}</button>
    <button id="adf-confirm-cancel" mat-button [mat-dialog-close]="false" data-automation-id="adf-confirm-dialog-reject"
        cdkFocusInitial>{{ noLabel | translate }}</button>
</mat-dialog-actions>
