<label class="adf-property-label"
     [attr.data-automation-id]="'card-dateitem-label-' + property.key"
     *ngIf="showProperty() || isEditable()"
     [attr.for]="'card-view-dateitem-' + property.key">
    {{ property.label | translate }}
</label>
<div class="adf-property-value adf-property-value-padding-top">
    <span *ngIf="!isEditable() && !property.multivalued"
          [attr.data-automation-id]="'card-' + property.type + '-value-' + property.key">
        <span *ngIf="showProperty()"
              [attr.data-automation-id]="'card-dateitem-' + property.key"
              (dblclick)="copyToClipboard(property.displayValue)"
              matTooltipShowDelay="1000"
              [matTooltip]="'CORE.METADATA.ACTIONS.COPY_TO_CLIPBOARD' | translate">{{ property.displayValue}}</span>
    </span>
    <div *ngIf="isEditable() && !property.multivalued"
         class="adf-dateitem-editable">
        <div class="adf-dateitem-editable-controls">
            <span class="adf-datepicker-toggle"
                  [attr.data-automation-id]="'datepicker-label-toggle-' + property.key"
                  (click)="showDatePicker()">
                <span *ngIf="showProperty(); else elseEmptyValueBlock"
                      [attr.data-automation-id]="'card-' + property.type + '-value-' + property.key">
                    {{ property.displayValue }}</span>
            </span>

            <mat-icon *ngIf="showClearAction()"
                      class="adf-date-reset-icon"
                      (click)="onDateClear()"
                      [attr.title]="'CORE.METADATA.ACTIONS.CLEAR' | translate"
                      [attr.data-automation-id]="'datepicker-date-clear-' + property.key">
                clear
            </mat-icon>

            <mat-datetimepicker-toggle [attr.tabindex]="-1"
                                       [attr.title]="'CORE.METADATA.ACTIONS.EDIT' | translate"
                                       [attr.data-automation-id]="'datepickertoggle-' + property.key"
                                       [for]="datetimePicker">
            </mat-datetimepicker-toggle>
        </div>

        <input class="adf-invisible-date-input"
               [attr.tabIndex]="-1"
               [matDatetimepicker]="datetimePicker"
               [value]="valueDate"
               (dateChange)="onDateChanged($event)"
               [attr.id]="'card-view-dateitem-' + property.key"
        >

        <mat-datetimepicker #datetimePicker
                            [type]="$any(property).type"
                            [timeInterval]="5"
                            [attr.data-automation-id]="'datepicker-' + property.key"
                            [startAt]="valueDate">
        </mat-datetimepicker>
    </div>
    <ng-template #elseEmptyValueBlock>
        {{ property.default | translate }}
    </ng-template>

    <div *ngIf="property.multivalued"
         class="adf-property-field adf-dateitem-chip-list-container adf-dateitem-editable">
        <mat-chip-list #chipList
                       class="adf-textitem-chip-list">
            <mat-chip *ngFor="let propertyValue of property.displayValue; let idx = index"
                      [removable]="isEditable()"
                      (removed)="removeValueFromList(idx)">
                {{ propertyValue }}
                <mat-icon *ngIf="isEditable()"
                          matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>

        <mat-form-field *ngIf="isEditable()"
                        class="adf-property-field adf-dateitem-editable-controls"
                        [floatLabel]="'never'"
                        (click)="showDatePicker()">
            <input matInput
                   class="adf-invisible-date-input"
                   [attr.tabIndex]="-1"
                   [matDatetimepicker]="datetimePicker"
                   (dateChange)="addDateToList($event)"
                   [attr.id]="'card-view-dateitem-' + property.key"
            >
            <mat-datetimepicker-toggle [attr.tabindex]="-1"
                                       matSuffix
                                       [attr.title]="'CORE.METADATA.ACTIONS.EDIT' | translate"
                                       [attr.data-automation-id]="'datepickertoggle-' + property.key"
                                       [for]="datetimePicker">
            </mat-datetimepicker-toggle>

            <mat-datetimepicker #datetimePicker
                                [type]="$any(property).type"
                                [timeInterval]="5"
                                [attr.data-automation-id]="'datepicker-' + property.key"
                                [startAt]="valueDate">
            </mat-datetimepicker>
        </mat-form-field>
    </div>
</div>
