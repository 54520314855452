<h2 mat-dialog-title>
  {{ 'APP.ARCHIVE.DIVORCE_DOCUMENT.TITLE' | translate }}
</h2>

<mat-dialog-content class="adf-divorce-document-dialog-content">
  <form [formGroup]="divorceForm" novalidate>
    <div class="form-column">
      <mat-form-field appearance="standard">
        <mat-label>{{ 'APP.ARCHIVE.DIVORCE_DOCUMENT.DATE' | translate }}:</mat-label>
        <input matInput formControlName="date" name="date" readonly />
      </mat-form-field>
    </div>
    <div class="form-column">
      <mat-form-field appearance="standard">
        <mat-label>{{ 'APP.ARCHIVE.DIVORCE_DOCUMENT.SIGN' | translate }}:</mat-label>
        <input matInput formControlName="description" name="description" />
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
    <span class="adf-fill-remaining-space"></span>

    <button
      mat-button
      class="adf-dialog-action-button"
      color="primary"
      (click)="divorceDocument()">
        {{ 'APP.ARCHIVE.DIVORCE_DOCUMENT.DIVORCE' | translate }}
    </button>
    <button
      mat-button
      mat-dialog-close>
      {{ 'APP.GENERAL.CANCEL' | translate }}
    </button>
</mat-dialog-actions>
