<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="APP.WORKFLOW.TASK_DETAILS.TITLE"> </adf-breadcrumb>
  </aca-page-layout-header>
  <aca-page-layout-content>
    <div class="task-details-main-content">
      <mat-spinner *ngIf="isLoading" [diameter]="60" style="margin: 10px auto;"></mat-spinner>
      <div *ngIf="!isLoading && taskError" class="task-details-error-container">
        {{ 'APP.WORKFLOW.TASK_DETAILS.TASK_ERROR' | translate }}
      </div>
      <div *ngIf="!isLoading && !taskError" class="task-details-main-container">
        <form [formGroup]="taskDetailsForm" novalidate (ngSubmit)="onFormSubmit($event)">
          <div class="tdmc-section">
            <span class="tdmcs-title">{{ 'APP.WORKFLOW.GENERAL.GENERAL_INFO' | translate }}</span>
            <div class="tdmcs-row">
              <span class="tdmcsr-pair">
                <span class="tdmcsrp-label">{{ 'APP.WORKFLOW.GENERAL.MESSAGE' | translate }}: </span>
                <span class="tdmcsrp-value">{{ getTaskMessage() }}</span>
              </span>
            </div>
            <div class="tdmcs-row">
              <div class="tdmcsr-column">
                <span class="tdmcsr-pair">
                  <span class="tdmcsrp-label">{{ 'APP.WORKFLOW.GENERAL.OWNER' | translate }}: </span>
                  <span class="tdmcsrp-value">{{ getTaskOwner() }}</span>
                </span>
              </div>
              <div class="tdmcsr-column">
                <span class="tdmcsr-pair">
                  <span class="tdmcsrp-label">{{ 'APP.WORKFLOW.GENERAL.PRIORITY' | translate }}: </span>
                  <span class="tdmcsrp-value">{{ getTaskPriority() }}</span>
                </span>
              </div>
              <div class="tdmcsr-column">
                <span class="tdmcsr-pair">
                  <span class="tdmcsrp-label">{{ 'APP.WORKFLOW.GENERAL.DUE' | translate }}: </span>
                  <span class="tdmcsrp-value">{{ getTaskDue() }}</span>
                </span>
              </div>
            </div>
            <div class="tdmcs-row">
              <span class="tdmcsr-pair">
                <span class="tdmcsrp-label">{{ 'APP.WORKFLOW.GENERAL.ADDITIONAL_DESCRIPTION' | translate }}: </span>
                <span class="tdmcsrp-value">{{ getTaskAdditionalDescription() }}</span>
              </span>
            </div>
            <div class="tdmcs-footer">
              <button [style.display]="canReassign() ? 'inline' : 'none'" mat-raised-button type="button" (click)="onReassign()">{{ 'APP.WORKFLOW.TASK_DETAILS.ACTION.REASSIGN' | translate }}</button>
              <button [style.display]="canClaim() ? 'inline' : 'none'" mat-raised-button type="button" (click)="onClaim()">{{ 'APP.WORKFLOW.TASK_DETAILS.ACTION.CLAIM' | translate }}</button>
              <button [style.display]="canRelease() ? 'inline' : 'none'" mat-raised-button type="button" (click)="onRelease()">{{ 'APP.WORKFLOW.TASK_DETAILS.ACTION.RELEASE' | translate }}</button>
            </div>
          </div>
          <div class="tdmc-section">
            <span class="tdmcs-title">{{ 'APP.WORKFLOW.GENERAL.DOCUMENTS' | translate }}</span>
              <div id="document-selector-container">
                  <aca-neocom-document-selector
                    [initialSelection]="initiallySelectedDocuments"
                    (selectionChanged)="onDocumentSelectorSelectionChanged($event)"
                    (navigate)="onDocumentSelectorNavigate($event)">
                  </aca-neocom-document-selector>
              </div>
          </div>
          <div class="tdmc-section">
            <span class="tdmcs-title">{{ 'APP.WORKFLOW.GENERAL.ACTIONS' | translate }}</span>
              <div>
                <mat-form-field appearance="standard" id="task-status">
                  <mat-label>{{ 'APP.WORKFLOW.GENERAL.STATUS' | translate }}</mat-label>
                  <mat-select formControlName="status">
                    <mat-option *ngFor="let status of statusOptions" [value]="status.id">{{ status.value }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <mat-form-field appearance="standard" id="task-comment" style="width: 100%">
                <mat-label>{{ 'APP.WORKFLOW.GENERAL.COMMENT' | translate }}</mat-label> 
                <textarea matInput formControlName="comment" name="comment" type="text"></textarea>
              </mat-form-field>
              <div class="tdmcs-footer">
                <button mat-raised-button type="button" (click)="onSaveAndClose($event)">{{ 'APP.WORKFLOW.TASK_DETAILS.ACTION.SAVE_CLOSE' | translate }}</button>
                <button mat-raised-button type="button" (click)="onCancel($event)">{{ 'APP.WORKFLOW.TASK_DETAILS.ACTION.CANCEL' | translate }}</button>
              </div>
          </div>
          <div class="tdmc-footer">
            <button [style.display]="canApprove() ? 'inline' : 'none'" mat-raised-button type="button" class="task-approve" (click)="onApprove()">{{ 'APP.WORKFLOW.TASK_DETAILS.ACTION.APPROVE' | translate }}</button>
            <button [style.display]="canReject() ? 'inline' : 'none'" mat-raised-button type="button" class="task-reject" (click)="onReject()">{{ 'APP.WORKFLOW.TASK_DETAILS.ACTION.REJECT' | translate }}</button>      
            <button [style.display]="canTaskDone() ? 'inline' : 'none'" mat-raised-button type="button" class="task-done" (click)="onTaskDone()">{{ 'APP.WORKFLOW.TASK_DETAILS.ACTION.TASK_DONE' | translate }}</button>
          </div>
        </form>
      </div>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
