<mat-form-field>
    <input
        matInput
        placeholder="{{ settings?.placeholder | translate }}"
        [(ngModel)]="value"
        (change)="onChangedHandler($event)">
    <button mat-button *ngIf="value" matSuffix mat-icon-button (click)="clear()">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>
