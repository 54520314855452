<div class="sidenav">
    <app-sidenav-header (toggleNavBar)="toggleClick()"></app-sidenav-header>

    <div class="section-sub-actions">
      <div *ngFor="let group of groups; trackBy: trackByGroupId" class="section">
          <mat-list-item *ngFor="let item of group.items; trackBy: trackByLinkId">
            <ng-container *ngIf="!item.component">
              <app-expand-menu [item]="item"></app-expand-menu>
            </ng-container>
            <ng-container *ngIf="item.component">
              <adf-dynamic-component [data]="{ item: item, state: 'expanded' }" [id]="item.component"></adf-dynamic-component>
            </ng-container>
          </mat-list-item>
      </div>
    </div>
</div>
