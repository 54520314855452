<div class="adf-tags-creation">
    <p
       class="adf-no-tags-message"
       *ngIf="!tags.length && !tagNameControlVisible">
        {{ 'TAG.TAGS_CREATOR.NO_TAGS_CREATED' | translate }}
    </p>
    <div
        class="adf-tags-list"
        [class.adf-tags-list-fixed]="!tagNameControlVisible"
        #tagsList>
        <p
            *ngFor="let tag of tags"
            class="adf-tag adf-label-with-icon-button">
            {{ tag }}
            <button
                data-automation-id="remove-tag-button"
                mat-icon-button
                (click)="removeTag(tag)"
                [attr.title]="'TAG.TAGS_CREATOR.TOOLTIPS.DELETE_TAG' | translate"
                [disabled]="disabledTagsRemoving">
                <mat-icon>remove</mat-icon>
            </button>
        </p>
    </div>
    <div
        class="adf-tag-name-field"
        *ngIf="(!tagNameControlVisible && tags.length) || tagNameControlVisible"
        [hidden]="!tagNameControlVisible">
        <mat-form-field *ngIf="tagNameControlVisible">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label id="adf-tag-name-input-label">
                {{ 'TAG.TAGS_CREATOR.NAME' | translate }}
            </mat-label>
            <input
                #tagNameInput
                matInput
                autocomplete="off"
                [formControl]="tagNameControl"
                (keyup.enter)="addTag()"
                aria-labelledby="adf-tag-name-input-label"
                adf-auto-focus
            />
            <mat-error [hidden]="!tagNameControl.invalid">{{ tagNameErrorMessageKey | translate }}</mat-error>
        </mat-form-field>
        <button
            data-automation-id="hide-tag-name-input-button"
            mat-icon-button
            (click)="hideNameInput()"
            [attr.title]="'TAG.TAGS_CREATOR.TOOLTIPS.HIDE_INPUT' | translate">
            <mat-icon>remove</mat-icon>
        </button>
    </div>
</div>
<div
    class="adf-existing-tags-panel"
    *ngIf="existingTagsPanelVisible">
    <span *ngIf="!spinnerVisible || existingTags"
        class="adf-create-tag-label"
        (click)="addTag()"
        [hidden]="tagNameControl.invalid || typing">
        {{ 'TAG.TAGS_CREATOR.CREATE_TAG' | translate : { tag: tagNameControl.value } }}
    </span>
    <p *ngIf="!spinnerVisible && existingTags" class="adf-existing-tags-label">
        {{ (isOnlyCreateMode() ? 'TAG.TAGS_CREATOR.EXISTING_TAGS' : 'TAG.TAGS_CREATOR.EXISTING_TAGS_SELECTION') | translate }}
    </p>
    <div class="adf-tags-list">
        <mat-selection-list
            *ngIf="!spinnerVisible && existingTags"
            (selectionChange)="addExistingTagToTagsToAssign($event)"
            [disabled]="isOnlyCreateMode()">
            <mat-list-option
                *ngFor="let tagRow of existingTags"
                class="adf-tag"
                [value]="tagRow">
                {{ tagRow.entry.tag }}
            </mat-list-option>
            <p *ngIf="!existingTags?.length">{{ 'TAG.TAGS_CREATOR.NO_EXISTING_TAGS' | translate }}</p>
        </mat-selection-list>
        <mat-spinner
            *ngIf="spinnerVisible"
            [diameter]="50"
            [attr.aria-label]="'TAG.TAGS_CREATOR.TAGS_LOADING' | translate">
        </mat-spinner>
    </div>
</div>
