import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DataCellEvent, DataColumn, DataRowActionEvent, DataTableModule, TranslationService } from '@alfresco/adf-core';
import { Store } from '@ngrx/store';
import { Person } from '@alfresco/js-api';
import { AppStore, SnackbarErrorAction, SnackbarInfoAction, getUserList } from '@alfresco/aca-shared/store';
import { SiteService, Constants, SiteRoles, debounce } from '@alfresco/aca-shared';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    DataTableModule,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-add-site-user-dialog',
  templateUrl: './add-site-user-dialog.component.html',
  styleUrls: ['./add-site-user-dialog.component.scss']
})
export class AddSiteUserDialogComponent implements OnInit {
  mode = 0;

  siteId: string;
  siteTitle: string;

  siteAddUsersSchema: DataColumn[];
  siteAddUsersRows: object[];
  siteAddUsersRowsAll: object[];

  allUsers: Person[];
  allSiteUsers: object[];

  selectedUser;
  roleChoice: string = SiteRoles.CONSUMER;

  loading: Boolean = false;
  hasMadeUserChange: Boolean = false;
  hasMadeUserChangeLastTime: Boolean = false;

  filterSiteAddUsers: string = '';
  filterPlaceholder: string;
  filterDebounce: Function;

  constructor(
    private dialog: MatDialogRef<AddSiteUserDialogComponent>,
    private store: Store<AppStore>,
    private translation: TranslationService,
    private siteService: SiteService,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    this.siteId = data.siteId;
    this.siteTitle = data.siteTitle;

    this.siteAddUsersSchema = [
      {
        type: 'text',
        key: 'id',
        title: this.translation.instant('APP.SETTINGS.USERNAME'),
        sortable: true
      },
      {
        type: 'text',
        key: 'displayName',
        title: this.translation.instant('APP.SETTINGS.FULL_NAME'),
        cssClass: 'full-width',
        sortable: true
      }
    ];

    this.filterPlaceholder = this.translation.instant('APP.SETTINGS.FILTER');
  }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers(utilizeTimeout = false) {
    const proc = () => {
      this.store.select(getUserList).subscribe((userList) => {
        this.allUsers = userList;
        this.siteService
            .getSiteMembers(this.siteId)
            .then((siteUsers) => {
              this.allSiteUsers = siteUsers;
              const userIdsInSite = this.allSiteUsers.map((u) => u['id']);
              this.siteAddUsersRowsAll = this.allUsers.filter((u) => userIdsInSite.indexOf(u.id) === -1);
              this.applySiteAddUsersFilter(true);
              this.loading = false;
            })
            .catch((_) => {
              this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
              this.loading = false;
            });
      });
    };

    this.loading = true;

    if (!utilizeTimeout) {
      proc();
    } else {
      setTimeout(proc, Constants.GROUP_MEMBERSHIP_UPDATE_TIMEOUT_VALUE);
    }
  }

  onSiteAddShowRowActionsMenu(event: DataCellEvent) {
    const addUserAction = {
      id: 0,
      title: this.translation.instant('APP.SETTINGS.ADD_USER')
    };
    event.value.actions = [addUserAction];
  }

  onSiteAddExecuteRowAction = (event: DataRowActionEvent) => {
    const args = event.value;
    if (args.action.id === 0) {
      this.selectedUser = args.row.obj;
      this.mode = 1;
    }
  };

  onRefreshList(): void {
    this.getUsers();
  }

  applySiteAddUsersFilter(skipTimeout = false) {
    if (!this.filterDebounce) this.filterDebounce = debounce(Constants.CONTINUED_TYPING_THRESHOLD_VALUE);

    const proc = () => {
      if (!this.filterSiteAddUsers) this.siteAddUsersRows = this.siteAddUsersRowsAll;
      else
        this.siteAddUsersRows = this.siteAddUsersRowsAll.filter(
          (u) =>
            u['id'].toLowerCase().indexOf(this.filterSiteAddUsers.toLowerCase()) !== -1 ||
            u['displayName'].toLowerCase().indexOf(this.filterSiteAddUsers.toLowerCase()) !== -1
        );
    };

    if (!skipTimeout) {
      this.filterDebounce(proc);
    } else {
      proc();
    }
  }

  onViewUsers(): void {
    if (this.hasMadeUserChange && this.hasMadeUserChangeLastTime) {
      this.getUsers();
      this.hasMadeUserChangeLastTime = false;
    }
    this.mode = 0;
  }

  matDialogContentStyle(): string {
    let style = 'max-height: unset; overflow-y: auto !important; ';
    if (this.loading) style += 'height: 75px;';
    else if (!this.siteAddUsersRowsAll || this.siteAddUsersRowsAll.length === 0) style += 'height: 50px';
    else style += 'height: 500px;';
    return style;
  }

  getRoleMessage(): string {
    return this.translation.instant('APP.SETTINGS.ADD_EDIT_USER_SITE_ROLE_MESSAGE').replace('{0}', this.selectedUser.displayName);
  }

  onRoleCancel() {
    this.selectedUser = null;
    this.roleChoice = SiteRoles.CONSUMER;
    this.onViewUsers();
  }

  onRoleSubmit() {
    this.siteService
      .addSiteMember(this.siteId, this.selectedUser.id, this.roleChoice as SiteRoles)
      .then(() => {
        this.hasMadeUserChange = true;
        this.hasMadeUserChangeLastTime = true;
        this.store.dispatch(
          new SnackbarInfoAction(
            this.translation
              .instant('APP.SETTINGS.USER_ROLE_EDITED_MESSAGE')
              .replace('{0}', this.selectedUser.displayName)
              .replace('{1}', this.translation.instant(SiteService.mapRoleToI18NString(this.roleChoice as SiteRoles)))
          )
        );
        this.onRoleCancel();
      })
      .catch((_) => {
        this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      });
  }

  onClose() {
    this.dialog.close(this.hasMadeUserChange);
  }
}
