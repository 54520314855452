<div mat-dialog-title>
    <mat-icon>lock</mat-icon>
</div>

<mat-dialog-content>
    <form (submit)="submit()">
        <mat-form-field class="adf-full-width">
            <input matInput
                   data-automation-id='adf-password-dialog-input'
                   type="password"
                   placeholder="{{ 'ADF_VIEWER.PDF_DIALOG.PLACEHOLDER' | translate }}"
                   [formControl]="passwordFormControl" />
        </mat-form-field>

        <mat-error *ngIf="isError()" data-automation-id='adf-password-dialog-error'>{{ 'ADF_VIEWER.PDF_DIALOG.ERROR' | translate }}</mat-error>
    </form>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
    <span class="adf-fill-remaining-space"></span>

    <button mat-button mat-dialog-close data-automation-id='adf-password-dialog-close'>{{ 'ADF_VIEWER.PDF_DIALOG.CLOSE' | translate }}</button>

    <button mat-button
            data-automation-id='adf-password-dialog-submit'
            class="adf-dialog-action-button"
            [disabled]="!isValid()"
            (click)="submit()">
        {{ 'ADF_VIEWER.PDF_DIALOG.SUBMIT' | translate }}
    </button>
</mat-dialog-actions>
