<h2 mat-dialog-title>
  {{ 'APP.ARCHIVE.STAMP_DOCUMENT.TITLE' | translate }}
</h2>

<mat-dialog-content class="adf-stamp-document-dialog-content">
  <form [formGroup]="stampForm" novalidate>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'APP.ARCHIVE.STAMP_DOCUMENT.STAMP_TYPE' | translate }}</mat-label>
      <mat-select formControlName="stampType">
        <mat-option value="incoming">{{ 'APP.ARCHIVE.STAMP_DOCUMENT.INCOMING_DOCUMENT' | translate }}</mat-option>
        <mat-option value="outgoing">{{ 'APP.ARCHIVE.STAMP_DOCUMENT.OUTGOING_DOCUMENT' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <aca-image-annotator
    [size]="previewSize"
    [image]="thumbnail"
    [mode]="annotatorMode()"
    [contentHtml]="annotationContentHtml"
    [textScaleCoef]="6.5"
    (coordinatesChanged)="onAnnotatorCoordinatesChanged($event)"
    (coordinatesReset)="onAnnotatorCoordinatesReset()">
  </aca-image-annotator>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
    <span class="adf-fill-remaining-space"></span>

    <button
      mat-button
      class="adf-dialog-action-button"
      color="primary"
      (click)="stampDocument()">
        {{ 'APP.ARCHIVE.STAMP_DOCUMENT.STAMP' | translate }}
    </button>
    <button
      mat-button
      mat-dialog-close>
      {{ 'APP.GENERAL.CANCEL' | translate }}
    </button>
</mat-dialog-actions>
