<h2 mat-dialog-title>{{ 'APP.ARCHIVE.SUBJECT_INFO.INFO' | translate }}</h2>

<mat-dialog-content>
  <adf-datatable
    [rows]="data"
    [columns]="subjectSchema"
    [actions]="true"
    (showRowActionsMenu)="onShowRowActionsUsersMenu($event)"
    (executeRowAction)="onExecuteUsersRowAction($event)"
  >
  </adf-datatable>
</mat-dialog-content>
