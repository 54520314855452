<div class="adf-pdf-thumbnails__content"
    data-automation-id='adf-thumbnails-content'
    [style.height.px]="virtualHeight"
    [style.transform]="'translate(-50%, ' + translateY + 'px)'">
    <adf-pdf-thumb *ngFor="let page of renderItems; trackBy: trackByFn"
        class="adf-pdf-thumbnails__thumb"
        [id]="page.id"
        [ngClass]="{'adf-pdf-thumbnails__thumb--selected' : isSelected(page.id)}"
        [page]="page"
        (click)="goTo(page.id)">
    </adf-pdf-thumb>
</div>
