<h2 mat-dialog-title>
  {{ 'APP.ACTIONS.EDIT_ROLE' | translate }}
</h2>

<mat-dialog-content>
  <div class="outer-container">
    <span class="role-message">{{ getRoleMessage() }}</span>
    <mat-form-field>
      <mat-select
        [(ngModel)]="roleChoice"
        placeholder="{{ 'APP.SETTINGS.SITE_ROLE' | translate }}">
          <mat-option value="SiteManager">{{ 'APP.SETTINGS.SITE_ROLE_MANAGER' | translate }}</mat-option>
          <mat-option value="SiteCollaborator">{{ 'APP.SETTINGS.SITE_ROLE_COLLABORATOR' | translate }}</mat-option>
          <mat-option value="SiteContributor">{{ 'APP.SETTINGS.SITE_ROLE_CONTRIBUTOR' | translate }}</mat-option>
          <mat-option value="SiteConsumer">{{ 'APP.SETTINGS.SITE_ROLE_CONSUMER' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
  <span class="adf-fill-remaining-space"></span>
  <button mat-button (click)="onCancel()">
    {{ 'APP.ACTIONS.BACK' | translate }}
  </button>
  <button mat-button (click)="onSubmit()">
    {{ 'APP.ACTIONS.SUBMIT' | translate }}
  </button>
</mat-dialog-actions>
