<h2 mat-dialog-title>
  {{ 'APP.SETTINGS.SITE_USERS' | translate }} {{ siteTitle }}
</h2>

<mat-dialog-content [style]="matDialogContentStyle()">
  <div *ngIf="mode === 0" class="mode0-container">
    <mat-spinner *ngIf="loading" [diameter]="60" style="margin: 0 auto;"></mat-spinner>
    <span *ngIf="!loading && (!siteAddUsersRowsAll || siteAddUsersRowsAll.length === 0)">{{ 'APP.SETTINGS.NO_USERS_LEFT_TO_ADD' | translate }}</span>
    <mat-form-field *ngIf="!loading">
      <input
        matInput
        (keyup)="applySiteAddUsersFilter()"
        [(ngModel)]="filterSiteAddUsers"
        [placeholder]="filterPlaceholder" />
    </mat-form-field>
    <div *ngIf="!loading && siteAddUsersRows && siteAddUsersRows.length > 0" class="datatable-container">
      <adf-datatable
        [rows]="siteAddUsersRows" 
        [columns]="siteAddUsersSchema"
        [actions]="true"
        [stickyHeader]="false"
        (showRowActionsMenu)="onSiteAddShowRowActionsMenu($event)"
        (executeRowAction)="onSiteAddExecuteRowAction($event)"> 
      </adf-datatable>
    </div>
  </div>
  <div *ngIf="mode === 1" class="mode1-container">
    <span class="role-message">{{ getRoleMessage() }}</span>
    <mat-form-field>
      <mat-select
        [(ngModel)]="roleChoice"
        placeholder="{{ 'APP.SETTINGS.SITE_ROLE' | translate }}">
          <mat-option value="SiteManager">{{ 'APP.SETTINGS.SITE_ROLE_MANAGER' | translate }}</mat-option>
          <mat-option value="SiteCollaborator">{{ 'APP.SETTINGS.SITE_ROLE_COLLABORATOR' | translate }}</mat-option>
          <mat-option value="SiteContributor">{{ 'APP.SETTINGS.SITE_ROLE_CONTRIBUTOR' | translate }}</mat-option>
          <mat-option value="SiteConsumer">{{ 'APP.SETTINGS.SITE_ROLE_CONSUMER' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="intra-dialog-buttons">
      <button mat-button (click)="onRoleCancel()">
        {{ 'APP.ACTIONS.BACK' | translate }}
      </button>
      <button mat-button (click)="onRoleSubmit()">
        {{ 'APP.ACTIONS.SUBMIT' | translate }}
      </button>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
  <span class="adf-fill-remaining-space"></span>
  <button
      mat-button
      id="adf-folder-cancel-button"
      (click)="onClose()">
      {{ 'APP.ACTIONS.CLOSE' | translate }}
  </button>
</mat-dialog-actions>
