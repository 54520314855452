<div
    id="userinfo_container"
    [class.adf-userinfo-name-right]="showOnRight"
    (keyup)="onKeyPress($event)"
    class="adf-userinfo-container"
    *ngIf="canShow"
>
    <span *ngIf="showName" id="adf-userinfo-ecm-name-display" class="adf-userinfo-name">
        {{ecmUser | fullName}}
    </span>
    <button mat-button [matMenuTriggerFor]="menu" class="adf-userinfo-menu_button"
            data-automation-id="adf-user-profile">
        <div class="adf-userinfo-button-profile" id="user-profile">
            <div *ngIf="identityUser; else showEcmUserImage" id="identity-user-image">
                <div *ngIf="ecmUser?.avatarId; else initialTemplate">
                    <div class="adf-userinfo-profile-container">
                        <img id="logged-user-img" [src]="getEcmAvatar(ecmUser.avatarId)" alt="user-info-profile-button"
                             class="adf-userinfo-profile-image"/>
                    </div>
                </div>
                <ng-template #initialTemplate>
                    <div [innerHTML]="identityUser | usernameInitials:'adf-userinfo-pic'"></div>
                </ng-template>
            </div>
            <ng-template #showEcmUserImage>
                <div id="ecm-user-image">
                    <div *ngIf="ecmUser.avatarId; else initialTemplate" class="adf-userinfo-profile-container">
                        <img id="logged-user-img" [src]="getEcmAvatar(ecmUser.avatarId)" alt="user-info-profile-button"
                             class="adf-userinfo-profile-image"/>
                    </div>
                    <ng-template #initialTemplate>
                        <div [outerHTML]="ecmUser | usernameInitials:'adf-userinfo-pic'"></div>
                    </ng-template>
                </div>
            </ng-template>
        </div>
    </button>

    <mat-menu #menu="matMenu" id="user-profile-lists" [xPosition]="menuPositionX" [yPosition]="menuPositionY"
              [overlapTrigger]="false" class="adf-userinfo-menu">
        <mat-tab-group id="tab-group-env" (click)="stopClosing($event)" selectedIndex="0" role="menuitem"
                       class="adf-userinfo-tab adf-hide-tab">
            <mat-tab label="{{ 'USER_PROFILE.TAB.CS' | translate }}" role="dialog"
                     *ngIf="mode === userInfoMode.CONTENT">
                <mat-card class="adf-userinfo-card">
                    <mat-card-header class="adf-userinfo-card-header"
                                     [style.background-image]="'url(' + ecmBackgroundImage + ')'">
                        <div *ngIf="ecmUser.avatarId; else initialTemplate"
                             class="adf-userinfo-profile-container adf-hide-small">
                            <img class="adf-userinfo-profile-picture" id="ecm-user-detail-image"
                                 alt="ecm-profile-image" [src]="getEcmAvatar(ecmUser.avatarId)"/>
                        </div>
                        <ng-template #initialTemplate>
                            <div
                                [outerHTML]="ecmUser | usernameInitials:'adf-userinfo-profile-initials adf-hide-small'"></div>
                        </ng-template>

                        <div class="adf-userinfo-title" id="ecm-username">{{ecmUser | fullName}}</div>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="adf-userinfo-supporting-text">
                            <div class="adf-userinfo-detail">
                                <span id="ecm-full-name"
                                      class="adf-userinfo__detail-title">{{ecmUser | fullName}}</span>
                                <span class="adf-userinfo__detail-profile" id="ecm-email"> {{ecmUser.email}} </span>
                                <a class="adf-userinfo__detail-profile" href="#/profile">
                                    {{ 'USER_PROFILE.LABELS.MY_PROFILE' | translate }}</a>
                            </div>
                            <div class="adf-userinfo-detail">
                                    <span class="adf-userinfo__secondary-info" id="ecm-job-title-label">
                                        {{ 'USER_PROFILE.LABELS.ECM.JOB_TITLE' | translate }}
                                        <span id="ecm-job-title"
                                              class="adf-userinfo__detail-profile"> {{ ecmUser.jobTitle ? ecmUser.jobTitle : 'N/A' }} </span>
                                    </span>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </mat-tab>
            <mat-tab id="identity-panel" role="dialog" *ngIf="mode === userInfoMode.CONTENT_SSO">
                <mat-card class="adf-userinfo-card">
                    <mat-card-header class="adf-userinfo-card-header"
                                     [style.background-image]="'url(' + bpmBackgroundImage + ')'">
                        <div *ngIf="ecmUser.avatarId; else initialTemplate"
                                class="adf-userinfo-profile-container adf-hide-small">
                            <img class="adf-userinfo-profile-picture" id="ecm-user-detail-image"
                                    alt="ecm-profile-image" [src]="getEcmAvatar(ecmUser.avatarId)"/>
                        </div>
                        <ng-template #initialTemplate>
                            <div
                                [outerHTML]="identityUser | usernameInitials:'adf-userinfo-profile-initials adf-hide-small'"></div>
                        </ng-template>
                        <div class="adf-userinfo-title" id="identity-username">{{identityUser | fullName}}</div>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="adf-userinfo-supporting-text">
                            <div class="adf-userinfo-detail">
                                <span id="identity-full-name"
                                      class="adf-userinfo__detail-title">{{identityUser | fullName}}</span>
                                <span class="adf-userinfo__detail-profile"
                                      id="identity-email"> {{identityUser.email}} </span>
                                <a class="adf-userinfo__detail-profile" href="#/profile">
                                      {{ 'USER_PROFILE.LABELS.MY_PROFILE' | translate }}</a>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </mat-tab>
        </mat-tab-group>
    </mat-menu>
</div>
