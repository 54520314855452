<form [formGroup]="paginationForm" novalidate>
  <div class="action-buttons">
    <button mat-raised-button class="print" (click)="printTable()">Print</button>
    <div class="pagination">
      <button class="smaller" mat-raised-button [disabled]="currentPage <= 1" (click)="previousPage()">&lt;</button>
      <mat-form-field appearance="standard">
        <input matInput formControlName="selectedPage" (change)="jumpToPage()" />
      </mat-form-field>
      <span class="pages-larger-font">/</span>
      <span class="total-pages pages-larger-font">{{ numberOfPages }}</span>
      <button class="smaller" mat-raised-button [disabled]="currentPage >= numberOfPages" (click)="nextPage()">&gt;</button>
    </div>
    <div class="pagination-right">
      <mat-form-field appearance="standard">
        <mat-label>{{ 'APP.ARCHIVE.ROWS_OF_ACTS' | translate }}</mat-label>
        <mat-select formControlName="selectedReportLimit" (selectionChange)="updateData()">
          <mat-option *ngFor="let row of rows" [value]="row.value">{{ row.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>
<div class="report-container">
  <div *ngIf="reportType === 'ioa_type'">
    <table class="top-left">
      <thead>
        <tr>
          <th>{{ 'APP.ARCHIVE.INDEX' | translate }}</th>
          <th>{{ 'APP.ARCHIVE.REPORTS.ORG_UNIT' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ reportData.index }}</td>
          <td>{{ reportData.orgUnit }}</td>
        </tr>
      </tbody>
    </table>
    <div class="title">{{ 'APP.ARCHIVE.SUBJECT' | translate }}: {{ reportData.title }}</div>
  </div>
  <table class="report-table">
    <thead>
      <tr>
        <th *ngFor="let header of headers.mainHeaders" [rowSpan]="header.rowSpan" [colSpan]="header.colSpan">{{ header.title }}</th>
      </tr>
      <tr>
        <th *ngFor="let header of headers.subHeaders" [rowSpan]="header.rowSpan" [colSpan]="header.colSpan">{{ header.title }}</th>
      </tr>
      <tr style="font-size: 10px;">
        <td *ngFor="let i of [].constructor(indexes); let index = index">
          {{ index + 1 }}
        </td>
      </tr>
    </thead>
    <tbody *ngIf="reportType === 'registry_book'; else ioa_type">
      <ng-container *ngFor="let row of data">
        <tr *ngIf="row.documents.length > 1" style="height: auto">
          <td class="text-center" [rowSpan]="row.documents.length + 1">{{ row.index }}</td>
        </tr>
        <tr *ngFor="let document of row.documents">
          <td *ngIf="row.documents.length === 1" class="text-center">{{ row.index }}</td>
          <td>
            <span style="cursor: pointer" (click)="openDocument(document.nodeRef)">{{ document.subject }}</span>
          </td>
          <td class="text-center">{{ document.seqIndex }}</td>
          <td>{{ document.dateArchived }}</td>
          <td>{{ document.sender }}</td>
          <td>{{ document.senderNumber }} - {{ document.dateSent }}</td>
          <td>{{ document.archiveSign }}</td>
          <td>{{ document.divorceDate }}</td>
          <td>{{ document.divorceSign }}</td>
        </tr>
      </ng-container>
    </tbody>
    <ng-template #ioa_type>
      <tbody>
        <ng-container *ngFor="let row of data">
          <tr *ngIf="row.documents.length > 1" style="height: auto">
            <td class="text-center" [rowSpan]="row.documents.length + 1">{{ row.seqIndex }}</td>
          </tr>
          <tr *ngFor="let document of row.documents">
            <td *ngIf="row.documents.length === 1" class="text-center">{{ row.seqIndex }}</td>
            <td>
              <span style="cursor: pointer" (click)="openDocument(row.nodeRef)">{{ document.sender }}</span>
            </td>
            <td>{{ document.dateArchived }}</td>
            <td>{{ document.senderNumber }}</td>
            <td>{{ document.divorceDate }}</td>
            <td>{{ document.divorceSign }}</td>
            <td>{{ document.note }}</td>
          </tr>
        </ng-container>
      </tbody>
    </ng-template>
  </table>
</div>
