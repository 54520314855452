/*!
 * @license
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

 /* eslint-disable @angular-eslint/component-selector */

import { FormOutcomeModel } from './form-outcome.model';

export class FormOutcomeEvent {

    private readonly _outcome: FormOutcomeModel;
    private _defaultPrevented: boolean = false;

    get outcome(): FormOutcomeModel {
        return this._outcome;
    }

    get defaultPrevented() {
        return this._defaultPrevented;
    }

    constructor(outcome: FormOutcomeModel) {
        this._outcome = outcome;
    }

    preventDefault() {
        this._defaultPrevented = true;
    }

}
