<aca-page-layout>
  <div class="aca-page-layout-header">
    <h1 class="aca-page-title">{{ 'APP.BROWSE.RECENT.TITLE' | translate }}</h1>
    <aca-toolbar [items]="actions"></aca-toolbar>
  </div>

  <div class="aca-page-layout-content">
    <div class="main-content">
      <adf-document-list
        #documentList
        acaDocumentList
        acaContextActions
        [currentFolderId]="'-recent-'"
        [selectionMode]="'multiple'"
        [navigate]="false"
        [sorting]="['modifiedAt', 'desc']"
        [sortingMode]="'client'"
        [imageResolver]="imageResolver"
        (node-dblclick)="handleNodeClick($event)"
        (name-click)="handleNodeClick($event)"
      >
        <adf-custom-empty-content-template>
          <adf-empty-content icon="access_time" [title]="'APP.BROWSE.RECENT.EMPTY_STATE.TITLE'" subtitle="APP.BROWSE.RECENT.EMPTY_STATE.TEXT">
          </adf-empty-content>
        </adf-custom-empty-content-template>

        <data-columns>
          <ng-container *ngFor="let column of columns; trackBy: trackByColumnId">
            <ng-container *ngIf="column.template && !(column.desktopOnly && isSmallScreen)">
              <data-column
                [key]="column.key"
                [title]="column.title"
                [type]="column.type"
                [format]="column.format"
                [class]="column.class"
                [sortable]="column.sortable"
              >
                <ng-template let-context>
                  <adf-dynamic-column [id]="column.template" [context]="context"> </adf-dynamic-column>
                </ng-template>
              </data-column>
            </ng-container>

            <ng-container *ngIf="!column.template && !(column.desktopOnly && isSmallScreen)">
              <data-column
                [key]="column.key"
                [title]="column.title"
                [type]="column.type"
                [format]="column.format"
                [class]="column.class"
                [sortable]="column.sortable"
              >
              </data-column>
            </ng-container>
          </ng-container>
        </data-columns>
      </adf-document-list>

      <adf-pagination acaPagination [target]="documentList"> </adf-pagination>
    </div>

    <div class="aca-sidebar" *ngIf="infoDrawerOpened$ | async">
      <aca-info-drawer [node]="selection.last"></aca-info-drawer>
    </div>
  </div>
</aca-page-layout>
