<ng-container [formGroup]="form">
  <mat-radio-group formControlName="dateRangeType">
    <span class="adf-search-date-range-horizontal-container">
      <mat-radio-button [value]="DateRangeType.ANY" data-automation-id="date-range-advanced-anytime">
        {{ 'SEARCH.DATE_RANGE_ADVANCED.OPTIONS.ANYTIME' | translate }}
      </mat-radio-button>
    </span>
    <span class="adf-search-date-range-horizontal-container">
      <mat-radio-button [value]="DateRangeType.IN_LAST" data-automation-id="date-range-advanced-in-last">
        {{ 'SEARCH.DATE_RANGE_ADVANCED.OPTIONS.IN_LAST' | translate }}
      </mat-radio-button>
      <mat-form-field class="adf-search-date-range-form-field adf-search-date-range-input-field">
        <input matInput type="number" min="1" formControlName="inLastValue" data-automation-id="date-range-advanced-in-last-input" (input)="narrowDownAllowedCharacters($event)" (keydown)="preventIncorrectNumberCharacters($event)">
        <mat-error *ngIf="form.controls.inLastValue.errors?.required">{{ 'SEARCH.DATE_RANGE_ADVANCED.ERROR.IN_LAST' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field class="adf-search-date-range-form-field">
        <mat-select formControlName="inLastValueType" data-automation-id="date-range-advanced-in-last-dropdown">
          <mat-option data-automation-id="date-range-advanced-in-last-option-days" [value]="InLastDateType.DAYS">{{ 'SEARCH.DATE_RANGE_ADVANCED.IN_LAST_LABELS.DAYS' | translate }}</mat-option>
          <mat-option data-automation-id="date-range-advanced-in-last-option-weeks" [value]="InLastDateType.WEEKS">{{ 'SEARCH.DATE_RANGE_ADVANCED.IN_LAST_LABELS.WEEKS' | translate }}</mat-option>
          <mat-option data-automation-id="date-range-advanced-in-last-option-months" [value]="InLastDateType.MONTHS">{{ 'SEARCH.DATE_RANGE_ADVANCED.IN_LAST_LABELS.MONTHS' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </span>
    <span class="adf-search-date-range-horizontal-container">
      <mat-radio-button [value]="DateRangeType.BETWEEN" data-automation-id="date-range-advanced-between">
        {{ 'SEARCH.DATE_RANGE_ADVANCED.OPTIONS.BETWEEN' | translate }}
      </mat-radio-button>
      <mat-form-field class="adf-search-date-range-form-field">
        <mat-date-range-input [rangePicker]="$any(picker)" [max]="convertedMaxDate">
          <input matStartDate placeholder="{{ 'SEARCH.DATE_RANGE_ADVANCED.BETWEEN_PLACEHOLDERS.START_DATE' | translate }}"
                 data-automation-id="date-range-advanced-between-start-input" [formControl]="betweenStartDateFormControl" (change)="dateChanged($event, betweenStartDateFormControl)">
          <input matEndDate placeholder="{{ 'SEARCH.DATE_RANGE_ADVANCED.BETWEEN_PLACEHOLDERS.END_DATE' | translate }}"
                 data-automation-id="date-range-advanced-between-end-input" [formControl]="betweenEndDateFormControl" (change)="dateChanged($event, betweenEndDateFormControl)">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker" data-automation-id="date-range-advanced-between-datepicker-toggle"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="betweenStartDateFormControl.errors?.invalidDate">{{ 'SEARCH.DATE_RANGE_ADVANCED.ERROR.START_DATE.INVALID_FORMAT' | translate }}</mat-error>
        <mat-error *ngIf="betweenStartDateFormControl.errors?.required">{{ 'SEARCH.DATE_RANGE_ADVANCED.ERROR.START_DATE.REQUIRED' | translate }}</mat-error>
        <mat-error *ngIf="betweenEndDateFormControl.errors?.invalidDate">{{ 'SEARCH.DATE_RANGE_ADVANCED.ERROR.END_DATE.INVALID_FORMAT' | translate }}</mat-error>
        <mat-error *ngIf="betweenEndDateFormControl.errors?.required">{{ 'SEARCH.DATE_RANGE_ADVANCED.ERROR.END_DATE.REQUIRED' | translate }}</mat-error>
      </mat-form-field>
    </span>
  </mat-radio-group>
</ng-container>
