<div class="{{field.className}}" id="data-time-widget" [class.adf-invalid]="!field.isValid && isTouched()" [class.adf-left-label-input-container]="field.leftLabels">
    <div *ngIf="field.leftLabels">
        <label class="adf-label adf-left-label" [attr.for]="field.id">{{field.name | translate }} ({{field.dateDisplayFormat}})<span class="adf-asterisk" *ngIf="isRequired()">*</span></label>
     </div>
    <div>
        <mat-form-field class="adf-date-time-widget" [class.adf-left-label-input-datepicker]="field.leftLabels" [hideRequiredMarker]="true">
            <label class="adf-label" *ngIf="!field.leftLabels" [attr.for]="field.id">{{field.name | translate }} ({{field.dateDisplayFormat}})<span class="adf-asterisk" *ngIf="isRequired()">*</span></label>
            <input matInput
                   [id]="field.id"
                   [value]="field.value"
                   [required]="isRequired()"
                   [disabled]="field.readOnly"
                   (change)="onDateChanged($any($event).srcElement.value)"
                   [placeholder]="field.placeholder"
                   [matTooltip]="field.tooltip"
                   (blur)="markAsTouched()"
                   matTooltipPosition="above"
                   matTooltipShowDelay="1000"
                   (focus)="datetimePicker.open()">
            <mat-datetimepicker-toggle matSuffix [for]="datetimePicker" [disabled]="field.readOnly"></mat-datetimepicker-toggle>
        </mat-form-field>
        <error-widget [error]="field.validationSummary"></error-widget>
        <error-widget *ngIf="isInvalidFieldRequired() && isTouched()" required="{{ 'FORM.FIELD.REQUIRED' | translate }}"></error-widget>
        <mat-datetimepicker #datetimePicker type="datetime" [touchUi]="true" [timeInterval]="5" [disabled]="field.readOnly"></mat-datetimepicker>
        <input
            type="hidden"
            [matDatetimepicker]="datetimePicker"
            [value]="field.value | adfMomentDate: field.dateDisplayFormat"
            [min]="minDate"
            [max]="maxDate"
            [disabled]="field.readOnly"
            (dateInput)="onDateChanged($any($event).targetElement.value)">
    </div>
</div>
