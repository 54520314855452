import { Component, OnInit, ViewEncapsulation, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from '@alfresco/adf-core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TaskStatus, WorkflowService, canReassignTask, canClaimTask, canReleaseTask, TaskTransition, getAvailableTransitionsForTaskType, PageLayoutComponent, PageLayoutHeaderComponent, PageLayoutContentComponent } from '@alfresco/aca-shared';
import { AppStore, SnackbarErrorAction, SnackbarInfoAction, getUserProfile, getUserList } from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';
import { Node, Person } from '@alfresco/js-api';
import { Utils } from '../utils';
import { MatDialog } from '@angular/material/dialog';
import { SelectUserDialogComponent } from '../../../dialogs/select-user/select-user.dialog';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NeocomDocumentSelectorComponent } from '../../neocom-document-selector/neocom-document-selector.component';
import { BreadcrumbModule } from '@alfresco/adf-content-services';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    PageLayoutComponent,
    PageLayoutHeaderComponent,
    PageLayoutContentComponent,
    BreadcrumbModule,
    NeocomDocumentSelectorComponent,
    MatProgressSpinnerModule,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss']
})
export class TaskDetailsComponent implements OnInit {

  activitiId: string = '';
  isLoading: boolean = true;
  taskError: any = null;
  task: any;
  taskTransitions: TaskTransition[] = [];
  statusOptions: any[] = [];
  users: Person[] = [];

  initiallySelectedDocuments: Node[] = [];
  selectedDocuments: Node[] = [];

  taskDetailsForm = new FormGroup({
    status: new FormControl(undefined),
    comment: new FormControl(''),
  });

  actionStarted: boolean = false;
  userName: string = '';

  constructor(
    private store: Store<AppStore>,
    private translation: TranslationService,
    private workflowService: WorkflowService,
    private dialogRef: MatDialog,
    private router: Router,
    route: ActivatedRoute,
  ) {
    this.activitiId = `activiti$${route.snapshot.params.taskId}`;
    this.statusOptions = Utils.getTaskStatusOptions(translation);
  }

  ngOnInit() {
    this.getTaskDetails();

    this.store.select(getUserProfile).subscribe((user) => {
      this.userName = user.id;
    });
    this.store.select(getUserList).subscribe((userList) => {
      this.users = userList;
    });
  }

  getTaskDetails() {
    this.workflowService
      .getTaskDetails(this.activitiId)
      .then(async (data: any) => {
        this.task = data;
        this.taskTransitions = getAvailableTransitionsForTaskType(data.name);

        const currentStatus = data['properties']['bpm_status'];
        this.taskDetailsForm.get('status').setValue(currentStatus);
        const existingComment = data['properties']['bpm_comment'] ? data.properties['bpm_comment'] : '';
        this.taskDetailsForm.get('comment').setValue(existingComment);

        const workflowPackage = data['properties']['bpm_package'];
        try {
          const packageContents = await this.workflowService.getPackageContents(workflowPackage);
          this.initiallySelectedDocuments = packageContents;
          this.selectedDocuments = packageContents;
        } catch (_) {
          this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
        }

        this.isLoading = false;
      })
      .catch((err) => {
        this.taskError = err;
        this.isLoading = false;
      });
  }

  onFormSubmit(event) {
    event.preventDefault();
  }

  onDocumentSelectorSelectionChanged(newSelection: Node[]) {
    this.selectedDocuments = newSelection;
  }

  onDocumentSelectorNavigate(index: number) {
    const node = this.selectedDocuments[index];
    const url = `#/libraries/${node.parentId}/(viewer:view/${node.id})?location=%2Flibraries%2F${node.parentId}`;
    window.open(url, '_blank');
  }

  onSaveAndClose(_) {
    this._saveAndClose();
  }

  onCancel(_) {
    this.router.navigateByUrl('/workflow/my-tasks');
  }

  onReassign() {
    this.actionStarted = true;
    const userList = this.users.filter(el => el.id !== this.userName);
    const dialogInstance = this.dialogRef.open(SelectUserDialogComponent, {
      data: {
        userList,
      },
      width: '300px'
    });
    dialogInstance.afterClosed().subscribe((userName) => {
      if (userName) {
        this.workflowService.reassignTask(this.activitiId, userName)
          .then(() => {
            this.actionStarted = false;
            this.router.navigateByUrl('/workflow/my-tasks');
          })
          .catch(() => {
            this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
            this.actionStarted = false;
          });
      } else {
        this.actionStarted = false;
      }
    });
  }

  onClaim() {
    this.actionStarted = true;
    this.workflowService.claimTask(this.activitiId, this.userName)
      .then(() => {
        this.isLoading = true;
        this.actionStarted = false;
        this.getTaskDetails();
      })
      .catch(() => {
        this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
        this.actionStarted = false;
      });
  }

  onRelease() {
    this.actionStarted = true;
    this.workflowService.releaseTask(this.activitiId)
      .then(() => {
        this.isLoading = true;
        this.actionStarted = false;
        this.getTaskDetails();
      })
      .catch(() => {
        this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
        this.actionStarted = false;
      });
  }

  onApprove() {
    let transition: TaskTransition = null;
    if (this.taskTransitions.indexOf(TaskTransition.APPROVE) !== -1) {
      transition = TaskTransition.APPROVE;
    } else if (this.taskTransitions.indexOf(TaskTransition.APPROVE_DOCUMENT) !== -1) {
      transition = TaskTransition.APPROVE_DOCUMENT;
    }
    if (transition) {
      this._saveAndClose(transition);
    }
  }

  onReject() {
    let transition: TaskTransition = null;
    if (this.taskTransitions.indexOf(TaskTransition.REJECT) !== -1) {
      transition = TaskTransition.REJECT;
    } else if (this.taskTransitions.indexOf(TaskTransition.REJECT_DOCUMENT) !== -1) {
      transition = TaskTransition.REJECT_DOCUMENT;
    }
    if (transition) {
      this._saveAndClose(transition);
    }
  }

  onTaskDone() {
    let transition: TaskTransition = null;
    if (this.taskTransitions.indexOf(TaskTransition.TASK_DONE) !== -1) {
      transition = TaskTransition.TASK_DONE;
    }
    if (transition) {
      this._saveAndClose(transition);
    }
  }

  getTaskMessage() {
    return this.task['description'];
  }

  getTaskOwner() {
    const owner = this.task['owner'];
    if (!owner) {
      return this.translation.instant('APP.WORKFLOW.GENERAL.EMPTY_FIELD');
    }
    return `${owner.firstName}${owner.lastName ? (' ' + owner.lastName) : ''}`;
  }

  getTaskPriority() {
    const priority = this.task['properties']['bpm_priority'];
    return Utils.getPriorityTranslation(this.translation, priority);
  }

  getTaskDue() {
    const dueDateIsoString = this.task['properties']['bpm_dueDate'];
    if (dueDateIsoString) {
      const dueDate = Utils.dateFromISO8601(dueDateIsoString);
      return Utils.formatDate(dueDate);
    } else {
      return this.translation.instant('APP.WORKFLOW.GENERAL.EMPTY_FIELD');
    }
  }

  getTaskAdditionalDescription() {
    const additionalDescription = this.task['properties']['ncwf_moreDescription'];
    if (!additionalDescription) {
      return this.translation.instant('APP.WORKFLOW.GENERAL.EMPTY_FIELD');
    }
    return additionalDescription;
  }

  canReassign() {
    return canReassignTask(this.task['name']);
  }

  canClaim() {
    return canClaimTask(this.task['name'], this.userName, this.task['owner'] ? this.task['owner']['userName'] : null);
  }

  canRelease() {
    return canReleaseTask(this.task['name'], this.userName, this.task['owner'] ? this.task['owner']['userName'] : null);
  }

  canApprove() {
    return this.taskTransitions.indexOf(TaskTransition.APPROVE) !== -1 || this.taskTransitions.indexOf(TaskTransition.APPROVE_DOCUMENT) !== -1;
  }

  canReject() {
    return this.taskTransitions.indexOf(TaskTransition.REJECT) !== -1 || this.taskTransitions.indexOf(TaskTransition.REJECT_DOCUMENT) !== -1;
  }

  canTaskDone() {
    return this.taskTransitions.indexOf(TaskTransition.TASK_DONE) !== -1;
  }

  private _saveAndClose(transition: TaskTransition = null) {
    if (this.actionStarted) {
      return;
    }

    this.actionStarted = true;

    const documentsAdded: string[] = this.selectedDocuments
      .filter(el => !Boolean(this.initiallySelectedDocuments.find(el2 => el2.id === el.id)))
      .map(el => `workspace://SpacesStore/${el.id}`);
    const documentsRemoved: string[] = this.initiallySelectedDocuments
      .filter(el => !Boolean(this.selectedDocuments.find(el2 => el2.id === el.id)))
      .map(el => `workspace://SpacesStore/${el.id}`);
    const comment = this.taskDetailsForm.get('comment').value;
    const status = this.taskDetailsForm.get('status').value as TaskStatus;
    this.workflowService.editTask(this.activitiId, documentsAdded, documentsRemoved, comment, status, transition)
      .then(() => {
        this.store.dispatch(new SnackbarInfoAction(this.translation.instant('APP.WORKFLOW.TASK_DETAILS.SUCCESSFULLY_EDITED')));
        
        this.router.navigateByUrl('/workflow/my-tasks');

        this.actionStarted = false;
      })
      .catch(_ => {
        this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));

        this.actionStarted = false;
      });
  }
}
