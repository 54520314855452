<div class="adf-comments-container">
    <div id="comment-header" class="adf-comments-header adf-comments-divider">
        {{'COMMENTS.HEADER' | translate: { count: comments?.length } }}
    </div>
    <div *ngIf="!readOnly" class="adf-comments-input-container adf-comments-divider">
            <mat-form-field>
                <textarea
                    matInput
                    id="comment-input"
                    [attr.aria-label]="'COMMENTS.ADD' | translate"
                    placeholder="{{'COMMENTS.ADD' | translate}}"
                    [(ngModel)]="message"
                    (keydown.escape)="clearMessage($event)"
                >
                </textarea>
            </mat-form-field>

            <div class="adf-comments-input-actions">
                <button
                    mat-button
                    class="adf-comments-input-add"
                    data-automation-id="comments-input-add"
                    color="primary"
                    (click)="addComment()"
                    [disabled]="!message"
                >
                    {{ 'COMMENTS.ADD' | translate }}
                </button>
            </div>
    </div>

    <adf-comment-list *ngIf="comments?.length > 0" [comments]="comments">
    </adf-comment-list>
</div>
