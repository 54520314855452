<nav
    *ngIf="folderNode"
    data-automation-id="breadcrumb"
    class="adf-breadcrumb-container"
    role="navigation"
    [attr.aria-label]="'BREADCRUMB.ARIA-LABEL.BREADCRUMB' | translate"
>
    <button
        *ngIf="hasPreviousNodes()"
        tabindex="0"
        class="adf-breadcrumb-dropdown-trigger"
        (click)="open()"
        [attr.aria-label]="'BREADCRUMB.ARIA-LABEL.DROPDOWN' | translate"
    >
        <div class="adf-breadcrumb-dropdown-trigger-icon">
            <mat-icon [class.adf-isRoot]="!hasPreviousNodes()">folder</mat-icon>
            <mat-icon
                [class.adf-isRoot]="!hasPreviousNodes()"
                class="adf-breadcrumb-dropdown-trigger-arrow"
                >arrow_drop_down</mat-icon
            >
        </div>
    </button>

    <mat-select
        #dropdown
        *ngIf="hasPreviousNodes()"
        class="adf-breadcrumb-dropdown-path"
        tabindex="-1"
    >
        <mat-option
            *ngFor="let node of previousNodes"
            (click)="onRoutePathClick(node, $event)"
            (onSelectionChange)="onRouteClick(node)"
            class="adf-breadcrumb-path-option"
            [disabled]="readOnly"
        >
            {{ node.name | translate }}
        </mat-option>
    </mat-select>

    <div
        *ngFor="let item of lastNodes; let last = last"
        [class.adf-active]="last"
        [ngSwitch]="breadcrumbItemIsAnchor(last)"
        title="{{ item.name | translate }}"
        class="adf-breadcrumb-item">
        <a
            *ngSwitchCase="true"
            href="#"
            [attr.data-automation-id]="'breadcrumb_' + item.name"
            class="adf-breadcrumb-item-anchor"
            (click)="onRoutePathClick(item, $event)"
        >
            {{ item.name | translate }}
        </a>

        <div *ngSwitchDefault class="adf-breadcrumb-item-current" aria-current="location">
            {{ item.name | translate }}
        </div>

        <mat-icon class="adf-breadcrumb-item-chevron" *ngIf="!last">
            chevron_right
        </mat-icon>
    </div>
</nav>

<nav
    *ngIf="!folderNode && hasRoot"
    data-automation-id="breadcrumb"
    role="navigation"
    [attr.aria-label]="'BREADCRUMB.ARIA-LABEL.BREADCRUMB' | translate"
>
    <div class="adf-breadcrumb-item adf-active" role="group">
        <div class="adf-breadcrumb-item-current">
            {{ root | translate }}
        </div>
    </div>
</nav>
