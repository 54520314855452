/*!
 * @license
 * Alfresco Example Content Application
 *
 * Copyright (C) 2005 - 2020 Alfresco Software Limited
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail.  Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with Alfresco. If not, see <http://www.gnu.org/licenses/>.
 */

import { ArchiveService } from '@alfresco/aca-shared';
import { AppStore, getUserProfile, SnackbarErrorAction, SnackbarInfoAction } from '@alfresco/aca-shared/store';
import { TranslationService } from '@alfresco/adf-core';
import { Node } from '@alfresco/js-api';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatDialogModule,
    MatButtonModule,
  ],
  templateUrl: './send-to-archive.dialog.html',
  styleUrls: ['./send-to-archive.dialog.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'aca-send-to-archive-dialog' }
})
export class SendToArchiveDialogComponent implements OnInit {
  archives: { id: string; name: string }[];
  node: Node;
  today = new Date();
  userName: string;

  archiveForm = new FormGroup({
    selectedArchive: new FormControl(undefined, Validators.required),
    archiveUntil: new FormControl(),
    message: new FormControl('')
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private store: Store<AppStore>,
    private archiveService: ArchiveService,
    private translate: TranslationService,
    private dialogRef: MatDialogRef<SendToArchiveDialogComponent>
  ) {
    this.node = data.node.entry;
  }

  get selectedArchive() {
    return this.archiveForm.get('selectedArchive');
  }

  async ngOnInit() {
    this.archives = (await this.archiveService.getArchives()) as { id: string; name: string }[];
    this.store.select(getUserProfile).subscribe((user) => (this.userName = user.id));
  }

  async sendToArchive() {
    if (this.archiveForm.invalid) return;

    const request = { ...this.archiveForm.value };

    try {
      await this.archiveService.sendDocumentToArchive(
        `workspace://SpacesStore/${this.node.id}`,
        request.selectedArchive,
        request.archiveUntil,
        request.message,
        this.userName
      );

      this.store.dispatch(new SnackbarInfoAction(this.translate.instant('APP.ARCHIVE.SEND_TO_ARCHIVE.SUCCESS.SEND_TO_ARCHIVE')));
      this.dialogRef.close();
    } catch (e) {
      this.store.dispatch(new SnackbarErrorAction(this.translate.instant('APP.ARCHIVE.SEND_TO_ARCHIVE.ERROR.SEND_TO_ARCHIVE')));
    }
  }
}
