<aca-page-layout [hasError]="hasError">
  <aca-page-layout-header>
    <adf-breadcrumb [root]="title" [folderNode]="documentLibraryNode">
    </adf-breadcrumb>
    <div class="settings-buttons">
      <button mat-button (click)="openAddUserDialog()">{{ 'APP.SETTINGS.ADD_USERS' | translate }}</button>
    </div>
  </aca-page-layout-header>
  <aca-page-layout-error>
    <aca-generic-error></aca-generic-error>
  </aca-page-layout-error>
  <aca-page-layout-content>
    <div style="height: 100%; width: 100%; overflow-y: auto;">
      <adf-datatable 
        [rows]="membersRows" 
        [columns]="membersSchema"
        [actions]="true"
        [stickyHeader]="true"
        (showRowActionsMenu)="onShowRowActionsMenu($event)"
        (executeRowAction)="onExecuteRowAction($event)"> 
      </adf-datatable>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
