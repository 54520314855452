import { CustomApiCallService, HTTPMethod, PayloadType, ResponseType } from './custom-api-call.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArchiveService {
  constructor(private customApiCallService: CustomApiCallService) {}

  getArchives(): Promise<object[]> {
    return new Promise((resolve, reject) => {
      this.customApiCallService
        .callServiceApi('archive/get-archives', HTTPMethod.GET, null, null, null, ResponseType.JSON)
        .then((value) => {
          if (value && value.archives) {
            resolve(value.archives);
          } else {
            resolve(null);
          }
        })
        .catch((err) => reject(err));
    });
  }

  getRegistryBooks(site: string = null): Promise<object[]> {
    return new Promise((resolve, reject) => {
      return this.customApiCallService
        .callServiceApi(`archive/get-registry-books${site ? ('?site=' + site) : ''}`, HTTPMethod.GET, null, null, null, ResponseType.JSON)
        .then((value) => {
          if (value && value.registries) {
            resolve(value.registries);
          } else {
            resolve(null);
          }
        })
        .catch((err) => reject(err));
    });
  }

  getOrganizationalUnits(registryBookNodeRef: string): Promise<object[]> {
    return new Promise((resolve, reject) => {
      return this.customApiCallService
        .callServiceApi(`archive/get-org-units?registryNodeRef=${registryBookNodeRef}`, HTTPMethod.GET, null, null, null, ResponseType.JSON)
        .then((value) => {
          if (value && value.units) {
            resolve(value.units);
          } else {
            resolve(null);
          }
        })
        .catch((err) => reject(err));
    });
  }

  getArchiveSigns(unitNodeRef: string): Promise<object[]> {
    return new Promise((resolve, reject) => {
      this.customApiCallService
        .callServiceApi(`archive/get-archive-signs?unitNodeRef=${unitNodeRef}`, HTTPMethod.GET, null, null, null, ResponseType.JSON)
        .then((value) => {
          if (value && value.signs) {
            resolve(value.signs);
          } else {
            resolve(null);
          }
        })
        .catch((err) => reject(err));
    });
  }

  getInventoryOfActs(registryBookNodeRef: string): Promise<object[]> {
    return new Promise((resolve, reject) => {
      this.customApiCallService
        .callServiceApi(`archive/get-inventory-acts?registryNodeRef=${registryBookNodeRef}`, HTTPMethod.GET, null, null, null, ResponseType.JSON)
        .then((value) => {
          if (value && value.acts) {
            resolve(value.acts);
          } else {
            resolve(null);
          }
        })
        .catch((err) => reject(err));
    });
  }

  getSubjectAtIndex(registryBookNodeRef: string, index: number): Promise<object[]> {
    return new Promise((resolve, reject) => {
      this.customApiCallService
        .callServiceApi(
          `archive/get-element-at-index?registry=${registryBookNodeRef}&index=${index}`,
          HTTPMethod.GET,
          null,
          null,
          null,
          ResponseType.JSON
        )
        .then((value) => {
          if (value && value.subject) {
            resolve(value.subject);
          } else {
            resolve(null);
          }
        })
        .catch((err) => reject(err));
    });
  }

  getActs(inventoryOfActsNodeRef: string): Promise<object[]> {
    return new Promise((resolve, reject) => {
      this.customApiCallService
        .callServiceApi(
          `archive/get-acts?inventoryOfActs=${inventoryOfActsNodeRef}`,
          HTTPMethod.GET,
          null,
          null,
          null,
          ResponseType.JSON
        )
        .then((value) => {
          if (value) {
            resolve(value);
          } else {
            resolve(null);
          }
        })
        .catch((err) => reject(err));
    });
  }

  getSubjectsSenderNumber(registryBookNodeRef: string, senderNumber: string): Promise<object[]> {
    return new Promise((resolve, reject) => {
      this.customApiCallService
        .callServiceApi(
          `archive/get-subjects-sender-number?registryBook=${registryBookNodeRef}&senderNumber=${senderNumber}`,
          HTTPMethod.GET,
          null,
          null,
          null,
          ResponseType.JSON
        )
        .then((value) => {
          resolve(value);
        })
        .catch((err) => reject(err));
    });
  }

  getActsSenderNumber(inventoryOfActsNodeRef: string, senderNumber: string): Promise<object[]> {
    return new Promise((resolve, reject) => {
      this.customApiCallService
        .callServiceApi(
          `archive/get-acts-sender-number?inventoryOfActs=${inventoryOfActsNodeRef}&senderNumber=${senderNumber}`,
          HTTPMethod.GET,
          null,
          null,
          null,
          ResponseType.JSON
        )
        .then((value) => {
          resolve(value);
        })
        .catch((err) => reject(err));
    });
  }

  getArchiveGlobalConfiguration(): Promise<object> {
    return new Promise((resolve, reject) => {
      this.customApiCallService
        .callServiceApi(`archive/get-configuration`, HTTPMethod.GET, null, null, null, ResponseType.JSON)
        .then((value) => {
          if (value && value.config && value.config[0]) {
            resolve(value.config[0]);
          } else {
            resolve(null);
          }
        })
        .catch((err) => reject(err));
    });
  }

  archiveSubject(
    documentNodeRef: string,
    registryBookNodeRef: string,
    organizationalUnitNodeRef: string,
    archiveSignNodeRef: string,
    index: number,
    subIndex: number,
    subject: string,
    dateArchived: Date,
    sender: string,
    senderNumber: string,
    dateSent: Date,
    note: string
  ): Promise<any> {
    const payload = {
      nodeRefs: documentNodeRef ? [documentNodeRef] : null,
      registryBook: registryBookNodeRef,
      orgUnit: organizationalUnitNodeRef,
      archiveSign: archiveSignNodeRef,
      index: index !== null && index !== undefined && index >= 0 ? index : '',
      subIndex: subIndex !== null && subIndex !== undefined && subIndex >= 0 ? subIndex : '',
      subject: subject ? subject : '',
      dateArchived: dateArchived ? this.formatDate(dateArchived) : '',
      sender: sender ? sender : '',
      senderNumber: senderNumber ? senderNumber : '',
      dateSent: dateSent ? this.formatDate(dateSent) : '',
      senderId: '', // unused
      note: note ? note : ''
    };
    return this.customApiCallService.callServiceApi('archive/archive-document', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
  }

  archiveInventoryAct(
    documentNodeRef: string,
    registryBookNodeRef: string,
    inventoryOfActsNodeRef: string,
    dateArchived: Date,
    sender: string,
    senderId: string,
    senderNumber: string,
    dateSent: Date,
    divorceDate: Date,
    divorceSign: string,
    note: string,
    isInvoice: boolean,
    amount: number,
    currency: string
  ): Promise<any> {
    const payload = {
      nodeRef: documentNodeRef ? documentNodeRef : null,
      registryBook: registryBookNodeRef,
      invAct: inventoryOfActsNodeRef,
      dateArchived: dateArchived ? this.formatDate(dateArchived) : '',
      sender: sender ? sender : '',
      senderNumber: senderNumber ? senderNumber : '',
      senderId,
      dateSent: dateSent ? this.formatDate(dateSent) : '',
      divorceDate: divorceDate ? this.formatDate(divorceDate) : '',
      divorceSign: divorceSign ? divorceSign : '',
      note: note ? note : '',
      isInvoice: isInvoice ? 'true' : 'false',
      amount: amount !== null && amount !== undefined ? amount.toString() : '',
      currency: currency ? currency : ''
    };
    return this.customApiCallService.callServiceApi(
      'archive/archive-inventory-acts',
      HTTPMethod.POST,
      payload,
      PayloadType.JSON,
      null,
      ResponseType.JSON
    );
  }

  createInventoryOfActs(
    registyBookNodeRef: string,
    organizationalUnitNodeRef: string,
    archiveSignNodeRef: string,
    index: number,
    title: string
  ): Promise<any> {
    const payload = {
      registryBook: registyBookNodeRef,
      orgUnit: organizationalUnitNodeRef,
      archiveSign: archiveSignNodeRef,
      index,
      title
    };
    return this.customApiCallService.callServiceApi(
      'archive/create-inventory-acts',
      HTTPMethod.POST,
      payload,
      PayloadType.JSON,
      null,
      ResponseType.JSON
    );
  }

  // filter is one of: "all", "index", "sign", "date", "expired"
  getRegistryBookReport(registryBookNodeRef: string, filter: string, filterParams: any[] = null, page: number = 0): Promise<any> {
    const payload = {
      registryBook: registryBookNodeRef,
      filter,
      reportType: 'registry_book',
      page
    };
    if (filter === 'index') {
      payload['from'] = filterParams[0];
      payload['to'] = filterParams[1];
    } else if (filter === 'sign') {
      payload['sign'] = filterParams[0];
    } else if (filter === 'date') {
      let date = filterParams[0] as Date;
      payload['from'] = this.formatDate(date);
      date = filterParams[1] as Date;
      payload['to'] = this.formatDate(date);
    }
    return this.customApiCallService.callServiceApi('archive/get-report-data', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
  }

  // filter is one of: "all", "seqIndex", "date", "sentDate"
  getInventoryOfActsReport(registryBookNodeRef: string, inventoryOfActsNodeRef: string, filter: string, filterParams: any[] = null, page: number = 0): Promise<any> {
    const payload = {
      registryBook: registryBookNodeRef,
      invAct: inventoryOfActsNodeRef,
      filter,
      reportType: 'ioa_type',
      page
    };
    if (filter === 'seqIndex') {
      payload['from'] = filterParams[0];
      payload['to'] = filterParams[1];
    } else if (filter === 'date' || filter === 'sentDate') {
      let date = filterParams[0] as Date;
      payload['from'] = this.formatDate(date);
      date = filterParams[1] as Date;
      payload['to'] = this.formatDate(date);
    }
    return this.customApiCallService.callServiceApi('archive/get-report-data', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
  }

  getPendingDocuments(): Promise<any> {
    return this.customApiCallService.callServiceApi('archive/get-pending-documents', HTTPMethod.GET, null, null, null, ResponseType.JSON);
  }

  removePendingDocument(pendingDocumentNodeRef: string, reason: string): Promise<void> {
    const payload = {
      nodeRef: pendingDocumentNodeRef,
      reason: reason ? reason : ''
    };
    return this.customApiCallService.callServiceApi(
      'archive/delete-pending-document',
      HTTPMethod.POST,
      payload,
      PayloadType.JSON,
      null,
      ResponseType.JSON
    );
  }

  reservePendingDocument(pendingDocumentNodeRef: string, currentUserName: string): Promise<void> {
    const payload = {
      nodeRef: pendingDocumentNodeRef,
      currentUser: currentUserName
    };
    return this.customApiCallService.callServiceApi('archive/reserved-document', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
  }

  releaseReservedPendingDocument(pendingDocumentNodeRef: string, currentUserName: string): Promise<void> {
    const payload = {
      nodeRef: pendingDocumentNodeRef,
      currentUser: currentUserName
    };
    return this.customApiCallService.callServiceApi('archive/release-document', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
  }

  sendDocumentToArchive(
    documentNodeRef: string,
    archiveSiteName: string,
    mustBeArchivedUntil: Date,
    message: string,
    currentUserName: string
  ): Promise<any> {
    const payload = {
      additionalMessage: message ? message : '',
      archive: archiveSiteName,
      nodeRef: documentNodeRef,
      until: mustBeArchivedUntil ? this.formatDate(mustBeArchivedUntil) : '',
      user: currentUserName
    };
    return this.customApiCallService.callService('action/send-for-archiving', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
  }

  insertDocumentIntoSubject(
    documentNodeRef: string,
    registryBookNodeRef: string,
    index: string,
    subIndex: string,
  ): Promise<any> {
    const payload = {
      nodeRef: documentNodeRef,
      registry_book: registryBookNodeRef,
      index,
      subIndex,
    };
    return this.customApiCallService.callService('action/insert-into-subject', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
  }

  insertDocumentIntoAct(
    documentNodeRef: string,
    registryBookNodeRef: string,
    inventoryOfActsNodeRef: string,
    subIndex: string,
  ): Promise<any> {
    const payload = {
      nodeRef: documentNodeRef,
      registry_book: registryBookNodeRef,
      inventory_of_acts: inventoryOfActsNodeRef,
      subIndex,
    };
    return this.customApiCallService.callService('action/insert-into-act', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
  }

  deleteArchiveItem(documentNodeRef: string, type: string = 'item'): Promise<void> {
    const payload = {
      nodeRef: documentNodeRef,
      type
    };
    return this.customApiCallService.callServiceApi('archive/delete-archive-item', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
  }

  divorceDocument(documentNodeRef: string, description: string): Promise<void> {
    const payload = {
      nodeRef: documentNodeRef,
      description,
    };
    return this.customApiCallService.callServiceApi('archive/divorce-subject', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
  }

  renameSubject(documentNodeRef: string, subject: string, newArchiveSign: string | undefined = undefined): Promise<void> {
    const payload = {
      nodeRef: documentNodeRef,
      subject,
      archiveSignSearchValue: newArchiveSign,
    };
    return this.customApiCallService.callService('action/rename-subject', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
  }

  changeSender(
    documentNodeRef: string,
    sender: string,
    senderId: string,
  ): Promise<any> {
    const payload = {
      nodeRef: documentNodeRef,
      sender,
      senderId,
    };
    return this.customApiCallService.callService('action/change-sender', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
  }

  getDocumentProperties(documentNodeRef: string): Promise<any> {
    return this.customApiCallService.callServiceApi(`archive/get-document-properties?nodeRef=${documentNodeRef}`, HTTPMethod.GET, null, null, null, ResponseType.JSON);
  }

  getPageResolution(documentNodeRef: string): Promise<any> {
    return this.customApiCallService.callServiceApi(`archive/get-page-resolution?nodeRef=${documentNodeRef}`, HTTPMethod.GET, null, null, null, ResponseType.JSON);
  }

  getDocumentThumbnail(documentNodeRefId: string): Promise<Blob> {
    return this.customApiCallService.callServiceApi(`node/workspace/SpacesStore/${documentNodeRefId}/content/thumbnails/imgpreview?c=force&ph=true`, HTTPMethod.GET, null, null, null, ResponseType.BLOB);
  }

  stampDocument(documentNodeRef: string, type: string, archiveNumber: string, dateArchived: string, x: number, y: number, containerWidth: number, containerHeight: number): Promise<any> {
    const payload = {
      nodeRef: documentNodeRef,
      type,
      archiveNumber,
      dateArchived,
      coordinates: JSON.stringify({ x, y, containerWidth, containerHeight }),
    };
    return this.customApiCallService.callService(`action/stamp-document`, HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
  }

  classifyDocument(documentNodeRef: string, type: string, x: number, y: number, containerWidth: number, containerHeight: number): Promise<any> {
    const payload = {
      nodeRef: documentNodeRef,
      type,
      coordinates: JSON.stringify({ x, y, containerWidth, containerHeight }),
    };
    return this.customApiCallService.callService(`action/classify-document`, HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
  }

  getKomitenti(): Promise<any> {
    return this.customApiCallService.callServiceApi(`archive/get-komitenti`, HTTPMethod.GET, null, null, null, ResponseType.JSON);
  }

  getPrintReceiptInfo(): Promise<any> {
    return this.customApiCallService.callServiceApi('archive/get-print-receipt-info', HTTPMethod.GET, null, null, null, ResponseType.JSON);
  }

  getPrintReceiptHtml(documentNodeRef: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      let documentProperties = null, printReceiptInfo = null;
      try {
        const results = await Promise.all([
          this.getDocumentProperties(documentNodeRef),
          this.getPrintReceiptInfo(),
        ]);
        documentProperties = results[0];
        printReceiptInfo = results[1];
      } catch (error) {
        reject(error);
      }

      const offsets = printReceiptInfo.offsets;
      const mergeNumberInSign = printReceiptInfo.mergeNumberInSign;
      const stamp = documentProperties.stampImage;
      let index = null, seqIndex = null, sign = null;
      if (!mergeNumberInSign) {
        index = documentProperties.index;
        seqIndex = documentProperties.seqIndex;
        sign = documentProperties.archiveSign;
      } else {
        sign = documentProperties.archiveSign + "-" + documentProperties.index + "/" + documentProperties.seqIndex;
      }
      const dateArchived = printReceiptInfo.time ? documentProperties.dateArchived : documentProperties.dateArchived.split(" ")[0];
      const offsetSignLeft = offsets[0][0];
      const offsetSignTop = offsets[0][1];
      const offsetIndexLeft = offsets[1][0];
      const offsetIndexTop = offsets[1][1];
      const offsetDateArchivedLeft = offsets[2][0];
      const offsetDateArchivedTop = offsets[2][1];
      const fontSize = printReceiptInfo.smallerFont ? '11' : '13';

      let printHtml = `<img src="data:image/png;base64,${stamp}" alt="Stamp image" style="margin-left: 125px; position:absolute" /><span style="position: relative; top: ${offsetSignTop}px; left: ${offsetSignLeft}px; font-family: 'Open Sans', Arial, Helvetica, sans-serif; font-size: ${fontSize}px;">${sign}</span>`;
      if (index && seqIndex) {
        printHtml += `<span style="position: relative; top: ${offsetIndexTop}px; left: ${offsetIndexLeft}px; font-family: 'Open Sans', Arial, Helvetica, sans-serif; font-size: ${fontSize}px;">${index}/${seqIndex}</span>`;
      }
      printHtml += `<span style="position: relative; top: ${offsetDateArchivedTop}px; left: ${offsetDateArchivedLeft}px; font-family: 'Open Sans', Arial, Helvetica, sans-serif !IMPORTANT; font-size: ${fontSize}px;">${dateArchived}</span>`;

      resolve(printHtml);
    });
  }

  // d.m.Y
  private formatDate(date: Date): string {
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  }
}
