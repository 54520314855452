<div class="adf-pdf-viewer__container">
    <ng-container *ngIf="showThumbnails">
        <div class="adf-pdf-viewer__thumbnails">
            <div class="adf-thumbnails-template__container">
                <div class="adf-thumbnails-template__buttons">
                    <button
                        mat-icon-button
                        data-automation-id='adf-thumbnails-close'
                        (click)="toggleThumbnails()"
                        [attr.aria-label]="'ADF_VIEWER.ARIA.THUMBNAILS_PANLEL_CLOSE' | translate"
                        title="{{ 'ADF_VIEWER.ACTIONS.CLOSE' | translate }}">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>

                <ng-container *ngIf="thumbnailsTemplate">
                    <ng-container *ngTemplateOutlet="thumbnailsTemplate;context:pdfThumbnailsContext"></ng-container>
                </ng-container>

                <ng-container *ngIf="!thumbnailsTemplate">
                    <adf-pdf-thumbnails (close)="toggleThumbnails()" [pdfViewer]="pdfViewer"></adf-pdf-thumbnails>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <div class="adf-pdf-viewer__content">
        <div [id]="randomPdfId+'-viewer-pdf-viewer'" class="adf-viewer-pdf-viewer" (window:resize)="onResize()">
            <div [id]="randomPdfId+'-viewer-viewerPdf'" class="adf-pdfViewer" role="document" tabindex="0" aria-expanded="true">
                <div id="loader-container" class="adf-loader-container">
                    <div class="adf-loader-item">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="adf-pdf-viewer__toolbar" *ngIf="showToolbar">
    <adf-toolbar>

        <ng-container *ngIf="allowThumbnails">
            <button mat-icon-button
                    [attr.aria-label]="'ADF_VIEWER.ARIA.THUMBNAILS' | translate"
                    [attr.aria-expanded]="showThumbnails"
                    data-automation-id="adf-thumbnails-button"
                    [disabled]="isPanelDisabled"
                    (click)="toggleThumbnails()">
                <mat-icon>dashboard</mat-icon>
            </button>
            <adf-toolbar-divider></adf-toolbar-divider>
        </ng-container>

        <button
            id="viewer-previous-page-button"
            title="{{ 'ADF_VIEWER.ARIA.PREVIOUS_PAGE' | translate }}"
            attr.aria-label="{{ 'ADF_VIEWER.ARIA.PREVIOUS_PAGE' | translate }}"
            mat-icon-button
            (click)="previousPage()">
            <mat-icon>keyboard_arrow_up</mat-icon>
        </button>

        <button
            id="viewer-next-page-button"
            title="{{ 'ADF_VIEWER.ARIA.NEXT_PAGE' | translate }}"
            attr.aria-label="{{ 'ADF_VIEWER.ARIA.NEXT_PAGE' | translate }}"
            mat-icon-button
            (click)="nextPage()">
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>

        <div class="adf-pdf-viewer__toolbar-page-selector">
            <label for="page-selector">{{ 'ADF_VIEWER.PAGE_LABEL.SHOWING' | translate }}</label>
            <input #page
                   id="page-selector"
                   type="text"
                   data-automation-id="adf-page-selector"
                   pattern="-?[0-9]*(\.[0-9]+)?"
                   value="{{ displayPage }}"
                   [attr.aria-label]="'ADF_VIEWER.PAGE_LABEL.PAGE_SELECTOR_LABEL' | translate"
                   (keyup.enter)="inputPage(page.value)">
            <span>{{ 'ADF_VIEWER.PAGE_LABEL.OF' | translate }} {{ totalPages }}</span>
        </div>

        <div class="adf-pdf-viewer__toolbar-page-scale" data-automation-id="adf-page-scale">
            {{ currentScaleText }}
        </div>

        <button
            id="viewer-zoom-in-button"
            title="{{ 'ADF_VIEWER.ARIA.ZOOM_IN' | translate }}"
            attr.aria-label="{{ 'ADF_VIEWER.ARIA.ZOOM_IN' | translate }}"
            mat-icon-button
            (click)="zoomIn()">
            <mat-icon>zoom_in</mat-icon>
        </button>

        <button
            id="viewer-zoom-out-button"
            title="{{ 'ADF_VIEWER.ARIA.ZOOM_OUT' | translate }}"
            attr.aria-label="{{ 'ADF_VIEWER.ARIA.ZOOM_OUT' | translate }}"
            mat-icon-button
            (click)="zoomOut()">
            <mat-icon>zoom_out</mat-icon>
        </button>

        <button
            id="viewer-scale-page-button"
            role="button" aria-pressed="true"
            title="{{ 'ADF_VIEWER.ARIA.FIT_PAGE' | translate }}"
            attr.aria-label="{{ 'ADF_VIEWER.ARIA.FIT_PAGE' | translate }}"
            mat-icon-button
            (click)="pageFit()">
            <mat-icon>zoom_out_map</mat-icon>
        </button>

    </adf-toolbar>
</div>
