<mat-slider
    [(value)]="value"
    [min]="min"
    [max]="max"
    [step]="step"
    [thumbLabel]="thumbLabel"
    (change)="onChangedHandler($event)"
    data-automation-id="slider-range">
</mat-slider>

<div class="adf-facet-buttons" *ngIf="!settings?.hideDefaultAction">
    <button mat-button color="primary" (click)="clear()" data-automation-id="slider-btn-clear">
        {{ 'SEARCH.FILTER.ACTIONS.CLEAR' | translate }}
    </button>
</div>
