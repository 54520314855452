import { ContentApiService } from '@alfresco/aca-shared';
import { TranslationService } from '@alfresco/adf-core';
import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NodeActionsService } from '../../../../services/node-actions.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-reports-table',
  templateUrl: './reports-table.component.html',
  styleUrls: ['./reports-table.component.scss']
})
export class ReportsTableComponent implements OnChanges {
  private reportTypeHeaders = {
    registry_book: {
      mainHeaders: [
        { title: this.translation.instant('APP.ARCHIVE.INDEX'), rowSpan: 2, colSpan: 1 },
        { title: this.translation.instant('APP.ARCHIVE.SUBJECT').toUpperCase(), rowSpan: 2, colSpan: 1 },
        { title: this.translation.instant('APP.ARCHIVE.SUBJECT_INFO.SEQUENTIAL_INDEX'), rowSpan: 2, colSpan: 1 },
        { title: this.translation.instant('APP.ARCHIVE.REPORTS.DATE'), rowSpan: 2, colSpan: 1 },
        { title: this.translation.instant('APP.ARCHIVE.SUBJECT_INFO.SENDER').toUpperCase(), rowSpan: 1, colSpan: 2 },
        { title: this.translation.instant('APP.ARCHIVE.ORGANIZATIONAL_UNIT'), rowSpan: 2, colSpan: 1 },
        { title: this.translation.instant('APP.ARCHIVE.REPORTS.DIVORCE').toUpperCase(), rowSpan: 1, colSpan: 2 }
      ],
      subHeaders: [
        { title: this.translation.instant('APP.ARCHIVE.REPORTS.NAME_SURNAME_PLACE'), rowSpan: 1, colSpan: 1 },
        { title: this.translation.instant('APP.ARCHIVE.REPORTS.NUMBER_DATE'), rowSpan: 1, colSpan: 1 },
        { title: this.translation.instant('APP.ARCHIVE.REPORTS.DATE'), rowSpan: 1, colSpan: 1 },
        { title: this.translation.instant('APP.ARCHIVE.REPORTS.SIGN'), rowSpan: 1, colSpan: 1 }
      ]
    },
    ioa_type: {
      mainHeaders: [
        { title: this.translation.instant('APP.ARCHIVE.SEQUENTIAL_INDEX'), rowSpan: 2, colSpan: 1 },
        { title: this.translation.instant('APP.ARCHIVE.SUBJECT_INFO.DATE_ARCHIVED'), rowSpan: 2, colSpan: 1 },
        { title: this.translation.instant('APP.ARCHIVE.SUBJECT_INFO.SENDER').toUpperCase(), rowSpan: 1, colSpan: 2 },
        { title: this.translation.instant('APP.ARCHIVE.REPORTS.DIVORCE').toUpperCase(), rowSpan: 1, colSpan: 2 },
        { title: this.translation.instant('APP.ARCHIVE.NOTES'), rowSpan: 2, colSpan: 1 }
      ],
      subHeaders: [
        { title: this.translation.instant('APP.ARCHIVE.REPORTS.NAME_SURNAME_PLACE'), rowSpan: 1, colSpan: 1 },
        { title: this.translation.instant('APP.ARCHIVE.REPORTS.NUMBER_DATE'), rowSpan: 1, colSpan: 1 },
        { title: this.translation.instant('APP.ARCHIVE.REPORTS.DATE'), rowSpan: 1, colSpan: 1 },
        { title: this.translation.instant('APP.ARCHIVE.REPORTS.SIGN'), rowSpan: 1, colSpan: 1 }
      ]
    }
  };

  rows = [
    {
      name: 10,
      value: 10,
    },
    {
      name: 25,
      value: 25,
    },
    {
      name: 50,
      value: 50,
    },
    {
      name: 100,
      value: 100,
    },
  ];

  @Input() reportType: string; /*'registry_book' | 'ioa_type'*/
  @Input() reportData: any;
  
  headers: { mainHeaders: any[]; subHeaders: any[] };
  allData: any[] = [];
  data: any[] = [];
  currentPage = 1;
  numberOfPages = 1;

  paginationForm = new FormGroup({
    selectedPage: new FormControl(1),
    selectedReportLimit: new FormControl(this.rows[1].value, Validators.required),
  });

  constructor(
    private translation: TranslationService,
    private contentApiService: ContentApiService,
    private nodeActionsService: NodeActionsService
  ) { }

  get indexes(): number {
    return this.headers.mainHeaders.reduce((sum: number, header: any) => {
      return sum + header.colSpan;
    }, 0);
  }

  get selectedPage() {
    return this.paginationForm?.get('selectedPage');
  }

  get selectedReportLimit() {
    return this.paginationForm?.get('selectedReportLimit');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reportType) {
      this.data = [];
      this.allData = [];
      this.headers = this.reportTypeHeaders[changes.reportType.currentValue];
    }

    if (changes.reportData) {
      this.updateData();
    }
  }

  updateData() {
    this.currentPage = 1;
    this.selectedPage.setValue(1);
    this.numberOfPages = 1;
    
    if (this.reportType === 'registry_book') {
      this.combineData(this.reportData.data);
    } else {
      this.combineIoaData(this.reportData.data);
    }
    this.calculatePages();
    this.getDataForPage();
  }

  jumpToPage() {
    const pageValue = this.selectedPage.value;
    if (pageValue >= 1 && pageValue <= this.numberOfPages) {
      this.currentPage = pageValue;
      this.getDataForPage();
    } else {
      this.selectedPage.setValue(this.currentPage);
    }
  }

  async openDocument(nodeRef: string) {
    const nodeGuid = nodeRef.substring(nodeRef.lastIndexOf('/') + 1);
    const node = await this.contentApiService.getNodeInfo(nodeGuid).toPromise();
    const nodeParentId = this.nodeActionsService.getEntryParentId(node);
    const url = `/#/libraries/${nodeParentId}/(viewer:view/${nodeGuid})`;

    window.open(url, '_blank');
  }

  printTable() {
    var printWindow = window.open('', 'PRINT', 'height=400,width=600');
    printWindow.document.write('<html><head><title>' + this.translation.instant(this.reportType === 'registry_book' ? 'APP.ARCHIVE.REPORTS.REGISTRY_BOOK_REPORT' : 'APP.ARCHIVE.REPORTS.INVENTORY_OF_ACTS_REPORT')  + '</title><style type="text/css">');
    printWindow.document.write('.report-table { border-collapse: collapse; border: 1px solid #000; margin-top: 15px; margin-bottom: 40px; width: 100%; font-family: Muli, Helvetica, Arial, sans-serif; }');
    printWindow.document.write('.report-table thead tr { text-align: center; }');
    printWindow.document.write('.report-table tr { height: 37px; }');
    printWindow.document.write('.report-table th, .report-table td { border: 1px solid #000; padding: 2px; }');
    printWindow.document.write('.text-center { text-align: center !important; }');
    printWindow.document.write('</style></head><body>');

    let html = '<table class="report-table"><thead><tr>';
    this.headers.mainHeaders.forEach(header => {
      html += `<th rowSpan="${header.rowSpan}" colSpan="${header.colSpan}">${header.title}</th>`;
    });
    html += "</tr><tr>";
    this.headers.subHeaders.forEach(header => {
      html += `<th rowSpan="${header.rowSpan}" colSpan="${header.colSpan}">${header.title}</th>`;
    });
    html += '</tr><tr style="font-size: 10px;">';
    for (let i = 0, len = this.indexes; i < len; ++i) {
      html += `<td>${i + 1}</td>`;
    };
    html += '</tr></thead><tbody>';
    if (this.reportType === 'registry_book') {
      this.allData.forEach(row => {
        if (row.documents.length > 1) {
          html += '<tr style="height: auto;">';
          html += `<td class="text-center" rowSpan="${row.documents.length + 1}">${row.index}</td>`;
          html += '</tr>';
        }
        row.documents.forEach(document => {
          html += '<tr>';
          if (row.documents.length === 1) {
            html += `<td class="text-center">${row.index}</td>`;
          }
          html += `<td>${document.subject}</td>`;
          html += `<td class="text-center">${document.seqIndex}</td>`;
          html += `<td>${document.dateArchived}</td>`;
          html += `<td>${document.sender}</td>`;
          html += `<td>${document.senderNumber} - ${document.dateSent}</td>`;
          html += `<td>${document.archiveSign}</td>`;
          html += `<td>${document.divorceDate}</td>`;
          html += `<td>${document.divorceSign}</td>`;
          html += '</tr>';
        });
      });
    } else {
      this.allData.forEach(row => {
        if (row.documents.length > 1) {
          html += '<tr style="height: auto;">';
          html += `<td class="text-center" rowSpan="${row.documents.length + 1}">${row.seqIndex}</td>`;
          html += '</tr>';
        }
        row.documents.forEach(document => {
          html += '<tr>';
          if (row.documents.length === 1) {
            html += `<td class="text-center">${row.seqIndex}</td>`;
          }
          html += `<td>${document.dateArchived}</td>`;
          html += `<td>${document.sender}</td>`;
          html += `<td>${document.senderNumber}</td>`;
          html += `<td>${document.divorceDate}</td>`;
          html += `<td>${document.divorceSign}</td>`;
          html += `<td>${document.note}</td>`;
          html += '</tr>';
        });
      });
    }
    html += '</tbody></table>';

    printWindow.document.write(html);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.focus();

    printWindow.print();
    setTimeout(() => {
      printWindow.close();
    }, 0);
  }

  nextPage() {
    if (this.currentPage < this.numberOfPages) {
      this.currentPage++;
      this.selectedPage.setValue(this.currentPage);
      this.getDataForPage();
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.selectedPage.setValue(this.currentPage);
      this.getDataForPage();
    }
  }

  private combineData(data: any[]) {
    this.allData = data.reduce((previousValue: any[], currentValue: any, index: number, array: any[]) => {
      if (previousValue.find((value) => value.index === currentValue.index)) return previousValue;

      for (let i = index + 1; i < array.length; i++) {
        const value = array[i];
        if (value.index !== currentValue.index) continue;

        currentValue.documents = currentValue.documents.concat(value.documents);
      }

      previousValue.push(currentValue);
      return previousValue;
    }, []);
  }

  private combineIoaData(data: any[]) {
    const result = data.map(el => ({
      'seqIndex': el['seqIndex'],
      'documents': data.filter(el2 => el2['seqIndex'] === el['seqIndex']),
    }));
    this.allData = result.filter((obj, index) => result.findIndex((item) => item['seqIndex'] === obj['seqIndex']) === index);
  }

  private calculatePages() {
    if (this.reportType === 'ioa_type') {
      this.numberOfPages = Math.floor(this.allData.length / this.selectedReportLimit.value) + 1;
      if (this.allData.length % this.selectedReportLimit.value === 0)
        this.numberOfPages--;
    } else {
      let page = 1;
      let items = 0;
      this.allData.forEach((value: any) => {
        if (value.documents.length + items > page * this.selectedReportLimit.value) {
          page++;
          this.numberOfPages++;
        }

        items += value.documents.length;
      });
    }
  }

  private getDataForPage() {
    const index = (this.currentPage - 1) * this.selectedReportLimit.value;
    this.data = this.allData.slice(index, index + this.selectedReportLimit.value);
  }
}
