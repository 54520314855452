<div *ngIf="pagination?.hasMoreItems || isLoading" class="adf-infinite-pagination">

    <button mat-button
        *ngIf="!isLoading"
        class="adf-infinite-pagination-load-more"
        (click)="onLoadMore()"
        data-automation-id="adf-infinite-pagination-button">
            <ng-content></ng-content>
    </button>

    <mat-progress-bar *ngIf="isLoading"
        mode="indeterminate"
        class="adf-infinite-pagination-spinner"
        data-automation-id="adf-infinite-pagination-spinner"
        [attr.aria-label]="'DATA_LOADING' | translate"></mat-progress-bar>
</div>
