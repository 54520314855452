export const debounce = (wait: number) => {
  let timeout;

  return (cb: Function) => {
    const later = () => {
      clearTimeout(timeout);
      cb();
    };

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
  };
};
