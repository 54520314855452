import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { GroupService, UserService, Constants, debounce } from '@alfresco/aca-shared';
import { MatDialog, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { AppStore, SnackbarErrorAction, SnackbarInfoAction, getGroupList } from '@alfresco/aca-shared/store';
import { DataCellEvent, DataColumn, DataRowActionEvent, DataTableModule, TranslationService } from '@alfresco/adf-core';
import { Store } from '@ngrx/store';
import { ConfirmDialogComponent } from '@alfresco/adf-content-services';
import { Group } from '@alfresco/js-api';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    DataTableModule,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-user-groups-dialog',
  templateUrl: './user-groups-dialog.component.html',
  styleUrls: ['./user-groups-dialog.component.scss']
})
export class UserGroupsDialogComponent implements OnInit {
  mode = 0;

  userDisplayName: string;
  username: string;

  userGroupsSchema: DataColumn[];
  userGroupsRows: object[];

  userAddGroupsSchema: DataColumn[];
  userAddGroupsRows: object[];
  userAddGroupsRowsAll: object[];

  allGroups: Group[];
  allUserGroups: Group[];

  loading: Boolean = false;
  hasMadeGroupChange: Boolean = false;

  filterUserAddGroups: string = '';
  filterPlaceholder: string;
  filterDebounce: Function;

  constructor(
    private dialog: MatDialog,
    private store: Store<AppStore>,
    private translation: TranslationService,
    private userService: UserService,
    private groupService: GroupService,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    this.userDisplayName = data.displayName;
    this.username = data.username;

    this.userGroupsSchema = [
      {
        type: 'text',
        key: 'id',
        title: this.translation.instant('APP.SETTINGS.ID'),
        sortable: true
      },
      {
        type: 'text',
        key: 'displayName',
        title: this.translation.instant('APP.SETTINGS.DISPLAY_NAME'),
        cssClass: 'full-width',
        sortable: true
      }
    ];

    this.userAddGroupsSchema = [
      {
        type: 'text',
        key: 'id',
        title: this.translation.instant('APP.SETTINGS.ID'),
        sortable: true
      },
      {
        type: 'text',
        key: 'displayName',
        title: this.translation.instant('APP.SETTINGS.DISPLAY_NAME'),
        cssClass: 'full-width',
        sortable: true
      }
    ];

    this.filterPlaceholder = this.translation.instant('APP.SETTINGS.FILTER');
  }

  ngOnInit(): void {
    this.getUserGroups();
  }

  getUserGroups(utilizeTimeout = false) {
    const proc = () => {
      this.store.select(getGroupList).subscribe((groupList) => {
        this.allGroups = groupList;
        this.userService
            .getUserGroups(this.username)
            .then((userGroups) => {
              this.allUserGroups = userGroups;
              const groups = userGroups.map((entity) => {
                const result = { ...entity };
                if (result.id.indexOf('GROUP_') !== 0) {
                  const groupEntity = groupList.find((group) => group['id'] === entity.id);
                  if (groupEntity) result.displayName = groupEntity['displayName'];
                }
                return result;
              });
              this.userGroupsRows = groups;
              this.applyUserAddGroupsFilter(true);
              this.loading = false;
            })
            .catch((_) => {
              this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
              this.loading = false;
            });
      });
    };

    this.loading = true;

    if (!utilizeTimeout) {
      proc();
    } else {
      setTimeout(proc, Constants.GROUP_MEMBERSHIP_UPDATE_TIMEOUT_VALUE);
    }
  }

  onUserGroupsShowRowActionsMenu(event: DataCellEvent) {
    const removeAction = {
      id: 0,
      title: this.translation.instant('APP.ACTIONS.REMOVE')
    };
    event.value.actions = [removeAction];
  }

  onUserGroupsExecuteRowAction = (event: DataRowActionEvent) => {
    const args = event.value;
    if (args.action.id === 0) {
      this.onRemoveGroup(args.row.obj);
    }
  };

  onRemoveGroup(obj) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'APP.SETTINGS.REMOVE_USER_GROUP_TITLE',
        message: this.translation.instant('APP.SETTINGS.REMOVE_USER_GROUP_MESSAGE').replace('{0}', this.userDisplayName).replace('{1}', obj.displayName),
        yesLabel: 'APP.DIALOGS.CONFIRM_REMOVE.YES_LABEL',
        noLabel: 'APP.DIALOGS.CONFIRM_REMOVE.NO_LABEL'
      },
      minWidth: '250px'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.groupService
          .removeUserFromGroup(obj.id, this.username)
          .then(() => {
            this.store.dispatch(
              new SnackbarInfoAction(this.translation.instant('APP.SETTINGS.USER_REMOVED_MESSAGE_GROUP').replace('{0}', this.userDisplayName))
            );
            this.getUserGroups(true);
          })
          .catch((_) => {
            this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
          });
      }
    });
  }

  onUserGroupsAddShowRowActionsMenu(event: DataCellEvent) {
    const addUserGroupAction = {
      id: 0,
      title: this.translation.instant('APP.SETTINGS.ADD_USER_GROUP')
    };
    event.value.actions = [addUserGroupAction];
  }

  onUserGroupsAddExecuteRowAction = (event: DataRowActionEvent) => {
    const args = event.value;
    if (args.action.id === 0) {
      this.executeAddUserGroup(args.row.obj.id, args.row.obj.displayName);
    }
  };

  onRefreshList(): void {
    this.getUserGroups();
  }

  onAddUserGroup(): void {
    const groupIdsForUser = this.allUserGroups.map((u) => u['id']);
    this.userAddGroupsRowsAll = this.allGroups.filter((u) => groupIdsForUser.indexOf(u['id']) === -1 && !u['id'].startsWith('site_'));
    this.userAddGroupsRows = [...this.userAddGroupsRowsAll];
    this.filterUserAddGroups = '';
    this.mode = 1;
  }

  onViewUserGroups(): void {
    if (this.hasMadeGroupChange) {
      this.getUserGroups(true);
      this.hasMadeGroupChange = false;
    }
    this.mode = 0;
  }

  executeAddUserGroup(groupId: string, displayName: string) {
    this.hasMadeGroupChange = true;
    this.groupService
      .addUserToGroup(groupId, this.username)
      .then(() => {
        this.userAddGroupsRows = this.userAddGroupsRows.filter((u) => u['id'] !== groupId);
        this.store.dispatch(
          new SnackbarInfoAction(
            this.translation.instant('APP.SETTINGS.USER_ADDED_MESSAGE_GROUP').replace('{0}', this.userDisplayName).replace('{1}', displayName)
          )
        );
      })
      .catch((_) => {
        this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      });
  }

  applyUserAddGroupsFilter(skipTimeout = false) {
    if (!this.filterDebounce) this.filterDebounce = debounce(Constants.CONTINUED_TYPING_THRESHOLD_VALUE);

    const proc = () => {
      if (!this.filterUserAddGroups) this.userAddGroupsRows = this.userAddGroupsRowsAll;
      else
        this.userAddGroupsRows = this.userAddGroupsRowsAll.filter(
          (u) =>
            u['id'].toLowerCase().indexOf(this.filterUserAddGroups.toLowerCase()) !== -1 ||
            u['displayName'].toLowerCase().indexOf(this.filterUserAddGroups.toLowerCase()) !== -1
        );
    };

    if (!skipTimeout) {
      this.filterDebounce(proc);
    } else {
      proc();
    }
  }

  matDialogContentStyle(): string {
    let style = 'max-height: unset; overflow-y: auto !important; ';
    if (this.loading) style += 'height: 75px;';
    else if ((!this.userGroupsRows || this.userGroupsRows.length === 0) && this.mode === 0) style += 'height: 75px';
    else style += 'height: 500px;';
    return style;
  }
}
