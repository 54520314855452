<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="APP.BROWSE.ARCHIVE_ADMIN.MENU.CREATE_ARCHIVE.TITLE"></adf-breadcrumb>
  </aca-page-layout-header>
  
  <aca-page-layout-content>
    <div class="create-archive-main-content">
      <form [formGroup]="archivesForm" #formDirective="ngForm" novalidate>
        <div class="form-column">
          <mat-form-field appearance="standard" >
            <mat-label>{{ 'APP.ARCHIVE_ADMIN.ARCHIVE_NAME' | translate }}</mat-label>
            <input matInput formControlName="name" name="name" type="text" />
            <mat-hint>{{ 'APP.ARCHIVE_ADMIN.NAME_HINT' | translate }}</mat-hint>
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ 'APP.ARCHIVE_ADMIN.ARCHIVE_ID' | translate }}</mat-label>
            <input matInput formControlName="id" name="id" type="text" maxlength="4" [readonly]="editMode" />
            <mat-hint>{{ 'APP.ARCHIVE_ADMIN.ID_HINT' | translate }}</mat-hint>
          </mat-form-field>
        </div>
        <div class="form-column">
          <mat-form-field appearance="standard">
            <mat-select matSelect formControlName="archivers" [placeholder]="'APP.ARCHIVE_ADMIN.SELECT_ARCHIVERS' | translate" (selectionChange)="onArchiversSelectionChange($event)">
              <mat-option [value]="user" *ngFor="let user of filteredUsers">
                {{ user.displayName }}
              </mat-option>
              <mat-option *ngIf="!filteredUsers || !filteredUsers.length" [value]="" [disabled]="true"></mat-option>  <!-- needed for filtering -->
            </mat-select>
          </mat-form-field>
          <ul class="archivers-list">
            <li *ngFor="let user of selectedUsers; let i = index">{{ user.displayName }} <span (click)="onRemoveSelectedUser(i)">(X)</span></li>
          </ul>
        </div>
        <div class="form-column">
          <div class="change-stamp-container">
            <img
              src="#"
              class="stamp-image-component"
              alt="stamp-preview"
              [hidden]="!stampData"
              #stampPreviewComp />
            <input
              hidden
              (change)="onStampFileSelected($event)"
              type="file"
              accept=".png"
              id="user-stamp-input"
              #stampInput />
            <div class="form-button-container">
              <div class="float-left-container">
                <button
                  type="button"
                  mat-button
                  mat-raised-button
                  (click)="stampInput.click()">
                  {{ 'APP.ARCHIVE_ADMIN.SELECT_STAMP' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="form-footer">
          <button *ngIf="!editMode" mat-raised-button (click)="createArchive()">{{ 'APP.ARCHIVE.CREATE' | translate }}</button>
          <button *ngIf="editMode" mat-raised-button (click)="updateArchive()">{{ 'APP.ARCHIVE.SAVE' | translate }}</button>
          <button *ngIf="editMode" mat-raised-button (click)="cancelEdit()">{{ 'APP.ARCHIVE.CANCEL' | translate }}</button>
        </div>
      </form>
      <div *ngIf="archives && archives.length > 0" class="table">
        <adf-datatable
          [rows]="archives"
          [columns]="archivesSchema"
          [actions]="true"
          (showRowActionsMenu)="onShowRowActionsMenu($event)"
          (executeRowAction)="onExecuteRowAction($event)">
        </adf-datatable>
      </div>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
