import { CustomApiCallService, HTTPMethod, PayloadType, ResponseType } from './custom-api-call.service';
import { Injectable } from '@angular/core';
import { SiteService, SiteVisibility } from './site.service';

@Injectable({
  providedIn: 'root'
})
export class ArchiveAdminService {

  constructor(
    private customApiCallService: CustomApiCallService,
    private siteService: SiteService,
  ) { }

  getArchiveInfo(siteShortName: string): Promise<object> {
    return this.customApiCallService.callServiceApi(`archive/get-archive-info?shortName=${siteShortName}`, HTTPMethod.GET, null, null, null, ResponseType.JSON);
  }

  createArchive(fourLetterId: string, displayName: string, archivers: string[], stampImage: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const siteShortName = `ne0arch1v3${fourLetterId}`;
      this.siteService.createSite(siteShortName, displayName, '', SiteVisibility.PRIVATE)
        .then(_ => {
          const payload = {
            shortName: siteShortName,
            archivers,
            stampImage,
            stampOutgoingImage: stampImage,
          };
          this.customApiCallService.callServiceApi('archive/create-archive', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON)
            .then(() => resolve())
            .catch(err => reject(err));
        })
        .catch(err => reject(err));
    });
  }

  updateArchive(siteShortName: string, archivers: string[], stampImage: string): Promise<void> {
    const payload = {
      shortName: siteShortName,
      archivers,
      stampImage,
      stampOutgoingImage: stampImage,
    };
    return this.customApiCallService.callServiceApi(`archive/update-archive?shortName=${siteShortName}`, HTTPMethod.PUT, payload, PayloadType.JSON, null, ResponseType.JSON);
  }

  createRegistryBook(siteShortName: string, title: string, year: number, isActive: boolean): Promise<void> {
    const payload = {
      archive: siteShortName,
      isActive,
      isLayered: true,
      title,
      year: year.toString(),
    };
    return this.customApiCallService.callServiceApi('archive/create-registry-book', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
  }

  changeRegistryStatus(registryBookNodeRef: string): Promise<void> {
    const payload = {
      nodeRef: registryBookNodeRef,
    };
    return this.customApiCallService.callServiceApi('archive/change-registry-status', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON);
  }

  // groups array elements should not be prefixed with "GROUP_"
  createOrganizationalUnit(registryBookNodeRef: string, index: string, title: string, groups: string[]): Promise<void> {
    return new Promise((resolve, reject) => {
      const payload = {
        registry: registryBookNodeRef,
        index,
        title,
        groups: groups ? groups : [],
      };
      this.customApiCallService.callServiceApi('archive/create-org-unit', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON)
        .then(_ => resolve())
        .catch(err => reject(err));
    });
  }

  // groups array elements should not be prefixed with "GROUP_"
  updateOrganizationalUnit(organizationalUnitNodeRef: string, index: string, title: string, groups: string[]): Promise<void> {
    return new Promise((resolve, reject) => {
      const payload = {
        nodeRef: organizationalUnitNodeRef,
        index,
        title,
        groups: groups ? groups : [],
      };
      this.customApiCallService.callServiceApi('archive/update-org-unit', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON)
        .then(_ => resolve())
        .catch(err => reject(err));
    });
  }

  deleteOrganizationalUnit(organizationalUnitNodeRef: string): Promise<void> {
    return this.deleteNode(organizationalUnitNodeRef);
  }

  createArchiveSign(organizationalUnitNodeRef: string, index: string, title: string, monthsPreservation: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const payload = {
        unit: organizationalUnitNodeRef,
        index,
        title,
        months: monthsPreservation ? monthsPreservation.toString() : '0',
      };
      this.customApiCallService.callServiceApi('archive/create-archive-sign', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON)
        .then(_ => resolve())
        .catch(err => reject(err));
    });
  }

  updateArchiveSign(archiveSignNodeRef: string, index: string, title: string, monthsPreservation: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const payload = {
        nodeRef: archiveSignNodeRef,
        index,
        title,
        months: monthsPreservation ? monthsPreservation.toString() : '0',
      };
      this.customApiCallService.callServiceApi('archive//update-archive-sign', HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON)
        .then(_ => resolve())
        .catch(err => reject(err));
    });
  }

  deleteArchiveSign(archiveSignNodeRef: string): Promise<void> {
    return this.deleteNode(archiveSignNodeRef);
  }

  private deleteNode(nodeRef: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const nodeId = nodeRef.substring(nodeRef.lastIndexOf('/') + 1);
      this.customApiCallService.callApi(`-default-/public/alfresco/versions/1/nodes/${nodeId}`, HTTPMethod.DELETE, null, PayloadType.JSON, ResponseType.JSON)
        .then(value => resolve(value))
        .catch(err => reject(err));
    });
  }
}
