<div class="adf-new-version-max-width">
    <mat-radio-group class="adf-new-version-radio-group" [(ngModel)]="semanticVersion" (change)="onVersionChange()">
        <mat-radio-button class="adf-new-version-radio-button" id="adf-new-version-minor"[value]="'minor'">
            {{ 'ADF_VERSION_LIST.ACTIONS.UPLOAD.MINOR' | translate: { version: minorVersion } }}
        </mat-radio-button>
        <mat-radio-button class="adf-new-version-radio-button" id="adf-new-version-major" [value]="'major'">
            {{ 'ADF_VERSION_LIST.ACTIONS.UPLOAD.MAJOR' | translate: { version: majorVersion } }}
        </mat-radio-button>
    </mat-radio-group>
    <mat-form-field class="adf-new-version-max-width">
                    <textarea matInput [(ngModel)]="comment" class="adf-new-version-text-area" id="adf-new-version-text-area"
                              (change)="onCommentChange()"
                              placeholder="{{ 'ADF_VERSION_LIST.ACTIONS.UPLOAD.COMMENT' | translate }}"></textarea>
    </mat-form-field>

</div>
<div class="adf-version-upload-buttons">
    <adf-upload-version-button *ngIf="showUploadButton"
        data-automation-id="adf-new-version-file-upload"
        staticTitle="{{ 'ADF_VERSION_LIST.ACTIONS.UPLOAD.TITLE' | translate }}"
        [node]="node"
        [disabled]="!canUpload()"
        [rootFolderId]="node.parentId"
        tooltip="{{ 'ADF_VERSION_LIST.ACTIONS.UPLOAD.TOOLTIP' | translate }}"
        [comment]="comment"
        [versioning]="true"
        [file]="newFileVersion"
        [majorVersion]="isMajorVersion()"
        (success)="onSuccess($event)"
        (error)="onError($event)">
    </adf-upload-version-button>
    <button mat-raised-button (click)="cancelUpload()" id="adf-new-version-cancel"  *ngIf="showCancelButton" >{{
        'ADF_VERSION_LIST.ACTIONS.UPLOAD.CANCEL'| translate }}
    </button>
</div>
