<h2 mat-dialog-title>
  {{ 'APP.WORKFLOW.SHOW_ALL_WORKFLOWS.TITLE' | translate }}
</h2>

<mat-dialog-content class="adf-show-all-workflows-dialog-content">
  <mat-spinner *ngIf="loading" [diameter]="60" style="margin: 0 auto;"></mat-spinner>
  <div *ngIf="!loading">
    <div *ngIf="workflows.length === 0">
      {{ 'APP.WORKFLOW.WORKFLOW_LIST.NO_ITEMS' | translate }}
    </div>
    <div *ngIf="workflows.length > 0" class="datatable-container">
      <adf-datatable
        [rows]="workflowRows"
        [columns]="workflowSchema"
        (rowClick)="onRowClicked($event)">
      </adf-datatable>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
  <span class="adf-fill-remaining-space"></span>

  <button
    mat-button
    mat-dialog-close>
    {{ 'APP.ACTIONS.CLOSE' | translate }}
  </button>
</mat-dialog-actions>
