<div class="adf-share-link__dialog-content">
    <div class="adf-share-link__dialog-container">
        <div class="adf-share-link--row">
            <div
                data-automation-id="adf-share-dialog-title"
                class="adf-share-link__title adf-share-link__label adf-share-link__heading"
                role="heading"
                aria-level="1">
                {{ 'SHARE.DIALOG-TITLE' | translate }} {{ fileName }}
            </div>
            <mat-icon mat-dialog-close class="adf-share-link__close adf-share-link__icon">close</mat-icon>
        </div>
        <mat-dialog-content>
            <hr class="adf-share-link__separation-line" />
            <form [formGroup]="form" class="adf-share-link__form">
                <div class="adf-share-link--row">
                    <mat-icon class="adf-share-link__icon">timer</mat-icon>
                    <div class="adf-share-link__label adf-sharable-link">{{ 'SHARE.LINK-EXPIRY-DATE' | translate }}
                    </div>
                    <mat-slide-toggle
                        #slideToggleExpirationDate
                        [disabled]="!canUpdate"
                        color="primary"
                        data-automation-id="adf-expire-toggle"
                        aria-label="{{ 'SHARE.EXPIRES' | translate }}"
                        [checked]="time.value"
                        (change)="onToggleExpirationDate($event)">
                    </mat-slide-toggle>
                </div>
                <div
                    [style.display]="isExpiryDateToggleChecked ? 'block' : 'none'"
                    data-automation-id="adf-slide-toggle-checked"
                    class="adf-share-link__date-time-container">
                    <mat-form-field class="adf-full-width adf-float-label" floatLabel='never'>
                        <mat-label>{{ 'SHARE.EXPIRATION-PLACEHOLDER' | translate }}</mat-label>
                        <mat-datepicker-toggle
                            [disabled]="time.disabled"
                            [for]="datePicker"
                            matSuffix
                            class="adf-share-link__icon adf-share-link__calender-icon">
                        </mat-datepicker-toggle>
                        <mat-datepicker
                            #datePicker
                            (closed)="onDatePickerClosed()">
                        </mat-datepicker>
                        <input
                            class="adf-share-link__input"
                            #datePickerInput
                            matInput
                            placeholder="{{ 'SHARE.EXPIRATION-PLACEHOLDER' | translate }}"
                            [attr.aria-label]="'SHARE.EXPIRATION-LABEL' | translate"
                            [min]="minDate"
                            formControlName="time"
                            [matDatepicker]="datePicker" />
                    </mat-form-field>
                </div>
                <p class="adf-share-link__info adf-share-link__para">{{ 'SHARE.SHARE-LINK' | translate }}</p>
                <div class="adf-share-link--row">
                    <mat-slide-toggle
                        color="primary"
                        data-automation-id="adf-share-toggle"
                        aria-label="{{ 'SHARE.TITLE' | translate }}"
                        [checked]="isFileShared"
                        [disabled]="!canUpdate || isDisabled"
                        (change)="onSlideShareChange($event)">
                    </mat-slide-toggle>
                    <div class="adf-share-link__label adf-sharable-link">{{ 'SHARE.SHARABLE-LINK-CREATED' | translate }}
                    </div>
                </div>
                <mat-form-field
                    class="adf-full-width adf-float-label"
                    floatLabel='never'
                    [ngClass]="isLinkWithExpiryDate? 'adf-share-link__border-color' : ''">
                    <input
                        #sharedLinkInput
                        data-automation-id="adf-share-link"
                        class="adf-share-link__input"
                        matInput
                        cdkFocusInitial
                        placeholder="{{ 'SHARE.PUBLIC-LINK' | translate }}"
                        [attr.aria-label]="'SHARE.PUBLIC-LINK' | translate"
                        formControlName="sharedUrl"
                        readonly="readonly">
                    <mat-icon
                        class="adf-input-action adf-share-link__icon"
                        role="button"
                        matSuffix
                        [clipboard-notification]="'SHARE.CLIPBOARD-MESSAGE' | translate"
                        [adf-clipboard]
                        [attr.aria-label]="'SHARE.COPY_BUTTON_LABEL' | translate"
                        [target]="sharedLinkInput"
                        tabindex="0">
                        content_copy
                    </mat-icon>
                </mat-form-field>
                <p class="adf-share-link__warn adf-share-link__para" *ngIf="isLinkWithExpiryDate">
                    {{ 'SHARE.LINK-WITH-EXPIRY-SETTINGS' | translate }}
                </p>
                <div class="adf-share-link--row">
                    <mat-icon class="adf-share-link__icon">public</mat-icon>
                    <p
                        class="adf-share-link__info adf-sharable-link adf-share-link__public-content adf-share-link__para">
                        {{ 'SHARE.PUBLIC-CONTENT' | translate }}
                    </p>
                </div>
            </form>
            <hr class="adf-share-link__separation-line" />
        </mat-dialog-content>
        <div mat-dialog-actions>
            <button data-automation-id="adf-share-dialog-close" mat-button color="primary" mat-dialog-close>
                {{ 'SHARE.CLOSE' | translate }}
            </button>
        </div>
    </div>
</div>
