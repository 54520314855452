import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslationService } from '@alfresco/adf-core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-edit-role-dialog',
  templateUrl: './edit-role-dialog.component.html',
  styleUrls: ['./edit-role-dialog.component.scss'],
})
export class EditRoleDialogComponent implements OnInit {
 
  roleChoice: string;
  userDisplayName: string;

  constructor(
    private dialog: MatDialogRef<EditRoleDialogComponent>,
    private translation: TranslationService,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    this.roleChoice = data.currentRole;
    this.userDisplayName = data.userDisplayName;
  }

  ngOnInit(): void {
  }

  getRoleMessage(): string {
    return this.translation.instant('APP.SETTINGS.ADD_EDIT_USER_SITE_ROLE_MESSAGE')
      .replace('{0}', this.userDisplayName);
  }

  onCancel() {
    this.dialog.close();
  }

  onSubmit() {
    this.dialog.close(this.roleChoice);
  }
}
