/*!
 * @license
 * Alfresco Example Content Application
 *
 * Copyright (C) 2005 - 2020 Alfresco Software Limited
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail.  Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with Alfresco. If not, see <http://www.gnu.org/licenses/>.
 */

import { ArchiveService } from '@alfresco/aca-shared';
import { AppStore, SnackbarErrorAction } from '@alfresco/aca-shared/store';
import { TranslationService } from '@alfresco/adf-core';
import { Node } from '@alfresco/js-api';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ImageAnnotatorComponent } from '../../components/image-annotator/image-annotator.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    MatButtonModule,
    ImageAnnotatorComponent,
  ],
  templateUrl: './stamp-document.dialog.html',
  styleUrls: ['./stamp-document.dialog.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'aca-stamp-document-dialog' }
})
export class StampDocumentDialogComponent implements OnInit {
  
  static maxPreviewSize = { width: 300, height: 400 };

  node: Node;
  archiveNumber: string;
  dateArchived: string;
  annotationContentHtml: string;
  stampForm = new FormGroup({
    stampType: new FormControl('incoming'),
  });
  previewSize = { ...StampDocumentDialogComponent.maxPreviewSize };
  thumbnail = null;
  annotatorCoordinates = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private store: Store<AppStore>,
    private archiveService: ArchiveService,
    private translation: TranslationService,
    private dialogRef: MatDialogRef<StampDocumentDialogComponent>
  ) {
    this.node = data.node.entry;
    const archiveSign = this.node.properties["nc:archiveSign"];
    const index = this.node.properties["nc:index"];
    const seqIndex = this.node.properties["nc:seqIndex"];
		this.archiveNumber = archiveSign + "-" + index + "/" + seqIndex;
    const dateArchivedObj = new Date(this.node.properties["nc:dateArchived"]);
    this.dateArchived = `${dateArchivedObj.getDate()}.${dateArchivedObj.getMonth() + 1}.${dateArchivedObj.getFullYear()}`;
    this.annotationContentHtml = `${this.archiveNumber}<br />${this.dateArchived}`;
  }

  async ngOnInit() {
    try {
      const pageResolution = await this.archiveService.getPageResolution(`workspace://SpacesStore/${this.node.id}`)
      const maxPreviewSize = StampDocumentDialogComponent.maxPreviewSize;
      let finalWidth, finalHeight;
      if (pageResolution.width > maxPreviewSize.width && pageResolution.height > maxPreviewSize.height) {
        // if the larger dimension of the document is made to fit to the corresponding thumbnail dimension, then the size of the resulting thumbnail will be less than maximum
        if (pageResolution.width > pageResolution.height) {
          // width to fit
          finalWidth = maxPreviewSize.width;
          finalHeight = Math.round(pageResolution.height * finalWidth / pageResolution.width);
        } else {
          // height to fit
          finalHeight = maxPreviewSize.height;
          finalWidth = Math.round(pageResolution.width * finalHeight / pageResolution.height);
        }
      } else if (pageResolution.width > maxPreviewSize.width) {
        // width to fit
        finalWidth = maxPreviewSize.width;
        finalHeight = Math.round(pageResolution.height * finalWidth / pageResolution.width);
      } else if (pageResolution.height > maxPreviewSize.height) {
        // height to fit
        finalHeight = maxPreviewSize.height;
        finalWidth = Math.round(pageResolution.width * finalHeight / pageResolution.height);
      } else {
        // original dimensions are fine
        finalWidth = pageResolution.width;
        finalHeight = pageResolution.height;
      }
      this.previewSize = { width: finalWidth, height: finalHeight };
      const newDialogWidth = (finalWidth > 180 ? finalWidth : 180) + 52;
      this.dialogRef.updateSize(newDialogWidth);

      const thumbnailBlob = await this.archiveService.getDocumentThumbnail(this.node.id);
      if (thumbnailBlob.type === 'image/jpeg' || thumbnailBlob.type === 'image/png') {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
          this.thumbnail = event.target.result;
        };
        fileReader.readAsDataURL(thumbnailBlob);
      } else {
        throw new Error('unexpected image format');
      }
    } catch (error) {
      this.store.dispatch(
        new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }

  annotatorMode(): ImageAnnotatorComponent.Mode {
    const stampType = this.stampForm.get('stampType').value;
    if (stampType === 'incoming') {
      return ImageAnnotatorComponent.Mode.Rect;
    } else {
      return ImageAnnotatorComponent.Mode.Text;
    }
  }

  onAnnotatorCoordinatesChanged(event): void {
    this.annotatorCoordinates = event;
  }

  onAnnotatorCoordinatesReset(): void {
    this.annotatorCoordinates = null;
  }

  async stampDocument() {
    if (!this.annotatorCoordinates) {
      this.store.dispatch(
        new SnackbarErrorAction(this.translation.instant('APP.ARCHIVE.STAMP_DOCUMENT.MISSING_COORDINATES')));
      return;
    }
    const stampType = this.stampForm.get('stampType').value;
    try {
      await this.archiveService.stampDocument(`workspace://SpacesStore/${this.node.id}`, stampType, this.archiveNumber, this.dateArchived, this.annotatorCoordinates.x, this.annotatorCoordinates.y, this.previewSize.width, this.previewSize.height);
      this.dialogRef.close(true);
    } catch (error) {
      this.store.dispatch(
        new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      this.dialogRef.close(false);
    }
  }
}
