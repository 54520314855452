<mat-chip-list class="adf-tag-chips-list">
    <div class="adf-list-tag" *ngFor="let currentEntry of tagsEntries; let idx = index">
        <mat-chip>
            <span id="tag_name_{{idx}}">{{currentEntry.entry.tag}}</span>
        </mat-chip>
    </div>
</mat-chip-list>

<div class="adf-tag-list-controls">
    <button
        *ngIf="!isSizeMinimum"
        [disabled]="isLoading"
        data-automation-id="show-fewer-tags"
        mat-icon-button
        (click)="loadLessTags()">
        <mat-icon>keyboard_arrow_up</mat-icon>
    </button>
    <button
        *ngIf="pagination.hasMoreItems"
        [disabled]="isLoading"
        data-automation-id="show-more-tags"
        mat-icon-button
        (click)="loadMoreTags()">
        <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
</div>
