<div class="sdc-container">
  <div class="sdc-list">
    <div *ngIf="selection.length === 0" class="sdc-empty">{{ 'APP.NEOCOM_DOCUMENT_SELECTOR.NO_DOCUMENTS' | translate }}.</div>
    <div class="sdc-list-item" *ngFor="let document of selection; let i = index">
      <img class="sdcli-icon" [src]="selectionThumbnails[i]" (click)="onPreview($event, i)" />
      <div class="sdcli-row">
        <p><span class="sdcclir-title" (click)="onPreview($event, i)">{{ document.name }}</span></p>
        <p><span class="sdcclir-modified">{{ 'APP.DOCUMENT_LIST.COLUMNS.MODIFIED_ON' | translate }}:</span> {{ formatDate(document.modifiedAt) }}</p>
      </div>
      <mat-icon class="sdcli-close" (click)="onRemove($event, i)" [style.display]="!readOnly ? 'block' : 'none'">cancel</mat-icon>
    </div>
  </div>
  <div class="sdc-controls" [style.display]="!readOnly ? 'block' : 'none'">
    <button mat-raised-button (click)="onAdd($event)" type="button">{{ 'APP.NEOCOM_DOCUMENT_SELECTOR.ADD_DOCUMENT' | translate }}</button>
    <button mat-raised-button (click)="onRemoveAll($event)" type="button" [style.display]="selection.length === 0 ? 'none' : 'inline-block'">{{ 'APP.NEOCOM_DOCUMENT_SELECTOR.REMOVE_ALL_DOCUMENTS' | translate }}</button>
  </div>
</div>