<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="APP.BROWSE.WORKFLOW.MENU.MY_WORKFLOWS.TITLE"> </adf-breadcrumb>
  </aca-page-layout-header>
  <aca-page-layout-content>
    <div class="workflows-main-content">
      <div class="wmc-header">
        <div class="wmc-header-row1">
          <mat-button-toggle-group [(ngModel)]="workflowActive" (change)="onFormChange($event)">
            <mat-button-toggle value="active">{{ 'APP.WORKFLOW.GENERAL.ACTIVE' | translate }}</mat-button-toggle>
            <mat-button-toggle value="complete">{{ 'APP.WORKFLOW.GENERAL.COMPLETE' | translate }}</mat-button-toggle>
          </mat-button-toggle-group>

          <span class="wmc-header-row1-right">
            <button mat-button (click)="onSortByDate($event)">
              {{ currentSort === 'date' ? '•' : '' }}
              {{ 'APP.WORKFLOW.GENERAL.DATE_STARTED' | translate }}
              <mat-icon>{{ sortDateAsc ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
            </button>
            <button mat-button (click)="onSortByPriority($event)">
              {{ currentSort === 'priority' ? '•' : '' }}
              {{ 'APP.WORKFLOW.GENERAL.PRIORITY' | translate }}
              <mat-icon>{{ sortPriorityAsc ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
            </button>

            <button mat-raised-button (click)="onToggleShowFilters($event)">
              <mat-icon>filter_list</mat-icon>
            </button>
          </span>
        </div>
        <div class="wmc-header-row2" [hidden]="!showFilters" (change)="onFilterFormChange($event)">
          <form [formGroup]="filterForm" (keydown.enter)="onFormEnter($event)" novalidate>
            <mat-form-field appearance="standard" id="mff-description">
              <mat-label>{{ 'APP.WORKFLOW.GENERAL.DESCRIPTION' | translate }}</mat-label>
              <input matInput formControlName="description" name="description" type="text" />
            </mat-form-field>
            <mat-form-field appearance="standard" id="mff-priority">
              <mat-label>{{ 'APP.WORKFLOW.GENERAL.PRIORITY' | translate }}</mat-label>
              <mat-select formControlName="priority" (selectionChange)="onFilterFormChange($event)">
                <mat-option *ngFor="let priorityOption of priorityOptions" [value]="priorityOption.id">{{ priorityOption.value }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="standard" id="mff-date-started-from">
              <mat-label>{{ 'APP.WORKFLOW.GENERAL.DATE_STARTED_FROM' | translate }}</mat-label>
              <input matInput [matDatepicker]="dateStartedFromPicker" [max]="today" formControlName="dateStartedFrom" (dateChange)="onFilterFormChange($event)" name="dateStartedFrom" readonly />
              <mat-datepicker-toggle matSuffix [for]="dateStartedFromPicker"></mat-datepicker-toggle>
              <mat-datepicker #dateStartedFromPicker  disabled="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="standard" id="mff-date-started-to">
              <mat-label>{{ 'APP.WORKFLOW.GENERAL.DATE_STARTED_TO' | translate }}</mat-label>
              <input matInput [matDatepicker]="dateStartedToPicker" [max]="today" formControlName="dateStartedTo" (dateChange)="onFilterFormChange($event)" name="dateStartedTo" readonly />
              <mat-datepicker-toggle matSuffix [for]="dateStartedToPicker"></mat-datepicker-toggle>
              <mat-datepicker #dateStartedToPicker disabled="false"></mat-datepicker>
            </mat-form-field>
            <button mat-button (click)="onResetFilters($event)">
              <mat-icon>clear_all</mat-icon>
            </button>
          </form>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="wmc-body">
        <mat-spinner *ngIf="isLoading" [diameter]="60" style="margin: 0 auto;"></mat-spinner>
        <div *ngIf="!isLoading">
          <div *ngIf="workflows.length === 0">
            {{ 'APP.WORKFLOW.WORKFLOW_LIST.NO_ITEMS' | translate }}
          </div>

          <div *ngIf="workflows.length > 0">
            <div class="wmcb-list">
              <div *ngFor="let workflow of workflows" class="wmcbl-workflow">
                <img [src]="getWorkflowIconURL(workflow)" alt="workflow icon" (click)="onClickWorkflow(workflow.id)" />
                <div class="wmcblt-data">
                  <div class="wmcbltd-row" (click)="onClickWorkflow(workflow.id)">{{ workflow.message }}</div>
                  <div class="wmcbltd-row">
                    <span class="wmcbltdr-pair">
                      <span class="wmcbltdrp-label">{{ 'APP.WORKFLOW.GENERAL.SENT' | translate }}: </span>
                      <span class="wmcbltdrp-value">{{ getWorkflowSentValue(workflow) }}</span>
                    </span>
                    <span class="wmcbltdr-pair">
                      <span class="wmcbltdrp-label">{{ 'APP.WORKFLOW.GENERAL.FROM' | translate }}: </span>
                      <span class="wmcbltdrp-value">{{ getWorkflowFromValue(workflow) }}</span>
                    </span>
                    <span class="wmcbltdr-pair">
                      <span class="wmcbltdrp-label">{{ 'APP.WORKFLOW.GENERAL.PRIORITY' | translate }}: </span>
                      <span class="wmcbltdrp-value">{{ getWorkflowPriorityValue(workflow) }}</span>
                    </span>
                  </div>
                  <div class="wmcbltd-row">
                    <span class="wmcbltdr-pair">
                      <span class="wmcbltdrp-label">{{ 'APP.WORKFLOW.GENERAL.TYPE' | translate }}: </span>
                      <span class="wmcbltdrp-value">{{ getWorkflowTypeValue(workflow) }}</span>
                    </span>
                    <span [hidden]="!workflow['dueDate']" class="wmcbltdr-pair">
                      <span class="wmcbltdrp-label">{{ 'APP.WORKFLOW.GENERAL.DUE' | translate }}: </span>
                      <span class="wmcbltdrp-value">{{ getWorkflowDueValue(workflow) }}</span>
                    </span>
                    <span [hidden]="!workflow['endDate']" class="wmcbltdr-pair">
                      <span class="wmcbltdrp-label">{{ 'APP.WORKFLOW.GENERAL.ENDED' | translate }}: </span>
                      <span class="wmcbltdrp-value">{{ getWorkflowEndedValue(workflow) }}</span>
                    </span>
                  </div>
                </div>
                <mat-icon class="wmcblw-delete" (click)="onRemoveWorkflow(workflow.id)">delete</mat-icon>
              </div>
            </div>
    
            <div class="wmcb-pagination">
              <div class="wmcbp-inner-container">
                <button mat-button [disabled]="currentPage <= 1" (click)="onPreviousPage()">
                  <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <mat-form-field appearance="standard" id="pagination-page-input">
                  <input matInput [(ngModel)]="selectedPage" (change)="onJumpToPage()" />
                </mat-form-field>
                <span class="wmcbp-pages-larger-font">/</span>
                <span class="wmcbp-total-pages wmcbp-pages-larger-font">{{ numberOfPages }}</span>
                <button mat-button [disabled]="currentPage >= numberOfPages" (click)="onNextPage()">
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
