<div *ngIf="showHeader" class="adf-info-drawer-layout-header">
    <div class="adf-info-drawer-layout-header-title">
        <ng-content select="[info-drawer-title]"></ng-content>
    </div>
    <div class="adf-info-drawer-layout-header-buttons">
        <ng-content select="[info-drawer-buttons]"></ng-content>
    </div>
</div>
<div class="adf-info-drawer-layout-content">
    <ng-content select="[info-drawer-content]"></ng-content>
</div>
