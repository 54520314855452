<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="APP.BROWSE.WORKFLOW.MENU.START_WORKFLOW.TITLE"> </adf-breadcrumb>
  </aca-page-layout-header>
  <aca-page-layout-content>
    <div class="start-workflow-main-content">
      <form [formGroup]="workflowForm" novalidate (ngSubmit)="onFormSubmit($event)">
        <mat-form-field appearance="fill" id="wff-workflow-definition" placeholder="">
          <mat-label>{{ 'APP.WORKFLOW.START_WORKFLOW.SELECT_WORKFLOW_LABEL' | translate }}</mat-label>
          <mat-select formControlName="workflowDefinition" name="workflowDefinition">
            <mat-option *ngFor="let workflowDefinition of workflowDefinitions" [value]="workflowDefinition.id">{{ workflowDefinition.title }}</mat-option>
          </mat-select>
        </mat-form-field>

        <div [hidden]="!workflowDefinition" id="wf-main-input-container">
          <div class="wfmic-section">
            <mat-form-field appearance="standard" id="wff-title">
              <mat-label>{{ 'APP.WORKFLOW.START_WORKFLOW.TITLE' | translate }}</mat-label>
              <textarea matInput formControlName="title" name="title" type="text"></textarea>
            </mat-form-field>
          </div>
          <div class="wfmic-section">
            <span class="wfmics-title">{{ 'APP.WORKFLOW.GENERAL.ASSIGNEES' | translate }}</span>
            <div [hidden]="!workflowDefinition || workflowDefinition !== 'multipleReviewArchDoc'" class="wfmicsa-container">
              <mat-form-field appearance="standard" id="wff-users-MRAD">
                <mat-label>{{ 'APP.WORKFLOW.GENERAL.USERS' | translate }}</mat-label>
                <input matInput formControlName="usersMRAD" [matAutocomplete]="autocompleteUsersMRAD" />
                <mat-autocomplete #autocompleteUsersMRAD="matAutocomplete" (optionSelected)="onAutocompleteUsersMRADSelect($event)">
                  <mat-option *ngFor="let user of usersFilteredMRAD | async" [value]="user.id">
                    {{ user.firstName + (user.lastName ? (' ' + user.lastName) : '') }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <div class="wfmics-user-chips">
                <span *ngFor="let user of usersSelectedMRAD">
                  <mat-icon (click)="onRemoveUsersSelectedMRAD($event, user.id)">cancel</mat-icon>{{ user.firstName + (user.lastName ? (' ' + user.lastName) : '') }}
                </span>
              </div>
            </div>
            <div [hidden]="!workflowDefinition || workflowDefinition !== 'multipleLevelApproveDoc'" class="wfmicsa-container">
              <div class="wfmicsac-mlad-level">
                <mat-form-field appearance="standard" class="wff-users-mlad-level">
                  <mat-label>{{ 'APP.WORKFLOW.GENERAL.MLAD.USERS_L1' | translate }}</mat-label>
                  <input matInput formControlName="usersMLADL1" [matAutocomplete]="autocompleteUsersMLADL1" />
                  <mat-autocomplete #autocompleteUsersMLADL1="matAutocomplete" (optionSelected)="onAutocompleteUsersMLADL1Select($event)">
                    <mat-option *ngFor="let user of usersFilteredMLADL1 | async" [value]="user.id">
                      {{ user.firstName + (user.lastName ? (' ' + user.lastName) : '') }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="standard" class="wff-users-mlad-percent">
                  <mat-label>{{ 'APP.WORKFLOW.GENERAL.APPROVAL_PERCENTAGE' | translate }}</mat-label>
                  <input matInput formControlName="usersMLADL1percent" type="number" />
                </mat-form-field>
                <div class="wfmics-user-chips">
                  <span *ngFor="let user of usersSelectedMLADL1">
                    <mat-icon (click)="onRemoveUsersSelectedMLADL1($event, user.id)">cancel</mat-icon>{{ user.firstName + (user.lastName ? (' ' + user.lastName) : '') }}
                  </span>
                </div>
              </div>
              <div [hidden]="mladLevelCount < 2" class="wfmicsac-mlad-level">
                <mat-form-field appearance="standard" class="wff-users-mlad-level">
                  <mat-label>{{ 'APP.WORKFLOW.GENERAL.MLAD.USERS_L2' | translate }}</mat-label>
                  <input matInput formControlName="usersMLADL2" [matAutocomplete]="autocompleteUsersMLADL2" />
                  <mat-autocomplete #autocompleteUsersMLADL2="matAutocomplete" (optionSelected)="onAutocompleteUsersMLADL2Select($event)">
                    <mat-option *ngFor="let user of usersFilteredMLADL2 | async" [value]="user.id">
                      {{ user.firstName + (user.lastName ? (' ' + user.lastName) : '') }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="standard" class="wff-users-mlad-percent">
                  <mat-label>{{ 'APP.WORKFLOW.GENERAL.APPROVAL_PERCENTAGE' | translate }}</mat-label>
                  <input matInput formControlName="usersMLADL2percent" type="number" />
                </mat-form-field>
                <div class="wfmics-user-chips">
                  <span *ngFor="let user of usersSelectedMLADL2">
                    <mat-icon (click)="onRemoveUsersSelectedMLADL2($event, user.id)">cancel</mat-icon>{{ user.firstName + (user.lastName ? (' ' + user.lastName) : '') }}
                  </span>
                </div>
              </div>
              <div [hidden]="mladLevelCount < 3" class="wfmicsac-mlad-level">
                <mat-form-field appearance="standard" class="wff-users-mlad-level">
                  <mat-label>{{ 'APP.WORKFLOW.GENERAL.MLAD.USERS_L3' | translate }}</mat-label>
                  <input matInput formControlName="usersMLADL3" [matAutocomplete]="autocompleteUsersMLADL3" />
                  <mat-autocomplete #autocompleteUsersMLADL3="matAutocomplete" (optionSelected)="onAutocompleteUsersMLADL3Select($event)">
                    <mat-option *ngFor="let user of usersFilteredMLADL3 | async" [value]="user.id">
                      {{ user.firstName + (user.lastName ? (' ' + user.lastName) : '') }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="standard" class="wff-users-mlad-percent">
                  <mat-label>{{ 'APP.WORKFLOW.GENERAL.APPROVAL_PERCENTAGE' | translate }}</mat-label>
                  <input matInput formControlName="usersMLADL3percent" type="number" />
                </mat-form-field>
                <div class="wfmics-user-chips">
                  <span *ngFor="let user of usersSelectedMLADL3">
                    <mat-icon (click)="onRemoveUsersSelectedMLADL3($event, user.id)">cancel</mat-icon>{{ user.firstName + (user.lastName ? (' ' + user.lastName) : '') }}
                  </span>
                </div>
              </div>
              <div [hidden]="mladLevelCount < 4" class="wfmicsac-mlad-level">
                <mat-form-field appearance="standard" class="wff-users-mlad-level">
                  <mat-label>{{ 'APP.WORKFLOW.GENERAL.MLAD.USERS_L4' | translate }}</mat-label>
                  <input matInput formControlName="usersMLADL4" [matAutocomplete]="autocompleteUsersMLADL4" />
                  <mat-autocomplete #autocompleteUsersMLADL4="matAutocomplete" (optionSelected)="onAutocompleteUsersMLADL4Select($event)">
                    <mat-option *ngFor="let user of usersFilteredMLADL4 | async" [value]="user.id">
                      {{ user.firstName + (user.lastName ? (' ' + user.lastName) : '') }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="standard" class="wff-users-mlad-percent">
                  <mat-label>{{ 'APP.WORKFLOW.GENERAL.APPROVAL_PERCENTAGE' | translate }}</mat-label>
                  <input matInput formControlName="usersMLADL4percent" type="number" />
                </mat-form-field>
                <div class="wfmics-user-chips">
                  <span *ngFor="let user of usersSelectedMLADL4">
                    <mat-icon (click)="onRemoveUsersSelectedMLADL4($event, user.id)">cancel</mat-icon>{{ user.firstName + (user.lastName ? (' ' + user.lastName) : '') }}
                  </span>
                </div>
              </div>
              <div [hidden]="mladLevelCount < 5" class="wfmicsac-mlad-level">
                <mat-form-field appearance="standard" class="wff-users-mlad-level">
                  <mat-label>{{ 'APP.WORKFLOW.GENERAL.MLAD.USERS_L5' | translate }}</mat-label>
                  <input matInput formControlName="usersMLADL5" [matAutocomplete]="autocompleteUsersMLADL5" />
                  <mat-autocomplete #autocompleteUsersMLADL5="matAutocomplete" (optionSelected)="onAutocompleteUsersMLADL5Select($event)">
                    <mat-option *ngFor="let user of usersFilteredMLADL5 | async" [value]="user.id">
                      {{ user.firstName + (user.lastName ? (' ' + user.lastName) : '') }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="standard" class="wff-users-mlad-percent">
                  <mat-label>{{ 'APP.WORKFLOW.GENERAL.APPROVAL_PERCENTAGE' | translate }}</mat-label>
                  <input matInput formControlName="usersMLADL5percent" type="number" />
                </mat-form-field>
                <div class="wfmics-user-chips">
                  <span *ngFor="let user of usersSelectedMLADL5">
                    <mat-icon (click)="onRemoveUsersSelectedMLADL5($event, user.id)">cancel</mat-icon>{{ user.firstName + (user.lastName ? (' ' + user.lastName) : '') }}
                  </span>
                </div>
              </div>
              <div id="wfmicsac-mlad-buttons-first" class="wfmicsac-mlad-buttons">
                <button [style.display]="mladLevelCount === 5 ? 'none' : 'inline-block'" mat-raised-button (click)="onAddMLADLevel($event)" type="button">{{ 'APP.WORKFLOW.START_WORKFLOW.ADD_MLAD_LEVEL' | translate }}</button>
                <button [style.display]="mladLevelCount === 1 ? 'none' : 'inline-block'" mat-raised-button (click)="onRemoveMLADLevel($event)" type="button">{{ 'APP.WORKFLOW.START_WORKFLOW.REMOVE_MLAD_LEVEL' | translate }}</button>
              </div>
              <div [hidden]="!mladHaveGroup" id="wfmicsac-mlad-group">
                <mat-form-field appearance="standard" id="wff-group-MLAD">
                  <mat-label>{{ 'APP.WORKFLOW.GENERAL.GROUP' | translate }}</mat-label>
                  <input matInput formControlName="groupMLAD" [matAutocomplete]="autocompleteGroupMLAD">
                  <mat-autocomplete #autocompleteGroupMLAD="matAutocomplete">
                    <mat-option *ngFor="let group of groupsFilteredMLAD | async" [value]="group.id">
                      {{ group.displayName }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div id="wfmicsac-mlad-buttons-second" class="wfmicsac-mlad-buttons">
                <button [style.display]="mladHaveGroup ? 'none' : 'inline-block'" mat-raised-button (click)="onAddMLADGroup($event)" type="button">{{ 'APP.WORKFLOW.START_WORKFLOW.ADD_MLAD_GROUP' | translate }}</button>
                <button [style.display]="!mladHaveGroup ? 'none' : 'inline-block'" mat-raised-button (click)="onRemoveMLADGroup($event)" type="button">{{ 'APP.WORKFLOW.START_WORKFLOW.REMOVE_MLAD_GROUP' | translate }}</button>
              </div>
            </div>
            <div [hidden]="!workflowDefinition || workflowDefinition !== 'groupReceiveDoc'" class="wfmicsa-container">
              <mat-form-field appearance="standard" id="wff-group-GRD">
                <mat-label>{{ 'APP.WORKFLOW.GENERAL.GROUP' | translate }}</mat-label>
                <input matInput formControlName="groupGRD" [matAutocomplete]="autocompleteGroupGRD">
                <mat-autocomplete #autocompleteGroupGRD="matAutocomplete">
                  <mat-option *ngFor="let group of groupsFilteredGRD | async" [value]="group.id">
                    {{ group.displayName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div [hidden]="!workflowDefinition || workflowDefinition !== 'groupApproveRejectDoc'" id="wff-container-GARD" class="wfmicsa-container">
              <mat-form-field appearance="standard" id="wff-group-GARD">
                <mat-label>{{ 'APP.WORKFLOW.GENERAL.GROUP' | translate }}</mat-label>
                <input matInput formControlName="groupGARD" [matAutocomplete]="autocompleteGroupGARD">
                <mat-autocomplete #autocompleteGroupGARD="matAutocomplete">
                  <mat-option *ngFor="let group of groupsFilteredGARD | async" [value]="group.id">
                    {{ group.displayName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field appearance="standard" id="wff-group-GARD-percent">
                <mat-label>{{ 'APP.WORKFLOW.GENERAL.APPROVAL_PERCENTAGE' | translate }}</mat-label>
                <input matInput formControlName="groupGARDpercent" type="number" />
              </mat-form-field>
            </div>
          </div>
          <div class="wfmic-section">
            <span class="wfmics-title">{{ 'APP.WORKFLOW.GENERAL.DOCUMENTS' | translate }}</span>
            <div id="document-selector-container">
              <aca-neocom-document-selector
                [initialSelection]="initiallySelectedDocuments"
                (selectionChanged)="onDocumentSelectorSelectionChanged($event)"
                (navigate)="onDocumentSelectorNavigate($event)">
              </aca-neocom-document-selector>
            </div>
          </div>
          <div class="wfmic-section">
            <span class="wfmics-title">{{ 'APP.WORKFLOW.GENERAL.GENERAL_INFO' | translate }}</span>
            <div id="wfmics-general-info-2col">
              <mat-form-field appearance="standard" id="wff-date-due">
                <mat-label>{{ 'APP.WORKFLOW.GENERAL.DUE' | translate }}</mat-label>
                <input matInput [matDatepicker]="dateDuePicker" [min]="today" formControlName="dateDue" name="dateDue" readonly />
                <mat-datepicker-toggle matSuffix [for]="dateDuePicker"></mat-datepicker-toggle>
                <mat-datepicker #dateDuePicker disabled="false"></mat-datepicker>
              </mat-form-field>
              <mat-form-field appearance="standard" id="wff-priority">
                <mat-label>{{ 'APP.WORKFLOW.GENERAL.PRIORITY' | translate }}</mat-label>
                <mat-select formControlName="priority">
                  <mat-option *ngFor="let priorityOption of priorityOptions" [value]="priorityOption.id">{{ priorityOption.value }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <mat-form-field appearance="standard" id="wff-additional-description">
              <mat-label>{{ 'APP.WORKFLOW.GENERAL.ADDITIONAL_DESCRIPTION' | translate }}</mat-label>
              <textarea matInput formControlName="additionalDescription" name="additionalDescription" type="text"></textarea>
            </mat-form-field>
            <mat-checkbox formControlName="sendEmailNotifications">
              {{ 'APP.WORKFLOW.START_WORKFLOW.SEND_EMAIL_NOTIFICATIONS' | translate }}
            </mat-checkbox>
          </div>
        </div>

        <div [hidden]="!workflowDefinition" id="wf-footer">
          <button mat-button (click)="onFormReset($event)" type="button">{{ 'APP.ACTIONS.RESET' | translate }}</button>
          <button mat-raised-button type="submit">{{ 'APP.ACTIONS.SUBMIT' | translate }}</button>
        </div>
      </form>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
