/*!
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Alfresco Example Content Application
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail. Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * from Hyland Software. If not, see <http://www.gnu.org/licenses/>.
 */

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  AppStore,
  NodeActionTypes,
  PurgeDeletedNodesAction,
  DeleteNodesAction,
  UndoDeleteNodesAction,
  CreateFolderAction,
  EditFolderAction,
  RestoreDeletedNodesAction,
  ShareNodeAction,
  ManageVersionsAction,
  UnlockWriteAction,
  UnshareNodesAction,
  CopyNodesAction,
  MoveNodesAction,
  ManagePermissionsAction,
  PrintFileAction,
  getCurrentFolder,
  getAppSelection,
  ManageAspectsAction,
  NavigateRouteAction,
  ExpandInfoDrawerAction,
  ManageRulesAction,
  ShowLoaderAction,
  SendToArchiveNodeAction,
  InsertIntoSubjectNodeAction,
  InsertIntoActNodeAction,
  ShowAllWorkflowsNodeAction,
  DigitallySignNodeAction,
  StartWorkflowNodeAction,
  DeleteArchiveItemNodeAction,
  DivorceDocumentNodeAction,
  PrintReceiptNodeAction,
  StampDocumentNodeAction,
  ClassifyDocumentNodeAction,
  RenameSubjectNodeAction,
  ChangeSenderNodeAction,
  ConvertToPDFNodeAction,
  ToggleInfoDrawerAction,
  ArchiveDocumentNodeAction,
  ArchiveInventoryActNodeAction,
} from '@alfresco/aca-shared/store';
import { ContentManagementService } from '../../services/content-management.service';
import { RenditionService } from '@alfresco/adf-content-services';
import { Router } from '@angular/router';

@Injectable()
export class NodeEffects {
  constructor(
    private store: Store<AppStore>,
    private actions$: Actions,
    private router: Router,
    private contentService: ContentManagementService,
    private renditionViewer: RenditionService
  ) {}

  shareNode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ShareNodeAction>(NodeActionTypes.Share),
        map((action) => {
          if (action.payload) {
            this.contentService.shareNode(action.payload, action.configuration?.focusedElementOnCloseSelector);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.shareNode(selection.file, action.configuration?.focusedElementOnCloseSelector);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  unshareNodes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UnshareNodesAction>(NodeActionTypes.Unshare),
        map((action) => {
          if (action && action.payload && action.payload.length > 0) {
            this.contentService.unshareNodes(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && !selection.isEmpty) {
                  this.contentService.unshareNodes(selection.nodes);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  purgeDeletedNodes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PurgeDeletedNodesAction>(NodeActionTypes.PurgeDeleted),
        map((action) => {
          if (action && action.payload && action.payload.length > 0) {
            this.contentService.purgeDeletedNodes(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.count > 0) {
                  this.contentService.purgeDeletedNodes(selection.nodes);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  restoreDeletedNodes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<RestoreDeletedNodesAction>(NodeActionTypes.RestoreDeleted),
        map((action) => {
          if (action && action.payload && action.payload.length > 0) {
            this.contentService.restoreDeletedNodes(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.count > 0) {
                  this.contentService.restoreDeletedNodes(selection.nodes);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  deleteNodes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DeleteNodesAction>(NodeActionTypes.Delete),
        map((action) => {
          this.store.dispatch(new ShowLoaderAction(true));
          if (action && action.payload && action.payload.length > 0) {
            this.contentService.deleteNodes(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.count > 0) {
                  this.contentService.deleteNodes(selection.nodes);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  undoDeleteNodes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UndoDeleteNodesAction>(NodeActionTypes.UndoDelete),
        map((action) => {
          if (action.payload.length > 0) {
            this.contentService.undoDeleteNodes(action.payload);
          }
        })
      ),
    { dispatch: false }
  );

  createFolder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CreateFolderAction>(NodeActionTypes.CreateFolder),
        map((action) => {
          if (action.payload) {
            this.contentService.createFolder(action.payload);
          } else {
            this.store
              .select(getCurrentFolder)
              .pipe(take(1))
              .subscribe((node) => {
                if (node && node.id) {
                  this.contentService.createFolder(node.id);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  editFolder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<EditFolderAction>(NodeActionTypes.EditFolder),
        map((action) => {
          if (action.payload) {
            this.contentService.editFolder(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.folder) {
                  this.contentService.editFolder(selection.folder, action.configuration?.focusedElementOnCloseSelector);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  copyNodes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CopyNodesAction>(NodeActionTypes.Copy),
        map((action) => {
          if (action.payload?.length > 0) {
            this.contentService.copyNodes(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && !selection.isEmpty) {
                  this.contentService.copyNodes(selection.nodes, action.configuration?.focusedElementOnCloseSelector);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  moveNodes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<MoveNodesAction>(NodeActionTypes.Move),
        map((action) => {
          if (action.payload?.length > 0) {
            this.contentService.moveNodes(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && !selection.isEmpty) {
                  this.contentService.moveNodes(selection.nodes, action.configuration?.focusedElementOnCloseSelector);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  managePermissions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ManagePermissionsAction>(NodeActionTypes.ManagePermissions),
        map((action) => {
          if (action?.payload) {
            const route = 'personal-files/details';
            this.store.dispatch(new NavigateRouteAction([route, action.payload.entry.id, 'permissions']));
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && !selection.isEmpty) {
                  const route = 'personal-files/details';
                  this.store.dispatch(new NavigateRouteAction([route, selection.first.entry.id, 'permissions']));
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  expandInfoDrawer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ExpandInfoDrawerAction>(NodeActionTypes.ExpandInfoDrawer),
        map((action) => {
          if (action?.payload) {
            const route = 'personal-files/details';
            this.router.navigate([route, action.payload.entry.id], {
              queryParams: {
                location: this.router.url
              }
            });
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && !selection.isEmpty) {
                  const route = 'personal-files/details';
                  this.router.navigate([route, selection.first.entry.id], {
                    queryParams: {
                      location: this.router.url
                    }
                  });
                }
              });
          }
          this.store.dispatch(new ToggleInfoDrawerAction());
        })
      ),
    { dispatch: false }
  );

  manageVersions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ManageVersionsAction>(NodeActionTypes.ManageVersions),
        map((action) => {
          if (action?.payload) {
            this.contentService.manageVersions(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.manageVersions(selection.file, action.configuration?.focusedElementOnCloseSelector);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  printFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PrintFileAction>(NodeActionTypes.PrintFile),
        map((action) => {
          if (action && action.payload) {
            this.printFile(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.printFile(selection.file);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  unlockWrite$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UnlockWriteAction>(NodeActionTypes.UnlockForWriting),
        map((action) => {
          if (action && action.payload) {
            this.contentService.unlockNode(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.unlockNode(selection.file);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  aspectList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ManageAspectsAction>(NodeActionTypes.ChangeAspects),
        map((action) => {
          if (action?.payload) {
            this.contentService.manageAspects(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && !selection.isEmpty) {
                  this.contentService.manageAspects(selection.nodes[0], action.configuration?.focusedElementOnCloseSelector);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  printFile(node: any) {
    if (node && node.entry) {
      // shared and favorite
      const id = node.entry.nodeId || node.entry.guid || node.entry.id;
      const mimeType = node.entry.content.mimeType;

      if (id) {
        this.renditionViewer.printFileGeneric(id, mimeType);
      }
    }
  }

  manageRules$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ManageRulesAction>(NodeActionTypes.ManageRules),
        map((action) => {
          if (action?.payload) {
            this.store.dispatch(new NavigateRouteAction(['nodes', action.payload.entry.id, 'rules']));
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && !selection.isEmpty) {
                  this.store.dispatch(new NavigateRouteAction(['nodes', selection.first.entry.id, 'rules']));
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  convertToPDFNode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ConvertToPDFNodeAction>(NodeActionTypes.ConvertToPDF),
        map((action) => {
          if (action.payload) {
            this.contentService.convertToPDFNode(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.convertToPDFNode(selection.file);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  sendToArchiveNode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SendToArchiveNodeAction>(NodeActionTypes.SendToArchive),
        map((action) => {
          if (action.payload) {
            this.contentService.sendToArchiveNode(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.sendToArchiveNode(selection.file);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  archiveDocumentNode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ArchiveDocumentNodeAction>(NodeActionTypes.ArchiveDocument),
        map((action) => {
          if (action.payload) {
            this.contentService.archiveDocumentNode(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.archiveDocumentNode(selection.file);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  archiveInventoryActNode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ArchiveInventoryActNodeAction>(NodeActionTypes.ArchiveInventoryAct),
        map((action) => {
          if (action.payload) {
            this.contentService.archiveInventoryActNode(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.archiveInventoryActNode(selection.file);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  insertIntoSubjectNode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InsertIntoSubjectNodeAction>(NodeActionTypes.InsertIntoSubject),
        map((action) => {
          if (action.payload) {
            this.contentService.insertIntoSubjectNode(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.insertIntoSubjectNode(selection.file);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  insertIntoActNode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<InsertIntoActNodeAction>(NodeActionTypes.InsertIntoAct),
        map((action) => {
          if (action.payload) {
            this.contentService.insertIntoActNode(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.insertIntoActNode(selection.file);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  showAllWorkflows$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ShowAllWorkflowsNodeAction>(NodeActionTypes.ShowAllWorkflows),
        map((action) => {
          if (action.payload) {
            this.contentService.showAllWorkflowsNode(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.showAllWorkflowsNode(selection.file);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  digitallySignNode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DigitallySignNodeAction>(NodeActionTypes.DigitallySign),
        map((action) => {
          if (action.payload) {
            this.contentService.digitallySignNode(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.digitallySignNode(selection.file);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  startWorkflowNode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<StartWorkflowNodeAction>(NodeActionTypes.StartWorkflow),
        map((action) => {
          if (action.payload) {
            this.contentService.startWorkflowNode(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.startWorkflowNode(selection.file);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  deleteArchiveItemNode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DeleteArchiveItemNodeAction>(NodeActionTypes.DeleteArchiveItem),
        map((action) => {
          if (action.payload) {
            this.contentService.deleteArchiveItemNode(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.deleteArchiveItemNode(selection.file);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  divorceDocumentNode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DivorceDocumentNodeAction>(NodeActionTypes.DivorceDocument),
        map((action) => {
          if (action.payload) {
            this.contentService.divorceDocumentNode(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.divorceDocumentNode(selection.file);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  printReceiptNode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PrintReceiptNodeAction>(NodeActionTypes.PrintReceipt),
        map((action) => {
          if (action.payload) {
            this.contentService.printReceiptNode(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.printReceiptNode(selection.file);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  stampDocumentNode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<StampDocumentNodeAction>(NodeActionTypes.StampDocument),
        map((action) => {
          if (action.payload) {
            this.contentService.archiveStampNode(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.archiveStampNode(selection.file);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  classifyDocumentNode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ClassifyDocumentNodeAction>(NodeActionTypes.ClassifyDocument),
        map((action) => {
          if (action.payload) {
            this.contentService.archiveClassifyNode(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.archiveClassifyNode(selection.file);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  renameSubjectNode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<RenameSubjectNodeAction>(NodeActionTypes.RenameSubject),
        map((action) => {
          if (action.payload) {
            this.contentService.renameSubjectNode(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.renameSubjectNode(selection.file);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  changeSenderNode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ChangeSenderNodeAction>(NodeActionTypes.ChangeSender),
        map((action) => {
          if (action.payload) {
            this.contentService.changeSenderNode(action.payload);
          } else {
            this.store
              .select(getAppSelection)
              .pipe(take(1))
              .subscribe((selection) => {
                if (selection && selection.file) {
                  this.contentService.changeSenderNode(selection.file);
                }
              });
          }
        })
      ),
    { dispatch: false }
  );
}
