import { CustomApiCallService, HTTPMethod, PayloadType, ResponseType } from '@alfresco/aca-shared';
import { Injectable } from '@angular/core';
import { MiniApplet } from './digital-signature-service-miniapplet';

@Injectable({
  providedIn: 'root'
})
export class DigitalSignatureService {
  constructor(private customApiCallService: CustomApiCallService) {}

  getNodeBase64Content(nodeRef: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.customApiCallService
        .callService('/keensoft/sign/base64-node-content?nodeRef=' + nodeRef, HTTPMethod.GET, null, null, null, ResponseType.JSON)
        .then((response) => {
          if (!response || !response.base64NodeContent) {
            reject('invalid response - cannot get base64 node content');
          }
          resolve(response.base64NodeContent as string);
        })
        .catch((err) => reject(err));
    });
  }

  getSignatureParams(): Promise<object> {
    return new Promise((resolve, reject) => {
      this.customApiCallService
        .callService('/keensoft/sign/signature-params', HTTPMethod.GET, null, null, null, ResponseType.JSON)
        .then((response) => {
          if (!response) {
            reject('invalid response - cannot get signature params');
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  }

  performSignature(mimeType: string, paramsCades: string, paramsPades: string, signatureAlg: string, base64NodeContent: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const showSignedCallback = (signatureBase64, certificateB64) => {
        resolve({ signatureBase64, certificateB64, })
      };
      const showErrorCallback = (errorType, errorMessage) => {
        reject({ errorType, errorMessage, });
      };
      if (mimeType === 'pdf') {
        console.log({ paramsPades });
        MiniApplet.sign(base64NodeContent, signatureAlg, 'PAdES', paramsPades, showSignedCallback, showErrorCallback);
      } else {
        MiniApplet.sign(base64NodeContent, signatureAlg, 'CAdES', paramsCades, showSignedCallback, showErrorCallback);
      }
    });
  }

  saveSignature(nodeRef: string, mimeType: string, signerRole: string, dataToSign: string, signedData: string, signerData: string, signerPostition: string, username: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const payload = {
        nodeRef,
        signerRole,
        dataToSign,
        signedData,
        signerData,
        mimeType,
        signerPostition,
      };
      this.customApiCallService
        .callService(`/keensoft/sign/save-sign?real_username=${username}`, HTTPMethod.POST, payload, PayloadType.JSON, null, ResponseType.JSON)
        .then((response) => {
          if (!response) {
            reject('invalid response - save signature failed');
          }
          try {
            const responseObj = JSON.parse(response);
            if (!responseObj || responseObj.code !== 'OK') {
              reject('invalid response - save signature failed');
            }
          } catch (err) {
            reject('invalid response - save signature failed');
          }
          resolve();
        })
        .catch((err) => reject(err));
    });
  }
}