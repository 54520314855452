<ng-container *ngIf="!property.isEmpty() || isEditable()">
    <div
        [attr.data-automation-id]="'card-select-label-' + property.key"
        class="adf-property-label"
    >{{ property.label | translate }}</div>
    <div class="adf-property-field">
        <div
            *ngIf="!isEditable()"
            class="adf-select-item-padding adf-property-value"
            [attr.data-automation-id]="'select-readonly-value-' + property.key"
            data-automation-class="read-only-value">{{ (property.displayValue | async) | translate }}
        </div>
        <div *ngIf="isEditable()">
            <mat-form-field class="adf-select-item-padding-editable adf-property-value">
                <mat-select
                    [(value)]="value"
                    panelClass="adf-select-filter"
                    (selectionChange)="onChange($event)"
                    data-automation-class="select-box"
                    [aria-label]="property.label | translate"
                >

                    <adf-select-filter-input *ngIf="showInputFilter" (change)="onFilterInputChange($event)"></adf-select-filter-input>

                    <mat-option *ngIf="showNoneOption()">{{ 'CORE.CARDVIEW.NONE' | translate }}</mat-option>
                    <mat-option
                        *ngFor="let option of list$ | async"
                        [value]="option.key">
                        {{ option.label | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</ng-container>
