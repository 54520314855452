<adf-datatable
    #dataTable
    [selectionMode]="selectionMode"
    [data]="data"
    [actions]="contentActions"
    [actionsPosition]="contentActionsPosition"
    [multiselect]="multiselect"
    [contextMenu]="contextMenuActions"
    [rowStyle]="rowStyle"
    [rowStyleClass]="rowStyleClass"
    [loading]="loading"
    [display]="display"
    [noPermission]="noPermission"
    [showHeader]="showHeader"
    [rowMenuCacheEnabled]="false"
    [stickyHeader]="stickyHeader"
    [allowFiltering]="allowFiltering"
    (showRowContextMenu)="onShowRowContextMenu($event)"
    (showRowActionsMenu)="onShowRowActionsMenu($event)"
    (executeRowAction)="onExecuteRowAction($event)"
    (rowClick)="onNodeClick($event.value?.node)"
    (rowDblClick)="onNodeDblClick($event.value?.node)"
    (row-select)="onNodeSelect($any($event).detail)"
    (row-unselect)="onNodeUnselect($any($event).detail)"
    (sorting-changed)="onSortingChanged($any($event))"
    [class.adf-datatable-gallery-thumbnails]="data.thumbnails">

    <div *ngIf="headerFilters">
        <adf-filter-header
            [currentFolderId]="currentFolderId"
            [value]="filterValue"
            (filterSelection)="onFilterSelectionChange($event)">
        </adf-filter-header>
    </div>

    <adf-no-content-template>
        <ng-template>
            <adf-empty-list *ngIf="!customNoContentTemplate">
                <div class="adf-empty-list_template adf-empty-folder">
                    <div class="adf-empty-folder-this-space-is-empty">{{'ADF-DOCUMENT-LIST.EMPTY.HEADER' | translate}}</div>
                    <div class="adf-empty-folder-drag-drop">{{ 'ADF-DATATABLE.EMPTY.DRAG-AND-DROP.TITLE' | translate }}</div>
                    <div class="adf-empty-folder-any-files-here-to-add">{{ 'ADF-DATATABLE.EMPTY.DRAG-AND-DROP.SUBTITLE' | translate }}</div>
                    <img [alt]="'ADF-DATATABLE.EMPTY.DRAG-AND-DROP.TITLE' | translate" class="adf-empty-folder-image" [src]="emptyFolderImageUrl">
                </div>
            </adf-empty-list>
            <ng-content select="adf-custom-empty-content-template, empty-folder-content"></ng-content>
        </ng-template>
    </adf-no-content-template>

    <adf-no-permission-template>
        <ng-template>
            <div class="adf-no-permission__template" *ngIf="!customNoPermissionsTemplate">
                <mat-icon>error</mat-icon>
                <p class="adf-no-permission__template--text">{{ 'ADF-DOCUMENT-LIST.NO_PERMISSION' | translate }}</p>
            </div>
            <ng-content select="adf-custom-no-permission-template, no-permission-content"></ng-content>
        </ng-template>
    </adf-no-permission-template>

    <adf-loading-content-template>
        <ng-template>
            <div class="adf-document-list-loading-container" *ngIf="!customLoadingContent">
                <mat-progress-spinner
                    id="adf-document-list-loading"
                    class="adf-document-list-loading-margin"
                    [attr.aria-label]="'ADF-DOCUMENT-LIST.LOADER_LABEL' | translate"
                    [color]="'primary'"
                    [mode]="'indeterminate'">
                </mat-progress-spinner>
            </div>
            <ng-content select="adf-custom-loading-content-template"></ng-content>
        </ng-template>
    </adf-loading-content-template>

</adf-datatable>
