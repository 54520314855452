import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DataCellEvent, DataColumn, DataRowActionEvent, DataTableModule, TranslationService } from '@alfresco/adf-core';
import { Store } from '@ngrx/store';
import { Person } from '@alfresco/js-api';
import { ConfirmDialogComponent } from '@alfresco/adf-content-services';
import { AppStore, SnackbarErrorAction, SnackbarInfoAction, getUserList } from '@alfresco/aca-shared/store';
import { SiteService, Constants, SiteRoles, debounce } from '@alfresco/aca-shared';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    DataTableModule,
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'aca-site-members-dialog',
  templateUrl: './site-members-dialog.component.html',
  styleUrls: ['./site-members-dialog.component.scss']
})
export class SiteMembersDialogComponent implements OnInit {
  mode = 0;

  siteId: string;
  siteTitle: string;

  siteUsersSchema: DataColumn[];
  siteUsersRows: object[];

  siteAddUsersSchema: DataColumn[];
  siteAddUsersRows: object[];
  siteAddUsersRowsAll: object[];

  allUsers: Person[];
  allSiteUsers: object[];

  addUserMode: Boolean = false;
  selectedUser;
  roleChoice: string = SiteRoles.CONSUMER;

  loading: Boolean = false;
  hasMadeUserChange: Boolean = false;

  filterSiteAddUsers: string = '';
  filterPlaceholder: string;
  filterDebounce: Function;

  constructor(
    private dialog: MatDialog,
    private store: Store<AppStore>,
    private translation: TranslationService,
    private siteService: SiteService,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    this.siteId = data.siteId;
    this.siteTitle = data.siteTitle;

    this.siteUsersSchema = [
      {
        type: 'text',
        key: 'person.displayName',
        title: this.translation.instant('APP.SETTINGS.FULL_NAME'),
        cssClass: 'full-width',
        sortable: true
      },
      {
        type: 'text',
        key: 'roleName',
        title: this.translation.instant('APP.SETTINGS.SITE_ROLE'),
        cssClass: 'full-width',
        sortable: true
      }
    ];

    this.siteAddUsersSchema = [
      {
        type: 'text',
        key: 'id',
        title: this.translation.instant('APP.SETTINGS.USERNAME'),
        sortable: true
      },
      {
        type: 'text',
        key: 'displayName',
        title: this.translation.instant('APP.SETTINGS.FULL_NAME'),
        cssClass: 'full-width',
        sortable: true
      }
    ];

    this.filterPlaceholder = this.translation.instant('APP.SETTINGS.FILTER');
  }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers(utilizeTimeout = false) {
    const proc = () => {
      this.store.select(getUserList).subscribe((userList) => {
        this.allUsers = userList;
        this.siteService
            .getSiteMembers(this.siteId)
            .then((siteUsers) => {
              this.allSiteUsers = siteUsers.map((el) => ({
                ...el,
                roleName: this.translation.instant(SiteService.mapRoleToI18NString(el.role as SiteRoles))
              }));
              this.siteUsersRows = this.allSiteUsers;
              this.applySiteAddUsersFilter(true);
              this.loading = false;
            })
            .catch((_) => {
              this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
              this.loading = false;
            });
      });
    };

    this.loading = true;

    if (!utilizeTimeout) {
      proc();
    } else {
      setTimeout(proc, Constants.GROUP_MEMBERSHIP_UPDATE_TIMEOUT_VALUE);
    }
  }

  onSiteShowRowActionsMenu(event: DataCellEvent) {
    const editRoleAction = {
      id: 0,
      title: this.translation.instant('APP.ACTIONS.EDIT_ROLE')
    };
    const removeAction = {
      id: 1,
      title: this.translation.instant('APP.ACTIONS.REMOVE')
    };
    event.value.actions = [editRoleAction, removeAction];
  }

  onSiteExecuteRowAction = (event: DataRowActionEvent) => {
    const args = event.value;
    if (args.action.id === 0) {
      this.addUserMode = false;
      this.selectedUser = args.row.obj.person;
      this.roleChoice = args.row.obj.role;
      this.mode = 2;
    } else if (args.action.id === 1) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'APP.SETTINGS.REMOVE_USER_SITE_TITLE',
          message: this.translation
            .instant('APP.SETTINGS.REMOVE_USER_SITE_MESSAGE')
            .replace('{0}', args.row.obj.person.displayName)
            .replace('{1}', this.siteTitle),
          yesLabel: 'APP.DIALOGS.CONFIRM_REMOVE.YES_LABEL',
          noLabel: 'APP.DIALOGS.CONFIRM_REMOVE.NO_LABEL'
        },
        minWidth: '250px'
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.siteService
            .removeSiteMember(this.siteId, args.row.obj.person.id)
            .then(() => {
              this.store.dispatch(
                new SnackbarInfoAction(
                  this.translation.instant('APP.SETTINGS.USER_REMOVED_MESSAGE_SITE').replace('{0}', args.row.obj.person.displayName)
                )
              );
              this.getUsers();
            })
            .catch((_) => {
              this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
            });
        }
      });
    }
  };

  onSiteAddShowRowActionsMenu(event: DataCellEvent) {
    const addUserAction = {
      id: 0,
      title: this.translation.instant('APP.SETTINGS.ADD_USER')
    };
    event.value.actions = [addUserAction];
  }

  onSiteAddExecuteRowAction = (event: DataRowActionEvent) => {
    const args = event.value;
    if (args.action.id === 0) {
      this.addUserMode = true;
      this.selectedUser = args.row.obj;
      this.mode = 2;
    }
  };

  onRefreshList(): void {
    this.getUsers();
  }

  onAddUsers(): void {
    const userIdsInSite = this.allSiteUsers.map((u) => u['id']);
    this.siteAddUsersRowsAll = this.allUsers.filter((u) => userIdsInSite.indexOf(u.id) === -1);
    this.siteAddUsersRows = [...this.siteAddUsersRowsAll];
    this.filterSiteAddUsers = '';
    this.mode = 1;
  }

  onViewUsers(): void {
    if (this.hasMadeUserChange) {
      this.getUsers();
      this.hasMadeUserChange = false;
    }
    this.mode = 0;
  }

  applySiteAddUsersFilter(skipTimeout = false) {
    if (!this.filterDebounce) this.filterDebounce = debounce(Constants.CONTINUED_TYPING_THRESHOLD_VALUE);
    const proc = () => {
      if (!this.filterSiteAddUsers) this.siteAddUsersRows = this.siteAddUsersRowsAll;
      else
        this.siteAddUsersRows = this.siteAddUsersRowsAll.filter(
          (u) =>
            u['id'].toLowerCase().indexOf(this.filterSiteAddUsers.toLowerCase()) !== -1 ||
            u['displayName'].toLowerCase().indexOf(this.filterSiteAddUsers.toLowerCase()) !== -1
        );
    };

    if (!skipTimeout) {
      this.filterDebounce(proc);
    } else {
      proc();
    }
  }

  matDialogContentStyle(): string {
    let style = 'max-height: unset; overflow-y: auto !important; ';
    if (this.loading) style += 'height: 75px;';
    else if (!this.siteUsersRows || this.siteUsersRows.length === 0) style += 'height: 50px';
    else style += 'height: 500px;';
    return style;
  }

  getRoleMessage(): string {
    return this.translation.instant('APP.SETTINGS.ADD_EDIT_USER_SITE_ROLE_MESSAGE').replace('{0}', this.selectedUser.displayName);
  }

  onRoleCancel() {
    this.selectedUser = null;
    this.roleChoice = SiteRoles.CONSUMER;
    if (this.addUserMode) {
      this.mode = 1;
    } else {
      this.onViewUsers();
    }
  }

  onRoleSubmit() {
    if (this.addUserMode) {
      this.siteService
        .addSiteMember(this.siteId, this.selectedUser.id, this.roleChoice as SiteRoles)
        .then(() => {
          this.hasMadeUserChange = true;
          this.siteAddUsersRows = this.siteAddUsersRows.filter((u) => u['id'] !== this.selectedUser.id);
          this.store.dispatch(
            new SnackbarInfoAction(
              this.translation
                .instant('APP.SETTINGS.USER_ADDED_MESSAGE_SITE')
                .replace('{0}', this.selectedUser.displayName)
                .replace('{1}', this.siteTitle)
            )
          );
          this.onRoleCancel();
        })
        .catch((_) => {
          this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
        });
    } else {
      this.siteService
        .updateSiteMember(this.siteId, this.selectedUser.id, this.roleChoice as SiteRoles)
        .then(() => {
          this.hasMadeUserChange = true;
          this.store.dispatch(
            new SnackbarInfoAction(
              this.translation
                .instant('APP.SETTINGS.USER_ROLE_EDITED_MESSAGE')
                .replace('{0}', this.selectedUser.displayName)
                .replace('{1}', this.translation.instant(SiteService.mapRoleToI18NString(this.roleChoice as SiteRoles)))
            )
          );
          this.onRoleCancel();
        })
        .catch((_) => {
          this.store.dispatch(new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
        });
    }
  }
}
