<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="APP.WORKFLOW.WORKFLOW_DETAILS.TITLE"> </adf-breadcrumb>
  </aca-page-layout-header>
  <aca-page-layout-content>
    <div class="workflow-details-main-content">
      <mat-spinner *ngIf="isLoading" [diameter]="60" style="margin: 10px auto;"></mat-spinner> 
      <div *ngIf="!isLoading && workflowError" class="workflow-details-error-container">
        {{ 'APP.WORKFLOW.WORKFLOW_DETAILS.WORKFLOW_ERROR' | translate }}
      </div>
      <div *ngIf="!isLoading && !workflowError" class="workflow-details-main-container">
        <div class="wdmc-section">
          <span class="wdmcs-title">{{ 'APP.WORKFLOW.GENERAL.GENERAL_INFO' | translate }}</span>
          <div class="wdmcs-row">
            <span class="wdmcsr-pair">
              <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.GENERAL.NAME' | translate }}: </span>
              <span class="wdmcsrp-value">{{ getWorkflowName() }}</span>
            </span>
          </div>
          <div class="wdmcs-row">
            <span class="wdmcsr-pair">
              <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.GENERAL.MESSAGE' | translate }}: </span>
              <span class="wdmcsrp-value">{{ getWorkflowMessage() }}</span>
            </span>
          </div>
          <div class="wdmcs-row">
            <div class="wdmcsr-column">
              <span class="wdmcsr-pair">
                <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.GENERAL.INITIATOR' | translate }}: </span>
                <span class="wdmcsrp-value">{{ getWorkflowInitiator() }}</span>
              </span>
            </div>
            <div class="wdmcsr-column">
              <span class="wdmcsr-pair">
                <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.GENERAL.PRIORITY' | translate }}: </span>
                <span class="wdmcsrp-value">{{ getWorkflowPriority() }}</span>
              </span>
            </div>
            <div class="wdmcsr-column">
              <span class="wdmcsr-pair">
                <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.GENERAL.DUE' | translate }}: </span>
                <span class="wdmcsrp-value">{{ getWorkflowDue() }}</span>
              </span>
            </div>
          </div>
          <div class="wdmcs-row">
            <div class="wdmcsr-column">
              <span class="wdmcsr-pair">
                <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.GENERAL.DATE_STARTED' | translate }}: </span>
                <span class="wdmcsrp-value">{{ getWorkflowStartDate() }}</span>
              </span>
            </div>
            <div class="wdmcsr-column">
              <span class="wdmcsr-pair">
                <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.GENERAL.STATUS' | translate }}: </span>
                <span class="wdmcsrp-value">{{ getWorkflowStatus() }}</span>
              </span>
            </div>
            <div class="wdmcsr-column">
              <span class="wdmcsr-pair">
                <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.GENERAL.SEND_EMAIL_NOTIFICATIONS' | translate }}: </span>
                <span class="wdmcsrp-value">{{ getEmailNotification() }}</span>
              </span>
            </div>
          </div>
          <div class="wdmcs-row">
            <span class="wdmcsr-pair">
              <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.GENERAL.ADDITIONAL_DESCRIPTION' | translate }}: </span>
              <span class="wdmcsrp-value">{{ getWorkflowAdditionalDescription() }}</span>
            </span>
          </div>       
        </div>
          <div class="wdmc-section">
          <span class="wdmcs-title">{{ 'APP.WORKFLOW.GENERAL.DOCUMENTS' | translate }}</span>
            <div id="document-selector-container">
                <aca-neocom-document-selector
                  [initialSelection]="initiallySelectedDocuments"
                  (navigate)="onDocumentSelectorNavigate($event)"
                  [readOnly]="true">
                </aca-neocom-document-selector>
            </div>
        </div>
        <div class="wdmc-section">
          <span class="wdmcs-title">{{ 'APP.WORKFLOW.WORKFLOW_DETAILS.WORKFLOW_INFO' | translate }}</span>
          <div [hidden]="workflow['name'] !== 'activiti$groupApproveRejectDoc'" class="wdmcs-container">
            <div class="wdmcs-row">
              <div class="wdmcsr-column">
                <span class="wdmcsr-pair">
                  <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.WORKFLOW_DETAILS.REVIEW_GROUP' | translate }}: </span>
                  <span class="wdmcsrp-value">{{ getGARDReviewGroup() }}</span>
                </span>
              </div>
              <div class="wdmcsr-column">
                <span class="wdmcsr-pair">
                  <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.GENERAL.APPROVAL_PERCENTAGE' | translate }}: </span>
                  <span class="wdmcsrp-value">{{ getGARDRequiredApprovalPercentage() }}</span>
                </span>
              </div>
              <div class="wdmcsr-column"></div>
            </div>
          </div>
          <div [hidden]="workflow['name'] !== 'activiti$groupReceiveDoc'" class="wdmcs-container">
            <div class="wdmcs-row">
              <div class="wdmcsr-column">
                <span class="wdmcsr-pair">
                  <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.WORKFLOW_DETAILS.REVIEW_GROUP' | translate }}: </span>
                  <span class="wdmcsrp-value">{{ getGRDReviewGroups() }}</span>
                </span>
              </div>
            </div>
          </div>
          <div [hidden]="workflow['name'] !== 'activiti$multipleLevelApproveDoc'" class="wdmcs-container">
            <div class="wdmcs-row">
              <div class="wdmcsr-column">
                <span class="wdmcsr-pair">
                  <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.WORKFLOW_DETAILS.ASSIGNEES_L1' | translate }}: </span>
                  <span class="wdmcsrp-value">{{ getMLARAssigneesLevel1() }}</span>
                </span>
              </div>
              <div class="wdmcsr-column">
                <span class="wdmcsr-pair">
                  <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.WORKFLOW_DETAILS.APPROVAL_PERCENTAGE' | translate }}: </span>
                  <span class="wdmcsrp-value">{{ getMLARRequiredApprovalPercentageLevel1() }}</span>
                </span>
              </div>
              <div class="wdmcsr-column"></div>
            </div>
            <div class="wdmcs-row" [style.display]="haveMLARLevel2() ? 'flex' : 'none'">
              <div class="wdmcsr-column">
                <span class="wdmcsr-pair">
                  <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.WORKFLOW_DETAILS.ASSIGNEES_L2' | translate }}: </span>
                  <span class="wdmcsrp-value">{{ getMLARAssigneesLevel2() }}</span>
                </span>
              </div>
              <div class="wdmcsr-column">
                <span class="wdmcsr-pair">
                  <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.WORKFLOW_DETAILS.APPROVAL_PERCENTAGE' | translate }}: </span>
                  <span class="wdmcsrp-value">{{ getMLARRequiredApprovalPercentageLevel2() }}</span>
                </span>
              </div>
              <div class="wdmcsr-column"></div>
            </div>
            <div class="wdmcs-row" [style.display]="haveMLARLevel3() ? 'flex' : 'none'">
              <div class="wdmcsr-column">
                <span class="wdmcsr-pair">
                  <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.WORKFLOW_DETAILS.ASSIGNEES_L3' | translate }}: </span>
                  <span class="wdmcsrp-value">{{ getMLARAssigneesLevel3() }}</span>
                </span>
              </div>
              <div class="wdmcsr-column">
                <span class="wdmcsr-pair">
                  <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.WORKFLOW_DETAILS.APPROVAL_PERCENTAGE' | translate }}: </span>
                  <span class="wdmcsrp-value">{{ getMLARRequiredApprovalPercentageLevel3() }}</span>
                </span>
              </div>
              <div class="wdmcsr-column"></div>
            </div>
            <div class="wdmcs-row" [style.display]="haveMLARLevel4() ? 'flex' : 'none'">
              <div class="wdmcsr-column">
                <span class="wdmcsr-pair">
                  <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.WORKFLOW_DETAILS.ASSIGNEES_L4' | translate }}: </span>
                  <span class="wdmcsrp-value">{{ getMLARAssigneesLevel4() }}</span>
                </span>
              </div>
              <div class="wdmcsr-column">
                <span class="wdmcsr-pair">
                  <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.WORKFLOW_DETAILS.APPROVAL_PERCENTAGE' | translate }}: </span>
                  <span class="wdmcsrp-value">{{ getMLARRequiredApprovalPercentageLevel4() }}</span>
                </span>
              </div>
              <div class="wdmcsr-column"></div>
            </div>
            <div class="wdmcs-row" [style.display]="haveMLARLevel5() ? 'flex' : 'none'">
              <div class="wdmcsr-column">
                <span class="wdmcsr-pair">
                  <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.WORKFLOW_DETAILS.ASSIGNEES_L5' | translate }}: </span>
                  <span class="wdmcsrp-value">{{ getMLARAssigneesLevel5() }}</span>
                </span>
              </div>
              <div class="wdmcsr-column">
                <span class="wdmcsr-pair">
                  <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.WORKFLOW_DETAILS.APPROVAL_PERCENTAGE' | translate }}: </span>
                  <span class="wdmcsrp-value">{{ getMLARRequiredApprovalPercentageLevel5() }}</span>
                </span>
              </div>
              <div class="wdmcsr-column"></div>
            </div>
            <div class="wdmcs-row" [style.display]="haveMLARGroup() ? 'flex' : 'none'">
              <div class="wdmcsr-column">
                <span class="wdmcsr-pair">
                  <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.WORKFLOW_DETAILS.REVIEW_GROUP' | translate }}: </span>
                  <span class="wdmcsrp-value">{{ getMLARGroup() }}</span>
                </span>
              </div>
              <div class="wdmcsr-column"></div>
              <div class="wdmcsr-column"></div>
            </div>
          </div>
          <div [hidden]="workflow['name'] !== 'activiti$multipleReviewArchDoc'" class="wdmcs-container">
            <div class="wdmcs-row">
              <div class="wdmcsr-column">
                <span class="wdmcsr-pair">
                  <span class="wdmcsrp-label">{{ 'APP.WORKFLOW.GENERAL.ASSIGNEES' | translate }}: </span>
                  <span class="wdmcsrp-value">{{ getMRADAssignees() }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="wdmc-section">
          <span class="wdmcs-title">{{ 'APP.WORKFLOW.WORKFLOW_DETAILS.CURRENT_TASKS' | translate }}</span>
            <div *ngIf="currentTasksRows && currentTasksRows.length > 0" class="datatable-container" (row-click)="onRowClick($event)">
              <adf-datatable
                [rows]="currentTasksRows" 
                [columns]="currentTasksSchema"
                [actions]="false"
                [stickyHeader]="false">
              </adf-datatable>
            </div>
            <div *ngIf="!currentTasksRows || currentTasksRows.length === 0" class="wdmcs-no-items">
              {{ 'APP.WORKFLOW.WORKFLOW_DETAILS.NO_CURRENT_TASKS' | translate }}
            </div>
        </div>
        <div class="wdmc-section">
          <span class="wdmcs-title">{{ 'APP.WORKFLOW.WORKFLOW_DETAILS.TASKS_HISTORY' | translate }}</span>
            <div *ngIf="tasksHistoryRows && tasksHistoryRows.length > 0" class="datatable-container">
              <adf-datatable
                [rows]="tasksHistoryRows" 
                [columns]="tasksHistorySchema"
                [actions]="false"
                [stickyHeader]="false">
              </adf-datatable>
            </div>
        </div>
        <div class="wdmcs-footer">
          <button mat-raised-button type="button" (click)="onRemove()">{{ 'APP.WORKFLOW.GENERAL.REMOVE_WORKFLOW' | translate }}</button>
        </div>
      </div>
    </div>
  </aca-page-layout-content>
</aca-page-layout>

