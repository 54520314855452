<adf-info-drawer-layout [showHeader]="showHeader">
    <div role="heading" aria-level="1" *ngIf="title" info-drawer-title>{{ title | translate }}</div>
    <ng-content *ngIf="!title" info-drawer-title select="[info-drawer-title]"></ng-content>

    <ng-content info-drawer-buttons select="[info-drawer-buttons]"></ng-content>

    <ng-container info-drawer-content *ngIf="showTabLayout(); then tabLayout else singleLayout"></ng-container>

    <ng-template #tabLayout>
        <mat-tab-group [(selectedIndex)]="selectedIndex" class="adf-info-drawer-tabs" (selectedTabChange)="onTabChange($event)">
            <mat-tab *ngFor="let contentBlock of contentBlocks"
                [label]="contentBlock.label | translate"
                class="adf-info-drawer-tab">

                <ng-template mat-tab-label>
                    <mat-icon *ngIf="contentBlock.icon">{{ contentBlock.icon }}</mat-icon>
                    <span *ngIf="contentBlock.label">{{ contentBlock.label | translate }}</span>
                </ng-template>

                <ng-container *ngTemplateOutlet="contentBlock.content"></ng-container>
            </mat-tab>
        </mat-tab-group>
    </ng-template>

    <ng-template #singleLayout>
        <ng-content select="[info-drawer-content]"></ng-content>
    </ng-template>
</adf-info-drawer-layout>
