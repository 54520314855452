/*!
 * @license
 * Copyright © 2005-2023 Hyland Software, Inc. and its affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { SearchFilterList } from './search-filter-list.model';
import { FacetFieldBucket } from './facet-field-bucket.interface';

export interface FacetField {
    field: string;
    label: string;
    mincount?: number;
    limit?: number;
    offset?: number;
    prefix?: string;

    buckets?: SearchFilterList<FacetFieldBucket>;
    pageSize?: number;
    currentPageSize?: number;
    checked?: boolean;
    type?: string;
    settings?: FacetFieldSettings;
    [propName: string]: any;
}

export interface FacetFieldSettings {
    /* allow the user to update search in every change */
    allowUpdateOnChange?: boolean;
    /* allow the user show/hide default search actions */
    hideDefaultAction?: boolean;
    /* a number to compare to other facets to determine the order in which they will appear */
    facetOrder?: number;
    /* the field used to sort the buckets */
    bucketSortBy?: FacetBucketSortBy;
    /* the direction in which the buckets are ordered */
    bucketSortDirection?: FacetBucketSortDirection;
}

// eslint-disable-next-line no-shadow
export enum FacetBucketSortBy {
    LABEL = 'LABEL',
    COUNT = 'COUNT'
}

// eslint-disable-next-line no-shadow
export enum FacetBucketSortDirection {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING'
}
