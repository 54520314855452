<h2 mat-dialog-title>
  {{ (editing ? editTitle : createTitle) | translate }}
</h2>

<mat-dialog-content>
    <form [formGroup]="form" (submit)="submit()">
        <mat-form-field class="adf-full-width">
            <input
                id="site-id-input"
                placeholder="{{ 'APP.SETTINGS.ID' | translate }}"
                matInput
                required
                [readonly]="editing"
                [formControl]="fcId"/>

            <mat-hint *ngIf="fcId.dirty">
                <span *ngIf="fcId.errors?.required">
                    {{ 'APP.SETTINGS.ID_REQUIRED' | translate }}
                </span>
                <span *ngIf="!fcId.errors?.required && fcId.errors?.message">
                    {{ fcId.errors?.message | translate }}
                </span>
            </mat-hint>
        </mat-form-field>

        <mat-form-field class="adf-full-width">
            <input
                id="site-title-input"
                matInput
                placeholder="{{ 'APP.SETTINGS.SITE_TITLE' | translate }}"
                required
                [formControl]="fcTitle"/>

            <mat-hint *ngIf="fcTitle.dirty">
                <span *ngIf="fcTitle.errors?.required">
                    {{ 'APP.SETTINGS.SITE_TITLE_REQUIRED' | translate }}
                </span>
                <span *ngIf="!fcTitle.errors?.required && fcTitle.errors?.message">
                    {{ fcTitle.errors?.message | translate }}
                </span>
            </mat-hint>
        </mat-form-field>

        <mat-form-field class="adf-full-width">
            <input
                id="site-description-input"
                matInput
                placeholder="{{ 'APP.SETTINGS.DESCRIPTION' | translate }}"
                [formControl]="fcDescription"/>
        </mat-form-field>

        <mat-form-field class="adf-full-width">
            <mat-select
                id="site-visibility-select"
                placeholder="{{ 'APP.SETTINGS.SITE_VISIBILITY' | translate }}"
                [formControl]="fcVisibility"
                required>
                    <mat-option value="PRIVATE">{{ 'APP.SETTINGS.SITE_VISIBILITY_PRIVATE' | translate }}</mat-option>
                    <mat-option value="PUBLIC">{{ 'APP.SETTINGS.SITE_VISIBILITY_PUBLIC' | translate }}</mat-option>
                    <mat-option value="MODERATED">{{ 'APP.SETTINGS.SITE_VISIBILITY_MODERATED' | translate }}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
    <span class="adf-fill-remaining-space"></span>

    <button
        mat-button
        id="adf-folder-cancel-button"
        (click)="closeDialog()">
        {{ 'CORE.FOLDER_DIALOG.CANCEL_BUTTON.LABEL' | translate }}
    </button>

    <button class="adf-dialog-action-button"
            id="adf-folder-create-button"
            mat-button
            (click)="submit()"
            [disabled]="!form.valid">
        {{
        (editing
        ? 'CORE.FOLDER_DIALOG.UPDATE_BUTTON.LABEL'
        : 'CORE.FOLDER_DIALOG.CREATE_BUTTON.LABEL'
        ) | translate
        }}
    </button>
</mat-dialog-actions>
