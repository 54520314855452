<h2 mat-dialog-title>
  {{ 'APP.SETTINGS.USER_GROUPS' | translate }} {{ userDisplayName }}
</h2>

<mat-dialog-content [style]="matDialogContentStyle()">
  <div *ngIf="mode === 0" class="mode0-container">
    <mat-spinner *ngIf="loading" [diameter]="60" style="margin: 0 auto;"></mat-spinner>
    <span class="no-users" *ngIf="!loading && (!userGroupsRows || userGroupsRows.length === 0)">{{ 'APP.SETTINGS.NO_GROUPS' | translate }}</span>
    <div *ngIf="!loading && userGroupsRows && userGroupsRows.length > 0" class="datatable-container">
      <adf-datatable
        [rows]="userGroupsRows" 
        [columns]="userGroupsSchema"
        [actions]="true"
        [stickyHeader]="false"
        (showRowActionsMenu)="onUserGroupsShowRowActionsMenu($event)"
        (executeRowAction)="onUserGroupsExecuteRowAction($event)"> 
      </adf-datatable>
    </div>
    <div *ngIf="!loading" class="intra-dialog-buttons-left">
      <button mat-button (click)="onRefreshList()">
        <mat-icon class="mat-icon-custom-color">refresh</mat-icon>
      </button>
    </div>
    <div *ngIf="!loading" class="intra-dialog-buttons">
      <button mat-button (click)="onAddUserGroup()">
        {{ 'APP.SETTINGS.ADD_USER_GROUP' | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="mode === 1" class="mode1-container">
    <span *ngIf="!userAddGroupsRowsAll || userAddGroupsRowsAll.length === 0">{{ 'APP.SETTINGS.NO_GROUPS_LEFT_TO_ADD' | translate }}</span>
    <mat-form-field>
      <input
        matInput
        (keyup)="applyUserAddGroupsFilter()"
        [(ngModel)]="filterUserAddGroups"
        [placeholder]="filterPlaceholder" />
    </mat-form-field>
    <div *ngIf="userAddGroupsRows && userAddGroupsRows.length > 0" class="datatable-container">
      <adf-datatable
        [rows]="userAddGroupsRows" 
        [columns]="userAddGroupsSchema"
        [actions]="true"
        [stickyHeader]="false"
        (showRowActionsMenu)="onUserGroupsAddShowRowActionsMenu($event)"
        (executeRowAction)="onUserGroupsAddExecuteRowAction($event)"> 
      </adf-datatable>
    </div>
    <div class="intra-dialog-buttons">
      <button mat-button (click)="onViewUserGroups()">
        {{ 'APP.ACTIONS.VIEW_GROUPS' | translate }}
      </button>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="adf-dialog-buttons">
  <span class="adf-fill-remaining-space"></span>
  <button
      mat-button
      id="adf-folder-cancel-button"
      mat-dialog-close>
      {{ 'APP.ACTIONS.CLOSE' | translate }}
  </button>
</mat-dialog-actions>
