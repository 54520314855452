/*!
 * @license
 * Alfresco Example Content Application
 *
 * Copyright (C) 2005 - 2020 Alfresco Software Limited
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail.  Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with Alfresco. If not, see <http://www.gnu.org/licenses/>.
 */

import { ArchiveService } from '@alfresco/aca-shared';
import { AppStore, SnackbarErrorAction, SnackbarInfoAction } from '@alfresco/aca-shared/store';
import { TranslationService } from '@alfresco/adf-core';
import { Node } from '@alfresco/js-api';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatButtonModule,
  ],
  templateUrl: './change-sender.dialog.html',
  styleUrls: ['./change-sender.dialog.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'aca-change-sender-dialog' }
})
export class ChangeSenderDialogComponent implements OnInit {

  node: Node;

  changeForm: FormGroup;
  komitenti: any[] = [];
  hasKomitenti: boolean = false;
  komitentiFiltered: Observable<any[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private store: Store<AppStore>,
    private archiveService: ArchiveService,
    private translation: TranslationService,
    private dialogRef: MatDialogRef<ChangeSenderDialogComponent>
  ) {
    this.node = data.node.entry;

    this.changeForm = new FormGroup({
      senderName: new FormControl('', this.requiredIfNotKomitenti()),
      senderValue: new FormControl('', this.requiredIfKomitenti()),
    }); 
  }

  get senderName() {
    return this.changeForm.get('senderName');
  }

  get senderValue() {
    return this.changeForm.get('senderValue');
  }

  async ngOnInit() {
    let currentSender: string = '', currentSenderId: string = '';
    const promise1 = this.archiveService.getDocumentProperties(`workspace://SpacesStore/${this.node.id}`)
      .then(docProperties => {
        currentSender = docProperties['sender'];
        currentSenderId = docProperties['senderId'];
      })
      .catch(_ => {
        this.store.dispatch(
          new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
      });
    
    const promise2 = this.loadKomitenti();

    await Promise.all([promise1, promise2]);

    if (this.hasKomitenti) {
      const element = this.komitenti.find(komitent => komitent['id'] === currentSenderId);
      this.senderValue.setValue(element ? element['id'] : undefined);
    } else {
      this.senderName.setValue(currentSender);
    }
  }

  async changeSender() {
    if (this.changeForm.invalid)
      return;

    try {
      const requestBody = { ...this.changeForm.value };

      let sender: string = '', senderId: string = '';
      if (this.hasKomitenti) {
        const selectedKomitent = this.komitenti.find(komitent => komitent['id'] === requestBody.senderValue);
        sender = selectedKomitent['name'];
        senderId = selectedKomitent['id'];
      } else {
        sender = requestBody.senderName;
      }
      await this.archiveService.changeSender(
        `workspace://SpacesStore/${this.node.id}`,
        sender,
        senderId,
      );

      this.store.dispatch(
        new SnackbarInfoAction(this.translation.instant('APP.ARCHIVE.CHANGE_SENDER.SUCCESS')));
      this.dialogRef.close(true);
      window.location.reload();
    } catch (e) {
      this.store.dispatch(
        new SnackbarErrorAction(this.translation.instant('CORE.MESSAGES.ERRORS.GENERIC')));
    }
  }

  private requiredIfKomitenti(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      return this.hasKomitenti && !control.value ? { required: false } : null;
    };
  }

  private requiredIfNotKomitenti(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      return !this.hasKomitenti && !control.value ? { required: false } : null;
    };
  }

  private async loadKomitenti() {
    const result = await this.archiveService.getKomitenti();
    this.komitenti = result.komitenti;
    this.hasKomitenti = this.komitenti.length > 0;
    this.komitentiFiltered = this.changeForm.get('senderValue').valueChanges.pipe(
      startWith(''),
      map(value => this._filterKomitenti(value || '')),
    );
  }

  private _filterKomitenti(value: string): object[] {
    if (this.komitenti.length === 0)
      return [];
    const filterValue = value.toLowerCase();
    return this.komitenti.filter(komitent => komitent['name'].toLowerCase().includes(filterValue) || komitent['id'].toLowerCase().includes(filterValue));
  }
}
