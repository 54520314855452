<header
    mat-dialog-title
    data-automation-id="login-dialog-title">
        {{data?.title}}
</header>

<mat-dialog-content class="adf-login-dialog-content">
    <adf-login-dialog-panel #adfLoginPanel (success)="onLoginSuccess($event)">
    </adf-login-dialog-panel>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
        mat-button (click)="close()" 
        data-automation-id="login-dialog-actions-cancel">
            {{ 'LOGIN.DIALOG.CANCEL' | translate }}
    </button>

    <button
        mat-button
        class="choose-action"
        data-automation-id="login-dialog-actions-perform"
        [disabled]="!isFormValid()"
        (click)="submitForm()">
            {{ buttonActionName | translate}}
    </button>
</mat-dialog-actions>
