<div class="adf-content-node-selector-content">
    <mat-form-field floatPlaceholder="never" class="adf-content-node-selector-content-input" *ngIf="showSearch">
        <input matInput
            id="searchInput"
            [formControl]="searchInput"
            type="text"
            placeholder="{{'NODE_SELECTOR.SEARCH' | translate}}"
            [value]="searchTerm"
            adf-auto-focus
            data-automation-id="content-node-selector-search-input">

        <mat-icon *ngIf="searchTerm.length > 0"
            matSuffix (click)="clear()"
            class="adf-content-node-selector-content-input-icon"
            data-automation-id="content-node-selector-search-clear">clear
        </mat-icon>

        <mat-icon *ngIf="searchTerm.length === 0"
            matSuffix
            class="adf-content-node-selector-content-input-icon"
            data-automation-id="content-node-selector-search-icon">search
        </mat-icon>

    </mat-form-field>
    <adf-sites-dropdown
        *ngIf="showDropdownSiteList"
        class="full-width"
        (change)="siteChanged($event)"
        [placeholder]="'NODE_SELECTOR.SELECT_LIBRARY'"
        [hideMyFiles]="dropdownHideMyFiles"
        [siteList]="dropdownSiteList"
        [value]="startSiteGuid"
        data-automation-id="content-node-selector-sites-combo">
    </adf-sites-dropdown>
    <button *ngIf="hasCustomModels()"
        data-automation-id="adf-toggle-search-panel-button"
        mat-icon-button
        (click)="toggleSearchPanel()">
        <mat-icon>filter_list</mat-icon>
        {{ 'SEARCH.SEARCH_HEADER.TITLE' | translate }}
    </button>
    <div class="adf-content-node-selector-search-panel-container">
        <adf-search-panel *ngIf="searchPanelExpanded">
        </adf-search-panel>
        <div class="adf-content-node-selector-document-list-container">
    <adf-toolbar>
        <adf-toolbar-title>
            <ng-container *ngIf="!showBreadcrumbs()">
                <h2 class="adf-search-results-label">{{ 'NODE_SELECTOR.SEARCH_RESULTS' | translate }}</h2>
            </ng-container>
            <adf-dropdown-breadcrumb *ngIf="showBreadcrumbs()"
                class="adf-content-node-selector-content-breadcrumb"
                (navigate)="clearSearch()"
                [target]="documentList"
                [rootId]="breadcrumbRootId"
                [transform]="breadcrumbTransform"
                [folderNode]="breadcrumbFolderNode"
                [root]="breadcrumbFolderTitle"
                data-automation-id="content-node-selector-content-breadcrumb">
            </adf-dropdown-breadcrumb>
            <ng-container *ngIf="showNodeCounter" [adf-node-counter]="getSelectedCount()"></ng-container>
        </adf-toolbar-title>
    </adf-toolbar>

    <div
        class="adf-content-node-selector-content-list"
        [class.adf-content-node-selector-content-list-searchLayout]="showingSearchResults"
        data-automation-id="content-node-selector-content-list">
        <adf-document-list
            #documentList
            [adf-highlight]="searchTerm"
            adf-highlight-selector=".adf-name-location-cell-name"
            [showHeader]="showHeader"
            [node]="nodePaging"
            [preselectNodes]="preselectedNodes"
            [maxItems]="pageSize"
            [rowFilter]="_rowFilter"
            [imageResolver]="imageResolver"
            [currentFolderId]="folderIdToShow"
            [selectionMode]="selectionMode"
            [contextMenuActions]="false"
            [contentActions]="false"
            [allowDropFiles]="false"
            [sorting]="sorting"
            sortingMode="server"
            [where]="where"
            (folderChange)="onFolderChange($event)"
            (ready)="onFolderLoaded($event)"
            (nodeSelected)="onCurrentSelection($event)"
            [class.adf-content-node-selector-content-list-empty]="emptyList"
            data-automation-id="content-node-selector-document-list">

            <adf-custom-empty-content-template>
                <div aria-live="polite">{{ 'NODE_SELECTOR.NO_RESULTS' | translate }}</div>
            </adf-custom-empty-content-template>

            <data-columns>
                <data-column key="$thumbnail" type="image"></data-column>
                <data-column key="name" type="text" title="ADF-DOCUMENT-LIST.LAYOUT.NAME" class="adf-full-width adf-ellipsis-cell">
                    <ng-template let-context>
                        <adf-name-location-cell [row]="context.row"></adf-name-location-cell>
                    </ng-template>
                </data-column>
                <data-column key="modifiedAt" type="date" title="ADF-DOCUMENT-LIST.LAYOUT.MODIFIED_ON" format="timeAgo" class="adf-content-selector-modified-cell"></data-column>
                <data-column key="createdByUser.displayName" type="text" title="ADF-DOCUMENT-LIST.LAYOUT.CREATED_BY" class="adf-content-selector-modifier-cell"></data-column>
                <data-column key="visibility" type="text" title="ADF-DOCUMENT-LIST.LAYOUT.STATUS" class="adf-content-selector-visibility-cell"></data-column>
            </data-columns>

        </adf-document-list>

        <adf-infinite-pagination
            [target]="target"
            [loading]="loadingSearchResults"
            (loadMore)="getNextPageOfSearch($event)"
            data-automation-id="content-node-selector-search-pagination">
            {{ 'ADF-DOCUMENT-LIST.LAYOUT.LOAD_MORE' | translate }}
        </adf-infinite-pagination>
    </div>
        </div>
    </div>
</div>
